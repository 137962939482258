import OfficeListAPI from "../serviceAPI/OfficeListAPI";
import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { DecryptData, EncryptData } from "../util/DataEncryption";

const initialState = {
  OfficeListData: [],
  selectedOfficeId: sessionStorage.getItem('officeId')
    ? DecryptData(sessionStorage.getItem('officeId'))
    : null,
  isLoading: false,
  errorCode: null,
  errorMessage: null,
};

export const getAllOffices = createAsyncThunk(
  "getAllOffices",
  async (anyNameOrEven_, { rejectWithValue }) => {
    try {
      const res = await OfficeListAPI.GetAllOffices();

      return res;
    } catch (error) {
      return rejectWithValue(error.response.status);
    }
  }
);

export const createNewOffice = createAsyncThunk(
  "createNewOffice",
  async (newData, { rejectWithValue }) => {
    try {
      const res = await OfficeListAPI.CreateNewOffice(newData);
      return res;
    } catch (error) {
      return rejectWithValue(error.response);
    }
  }
);

export const updateOffices = createAsyncThunk(
  "updateOffices",
  async ({ newData, id }, { rejectWithValue }) => {
    try {
      const res = await OfficeListAPI.UpdateOffices(newData, id);
      return res;
    } catch (error) {
      return rejectWithValue(error.response);
    }
  }
);

export const deleteOffice = createAsyncThunk(
  "deleteOffice",
  async (id, { rejectWithValue }) => {
    try {
      const res = await OfficeListAPI.DeleteOffice(id);
      return res;
    } catch (error) {
      return rejectWithValue(error.response.status);
    }
  }
);

const OfficeListSlice = createSlice({
  name: "officelist",
  initialState,
  reducers: {
    resetErrorAlert: (state, action) => {
      state.errorCode = null;
      state.errorMessage = null;
    },
    updateOfficeId: (state, action) => {
      sessionStorage.setItem("officeId", EncryptData(action.payload.id));
      state.selectedOfficeId = action.payload.id;
    },
  },
  extraReducers: {
    [getAllOffices.pending]: (state, action) => {
      state.isLoading = true;
    },
    [getAllOffices.fulfilled]: (state, action) => {
      state.isLoading = false;
      state.OfficeListData = [...action.payload];
    },
    [getAllOffices.rejected]: (state, action) => {
      state.isLoading = false;
      state.errorCode = action.payload;
    },
    [createNewOffice.fulfilled]: (state, action) => {
      state.OfficeListData.push(action.payload);
    },
    [createNewOffice.rejected]: (state, action) => {
      state.errorMessage = action.payload.data.message;
      state.errorCode = action.payload.status;
    },
    [updateOffices.fulfilled]: (state, action) => {
      state.OfficeListData = state.OfficeListData.map((officelist) =>
        officelist._id === action.payload._id ? action.payload : officelist
      );
    },
    [updateOffices.rejected]: (state, action) => {
      state.errorMessage = action.payload.data.message;
      state.errorCode = action.payload.status;
    },
    [deleteOffice.fulfilled]: (state, action) => {
      const id = action.payload._id;
      const index = state.OfficeListData.map((item) => item._id).indexOf(id);
      state.OfficeListData.splice(index, 1);
    },
    [deleteOffice.rejected]: (state, action) => {
      state.errorCode = action.payload;
    },
  },
});

export const { resetErrorAlert, updateOfficeId } =
  OfficeListSlice.actions;
export default OfficeListSlice.reducer;
