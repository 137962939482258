import "../../../styles/screens/client-visit-details/ProfileCard.css";
import "../../../styles/screens/client-visit-details/Feedback.css";
import { useState } from "react";
import { useDispatch } from "react-redux";
import ReactStars from "react-rating-star-with-type";

function UserFeedbackCard({ dataItem, onCloseHandle }) {
  const [showDelete, setShowDelete] = useState(false);
  const [showForm, setShowForm] = useState(false);
  const dispatch = useDispatch();

  const [star, setStar] = useState(5);

  const onChange = (nextValue) => {
    setStar(nextValue);
  };

  return (
    <div className='feedback-card-container'>
      <div className='profile-container'>
        <div className='profile'>
          <div className='profile-logo-detail'>
            <div className='profile-detail'>
              <div className='profile-details-names'>{dataItem.userName}</div>
              <div className='profile-details-email'>{dataItem.email}</div>
              {dataItem.visitName && dataItem.region ? (
                <div className='profile-details-visit-name'>{dataItem.visitName}({dataItem.region})</div>
              ) : null}             
            </div>
          </div>
          {/* <div className="profile-details">
              <div className="profile-details-name">{dataItem._id}</div>
              <div className="profile-details-designation speaker-style">
                {data.designation}
              </div>
              <div className="profile-details-company">{dataItem.email} </div>
            </div> */}
        </div>
      </div>
      <div className='line'></div>
      <div className='profile-description'>
        <ReactStars
          count={5} // Number of stars to display
          value={dataItem.rating}
          edit={true}
          activeColor='#FFB600'
          inactiveColor='#BFBFBF'
          size='25px'
        />
        {/* <Rating name="read-only" size="large" value={dataItem.rating} readOnly /> */}
        <div className='profile-message-body'>{dataItem.message}</div>
        <div
          style={{
            overflow: "hidden",
            textOverflow: "ellipsis",
            display: "-webkit-box",
            lineClamp: 5,
            WebkitLineClamp: 5,
            WebkitBoxOrient: "vertical",
            fontSize: 14,
            fontWeight: 400,
            color: "#818181",
          }}
        ></div>
      </div>
    </div>
  );
}

export default UserFeedbackCard;
