import { useState } from 'react';
import '../../../styles/screens/client-visit-screens/CreateNewVisitForm.css';
import Required from '../../common-components/Required';
import { reverseLogicForParsing } from '../../../util/DateFormatter';
import BlackButton from '../../custom-components/BlackButton';
import CancelButton from '../../custom-components/CancelButton';
import CloseButton from '../../custom-components/CloseButton';
import { ClientVisitScreenStringLabels } from '../../../util/Strings';
import { NewVisitFormValidations } from '../../../util/Validations';
import { GetDate } from '../../../util/FilterDate';
import ErrorMessageComponent from '../../common-components/ErrorMessageComponent';
import { useDispatch, useSelector } from 'react-redux';
import useFileValidation from '../../../util/useFileValidation';
import {
  fileDelete,
  fileUpload,
  previewImage,
  progressPercentage,
  setSelectedFile,
} from '../../../slices/FileUploadSlice';
import {
  setPreThumbnailId,
  setPrevThumbnailDetails,
} from '../../../slices/VisitSlice';
import ErrorAlert from '../../common-components/ErrorAlert';
import ErrorMessages from '../../../util/ErrorMessages';
import FileUploader from '../../common-components/FileUploader';
import ImagePreviewWithFilename from '../../common-components/ImagePreviewWithFilename';

function CreateNewVisitForm({ show, onClickNext, data }) {
  const dispatch = useDispatch();
  const [state, setState] = useState({
    name: data && data.name ? data.name : '',
    startDate:
      data && data.startDate ? data.startDate : '',
    endDate: data && data.endDate ? data.endDate : '',
    region: data && data.region ? data.region : 'Bangalore AC',
    companyName: data && data.companyName ? data.companyName : '',
    companyLogoId: data && data.companyLogoId ? data.companyLogoId : null,
  });
  const [prevFileData, setPreFileData] = useState({
    photoUrl: data && data.companyLogoUrl ? data.companyLogoUrl : null,
    fileName: data && data.fileName ? data.fileName : null,
  });
  const [prevFileId, setPrevFileId] = useState(
    data && data.companyLogoId ? data.companyLogoId : null
  );
  const [photoUrl, setPhotoUrl] = useState(null);
  const prevImageId = useSelector((state) => state.visits.prevThumbnailId);
  const [validation, setValidation] = useState({});
  const { errorSingleMessage, setErrorSingleMessage, validateFile } =
    useFileValidation();
  const [imageDeleteList, setImageDeleteList] = useState([]);
  function handleChange(e) {
    const value = e.target.value;
    setState({
      ...state,
      [e.target.name]: value,
    });
  }
  const resetPrevFileData = useSelector(
    (state) => state.visits.prevThumbnailDetails
  );

  const errorMessageCode = useSelector((state) => state.upload.errorCode);

  function handleImageCancel() {
    setState({
      ...state,
      ['companyLogoId']: null,
    });
    prevFileData.photoUrl = null;
    setImageDeleteList([...imageDeleteList, prevFileId]);
  }

  function handleCloseErrorAlert() {
    setPreFileData(resetPrevFileData);
    handleState();
    dispatch(setSelectedFile(null));
    setImageDeleteList([]);
    onClickNext();
  }

  function resetImage() {
    dispatch(progressPercentage(0));
    dispatch(setSelectedFile(null));
    dispatch(previewImage(null));
  }

  function handleState() {
    setState({ ...state, ['companyLogoId']: data && data.companyLogoId });
  }

  const checkValidation = () => {
    const { errors, isValid } = NewVisitFormValidations(state);
    setValidation(errors);
    return isValid;
  };

  function handleNextButton() {
    if (checkValidation()) {
      if (state.name && state.startDate && state.endDate && state.region) {
        resetImage();
        if (data) {
          imageDeleteList &&
            imageDeleteList.map((image) => {
              dispatch(fileDelete({ id: image }));
            });
          setPhotoUrl(null);
          onClickNext(state);

          // if (state.photoId !== prevImageId && prevImageId !== '') {
          //   const id = { id: prevImageId, isVideo: false };
          //   dispatch(fileDelete(id));
          //   dispatch(setPreThumbnailId(''));
          // }

          setPreFileData({
            photoUrl: data && data.companyLogoUrl ? data.companyLogoUrl : null,
            fileName: data && data.fileName ? data.fileName : null,
          });
          setErrorSingleMessage('');
          setImageDeleteList([]);
        } else {
          setState({});
          setPhotoUrl(null);
          setImageDeleteList([]);
          onClickNext(state);
        }
      } else {
        onClickNext();
      }
    }
  }

  if (!show) {
    return null;
  }
  const handleFileSelect = function (event) {
    const files = event.target.files;
    postFileData(files);
  };

  const handleDrag = function (event) {
    event.preventDefault();
  };

  const handleDrop = function (event) {
    event.preventDefault();
    const files = event.dataTransfer.files;
    postFileData(files);
  };

  function postFileData(files) {
    const valid = validateFile(files);
    if (valid) {
      const file = files[0];
      if (prevFileData) {
        dispatch(setPreThumbnailId(prevFileId));
        setPrevFileId(prevFileId);
        dispatch(setPrevThumbnailDetails(prevFileData));
        setPreFileData({ photoUrl: null, fileName: null });
      }
      if (file) {
        dispatch(setSelectedFile(file));
        dispatch(fileUpload(file))
          .unwrap()
          .then((res) => {
            setPhotoUrl(res[0]._id);
            setState({ ...state, ['companyLogoId']: res[0]._id });
            setPrevFileId(res[0]._id);
          });
      }
    }
  }

  if (errorMessageCode) {
    return (
      <ErrorAlert
        show={errorMessageCode}
        onClickHandle={handleCloseErrorAlert}
        title="Error"
        subTitle={ErrorMessages(errorMessageCode)}
      />
    );
  }
  return (
    <div className="modal">
      <div className="form-scroll">
        <div className="form-flex">
          <div className="modal-content" onClick={(e) => e.stopPropagation()}>
            <div className="form-header">
              <div className="header">
                {data ? 'Update Visit' : 'Create New Visit'}
              </div>
              <CloseButton
                onClickhandle={() => {
                  if (data === null) {
                    setState({});
                    setValidation({});
                  }
                  setImageDeleteList([]);
                  onClickNext();
                }}
              />
            </div>

            <div className="form-container">
              <div className="form-label-input">
                <label className="form-label">
                  {ClientVisitScreenStringLabels.name}
                  <Required />
                </label>
                <div className="form-input-container">
                  <input
                    type="text"
                    value={state.name}
                    name="name"
                    onChange={(e) => handleChange(e)}
                    className="form-input"
                    placeholder="Input"
                    required={true}
                  />
                </div>
                <ErrorMessageComponent message={validation.name} />
              </div>

              <div className="form-label-input">
                <label className="form-label">
                  {ClientVisitScreenStringLabels.startDate}
                  <Required />
                </label>
                <div className="form-input-container">
                  <input
                    className="form-input"
                    type="date"
                    name="startDate"
                    value={state.startDate}
                    onChange={(e) => handleChange(e)}
                    placeholder="Input"
                    required={true}
                    min={GetDate()}
                  />
                </div>
                <ErrorMessageComponent message={validation.startDate} />
              </div>

              <div className="form-label-input">
                <label className="form-label">
                  {ClientVisitScreenStringLabels.endDate}
                  <Required />
                </label>
                <div className="form-input-container">
                  <input
                    className="form-input"
                    type="date"
                    name="endDate"
                    value={state.endDate}
                    onChange={(e) => handleChange(e)}
                    placeholder="Input"
                    required={true}
                    min={GetDate()}
                  />
                </div>
                <ErrorMessageComponent message={validation.endDate} />
              </div>

              <div className="form-label-input">
                <label className="form-label">
                  {ClientVisitScreenStringLabels.region}
                  <Required />
                </label>
                <div className="form-input-container">
                  <input
                    className="form-input"
                    type="text"
                    name="region"
                    value={state.region}
                    onChange={(e) => handleChange(e)}
                    placeholder="Input"
                    required={true}
                  />
                </div>
                <ErrorMessageComponent message={validation.region} />
              </div>

              <div className="form-label-input">
                <label className="form-label">
                  {ClientVisitScreenStringLabels.companyName}
                </label>
                <div className="form-input-container">
                  <input
                    type="text"
                    value={state.companyName}
                    name="companyName"
                    onChange={(e) => handleChange(e)}
                    className="form-input"
                    placeholder="Input"
                    // required={true}
                  ></input>
                </div>
                <ErrorMessageComponent message={validation.companyName} />
              </div>

              <FileUploader
                previewShape="circular"
                headerName="Company Logo Image"
                handleDragOver={handleDrag}
                handleDrop={handleDrop}
                handleFileSelect={handleFileSelect}
                setPreFileData={setPreFileData}
                handleState={handleState}
                type="visit"
              />
              {errorSingleMessage !== '' && (
                <p className="error image-error">{errorSingleMessage}</p>
              )}
              {prevFileData &&
              prevFileData.photoUrl &&
              data &&
              data.companyLogoUrl &&
              data.fileName ? (
                <ImagePreviewWithFilename
                  photoUrl={data.companyLogoUrl}
                  fileName={data.fileName}
                  previewShape="circular"
                  handleCancel={handleImageCancel}
                />
              ) : null}

              <div className="footer-button-container">
                <CancelButton
                  onClickhandle={() => {
                    if (data === null) {
                      setState({});
                      setValidation({});
                    }
                    setImageDeleteList([]);
                    onClickNext();
                  }}
                >
                  {ClientVisitScreenStringLabels.cancel}
                </CancelButton>
                <BlackButton onClickhandle={() => handleNextButton()}>
                  {data
                    ? ClientVisitScreenStringLabels.update
                    : ClientVisitScreenStringLabels.create}
                </BlackButton>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default CreateNewVisitForm;
