import SubAdminAPI from '../serviceAPI/SubAdminAPI';
import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';

const initialState = {
  subAdminListData: [],
  isLoading: false,
  errorCode: null,
  errorMessage: null,
};

export const getAllSubAdmins = createAsyncThunk(
  'getAllSubAdmins',
  async (anyNameOrEven_, { rejectWithValue }) => {
    try {
      const res = await SubAdminAPI.GetAllSubAdmins();
      return res;
    } catch (error) {
      return rejectWithValue(error.response.status);
    }
  }
);

export const createNewSubAdmin = createAsyncThunk(
  'createNewSubAdmin',
  async (newData, { rejectWithValue }) => {
    try {
      const res = await SubAdminAPI.CreateNewSubAdmin(newData);
      return res;
    } catch (error) {
      return rejectWithValue(error.response);
    }
  }
);

export const updateSubAdmin = createAsyncThunk(
  'updateSubAdmin',
  async ({ newData, id }, { rejectWithValue }) => {
    try {
      const res = await SubAdminAPI.UpdateSubAdmin(newData, id);
      return res;
    } catch (error) {
      return rejectWithValue(error.response);
    }
  }
);

export const deleteSubAdmin = createAsyncThunk(
  'deleteSubAdmin',
  async (id, { rejectWithValue }) => {
    try {
      const res = await SubAdminAPI.DeleteSubAdmin(id);
      return res;
    } catch (error) {
      return rejectWithValue(error.response.status);
    }
  }
);

const SubAdminSlice = createSlice({
  name: 'subAdmin',
  initialState,
  reducers: {
    resetErrorAlert: (state, action) => {
      state.errorCode = null;
      state.errorMessage = null;
    },
  },
  extraReducers: {
    [getAllSubAdmins.pending]: (state, action) => {
      state.isLoading = true;
    },
    [getAllSubAdmins.fulfilled]: (state, action) => {
      state.isLoading = false;
      state.subAdminListData = [...action.payload];
    },
    [getAllSubAdmins.rejected]: (state, action) => {
      state.isLoading = false;
      state.errorCode = action.payload;
    },
    [createNewSubAdmin.fulfilled]: (state, action) => {
      state.subAdminListData.push(action.payload);
    },
    [createNewSubAdmin.rejected]: (state, action) => {
      state.errorMessage = action.payload.data.message
      state.errorCode = action.payload.status;
    },
    [updateSubAdmin.fulfilled]: (state, action) => {
      state.subAdminListData = state.subAdminListData.map((subAdmin) =>
        subAdmin._id === action.payload._id ? action.payload : subAdmin
      );
    },
    [updateSubAdmin.rejected]: (state, action) => {
      state.errorMessage = action.payload.data.message
      state.errorCode = action.payload.status;
    },
    [deleteSubAdmin.fulfilled]: (state, action) => {
      const id = action.payload._id;
      const index = state.subAdminListData.map((item) => item._id).indexOf(id);
      state.subAdminListData.splice(index, 1);
    },
    [deleteSubAdmin.rejected]: (state, action) => {
      state.errorCode = action.payload;
    },
  },
});

export const { resetErrorAlert } = SubAdminSlice.actions;
export default SubAdminSlice.reducer;
