import { useDispatch, useSelector } from "react-redux";
import "../../../../styles/screens/client-visit-details/manage-module/SpeakersList.css";
import noTitanOfIndustry from "../../../../assets/no-titan-of-industry.svg";
import Loader from "../../../common-components/Loader";
import { useEffect, useState } from "react";
import {
  getAllSpeakers,
  getAllSpeakersOfVisit,
  resetErrorAlert,
} from "../../../../slices/SpeakerSlice";
import { TitansOfIndustryStringLabels } from "../../../../util/Strings";
import ErrorAlert from "../../../common-components/ErrorAlert";
import ErrorMessages from "../../../../util/ErrorMessages";
import BlackButton from "../../../custom-components/BlackButton";
import VisitSpeakerCard from "../VisitSpeakerCard";
import SpeakerCard from "../SpeakerCard";
import {
  updateVisit,
  updateVisitSpeakers,
} from "../../../../slices/VisitSlice";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faMagnifyingGlass } from "@fortawesome/free-solid-svg-icons";
import DraggableList from "../../../common-components/DraggableList";

function VisitSpeakersList() {
  const data = useSelector((state) => state.speakers.speakerData);
  const visitSpeakerData = useSelector(
    (state) => state.speakers.visitSpeakerData
  );
  const updatedVisitSpeakerData = useSelector(
    (state) => state.visits.updatedVisitData
  );
  const isLoading = useSelector((state) => state.speakers.isLoading);
  const id = useSelector((state) => state.visits.selectedVisitId);
  const [showModal, setShowModal] = useState(false);
  const [showSorting, setSorting] = useState(false);
  const [preSelctedIds, setPreSelectedIds] = useState([]);
  const selectedOfficeId = useSelector(
    (state) => state.officelist.selectedOfficeId
  );
  const errorMessageCode = useSelector((state) => state.speakers.errorCode);
  const dispatch = useDispatch();
  const [modalInput, setModalInput] = useState("");
  const [updatedSequence, setUpdatedSequence] = useState(visitSpeakerData);

  function getSpeakersData() {
    dispatch(getAllSpeakers(selectedOfficeId));
  }

  function getVisitSpeakersData() {
    dispatch(getAllSpeakersOfVisit(id));
  }

  function handleCloseErrorAlert() {
    dispatch(resetErrorAlert(false));
  }

  useEffect(() => {
    getSpeakersData();
    getVisitSpeakersData();
  }, []);

  useEffect(() => {
    getVisitSpeakersData();
  }, [updatedVisitSpeakerData]);

  useEffect(() => {
    let preIds = visitSpeakerData.map((item) => item._id.toString());
    setPreSelectedIds(preIds);
    console.log("ids", preIds, preSelctedIds);
    setUpdatedSequence(visitSpeakerData);
  }, [visitSpeakerData]);

  if (isLoading) {
    return <Loader />;
  }
  if (errorMessageCode) {
    return (
      <ErrorAlert
        show={errorMessageCode}
        onClickHandle={handleCloseErrorAlert}
        title="Error"
        subTitle={ErrorMessages(errorMessageCode)}
      />
    );
  }

  const onUpdateHandler = () => {
    if (showSorting) {
      let sequence = updatedSequence.map((item) => item._id);
      sequence.unshift('Sequence');
      dispatch(updateVisitSpeakers({ id: id, speakers: sequence }));
    }
    setSorting(!showSorting);
  }

  const reorder = (list, startIndex, endIndex) => {
    const result = Array.from(list);
    const [removed] = result.splice(startIndex, 1);
    result.splice(endIndex, 0, removed);
    return result;
  };

  const handleListReorder = (result) => {
    if (!result.destination) {
      return;
    }

    const items = reorder(
      updatedSequence,
      result.source.index,
      result.destination.index
    );
    setUpdatedSequence(items);
  };

  const onAddHandler = () => {
    setShowModal(true);
  };

  const onSelectHandler = (speakerId) => {
    let selectedIds = [...preSelctedIds];
    const index = selectedIds.indexOf(speakerId);
    if (index > -1) {
      selectedIds.splice(index, 1);
    } else {
      selectedIds.push(speakerId);
    }
    setPreSelectedIds(selectedIds);
  };

  const checkIsSelected = (speakerId) => {
    let index = preSelctedIds.indexOf(speakerId);
    return index > -1;
  };

  const onSubmitHandler = () => {
    setShowModal(false);
    let newData = { id: id, speakers: preSelctedIds };
    dispatch(updateVisitSpeakers(newData));
  };

  return (
    <div className="speakers-list-container">
      <div className="speakers-list-heading">
        {/* Speakers List */}
        {TitansOfIndustryStringLabels.titansOfIndustry}

        <div className="speaker-add-button">
          {visitSpeakerData?.length != 0 && <BlackButton onClickhandle={onUpdateHandler} style={{ width: "80px" }}>
            {!showSorting ? 'Update Order' : 'Submit'}
          </BlackButton>}
          {!showSorting && <BlackButton onClickhandle={onAddHandler} style={{ width: "80px" }}>
            Add
          </BlackButton>}
        </div>
        {!showSorting && <div className="speakers-list-body-modal">
          {visitSpeakerData &&
            visitSpeakerData
              .filter((dataItem) => {
                return modalInput.toLowerCase() === ""
                  ? dataItem
                  : dataItem.name.toLowerCase().includes(modalInput);
              })
              .map((item) => {
                return (
                  <SpeakerCard data={item} key={item._id} readOnly={true} />
                );
              })}
        </div>}
        <div className="draggable-list">
          {showSorting && updatedSequence.length > 0 && (
            <DraggableList
              onDragEnd={handleListReorder}
              dataItems={updatedSequence}
            />
          )}
        </div>
        {data.length === 0 && (
          <img
            src={noTitanOfIndustry}
            alt="no titan of industry"
            className="no-img"
          />
        )}
      </div>
      {showModal && (
        <div className="speaker-modal">
          <div className="speaker-form-scroll">
            <div className="speaker-form-flex">
              <div className="speaker-modal-content">
                <div className="modal-speaker-searchContainer">
                  <div className="speaker-searchIconText">
                    <FontAwesomeIcon
                      size="2xs"
                      icon={faMagnifyingGlass}
                      color="#D1D1D1"
                    />
                    <input
                      className="search"
                      icon="search"
                      placeholder="Search"
                      value={modalInput}
                      onChange={(e) => setModalInput(e.target.value)}
                    ></input>
                  </div>
                </div>
                <div className="speaker-submit-button">
                  <BlackButton onClickhandle={onSubmitHandler}>
                    Submit
                  </BlackButton>
                </div>
                <div className="speakers-list-body-modal">
                  {data &&
                    data
                      .filter((dataItem) => {
                        return modalInput.toLowerCase() === ""
                          ? dataItem
                          : dataItem.name.toLowerCase().includes(modalInput);
                      })
                      .map((item) => {
                        return (
                          <VisitSpeakerCard
                            data={item}
                            key={item._id}
                            onSelectHandler={onSelectHandler}
                            isSelected={checkIsSelected(item._id)}
                          />
                        );
                      })}
                </div>
                {data.length === 0 && (
                  <img
                    src={noTitanOfIndustry}
                    alt="no titan of industry"
                    className="no-img"
                  />
                )}
              </div>
            </div>
          </div>
        </div>
      )}
    </div>
  );
}

export default VisitSpeakersList;
