import http from '../http-common';
import { DecryptData } from '../util/DataEncryption';
import { reverseLogicForParsing } from '../util/DateFormatter';

const GetAllVisits = async (officeId) => {
  const tokenStr = sessionStorage.getItem('token')
    ? DecryptData(sessionStorage.getItem('token'))
    : null;
  // mutating the token so it is set properly
  http.defaults.headers.Authorization = `Bearer ${tokenStr}`;
  const visits = await http.get(`/visits/${officeId}`).then((response) => {
    return response.data;
  });

  return visits;
};

const CreateNewVisit = async (newData) => {
  const auth = await http
    .post('/createVisit', {
      name: newData.name,
      startDate: newData.startDate,
      endDate: newData.endDate,
      region: newData.region,
      status: 'ACTIVE',
      companyName: newData.companyName,
      companyLogoId: newData.companyLogoId,
      officeId: newData.officeId
    })
    .then((response) => {
      return response;
    });

  return auth.data;
};

const UpdateVisit = async (updateData, id) => {
  const updatedData = await http
    .put(`/updateVisit/${id}`, {
      name: updateData.name,
      startDate: updateData.startDate,
      endDate: updateData.endDate,
      region: updateData.region,
      status: 'ACTIVE',
      companyName: updateData.companyName,
      companyLogoId: updateData.companyLogoId,
    })
    .then((response) => {
      return response.data;
    });
  return updatedData;
};

const UpdateVisitSpeakers = async (newData, id) => {
  console.log(newData)
  const updatedData = await http
    .put(`/updateVisit/${id}`, {
      speakers: newData
    })
    .then((response) => {
      return response.data;
    });
  return updatedData;
};

const DeleteVisit = async (id) => {
  const deletedData = await http
    .delete(`/deleteVisit/${id}`)
    .then((response) => {
      return response.data;
    });
  return deletedData;
};

const VisitAPI = {
  GetAllVisits,
  CreateNewVisit,
  UpdateVisit,
  DeleteVisit,
  UpdateVisitSpeakers
};

export default VisitAPI;
