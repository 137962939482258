import { useState } from 'react';

const MAX_FILE_SIZE = 15000000;
const MAX_VIDEO_FILE_SIZE = 50000000;
const FILE_EXT_REGEX = /\.(jpg|jpeg|png)$/;
const FILE_EXT_REGEX_MATERIAL = /\.(jpg|jpeg|png|pdf|docx|xlsx|pptx)$/;
const FILE_EXT_REGEX_VIDEO = /\.(mp4|mov)$/;
const FILE_EXT_REGEX_AUDIO = /\.(mp3|wav)$/;

const useFileValidation = () => {
  const [errorSingleMessage, setErrorSingleMessage] = useState('');
  const [errorMultiMessage, setErrorMultiMessage] = useState('');
  const [errorVideoMessage, setErrorVideoMessage] = useState('');
  const [errorMaterialMessage, setErrorMaterialMessage] = useState('');
  const [errorAudioMessage, setErrorAudioMessage] = useState('');

  const validateAudio = (file) => {
    let isValid = true;
    let errorMessage = '';

    if (file.length > 1) {
      isValid = false;
      errorMessage = 'Only 1 audio file can be uploaded';
    }
    else if (file[0].size > MAX_VIDEO_FILE_SIZE) {
      isValid = false;
      errorMessage = 'Audio file size must not exceed 50 MB';
    }
    else if (!file[0].name.match(FILE_EXT_REGEX_AUDIO)) {
      isValid = false;
      errorMessage = 'Please upload valid file type - mp3 or wav';
    }
    else if (file[0].name.includes('.exe')) {
      isValid = false;
      errorMessage = 'Please upload valid file type - mp3 or wav';
    }

    setErrorAudioMessage(errorMessage);
    return isValid;
  };

  const validateFiles = (files) => {
    let isValid = true;
    let errorMessage = '';

    for (let i = 0; i < files.length; i++) {
      const file = files[i];
      const { name, size } = file;
      if (size > MAX_FILE_SIZE) {
        isValid = false;
        errorMessage = 'Image file size must not exceed 15 MB';
        break;
      }
      else if (!name.match(FILE_EXT_REGEX)) {
        isValid = false;
        errorMessage = 'Please upload valid file type - jpg, jpeg, png';
        break;
      }
      else if (name.includes('.exe')) {
        isValid = false;
        errorMessage = 'Please upload valid file type - jpg, jpeg, png';
      }
    }
    setErrorMultiMessage(errorMessage);
    return isValid;
  };

  const validateFile = (file) => {
    let isValid = true;
    let errorMessage = '';

    if (file.length > 1) {
      isValid = false;
      errorMessage = 'Only 1 Image file can be uploaded';
    }
    else if (file[0].size > MAX_FILE_SIZE) {
      isValid = false;
      errorMessage = 'Image file size must not exceed 15 MB';
    }
    else if (!file[0].name.match(FILE_EXT_REGEX)) {
      isValid = false;
      errorMessage = 'Please upload valid file type - jpg, jpeg, png';
    }
    else if (file[0].name.includes('.exe')) {
      isValid = false;
      errorMessage = 'Please upload valid file type - jpg, jpeg, png';
    }
    setErrorSingleMessage(errorMessage);
    return isValid;
  };

  const validateVideoFile = (file) => {
    let isValid = true;
    let errorMessage = '';

    if (file.length > 1) {
      isValid = false;
      errorMessage = 'Only 1 video file can be uploaded';
    }
    else if (file[0].size > MAX_VIDEO_FILE_SIZE) {
      isValid = false;
      errorMessage = 'Image file size must not exceed 50 MB';
    }
    else if (!file[0].name.match(FILE_EXT_REGEX_VIDEO)) {
      isValid = false;
      errorMessage = 'Please upload valid file type - mp4 or mov';
    }
    else if (file[0].name.includes('.exe')) {
      isValid = false;
      errorMessage = 'Please upload valid file type - mp4 or mov';
    }
    setErrorVideoMessage(errorMessage);
    return isValid;
  };

  const validateMaterialFiles = (files) => {
    let isValid = true;
    let errorMessage = '';

    for (let i = 0; i < files.length; i++) {
      const file = files[i];
      const { name, size } = file;
      if (size > MAX_FILE_SIZE) {
        isValid = false;
        errorMessage = 'Material file size must not exceed 15 MB';
        break;
      }
      else if (!name.match(FILE_EXT_REGEX_MATERIAL)) {
        isValid = false;
        errorMessage =
          'Please upload valid file type - jpg, jpeg, png, pdf, docx, xlsx, pptx';
        break;
      }
      else if (name.includes('.exe')) {
        isValid = false;
        errorMessage = 'Please upload valid file type - jpg, jpeg, png, pdf, docx, xlsx, pptx';
      }
    }
    setErrorMaterialMessage(errorMessage);
    return isValid;
  };

  return {
    errorSingleMessage,
    setErrorSingleMessage,
    errorMultiMessage,
    setErrorMultiMessage,
    validateFile,
    validateFiles,
    errorVideoMessage,
    setErrorVideoMessage,
    validateVideoFile,
    errorMaterialMessage,
    setErrorMaterialMessage,
    validateMaterialFiles,
    errorAudioMessage,
    setErrorAudioMessage,
    validateAudio,
  };
};

export default useFileValidation;
