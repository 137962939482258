import "../../../styles/screens/client-visit-details/ProfileCard.css";
import "../../../styles/screens/client-visit-details/Feedback.css";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router";
import "../office-list/OfficeList.css";
import "../office-list/OfficeCard.css";
import { updateOfficeId } from "../../../slices/OfficeListSlice";

function OfficeCard({ dataItem }) {
  const data = useSelector((state) => state.officelist.OfficeListData);
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const handleClick = (event) => {
    dispatch(updateOfficeId({ id: dataItem._id }));
    navigate("/officehome");
    //navigate(`/manage-office/${dataItem._id}/visits`, { state: dataItem });
  };

  return (
    <div
      className={
        dataItem.available
          ? "office-card-container"
          : "office-card-disable-container"
      }
      onClick={handleClick}
    >
      {/* <div className='office-profile-container'> */}
      <img
        src= {dataItem.photoUrl}
        alt='no feedback list'
        className='office-profile-container'
      />

      {/* </div> */}
      <div className='office-description'>
        <div className={dataItem.available ? "lines" : "line-red"}></div>
        <div
          style={{
            color: " #303030",
            fontSize: "24px",
            fontStyle: "normal",
            fontWeight: "500",
            overflow: "hidden",
            textOverflow: "ellipsis",
            display: "-webkit-box",
            lineClamp: 5,
            WebkitLineClamp: 5,
            WebkitBoxOrient: "vertical",
            fontfamily: "Helvetica Neue",
            paddingLeft: "8px",
            marginTop: "5px",
            marginBottom: "5px",
          }}
        >
          {" "}
          {dataItem.name}
        </div>
        {dataItem.address && (
          <div
            style={{
              overflow: "hidden",
              textOverflow: "ellipsis",
              display: "-webkit-box",
              lineClamp: 5,
              WebkitLineClamp: 5,
              WebkitBoxOrient: "vertical",
              fontSize: 14,
              fontfamily: "Helvetica Neue",
              paddingLeft: "8px",
              marginTop: "5px",
              marginBottom: "5px",
              color: " #303030",
              lineHeight: "20px",
            }}
          >
            {dataItem.address}
          </div>
        )}
      </div>
    </div>
  );
}

export default OfficeCard;
