import { useDispatch, useSelector } from "react-redux";
import back from "../../../assets/right-chevron.svg";
import "../../../styles/screens/client-visit-details/manage-module/SpeakersList.css";
import { useNavigate } from "react-router";
import { useEffect, useState } from "react";

import {
  createFeedbackByUser,
  getAllFeedbacks,
  resetErrorAlert,
} from "../../../slices/UserFeedbackSlice";
import Loader from "../../common-components/Loader";
import {
  TitansOfIndustryStringLabels,
  UserFeedbackStringLabels,
} from "../../../util/Strings";
import ErrorAlert from "../../common-components/ErrorAlert";
import ErrorMessages from "../../../util/ErrorMessages";

import UserFeedbackCard from "./UserFeedbackCard";

function UserFeedbackList() {
  const data = useSelector((state) => state.userfeedback.FeedbackListData);
  const isLoading = useSelector((state) => state.userfeedback.isLoading);
  const [showForm, setShowForm] = useState(false);
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const errorMessageCode = useSelector((state) => state.userfeedback.errorCode);
  const errorMessage = useSelector((state) => state.userfeedback.errorMessage);
  function getAllFeedbacksData() {
    dispatch(getAllFeedbacks());
  }

  function handleCloseErrorAlert() {
    dispatch(resetErrorAlert(false));
  }

  useEffect(() => {
    getAllFeedbacksData();
  }, []);

  function handleFormSubmit(newData) {
    if (newData) {
      dispatch(createFeedbackByUser(newData));
    }
    setShowForm(false);
  }
  if (isLoading) {
    return <Loader />;
  }
  if (errorMessage && errorMessageCode === 400) {
    return (
      <ErrorAlert
        show={errorMessageCode}
        onClickHandle={handleCloseErrorAlert}
        title="Error"
        subTitle={errorMessage}
      />
    );
  } else if (errorMessageCode) {
    return (
      <ErrorAlert
        show={errorMessageCode}
        onClickHandle={handleCloseErrorAlert}
        title="Error"
        subTitle={ErrorMessages(errorMessageCode)}
      />
    );
  }
  return (
    <div>
      <div className="client-container">
        <div className="client-header">
          <div>
            <div
              onClick={() => {
                navigate("/home", { replace: true });
              }}
              className="back-to-visit"
            >
              <img src={back} alt="back to Visit Screen" />
              <div className="back-to-visit-text">
                {UserFeedbackStringLabels.backToHome}
              </div>
            </div>
            <div className="headings">
              {TitansOfIndustryStringLabels.feedbacklist}
            </div>
          </div>
        </div>
        <div className="users-list-container">
          <div className="users-list-header-container">
            <div className="event-list-heading">
              {TitansOfIndustryStringLabels.feedbacklist}
            </div>
          </div>
          <div className="speakers-body">
            {data.map((dataItem) => {
              return <UserFeedbackCard dataItem={dataItem} />;
            })}
          </div>
        </div>
      </div>
    </div>
  );
}
export default UserFeedbackList;
