import UploadAPI from '../serviceAPI/UploadAPI';
import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';

const initialState = {
  id: 0,
  progress: 0,
  photoId: 0,
  previewImage: null,
  selectedFile: null,
  multipleProgress: 0,
  multipleFileDetails: [],
  mutliplePhotoIds: [],
  filteredIdForDelete: '',
  errorCode: null,
};

export const fileUpload = createAsyncThunk(
  'fileUpload',
  async (data, { dispatch, rejectWithValue }) => {
    try {
      const res = await UploadAPI.UploadNewFile(data, dispatch);
      return res;
    } catch (error) {
      return rejectWithValue(error.response.status);
    }
  }
);

export const fileDelete = createAsyncThunk(
  'fileDelete',
  async (id, { dispatch, rejectWithValue }) => {
    try {
      if (id.id === '' || id.id === null) {
        return;
      }
      const res = await UploadAPI.DeleteUploadedFile(id, dispatch);
      return res;
    } catch (error) {
      return rejectWithValue(error.response.status);
    }
  }
);

export const multipleFileUpload = createAsyncThunk(
  'multiFileUpload',
  async (data, { dispatch, rejectWithValue }) => {
    try {
      const res = await UploadAPI.UploadMultipleFiles(data, dispatch);
      return res;
    } catch (error) {
      return rejectWithValue(error.response.status);
    }
  }
);

const FileUploadSlice = createSlice({
  name: 'upload',
  initialState,
  reducers: {
    progressPercentage: (state, action) => {
      state.progress = action.payload;
    },
    setSelectedFile: (state, action) => {
      state.selectedFile = action.payload;
    },
    previewImage: (state, action) => {
      state.previewImage = action.payload;
    },
    mutlipleProgressPercentage: (state, action) => {
      state.multipleProgress = action.payload;
    },
    setMultipleFileDetails: (state, action) => {
      state.multipleFileDetails = action.payload;
    },
    setFilteredIdForDelete: (state, action) => {
      state.filteredIdForDelete = action.payload;
    },
    resetMultipleFileUpload: (state) => {
      state.multipleProgress = 0;
      state.multipleFileDetails = [];
      state.mutliplePhotoIds = [];
      state.filteredIdForDelete = '';
    },
    resetMediaErrorAlert: (state, action) => {
      state.errorCode = null;
    },
  },
  extraReducers: {
    [fileUpload.fulfilled]: (state, action) => {
      state.photoId = action.payload?.[0]._id;
    },
    [fileUpload.rejected]: (state, action) => {
      state.errorCode = action.payload;
    },
    [multipleFileUpload.fulfilled]: (state, action) => {
      const ids = action.payload.map((payload) => {
        return { fileId: payload._id, fileName: payload.fileName };
      });
      state.mutliplePhotoIds = ids;
    },
    [multipleFileUpload.rejected]: (state, action) => {
      state.errorCode = action.payload;
    },
    [fileDelete.fulfilled]: (state) => {
      if (state.filteredIdForDelete !== '') {
        const index = state.mutliplePhotoIds
          .map((item) => item.fileId)
          .indexOf(state.filteredIdForDelete);
        state.mutliplePhotoIds.splice(index, 1);
        state.filteredIdForDelete = '';
      }
    },
    [fileDelete.rejected]: (action, state) => {
      state.errorCode = action.payload;
    },
  },
});

export const {
  resetUploadFile,
  progressPercentage,
  setSelectedFile,
  previewImage,
  mutlipleProgressPercentage,
  setMultipleFileDetails,
  setFilteredIdForDelete,
  resetMultipleFileUpload,
  resetMediaErrorAlert,
} = FileUploadSlice.actions;
export default FileUploadSlice.reducer;
