import "../../../styles/screens/client-visit-screens/ClientVisitCardItem.css";
import { useNavigate } from "react-router-dom";
import edit from "../../../assets/edit.svg";
import del from "../../../assets/delete.svg";
import { useDispatch, useSelector } from "react-redux";
import {
  deleteVisit,
  updateVisit,
  updateVisitId,
  updatedIsVisitCompleted,
} from "../../../slices/VisitSlice";
import CreateNewVisitForm from "./CreateNewVisitForm";
import DateFormatter from "../../../util/DateFormatter";
import { useState } from "react";
import { CheckIsVisitCompleted } from "../../../util/FilterDate";
import ConfirmationDialog from "../../common-components/ConfirmationDialog";
import { ClientVisitScreenStringLabels } from "../../../util/Strings";

function ClientVisitCardItem({ data, sl_number, state }) {
  const isSubAdmin = useSelector((state) => state.login.isSubAdmin);
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [showForm, setShowForm] = useState(false);
  const [showDelete, setShowDelete] = useState(false);

  function handleOnClickUpdateData(newData) {
    if (newData) {
      let newStartDate = newData.startDate;
      let newEndDate = newData.endDate;
      newData.startDate = newStartDate;
      newData.endDate = newEndDate;
      const dataWithId = { id: data._id, newData: newData };
      dispatch(updateVisit(dataWithId));
    }
    setShowForm(false);
  }

  function onEditButtonClick() {
    dispatch(
      updateVisitId({ id: data._id, name: data.name, endDate: data.endDate })
    );
    setShowForm(true);
  }

  return (
    <>
      <div
        className='row card'
        onClick={() => {
          navigate(`/client/${data._id}/manage-modules/welcome`, {
            state: data,
          });
          const isVisitCompleted = CheckIsVisitCompleted(data.endDate);
          dispatch(
            updateVisitId({
              id: data._id,
              name: data.name,
              endDate: data.endDate,
            })
          );
          dispatch(updatedIsVisitCompleted(isVisitCompleted));
        }}
      >
        <div className='serial-number'>
          <span>{sl_number}</span>
        </div>
        <div className='column1'>
          <span>{data.name}</span>
        </div>
        <div className='column2'>
          <span>{DateFormatter(data.startDate)}</span>
        </div>
        <div className='column3'>
          <span>{DateFormatter(data.endDate)}</span>
        </div>
        <div className='column4'>
          <span>{data.companyName ? data.companyName : "-"}</span>
        </div>
        <div className='column5'>
          <div className='visits-logo'>
            {data.companyLogoUrl ? (
              <img src={data.companyLogoUrl} alt='Avatar' />
            ) : (
              "-"
            )}
          </div>
        </div>
        <div className='column6'>
          <span>{data.region}</span>
        </div>
        <div className='column4 icon'>
          <span>
            { isSubAdmin === false ? (
              <img
                src={edit}
                alt='edit'
                onClick={(e) => {
                  e.stopPropagation();
                  onEditButtonClick();
                }}
              />
            ) : null}
            {isSubAdmin === false && (
              <img
                src={del}
                alt='delete'
                className='icon-middle'
                onClick={(e) => {
                  e.stopPropagation();
                  setShowDelete(true);
                }}
              />
            )}
          </span>
        </div>
      </div>

      <CreateNewVisitForm
        show={showForm}
        onClickNext={handleOnClickUpdateData}
        data={data}
      />
      <ConfirmationDialog
        show={showDelete}
        buttonName={ClientVisitScreenStringLabels.delete}
        onClickHandle={(flag) => {
          if (flag) {
            dispatch(deleteVisit(data._id));
          }
          setShowDelete(false);
        }}
        title={ClientVisitScreenStringLabels.deleteTitle}
        subTitle={`Are you sure you want to delete ${data.name} record`}
      />
    </>
  );
}

export default ClientVisitCardItem;
