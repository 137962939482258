import React from "react";
import "../../../styles/screens/client-visit-screens/ClientVisitScreen.css";
import { useState } from "react";
import SpeakersList from "../client-visit-details/manage-module/SpeakersList";
import Refuel from "../client-visit-details/manage-module/RefuelList";
import {
  ClientVisitScreenStringLabels,
  ManageModulesStringLabels,
  RecreationStringLabels,
} from "../../../util/Strings";
import "../../../styles/screens/client-visit-details/manage-module/SpeakersList.css";
import ClientVisitScreen from "../client-visit-screens/ClientVisitScreen";
import Recreation from "../client-visit-details/manage-module/Recreation";
import HotelList from "../client-visit-details/manage-module/HotelList";
import { useDispatch, useSelector } from "react-redux";
import AddNewSpeakerForm from "../client-visit-details/manage-module/AddNewSpeakerForm";
import AddNewRecreationForm from "../client-visit-details/manage-module/AddNewRecreationForm";
import AddNewRefuelForm from "../client-visit-details/manage-module/AddNewRefuelForm";
import AddHotelForm from "../client-visit-details/manage-module/AddHotelForm";
import { createNewRecreationData } from "../../../slices/RecreationSlice";
import { createNewRefuelData } from "../../../slices/RefuelSlice";
import { createNewSpeaker } from "../../../slices/SpeakerSlice";
import { createNewHotel } from "../../../slices/HotelSlice";
import back from "../../../assets/right-chevron.svg";
import { useNavigate } from "react-router-dom";
import "../../screens/office-home-tabs/OfficeTabs.css";
import GetToKnowACList from "../client-visit-details/manage-module/GetToKnowACList";
import AddGetToKnowACForm from "../client-visit-details/manage-module/AddGetToKnowACForm";
import { createNewGetToKnowAC } from "../../../slices/GetToKnowACSlice";

function OfficeTabs() {
  const [showSpeakersList, setShowSpeakersList] = useState(false);
  const [showCreateNewForm, setShowCreateNewForm] = useState(false);
  const [activeTab, setActiveTab] = useState("VisitActive");
  const [showRefuel, setShowRefuel] = useState(false);
  const [state, setState] = useState("");
  const [showVisits, setShowVisits] = useState("VisitActive");
  const [showRoam, setShowRoam] = useState(false);
  const [showRecharge, setShowRecharge] = useState(false);
  const [showAboutAC, setShowAboutAc] = useState(false);
  const dispatch = useDispatch();
  const visit = useSelector((state) => state.visits);
  const navigate = useNavigate();
  const id = visit.selectedVisitId;
  const selectedOfficeId = useSelector(
    (state) => state.officelist.selectedOfficeId
  );
  async function handleAddNewSpeakerFormSubmit(newData) {
    if (newData) {
      const data = { id: selectedOfficeId, newData: newData };
      dispatch(createNewSpeaker(data));
    }
    setShowCreateNewForm(false);
  }
  function handleAddNewRecreationFormSubmit(newData) {
    if (newData) {
      const recreation = {
        id: selectedOfficeId,
        newData: newData,
      };
      dispatch(createNewRecreationData(recreation));
    }
    setShowCreateNewForm(false);
  }
  async function handleHotelListFormSubmit(newData) {
    if (newData) {
      const hotel = {
        id: selectedOfficeId,
        newData: newData,
      };
      dispatch(createNewHotel(hotel));
    }
    setShowCreateNewForm(false);
  }

  function handleAddNewRefuelFormSubmit(newData) {
    if (newData) {
      const refuel = {
        id: selectedOfficeId,
        newData: newData,
      };
      dispatch(createNewRefuelData(refuel));
    }
    setShowCreateNewForm(false);
  }

  async function handleAddNewACFormSubmit(newData) {
    if (newData) {
      const data = { id: selectedOfficeId, newData: newData };
      dispatch(createNewGetToKnowAC(data));
    }
    setShowCreateNewForm(false);
  }

  return (
    <div className='clientVisit'>
      <div
        onClick={() => {
          navigate("/home", { replace: true });
        }}
        className='back-to-home'
      >
        <img
          src={back}
          alt='back to Home Screen'
          className='back-to-home-text'
        />
        <div className='back-to-home-text'>
          {ClientVisitScreenStringLabels.backToHomeScreen}
        </div>
      </div>
      <div
        style={{
          overflow: "hidden",
          textOverflow: "ellipsis",
          display: "-webkit-box",
          lineClamp: 5,
          WebkitLineClamp: 5,
          WebkitBoxOrient: "vertical",
          fontSize: 14,
          fontWeight: 400,
          color: "#818181",
        }}
      ></div>
      <div className='office-home-header-container'>
        <button
          className={
            activeTab === "VisitActive" ? "tablinksActive" : "tablinks"
          }
          style={{ fontSize: "23px", fontWeight: "500" }}
          onClick={() => {
            {
              setShowAboutAc(false)
              setShowVisits("VisitActive");
              setShowSpeakersList(false);
              setShowRoam(false);
              setShowRefuel(false);
              setShowRecharge(false);
              setState("VisitActive");
              setActiveTab("VisitActive");
            }
          }}
        >
          {ClientVisitScreenStringLabels.visits}
        </button>
        <button
          className={state === "SpeakerActive" ? "tablinksActive" : "tablinks"}
          style={{ fontSize: "23px", fontWeight: "500" }}
          onClick={() => {
            {
              setShowAboutAc(false)
              setShowSpeakersList(true);
              setShowVisits(false);
              setShowRoam(false);
              setShowRefuel(false);
              setShowRecharge(false);
              setState("SpeakerActive");
              setActiveTab("Speaker");
            }
          }}
        >
          {ManageModulesStringLabels.titansOfIndustry}
        </button>
        <button
          className={state === "RefuelActive" ? "tablinksActive" : "tablinks"}
          style={{ fontSize: "23px", fontWeight: "500" }}
          onClick={() => {
            {
              setShowAboutAc(false)
              setShowRefuel(true);
              setShowVisits(false);
              setShowRoam(false);
              setShowSpeakersList(false);
              setShowRecharge(false);
              setState("RefuelActive");
              setActiveTab("Refuel");
            }
          }}
        >
          {ManageModulesStringLabels.refuel}
        </button>
        <button
          className={state === "RoamActive" ? "tablinksActive" : "tablinks"}
          style={{ fontSize: "23px", fontWeight: "500" }}
          onClick={() => {
            {
              setShowAboutAc(false)
              setShowRoam(true);
              setShowVisits(false);
              setShowRefuel(false);
              setShowSpeakersList(false);
              setShowRecharge(false);
              setState("RoamActive");
              setActiveTab("Roam");
            }
          }}
        >
          {RecreationStringLabels.roam}
        </button>
        <button
          className={state === "RechargeActive" ? "tablinksActive" : "tablinks"}
          style={{ fontSize: "23px", fontWeight: "500" }}
          onClick={() => {
            {
              setShowAboutAc(false)
              setShowRecharge(true);
              setShowVisits(false);
              setShowRefuel(false);
              setShowSpeakersList(false);
              setShowRoam(false);
              setState("RechargeActive");
              setActiveTab("Recharge");
            }
          }}
        >
          {ManageModulesStringLabels.recharge}
        </button>
        <button
          className={state === "AboutACActive" ? "tablinksActive" : "tablinks"}
          style={{ fontSize: "23px", fontWeight: "500" }}
          onClick={() => {
            {
              setShowAboutAc(true)
              setShowSpeakersList(false);
              setShowVisits(false);
              setShowRoam(false);
              setShowRefuel(false);
              setShowRecharge(false);
              setState("AboutACActive");
              setActiveTab("AboutAC");
            }
          }}
        >
          {ManageModulesStringLabels.getToKnowAC}
        </button>
      </div>
      <div className='speakers-list-container'>
        <div className='visit-list-heading'>
          <div className='office-card'>
            <div className='office-main-container'>
              <div className='add-button'>
                {activeTab !== "VisitActive" && (
                  <button
                    onClick={() => setShowCreateNewForm(true)}
                    className='button-style'
                  >
                    {ManageModulesStringLabels.add}
                  </button>
                )}
              </div>
              {showVisits && <ClientVisitScreen />}
              {activeTab === "Speaker" && (
                <>
                  {showSpeakersList && <SpeakersList />}
                  {
                    <AddNewSpeakerForm
                      show={showCreateNewForm}
                      onClickNext={handleAddNewSpeakerFormSubmit}
                      data={null}
                    />
                  }
                </>
              )}

              {activeTab === "Roam" && (
                <>
                  {showRoam && <Recreation />}
                  {
                    <AddNewRecreationForm
                      show={showCreateNewForm}
                      onClickNext={handleAddNewRecreationFormSubmit}
                      data={null}
                    />
                  }
                </>
              )}

              {activeTab === "Refuel" && (
                <>
                  {showRefuel && <Refuel />}
                  {
                    <AddNewRefuelForm
                      show={showCreateNewForm}
                      onClickNext={handleAddNewRefuelFormSubmit}
                      data={null}
                    />
                  }
                </>
              )}
              {activeTab === "Recharge" && (
                <>
                  {showRecharge && <HotelList />}
                  {
                    <AddHotelForm
                      onClickSubmit={handleHotelListFormSubmit}
                      show={showCreateNewForm}
                      data={null}
                    />
                  }
                </>
              )}
              {activeTab === "AboutAC" && (
                <>
                  {showAboutAC && <GetToKnowACList />}
                  {
                    <AddGetToKnowACForm
                      onClickNext={handleAddNewACFormSubmit}
                      show={showCreateNewForm}
                      data={null}
                    />
                  }
                </>
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
export default OfficeTabs;
