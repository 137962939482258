import { useDispatch, useSelector } from "react-redux";
import noRecharge from "../../../../assets/no-recharge.svg";
import "../../../../styles/screens/client-visit-details/manage-module/HotelList.css";
import { useEffect, useState } from "react";
import Loader from "../../../common-components/Loader";
import {
  getAllHotelList,
  resetErrorAlert,
} from "../../../../slices/HotelSlice";
import HotelListTableBody from "./HotelListTableBody";
import { RechargeStringLabels } from "../../../../util/Strings";
import ErrorAlert from "../../../common-components/ErrorAlert";
import ErrorMessages from "../../../../util/ErrorMessages";

function HotelListTable() {
  const data = useSelector((state) => state.hotel.hotelList);
  const isLoading = useSelector((state) => state.hotel.isLoading);
  const isVisitCompleted = useSelector(
    (state) => state.visits.selectedVisitIsCompleted
  );
  const id = useSelector((state) => state.visits.selectedVisitId);
  const selectedOfficeId = useSelector(
    (state) => state.officelist.selectedOfficeId
  );
  const dispatch = useDispatch();
  const errorMessageCode = useSelector((state) => state.hotel.errorCode);

  function handleCloseErrorAlert() {
    dispatch(resetErrorAlert(false));
  }
  function getHotelDetails() {
    dispatch(getAllHotelList(selectedOfficeId));
  }

  useEffect(() => {
    getHotelDetails();
  }, []);

  if (isLoading) {
    return <Loader />;
  }

  if (errorMessageCode) {
    return (
      <ErrorAlert
        show={errorMessageCode}
        onClickHandle={handleCloseErrorAlert}
        title='Error'
        subTitle={ErrorMessages(errorMessageCode)}
      />
    );
  }

  return (
    <>
      {data && data.length > 0 ? (
        <table>
          <thead>
            <tr className='table-header'>
              <th>{RechargeStringLabels.hotelName}</th>
              <th className='description-hotel'>
                {RechargeStringLabels.description}
              </th>
              <th>{RechargeStringLabels.location}</th>
              {/* <th>{RechargeStringLabels.distanceAndDuration}</th> */}
              <th>{RechargeStringLabels.time}</th>
              <th className='address'>{RechargeStringLabels.address}</th>
              <th>{RechargeStringLabels.photo}</th>
              {isVisitCompleted ? null : <th> </th>}
            </tr>
          </thead>
          {data.map((dataItem) => {
            return <HotelListTableBody dataItem={dataItem} />;
          })}
        </table>
      ) : (
        <img src={noRecharge} alt='No Possibilities' className='no-img' />
      )}
    </>
  );
}

export default HotelListTable;
