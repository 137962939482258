import http from '../http-common';
import DateFormatter from '../util/DateFormatter';

const GetAllEvents = async (id) => {
  const events = await http.get(`/events/${id}`).then((response) => {
    return response.data;
  });
  return events;
};

const CreateNewEvent = async (newData, id) => {
  const auth = await http
    .post(`/createEvent/${id}`, {
      eventType: newData.eventType,
      // eventThumbnailUrl: newData.eventThumbnailUrl? newData.eventThumbnailUrl:null,
      startTime: newData.startTime,
      endTime: newData.endTime,
      title: newData.title,
      description: newData.description,
      location: newData.location,
      speakers: newData.speakers,
      materials: newData.materials,
      date: DateFormatter(newData.date),
    })
    .then((response) => {
      return response.data;
    });

  return auth;
};

const UpdateEvent = async (updateData, id) => {
  const updatedData = await http
    .put(`/updateEvent/${id}`, {
      eventType: updateData.eventType,
      // eventThumbnailUrl: newData.eventThumbnailUrl? newData.eventThumbnailUrl:null,
      startTime: updateData.startTime,
      endTime: updateData.endTime,
      title: updateData.title,
      description: updateData.description,
      location: updateData.location,
      speakers: updateData.speakers,
      attendees: updateData.attendees,
      materials: updateData.materials,
      date: DateFormatter(updateData.date),
    })
    .then((response) => {
      return response.data;
    });
  return updatedData;
};

const DeleteEvent = async (id) => {
  const deletedData = await http
    .delete(`/deleteEvent/${id}`)
    .then((response) => {
      return response.data;
    });
  return deletedData;
};

const EventListAPI = { GetAllEvents, CreateNewEvent, UpdateEvent, DeleteEvent };
export default EventListAPI;
