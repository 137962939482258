import http from "../http-common";

const GetAllUsers = async (id) => {
  const users = await http.get(`/users/${id}`).then((response) => {
    return response.data;
  });
  return users;
};

const CreateNewUser = async (newData, id) => {
  const auth = await http
    .post(`/createUser/${id}`, {
      email: newData.email,
      name: newData.name,
      phoneNumber: newData.phoneNumber,
      countryCode: newData.countryData,
      // jobTitle: newData.jobTitle,
      // company: newData.company,
      // phoneNumber: newData.phoneNumber,
      // aboutMe: newData.aboutMe,
      // linkedInLink: newData.linkedInLink,
      // imageId: newData.imageId
    })
    .then((response) => {
      return response;
    });

  return auth.data;
};

const UpdateUser = async (updateData, id) => {
  const updatedData = await http
    .put(`/updateUser/${id}`, {
      email: updateData.email,
      name: updateData.name,
      phoneNumber: updateData.phoneNumber,
      countryCode: updateData.countryData,
    })
    .then((response) => {
      return response.data;
    });
  return updatedData;
};

const DeleteUser = async (id) => {
  const deletedData = await http
    .delete(`/deleteUser/${id}`)
    .then((response) => {
      return response.data;
    });
  return deletedData;
};

const UsersListAPI = { GetAllUsers, CreateNewUser, UpdateUser, DeleteUser };
export default UsersListAPI;
