import { useState } from 'react';
import '../../../../styles/screens/client-visit-details/manage-users/UsersList.css';
import AddNewUserForm from './AddNewUserForm';
import { useDispatch, useSelector } from 'react-redux';
import { createNewUser } from '../../../../slices/UserSlice';
import UsersListTable from './UsersListTable';
import { UserScreenStringLabels } from '../../../../util/Strings';

function UsersList() {
  const visit = useSelector((state) => state.visits);
  const [showCreateNewUserForm, setShowCreateNewUserForm] = useState(false);
  const dispatch = useDispatch();
  const isSubAdmin = useSelector((state) => state.login.isSubAdmin);
  function handleFormSubmit(newData) {
    if (newData) {
      const data = { id: visit.selectedVisitId, newData: newData };
      dispatch(createNewUser(data));
    }
    setShowCreateNewUserForm(false);
  }

  return (
    <>
      <div className="users-list-container">
        <div className="users-list-header-container">
          <div className="event-list-heading">
            {UserScreenStringLabels.userList}
          </div>
          <button
            onClick={() => setShowCreateNewUserForm(true)}
            className={
              isSubAdmin
                ? 'button-style-disabled'
                : 'button-style'
            }
            disabled={isSubAdmin}
          >
            {UserScreenStringLabels.add}
          </button>
        </div>
        <AddNewUserForm
          show={showCreateNewUserForm}
          onClickNext={handleFormSubmit}
          data={null}
        />
        <div className="events-list-content">
          <UsersListTable />
        </div>
      </div>
    </>
  );
}
export default UsersList;
