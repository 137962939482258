import { useState } from 'react';
import '../../../../styles/screens/client-visit-details/manage-module/EventsList.css';
import Required from '../../../common-components/Required';
import { useDispatch, useSelector } from 'react-redux';
import CloseButton from '../../../custom-components/CloseButton';
import CancelButton from '../../../custom-components/CancelButton';
import BlackButton from '../../../custom-components/BlackButton';
import { CabScheduleFormValidations } from '../../../../util/Validations';
import ErrorMessageComponent from '../../../common-components/ErrorMessageComponent';
import Select from 'react-select';
import { getAllUsers } from '../../../../slices/UserSlice';
import { RequestScreenStringLabels } from '../../../../util/Strings';

function CabScheduleForm({ show, handleSubmit, data }) {
  const [state, setState] = useState({
    contactName: data && data.contactName ? data.contactName : '',

    contactNumber: data && data.contactNumber ? data.contactNumber : '',

    carRegNumber: data && data.carRegNumber ? data.carRegNumber : '',

    carName: data && data.carName ? data.carName : '',

    carType: data && data.carType ? data.carType : '',

    bookings: [
      {
        date: data && data.date ? data.date : '',
        pickupTime: data && data.pickupTime ? data.pickupTime : '',
        pickupLocation: data && data.pickupLocation ? data.pickupLocation : '',
        dropTime: data && data.dropTime ? data.dropTime : '',
        showDropTime: data && data.showDropTime ? data.showDropTime : false,
        dropLocation: data && data.dropLocation ? data.dropLocation : '',
        usersId: data && data.usersId ? data.usersId : [],
      },
    ],
  });

  const [validation, setValidation] = useState({});
  const userOptions = useSelector((state) => state.users.userOptions);
  const overlapError = useSelector((state) => state.cabs.overlapError);
  const overlapUpdateError = useSelector((state) => state.cabs.overlapUpdateError);
  const errorMessage = useSelector((state) => state.cabs.errorMessage);

  const dispatch = useDispatch();

  const checkValidation = () => {
    const { errors, isValid } = CabScheduleFormValidations(state);
    setValidation(errors);
    return isValid;
  };

  const visitId = useSelector((state) => state.visits.selectedVisitId);

  if (userOptions.length === 0) {
    dispatch(getAllUsers(visitId));
  }

  const handleBookingChange = (index, field, value) => {
    const newBookings = [...state.bookings];
    newBookings[index][field] = value;
    setState({ ...state, bookings: newBookings });
  };

  const addBooking = () => {
    setState({
      ...state,
      bookings: [
        ...state.bookings,
        {
          date: '',
          pickupTime: '',
          pickupLocation: '',
          dropTime: '',
          showDropTime: false,
          dropLocation: '',
          usersId: [],
        },
      ],
    });
  };

  const handleSubmitButton = () => {
    if (checkValidation()) {
      handleSubmit(state);
    }
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    setState({ ...state, [name]: value });
  };

  if (!show) {
    return null;
  }
  
  return (
    <div className="modal">
      <div className="form-scroll">
        <div className="form-flex">
          <div
            className="user-form-modal-content"
            onClick={(e) => e.stopPropagation()}
          >
            <div className="form-header">
              <div className="header">
                {data
                  ? RequestScreenStringLabels.updateCabInfo
                  : RequestScreenStringLabels.shareCabInfo}
              </div>
              <CloseButton onClickhandle={() => handleSubmit()} />
            </div>
            <div className="form-container">
              <div className="form-section">
                <h3>Cab Details</h3>
                <div className="form-label-input">
                  <label className="form-label">
                    Cab Driver Name
                    <Required />
                  </label>
                  <div className="form-input-container">
                    <input
                      className="form-input"
                      type="text"
                      value={state.contactName}
                      name="contactName"
                      onChange={handleChange}
                      placeholder="Input"
                      required={true}
                    ></input>
                  </div>
                  <ErrorMessageComponent message={validation.contactName} />
                </div>

                <div className="form-label-input">
                  <label className="form-label">
                    Contact Number
                    <Required />
                  </label>
                  <div className="form-input-container">
                    <input
                      className="form-input"
                      type="tel"
                      value={state.contactNumber}
                      name="contactNumber"
                      onChange={handleChange}
                      placeholder="Input"
                      required={true}
                    ></input>
                  </div>
                  <ErrorMessageComponent message={validation.contactNumber} />
                </div>

                <div className="form-label-input">
                  <label className="form-label">
                    Car Registration Number
                    <Required />
                  </label>
                  <div className="form-input-container">
                    <input
                      className="form-input"
                      type="text"
                      value={state.carRegNumber}
                      name="carRegNumber"
                      onChange={handleChange}
                      placeholder="Input"
                      required={true}
                    ></input>
                  </div>
                  <ErrorMessageComponent message={validation.carRegNumber} />
                </div>

                <div className="form-label-input">
                  <label className="form-label">
                    Car Name
                  </label>
                  <div className="form-input-container">
                    <input
                      className="form-input"
                      type="text"
                      value={state.carName}
                      name="carName"
                      onChange={handleChange}
                      placeholder="Input"
                    ></input>
                  </div>
                  <ErrorMessageComponent message={validation.carName} />
                </div>

                <div className="form-label-input">
                  <label className="form-label">
                    Car Type
                  </label>
                  <div className="form-input-container">
                    <input
                      className="form-input"
                      type="text"
                      value={state.carType}
                      name="carType"
                      onChange={handleChange}
                      placeholder="Input"
                    ></input>
                  </div>
                  <ErrorMessageComponent message={validation.carType} />
                </div>
              </div>

              {state.bookings.map((booking, index) => (
                <div key={index} className="form-section">
                  <h3>Booking {index + 1}</h3>
                  <div className="form-label-input">
                    <label className="form-label">
                      Select a Date
                      <Required />
                    </label>
                    <div className="form-input-container">
                      <input
                        className="form-input"
                        type="date"
                        value={booking.date}
                        name="date"
                        onChange={(e) =>
                          handleBookingChange(index, 'date', e.target.value)
                        }
                        placeholder="Input"
                        required={true}
                      ></input>
                    </div>
                    <ErrorMessageComponent message={validation.date} />
                  </div>

                  <div className="form-label-input">
                    <label className="form-label">
                      Pick Up Time
                      <Required />
                    </label>
                    <div className="form-input-container">
                      <input
                        className="form-input"
                        type="time"
                        value={booking.pickupTime}
                        name="pickupTime"
                        onChange={(e) =>
                          handleBookingChange(index, 'pickupTime', e.target.value)
                        }
                        placeholder="Input"
                        required={true}
                      ></input>
                    </div>
                    <ErrorMessageComponent message={validation.pickupTime} />
                  </div>

                  <div className="form-label-input">
                    <label className="form-label">
                      Pick Up Location
                      <Required />
                    </label>
                    <div className="form-input-container">
                      <input
                        className="form-input"
                        type="text"
                        value={booking.pickupLocation}
                        name="pickupLocation"
                        onChange={(e) =>
                          handleBookingChange(index, 'pickupLocation', e.target.value)
                        }
                        placeholder="Input"
                        required={true}
                      ></input>
                    </div>
                    <ErrorMessageComponent message={validation.pickupLocation} />
                  </div>
                 
                  <div className="form-label-input">
                    <label className="form-label">
                      Drop Time
                      <Required />
                    </label>
                    <div className="form-input-container">
                      <input
                        className="form-input"
                        type="time"
                        value={booking.dropTime}
                        name="dropTime"
                        onChange={(e) =>
                          handleBookingChange(index, 'dropTime', e.target.value)
                        }
                        placeholder="Input"
                        required={true}
                      ></input>
                    </div>
                    <ErrorMessageComponent message={validation.dropTime} />
                  </div>

                  <div className="form-label-input-checkbox">
                    <label className="form-label">
                      Show Drop Time in App
                    </label>
                    <div className="checkbox">
                      <input
                        className="checkbox-input"
                        type="checkbox"
                        checked={booking.showDropTime}
                        name="showDropTime"
                        onChange={(e) =>
                          handleBookingChange(index, 'showDropTime', e.target.checked)
                        }
                      ></input>
                    </div>
                  </div>

                  <div className="form-label-input">
                    <label className="form-label">
                      Drop Location
                    </label>
                    <div className="form-input-container">
                      <input
                        className="form-input"
                        type="text"
                        value={booking.dropLocation}
                        name="dropLocation"
                        onChange={(e) =>
                          handleBookingChange(index, 'dropLocation', e.target.value)
                        }
                        placeholder="Input"
                      ></input>
                    </div>
                    <ErrorMessageComponent message={validation.dropLocation} />
                  </div>

                  <div className="form-label-input">
                    <label className="form-label">
                      Select Users
                      <Required />
                    </label>
                    <Select
                      options={userOptions}
                      className="basic-multi-select"
                      classNamePrefix="select"
                      name="usersId"
                      isMulti
                      value={userOptions.filter((user) =>
                        booking.usersId.includes(user.value)
                      )}
                      onChange={(selectedOptions) =>
                        handleBookingChange(
                          index,
                          'usersId',
                          selectedOptions.map((option) => option.value)
                        )
                      }
                    />
                    <ErrorMessageComponent message={validation.user} />
                  </div>
                </div>
              ))}

              {(overlapError || overlapUpdateError) && (
                <div className='overlap-error'>
                  {errorMessage}
                </div>
              )}

              <div className="footer-button-container">
                {!data && (
                  <button type="button" className='add-booking-button' onClick={addBooking}>
                    Add Another Booking
                  </button>
                )}
                <CancelButton onClickhandle={() => handleSubmit()}>
                  {RequestScreenStringLabels.cancel}
                </CancelButton>
                <BlackButton onClickhandle={handleSubmitButton}>
                  {data
                    ? RequestScreenStringLabels.update
                    : RequestScreenStringLabels.book}
                </BlackButton>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default CabScheduleForm;
