import SpeakerListAPI from '../serviceAPI/SpeakerListAPI';
import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import UploadAPI from '../serviceAPI/UploadAPI';

const initialState = {
  speakerData: [],
  speakerOptions: [],
  visitSpeakerData: [],
  visitSpeakerOptions: [],
  isLoading: false,
  prevFileDetails: null,
  prevPhotoId: '',
  prevMediaFileDetails: null,
  prevMediaId: '',
  videoStatus: null,
  videoUrl: '',
  mediaId: 0,
  mediaProgress: 0,
  selectedMediaFile: null,
  prevFileType: null,
  errorCode: null,
};

export const getAllSpeakers = createAsyncThunk(
  'getSpeakers',
  async (id, { rejectWithValue }) => {
    try {
      const res = await SpeakerListAPI.GetAllSpeakers(id);
      return res;
    } catch (error) {
      return rejectWithValue(error.response.status);
    }
  }
);

export const getAllSpeakersOfVisit = createAsyncThunk(
  'getVisitSpeakers',
  async (visitId, { rejectWithValue }) => {
    try {
      const res = await SpeakerListAPI.GetAllSpeakersOfVisit(visitId);
      return res;
    } catch (error) {
      return rejectWithValue(error.response.status);
    }
  }
);

export const createNewSpeaker = createAsyncThunk(
  'createNewSpeaker',
  async ({ newData, id }, { rejectWithValue }) => {
    try {
      const res = await SpeakerListAPI.CreateNewSpeaker(newData, id);
      return res;
    } catch (error) {
      return rejectWithValue(error.response.status);
    }
  }
);

export const audioFileUpload = createAsyncThunk(
  'audioFileUpload',
  async (data, { dispatch, rejectWithValue }) => {
    try {
      const res = await UploadAPI.UploadNewAudioFile(data, dispatch);
      return res;
    } catch (error) {
      return rejectWithValue(error.response.status);
    }
  }
);

export const updateSpeaker = createAsyncThunk(
  'updateSpeaker',
  async ({ newData, id }, { rejectWithValue }) => {
    try {
      const res = await SpeakerListAPI.UpdateSpeaker(newData, id);
      return res;
    } catch (error) {
      return rejectWithValue(error.response.status);
    }
  }
);
export const videoMediaFileUpload = createAsyncThunk(
  'videoMediaFileUpload',
  async (data, { rejectWithValue }) => {
    try {
      const res = await UploadAPI.UploadVideoFile(data);
      return res;
    } catch (error) {
      return rejectWithValue(error.response.status);
    }
  }
);

export const deleteSpeaker = createAsyncThunk(
  'deleteSpeaker',
  async (id, { rejectWithValue }) => {
    try {
      const res = await SpeakerListAPI.DeleteSpeaker(id);
      return res;
    } catch (error) {
      return rejectWithValue(error.response.status);
    }
  }
);

export const fileMediaDelete = createAsyncThunk(
  'fileMediaDelete',
  async (id, { dispatch, rejectWithValue }) => {
    try {
      if (id.id === '' || id.id === null) {
        return;
      }
      const res = await UploadAPI.DeleteUploadedMediaFile(id, dispatch);
      return res;
    } catch (error) {
      return rejectWithValue(error.response.status);
    }
  }
);

const SpeakerSlice = createSlice({
  name: 'speakers',
  initialState,
  reducers: {
    setPreviousFileDetails: (state, action) => {
      state.prevFileDetails = action.payload;
    },
    setPrevPhotoId: (state, action) => {
      state.prevPhotoId = action.payload;
    },
    setPreviousMediaFileDetails: (state, action) => {
      state.prevMediaFileDetails = action.payload;
    },
    setPrevMediaId: (state, action) => {
      state.prevMediaId = action.payload;
    },
    mediaProgressPercentage: (state, action) => {
      state.mediaProgress = action.payload;
    },
    setMediaSelectedFile: (state, action) => {
      state.selectedMediaFile = action.payload;
    },
    cancelVideoUpload: (state, action) => {
      state.videoStatus = null;
      state.videoUrl = '';
    },
    setPrevFileType: (state, action) => {
      state.prevFileType = action.payload;
    },
    resetErrorAlert: (state, action) => {
      state.errorCode = null;
    },
  },
  extraReducers: {
    [getAllSpeakers.pending]: (state) => {
      state.isLoading = true;
    },
    [getAllSpeakers.fulfilled]: (state, action) => {
      state.isLoading = false;
      const options = action.payload.map((speaker) => {
        const speakerOption = { value: speaker._id, label: speaker.name };
        return speakerOption;
      });
      state.speakerOptions = options;
      state.speakerData = [...action.payload];
    },
    [getAllSpeakers.rejected]: (state, action) => {
      state.isLoading = false;
      state.errorCode = action.payload;
    },
    [getAllSpeakersOfVisit.pending]: (state) => {
      state.isLoading = true;
    },
    [getAllSpeakersOfVisit.fulfilled]: (state, action) => {
      state.isLoading = false;
      const options = action.payload.map((speaker) => {
        const speakerOption = { value: speaker._id, label: speaker.name };
        return speakerOption;
      });
      state.visitSpeakerOptions = options;
      state.visitSpeakerData = [...action.payload];
    },
    [getAllSpeakersOfVisit.rejected]: (state, action) => {
      state.isLoading = false;
      state.errorCode = action.payload;
    },
    [createNewSpeaker.fulfilled]: (state, action) => {
      state.speakerOptions = [
        ...state.speakerOptions,
        { value: action.payload._id, label: action.payload.name },
      ];
      state.speakerData.push(action.payload);
    },
    [createNewSpeaker.rejected]: (state, action) => {
      state.errorCode = action.payload;
    },
    [updateSpeaker.fulfilled]: (state, action) => {
      state.speakerData = state.speakerData.map((speaker) =>
        speaker._id === action.payload._id ? action.payload : speaker
      );
      state.speakerOptions = state.speakerOptions.map((speaker) =>
        speaker.value === action.payload._id
          ? { value: action.payload._id, label: action.payload.name }
          : speaker
      );
    },
    [updateSpeaker.rejected]: (state, action) => {
      state.errorCode = action.payload;
    },
    [deleteSpeaker.fulfilled]: (state, action) => {
      const id = action.payload._id;
      const index = state.speakerData.map((item) => item._id).indexOf(id);
      state.speakerData.splice(index, 1);
    },
    [deleteSpeaker.rejected]: (state, action) => {
      state.errorCode = action.payload;
    },
    [videoMediaFileUpload.pending]: (state, action) => {
      state.videoStatus = "pending";
    },
    [videoMediaFileUpload.fulfilled]: (state, action) => {
      state.mediaId = action.payload[0]._id;
      state.videoStatus = action.payload[0].fileUploadStatus;
    },
    [videoMediaFileUpload.rejected]: (state, action) => {
      state.errorCode = action.payload;
    },
    [audioFileUpload.fulfilled]: (state, action) => {
      state.mediaId = action.payload[0]._id;
    },
    [audioFileUpload.rejected]: (state, action) => {
      state.errorCode = action.payload;
    },
    [fileMediaDelete.fulfilled]: (state) => {
      state.videoStatus = null;
      state.videoUrl = '';
    },
    [fileMediaDelete.rejected]: (state, action) => {
      state.errorCode = action.payload;
    },
  },
});

export const {
  setPreviousFileDetails,
  setPrevPhotoId,
  setPreviousMediaFileDetails,
  setPrevMediaId,
  mediaProgressPercentage,
  setMediaSelectedFile,
  cancelVideoUpload,
  setPrevFileType,
  resetErrorAlert,
} = SpeakerSlice.actions;
export default SpeakerSlice.reducer;
