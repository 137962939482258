import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import HotelAPI from '../serviceAPI/HotelAPI';

const initialState = {
  hotelList: [],
  hotelRequestList: [],
  hotelScheduledList: [],
  isLoading: false,
  prevThumbnailDetails: null,
  prevThumbnailId: '',
  prevHotelImageDetails: null,
  prevHotelIds: null,
  errorCode: null,
};

export const getAllHotelList = createAsyncThunk(
  'getAllHotelList',
  async (id, { rejectWithValue }) => {
    try {
      const res = await HotelAPI.GetAllHotels(id);
      return res;
    } catch (error) {
      return rejectWithValue(error.response.status);
    }
  }
);

export const getAllHotelRequestList = createAsyncThunk(
  'getAllHotelRequestList',
  async (id, { rejectWithValue }) => {
    try {
      const res = await HotelAPI.GetAllHotelRequests(id);
      return res;
    } catch (error) {
      return rejectWithValue(error.response.status);
    }
  }
);

export const getAllHotelScheduledList = createAsyncThunk(
  'getAllHotelScheduledList',
  async (id, { rejectWithValue }) => {
    try {
      const res = await HotelAPI.GetAllHotelScheduled(id);
      return res;
    } catch (error) {
      return rejectWithValue(error.response.status);
    }
  }
);

export const createNewHotel = createAsyncThunk(
  'createNewHotel',
  async ({ id, newData }, { rejectWithValue }) => {
    try {
      const res = await HotelAPI.CreateNewHotel(newData, id);
      return res;
    } catch (error) {
      return rejectWithValue(error.response.status);
    }
  }
);

export const createNewHotelSchedule = createAsyncThunk(
  'createNewHotelSchedule',
  async ({ id, newData }, { rejectWithValue }) => {
    try {
      const res = await HotelAPI.ScheduleHotelBooking(newData, id);
      return res;
    } catch (error) {
      return rejectWithValue(error.response.status);
    }
  }
);

export const updateHotel = createAsyncThunk(
  'updateHotel',
  async ({ newData, id }, { rejectWithValue }) => {
    try {
      const res = await HotelAPI.UpdateHotel(newData, id);
      return res;
    } catch (error) {
      return rejectWithValue(error.response.status);
    }
  }
);

export const deleteHotel = createAsyncThunk(
  'deleteHotel',
  async (id, { rejectWithValue }) => {
    try {
      const res = await HotelAPI.DeleteHotel(id);
      return res;
    } catch (error) {
      return rejectWithValue(error.response.status);
    }
  }
);

const HotelSlice = createSlice({
  name: 'hotel',
  initialState,
  reducers: {
    setPrevThumbnailDetails: (state, action) => {
      state.prevThumbnailDetails = action.payload;
    },
    setPreThumbnailId: (state, action) => {
      state.prevThumbnailId = action.payload;
    },
    setPrevHotelImageDetails: (state, action) => {
      state.prevHotelImageDetails = action.payload;
    },
    setPrevHotelIds: (state, action) => {
      state.prevHotelIds = action.payload;
    },
    resetErrorAlert: (state, action) => {
      state.errorCode = null;
    },
  },
  extraReducers: {
    [getAllHotelList.pending]: (state) => {
      state.isLoading = true;
    },
    [getAllHotelList.fulfilled]: (state, action) => {
      state.isLoading = false;
      state.hotelList = [...action.payload];
    },
    [getAllHotelList.rejected]: (state, action) => {
      state.isLoading = false;
      state.errorCode = action.payload;
    },
    [getAllHotelRequestList.fulfilled]: (state, action) => {
      const requestResult = action.payload.filter(
        (req) => req.isBooked === false
      );
      state.hotelRequestList = [...requestResult];
    },
    [getAllHotelRequestList.rejected]: (state, action) => {
      state.errorCode = action.payload;
    },
    [getAllHotelScheduledList.fulfilled]: (state, action) => {
      state.hotelScheduledList = [...action.payload];
    },
    [getAllHotelScheduledList.rejected]: (state, action) => {
      state.errorCode = action.payload;
    },
    [createNewHotel.fulfilled]: (state, action) => {
      state.hotelList.push(action.payload);
    },
    [createNewHotel.rejected]: (state, action) => {
      state.errorCode = action.payload;
    },
    [createNewHotelSchedule.fulfilled]: (state, action) => {
      state.hotelScheduledList.push(action.payload);
      const requestResult = state.hotelRequestList.filter(
        (req) => req._id !== action.payload.requestedId
      );
      state.hotelRequestList = [...requestResult];
    },
    [createNewHotelSchedule.rejected]: (state, action) => {
      state.errorCode = action.payload;
    },
    [updateHotel.fulfilled]: (state, action) => {
      state.hotelList = state.hotelList.map((hotel) =>
        hotel._id === action.payload._id ? action.payload : hotel
      );
    },
    [updateHotel.rejected]: (state, action) => {
      state.errorCode = action.payload;
    },
    [deleteHotel.fulfilled]: (state, action) => {
      const id = action.payload._id;
      const index = state.hotelList.map((item) => item._id).indexOf(id);
      state.hotelList.splice(index, 1);
    },
    [deleteHotel.rejected]: (state, action) => {
      state.errorCode = action.payload;
    },
  },
});

export const {
  setPrevThumbnailDetails,
  setPreThumbnailId,
  setPrevHotelImageDetails,
  setPrevHotelIds,
  resetErrorAlert,
} = HotelSlice.actions;
export default HotelSlice.reducer;
