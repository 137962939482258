import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import EventListAPI from '../serviceAPI/EventListAPI';

const initialState = {
  eventList: [],
  selectedEvent: {},
  filteredEvent: {},
  isLoading: false,
  prevMaterialDetails: null,
  prevMaterialIds: null,
  errorCode: null,
};

export const getAllEvents = createAsyncThunk(
  'getAllEvents',
  async (id, { rejectWithValue }) => {
    try {
      const res = await EventListAPI.GetAllEvents(id);
      return res;
    } catch (error) {
      return rejectWithValue(error.response.status);
    }
  }
);

export const createNewEvent = createAsyncThunk(
  'createNewEvent',
  async ({ id, newData }, { rejectWithValue }) => {
    try {
      const res = await EventListAPI.CreateNewEvent(newData, id);
      return res;
    } catch (error) {
      return rejectWithValue(error.response.status);
    }
  }
);

export const updateEvent = createAsyncThunk(
  'updateEvent',
  async ({ newData, id }, { rejectWithValue }) => {
    try {
      const res = await EventListAPI.UpdateEvent(newData, id);
      return res;
    } catch (error) {
      return rejectWithValue(error.response.status);
    }
  }
);

export const deleteEvent = createAsyncThunk(
  'deleteEvent',
  async (id, { rejectWithValue }) => {
    try {
      const res = await EventListAPI.DeleteEvent(id);
      return res;
    } catch (error) {
      return rejectWithValue(error.response.status);
    }
  }
);

const EventListSlice = createSlice({
  name: 'events',
  initialState,
  reducers: {
    updateSelectedEvent: (state, action) => {
      state.selectedEvent = action.payload;
    },
    setPrevMaterialDetails: (state, action) => {
      state.prevMaterialDetails = action.payload;
    },
    setPrevMaterialIds: (state, action) => {
      state.prevMaterialIds = action.payload;
    },
    resetErrorAlert: (state, action) => {
      state.errorCode = null;
    },
  },
  extraReducers: {
    [getAllEvents.pending]: (state) => {
      state.isLoading = true;
    },
    [getAllEvents.fulfilled]: (state, action) => {
      state.isLoading = false;
      state.eventList = [...action.payload];
    },
    [getAllEvents.rejected]: (state, action) => {
      state.isLoading = false;
      state.errorCode = action.payload;
    },
    [createNewEvent.fulfilled]: (state, action) => {
      state.eventList.push(action.payload);
    },
    [createNewEvent.rejected]: (state, action) => {
      state.errorCode = action.payload;
    },
    [updateEvent.fulfilled]: (state, action) => {
      state.eventList = state.eventList.map((event) =>
        event._id === action.payload._id ? action.payload : event
      );
    },
    [updateEvent.rejected]: (state, action) => {
      state.errorCode = action.payload;
    },
    [deleteEvent.fulfilled]: (state, action) => {
      const id = action.payload._id;
      const index = state.eventList.map((item) => item._id).indexOf(id);
      state.eventList.splice(index, 1);
    },
    [deleteEvent.rejected]: (state, action) => {
      state.errorCode = action.payload;
    },
  },
});

export const {
  updateSelectedEvent,
  setPrevMaterialDetails,
  setPrevMaterialIds,
  resetErrorAlert,
} = EventListSlice.actions;

export default EventListSlice.reducer;
