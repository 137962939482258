import { useState } from 'react';
import Required from '../../../common-components/Required';
import { useDispatch, useSelector } from 'react-redux';
import {
  fileDelete,
  fileUpload,
  previewImage,
  progressPercentage,
  resetMediaErrorAlert,
  setSelectedFile,
} from '../../../../slices/FileUploadSlice';
import FileUploader from '../../../common-components/FileUploader';
import CloseButton from '../../../custom-components/CloseButton';
import CancelButton from '../../../custom-components/CancelButton';
import BlackButton from '../../../custom-components/BlackButton';
import { TitansOfIndustryStringLabels } from '../../../../util/Strings';
import {
  audioFileUpload,
  fileMediaDelete,
  mediaProgressPercentage,
  resetErrorAlert,
  setMediaSelectedFile,
  setPrevMediaId,
  setPrevPhotoId,
  setPreviousFileDetails,
  setPreviousMediaFileDetails,
  videoMediaFileUpload,
} from '../../../../slices/SpeakerSlice';
import ImagePreviewWithFilename from '../../../common-components/ImagePreviewWithFilename';
import { NewSpeakerFormValidations } from '../../../../util/Validations';
import ErrorMessageComponent from '../../../common-components/ErrorMessageComponent';
import useFileValidation from '../../../../util/useFileValidation';
import FileMediaUploader from '../../../common-components/FileMediaUploader';
import audioThumbnail from '../../../../assets/speakers-audio.svg';
import videoThumbnail from '../../../../assets/speakers-video.svg';
import Select from 'react-select';
import ErrorAlert from '../../../common-components/ErrorAlert';
import ErrorMessages from '../../../../util/ErrorMessages';
import ConfirmationDialog from '../../../common-components/ConfirmationDialog';
import circleDelete from '../../../../assets/circle-delete.svg';

const FILE_EXT_REGEX_VIDEO = /\.(mp4|mov)$/;
const FILE_EXT_REGEX_AUDIO = /\.(mp3|wav)$/;

function AddNewSpeakerForm({ show, onClickNext, data }) {
  const dispatch = useDispatch();
  const fileUploadId = useSelector((state) => state.upload.id);
  const [state, setState] = useState({
    name: data && data.name ? data.name : '',
    designation: data && data.designation ? data.designation : '',
    designationOther: data && data.designationOther ? data.designationOther : '',
    practice: data && data.practice ? data.practice : '',
    about: data && data.about ? data.about : '',
    photoId: data && data.photoId ? data.photoId : null,
    videoId: data && data.videoId ? data.videoId : '',
    audioId: data && data.audioId ? data.audioId : '',
  });
  const [imageDeleteList, setImageDeleteList] = useState([]);
  const [showDelete, setShowDelete] = useState(false);
  const designationOptions = [
    { value: 1, label: 'Partner' },
    { value: 2, label: 'Managing Director' },
    { value: 3, label: 'Director' },
    { value: 4, label: 'Senior Manager' },
    { value: 5, label: 'Manager' },
    { value: 6, label: 'Senior Associate' },
    { value: 7, label: 'Associate' },
    { value: 8, label: 'Others' },
    { value: 9, label: 'Client' }
  ];

  const [prevFileData, setPreFileData] = useState({
    photoUrl: data && data.photoUrl ? data.photoUrl : null,
    fileName: data && data.fileName ? data.fileName : null,
  });
  const [prevFileId, setPrevFileId] = useState(
    data && data.photoId ? data.photoId : null
  );
  const prevImageId = useSelector((state) => state.speakers.prevPhotoId);
  const [mediaType, setMediaType] = useState(null);
  const [prevMediaFileData, setPreMediaFileData] = useState({
    mediaUrl:
      data && (data.video ? data.video : data.audio ? data.audio : null),
    fileName:
      data &&
      (data.videoName
        ? data.videoName
        : data.audioName
        ? data.audioName
        : null),
  });

  const [prevMediaFileId, setPrevMediaFileId] = useState(
    data && (data.videoId ? data.videoId : data.audioId ? data.audioId : null)
  );
  const [prevMediaType, setPrevMediaType] = useState(
    data && (data.video ? 'video' : data.audio ? 'audio' : null)
  );
  const prevMediaId = useSelector((state) => state.speakers.prevMediaId);

  const [validation, setValidation] = useState({});
  const {
    errorSingleMessage,
    setErrorSingleMessage,
    validateFile,
    validateVideoFile,
    errorVideoMessage,
    setErrorVideoMessage,
    errorAudioMessage,
    setErrorAudioMessage,
    validateAudio,
  } = useFileValidation();
  const [mediaError, setMediaError] = useState('');

  function handleChange(e) {
    const value = e.target.value;
    setState({
      ...state,
      [e.target.name]: value,
    });
  }
  const resetPrevFileData = useSelector(
    (state) => state.speakers.prevFileDetails
  );
  const resetPrevMediaFileData = useSelector(
    (state) => state.speakers.prevMediaFileDetails
  );
  const errorMessageCode = useSelector((state) => state.upload.errorCode);
  const errorMediaCode = useSelector((state) => state.speakers.errorCode);

  function handleImageCancel() {
    setState({
      ...state,
      ['photoId']: null,
    });
    prevFileData.photoUrl = null;
    setImageDeleteList([...imageDeleteList, prevFileId]);
  }

  function handleMediaCancel() {
    setState({
      ...state,
      ['videoId']: null,
      ['audioId']: null,
    });
    setPreMediaFileData({ ...prevMediaFileData, ['mediaUrl']: null });
    setImageDeleteList([...imageDeleteList, prevMediaFileId]);
  }

  function handleCloseErrorAlert() {
    dispatch(resetMediaErrorAlert(false));
    setPreFileData(resetPrevFileData);
    handleState();
    dispatch(setSelectedFile(null));
    setImageDeleteList([]);
    onClickNext();
  }

  function handleCloseMediaErrorAlert() {
    dispatch(resetErrorAlert(false));
    setPreMediaFileData(resetPrevMediaFileData);
    handleMediaState();
    dispatch(setMediaSelectedFile(null));
    onClickNext();
  }
  const [photoUrl, setPhotoUrl] = useState(null);

  function resetImage() {
    dispatch(progressPercentage(0));
    dispatch(setSelectedFile(null));
    dispatch(previewImage(null));
  }

  function resetMedia() {
    dispatch(mediaProgressPercentage(0));
    dispatch(setMediaSelectedFile(null));
  }

  function handleState() {
    setState({ ...state, ['photoId']: data && data.photoId });
  }

  function handleMediaState() {
    if (mediaType === 'video') {
      setState({ ...state, ['videoId']: data && data.videoId });
    } else if (mediaType === 'audio') {
      setState({ ...state, ['audioId']: data && data.audioId });
    }
  }

  const checkValidation = () => {
    const { errors, isValid } = NewSpeakerFormValidations(state);
    setValidation(errors);
    return isValid;
  };

  function handleCreateButton() {
    const currentMediaId =
      mediaType === 'audio'
        ? state.audioId
        : mediaType === 'video'
        ? state.videoId
        : null;
    if (checkValidation()) {
      if (state.name && state.designation && state.practice && state.about) {
        resetImage();
        resetMedia();
        if (data) {
          const newData = {
            name: state.name,
            designation: state.designation,
            designationOther: state.designationOther,
            practice: state.practice,
            about: state.about,
            photoId: state.photoId,
          };
          imageDeleteList &&
            imageDeleteList.map((image) => {
              dispatch(fileDelete({ id: image }));
            });
          if (mediaType === 'audio') {
            newData['audioId'] = state.audioId;
            newData['videoId'] = null;
          } else if (mediaType === 'video') {
            newData['videoId'] = state.videoId;
            newData['audioId'] = null;
          } else {
            newData['videoId'] = state.videoId;
            newData['audioId'] = state.audioId;
          }

          setPhotoUrl(null);
          onClickNext(newData);

          setErrorSingleMessage('');
          setErrorVideoMessage('');
          setErrorVideoMessage('');
          if (state.photoId !== prevImageId && prevImageId !== '') {
            const id = { id: prevImageId, isVideo: false };
            dispatch(fileDelete(id));
            dispatch(setPrevPhotoId(''));
          }
          setPreFileData({
            photoUrl: data && data.photoUrl ? data.photoUrl : null,
            fileName: data && data.fileName ? data.fileName : null,
          });

          if (currentMediaId !== prevMediaId && prevMediaId !== null) {
            const id = {
              id: prevMediaId,
              isVideo: prevMediaType === 'video' ? true : false,
            };
            dispatch(fileMediaDelete(id));
            dispatch(setPrevMediaId(''));
            setImageDeleteList([]);
          }
          setPreMediaFileData({
            mediaUrl:
              data &&
              (data.video ? data.video : data.audio ? data.audio : null),
            fileName:
              data &&
              (data.videoName
                ? data.videoName
                : data.audioName
                ? data.audioName
                : null),
          });
          setPrevMediaType(mediaType);
        } else {
          setState({});
          setPhotoUrl(null);
          setImageDeleteList([]);
          onClickNext(state);
        }
      } else {
        onClickNext();
      }
    }
  }

  if (!show) {
    return null;
  }

  const handleFileSelect = function (event) {
    const files = event.target.files;
    postFileData(files);
  };

  const handleMediaFileSelect = function (event) {
    const files = event.target.files;
    postMediaFileData(files);
  };

  const handleDrag = function (event) {
    event.preventDefault();
  };

  const handleDrop = function (event) {
    event.preventDefault();
    const files = event.dataTransfer.files;
    postFileData(files);
  };

  const handleMediaDrop = function (event) {
    event.preventDefault();
    const files = event.dataTransfer.files;
    postMediaFileData(files);
  };

  function postMediaFileData(files) {
    var valid = false;
    if (files[0].name.match(FILE_EXT_REGEX_VIDEO)) {
      setMediaType('video');
      valid = validateVideoFile(files);
      if (valid) {
        const file = files[0];
        if (prevMediaFileData) {
          dispatch(setPrevMediaId(prevMediaFileId));
          setPrevMediaFileId(prevMediaFileId);
          dispatch(setPreviousMediaFileDetails(prevMediaFileData));
          setPreMediaFileData({ video: null, audio: null, fileName: null });
        }
        if (file) {
          dispatch(setMediaSelectedFile(file));
          dispatch(videoMediaFileUpload(file))
            .unwrap()
            .then((res) => {
              setState({ ...state, ['videoId']: res[0]._id });
              setPrevMediaFileId(res[0]._id);
            });
        }
      }
    } else if (files[0].name.match(FILE_EXT_REGEX_AUDIO)) {
      setMediaType('audio');
      valid = validateAudio(files);
      if (valid) {
        const file = files[0];
        if (prevMediaFileData) {
          dispatch(setPrevMediaId(prevMediaFileId));
          setPrevMediaFileId(prevMediaFileId);
          dispatch(setPreviousMediaFileDetails(prevMediaFileData));
          setPreMediaFileData({ video: null, audio: null, fileName: null });
        }
        if (file) {
          dispatch(setMediaSelectedFile(file));
          dispatch(audioFileUpload(file))
            .unwrap()
            .then((res) => {
              setState({ ...state, ['audioId']: res[0]._id });
              setPrevMediaFileId(res[0]._id);
            });
        }
      }
    } else
      setMediaError('Please upload valid file type - mp3, wav, mp4 or mov');
  }

  function postFileData(files) {
    const valid = validateFile(files);
    if (valid) {
      const file = files[0];
      if (prevFileData) {
        dispatch(setPrevPhotoId(prevFileId));
        setPrevFileId(prevFileId);
        dispatch(setPreviousFileDetails(prevFileData));
        setPreFileData({ photoUrl: null, fileName: null });
      }
      if (file) {
        dispatch(setSelectedFile(file));
        dispatch(fileUpload(file))
          .unwrap()
          .then((res) => {
            setPhotoUrl(res[0]._id);
            setState({ ...state, ['photoId']: res[0]._id });
            setPrevFileId(res[0]._id);
          });
      }
    }
  }

  if (errorMessageCode || errorMediaCode) {
    return (
      <ErrorAlert
        show={errorMessageCode || errorMediaCode}
        onClickHandle={
          errorMediaCode ? handleCloseMediaErrorAlert : handleCloseErrorAlert
        }
        title="Error"
        subTitle={ErrorMessages(errorMessageCode)}
      />
    );
  }

  return (
    <div className="modal">
      <div className="form-scroll">
        <div className="form-flex">
          <div
            className="user-form-modal-content"
            onClick={(e) => e.stopPropagation()}
          >
            <div className="form-header">
              <div className="header">
                {/* New Speaker */}
                {data ? 'Update Collaborator' : 'Add New Collaborator'}
              </div>
              <CloseButton
                onClickhandle={() => {
                  resetImage();
                  resetMedia();
                  if (data === null) {
                    setState({});
                    setValidation({});
                  }
                  setPreFileData({
                    photoUrl: data && data.photoUrl ? data.photoUrl : null,
                    fileName: data && data.fileName ? data.fileName : 'file',
                  });
                  setPreMediaFileData({
                    mediaUrl:
                      data &&
                      (data.video
                        ? data.video
                        : data.audio
                        ? data.audio
                        : null),
                    fileName:
                      data &&
                      (data.videoName
                        ? data.videoName
                        : data.audioName
                        ? data.audioName
                        : null),
                  });
                  setImageDeleteList([]);
                  setErrorSingleMessage('');
                  onClickNext();
                }}
              />
            </div>

            <div className="form-container">
              <div className="form-label-input">
                <label className="form-label">
                  {TitansOfIndustryStringLabels.name}
                  <Required />
                </label>
                <div className="form-input-container">
                  <input
                    type="text"
                    value={state.name}
                    name="name"
                    onChange={(e) => handleChange(e)}
                    className="form-input"
                    placeholder="Input"
                    required={true}
                  ></input>
                </div>
                <ErrorMessageComponent message={validation.name} />
              </div>

              <div className="form-label-input-select">
                <label className="form-label">
                  {TitansOfIndustryStringLabels.jobTitle}
                  <Required />
                </label>
                <Select
                  options={designationOptions}
                  className="basic-single"
                  classNamePrefix="select"
                  name="designation"
                  defaultValue={
                    data &&
                    designationOptions.filter(
                      (designation) => designation.label === data.designation
                    )
                  }
                  closeMenuOnSelect={true}
                  onChange={(e) => {
                    setState({
                      ...state,
                      ['designation']: e.label,
                    });
                  }}
                />
                <ErrorMessageComponent message={validation.designation} />
              </div>

              {(state.designation === 'Others' || state.designation === 'Client') ?<div className="form-label-input">
            <label className="form-label">
              {TitansOfIndustryStringLabels.jobTitleOther}
              <Required />
            </label>
            <div className="form-input-container">
              <input
                className="form-input"
                type="text"
                value={state.designationOther}
                name="designationOther"
                onChange={(e) => handleChange(e)}
                placeholder="Input"
                required={true}
              ></input>
            </div>
            <ErrorMessageComponent message={validation.designationOther} />
          </div>:''}

              <div className="form-label-input">
                <label className="form-label">
                  {TitansOfIndustryStringLabels.practice}
                  <Required />
                </label>
                <div className="form-input-container">
                  <input
                    className="form-input"
                    type="text"
                    value={state.practice}
                    name="practice"
                    onChange={(e) => handleChange(e)}
                    placeholder="Input"
                    required={true}
                  ></input>
                </div>
                <ErrorMessageComponent message={validation.practice} />
              </div>

              <div className="form-label-input">
                <label className="form-label">
                  {TitansOfIndustryStringLabels.aboutMe}
                  <Required />
                </label>
                <div className="user-form-input-container">
                  <textarea
                    className="form-input"
                    type="text"
                    value={state.about}
                    name="about"
                    onChange={(e) => handleChange(e)}
                    placeholder="Input"
                    rows={3}
                  ></textarea>
                </div>
                <ErrorMessageComponent message={validation.about} />
              </div>

              <FileUploader
                previewShape="circular"
                headerName="Collaborator Photo"
                handleDragOver={handleDrag}
                handleDrop={handleDrop}
                handleFileSelect={handleFileSelect}
                setPreFileData={setPreFileData}
                handleState={handleState}
                type="titan"
              />
              {errorSingleMessage !== '' && (
                <p className="error image-error">{errorSingleMessage}</p>
              )}
              {prevFileData &&
              prevFileData.photoUrl &&
              data &&
              data.photoUrl &&
              data.fileName ? (
                <ImagePreviewWithFilename
                  photoUrl={data.photoUrl}
                  fileName={data.fileName}
                  previewShape="circular"
                  handleCancel={handleImageCancel}
                />
              ) : null}

              <FileMediaUploader
                previewShape="circular"
                headerName="Video/Audio"
                handleDragOver={handleDrag}
                handleDrop={handleMediaDrop}
                handleFileSelect={handleMediaFileSelect}
                setPreFileData={setPreMediaFileData}
                handleState={handleMediaState}
                type={mediaType}
              />
              {errorAudioMessage !== '' ? (
                <p className="error image-error">{errorAudioMessage}</p>
              ) : errorVideoMessage !== '' ? (
                <p className="error image-error">{errorVideoMessage}</p>
              ) : (
                <p className="error image-error">{mediaError}</p>
              )}
              {prevMediaFileData &&
              prevMediaFileData.mediaUrl &&
              data &&
              (data.video || data.audio) ? (
                <div className="existing-media-file-details">
                  <img
                    className="preview-media"
                    src={
                      prevMediaType === 'audio'
                        ? audioThumbnail
                        : videoThumbnail
                    }
                    alt="preview img"
                  />
                  <div className="media-name">
                    {data.hasOwnProperty('videoName')
                      ? data.videoName
                      : data.hasOwnProperty('audioName')
                      ? data.audioName
                      : null}
                  </div>
                  <div className="close-image">
                    <img
                      src={circleDelete}
                      alt="close"
                      onClick={() => {
                        setShowDelete(true);
                      }}
                    />
                  </div>
                  <ConfirmationDialog
                    show={showDelete}
                    buttonName="Delete"
                    onClickHandle={(flag) => {
                      if (flag) {
                        handleMediaCancel();
                      }
                      setShowDelete(false);
                    }}
                    title="Delete Media"
                    subTitle={`Are you sure you want to delete this audio/video`}
                  />
                </div>
              ) : null}
              <div className="footer-button-container">
                <CancelButton
                  onClickhandle={() => {
                    resetImage();
                    resetMedia();
                    if (data === null) {
                      setState({});
                      setValidation({});
                    }
                    setPreFileData({
                      photoUrl: data && data.photoUrl ? data.photoUrl : null,
                      fileName: data && data.fileName ? data.fileName : null,
                    });
                    setPreMediaFileData({
                      mediaUrl:
                        data &&
                        (data.video
                          ? data.video
                          : data.audio
                          ? data.audio
                          : null),
                      fileName:
                        data &&
                        (data.videoName
                          ? data.videoName
                          : data.audioName
                          ? data.audioName
                          : null),
                    });
                    setImageDeleteList([]);
                    setErrorSingleMessage('');
                    onClickNext();
                  }}
                >
                  {TitansOfIndustryStringLabels.cancel}
                </CancelButton>
                <BlackButton onClickhandle={() => handleCreateButton()}>
                  {data
                    ? TitansOfIndustryStringLabels.update
                    : TitansOfIndustryStringLabels.add}
                </BlackButton>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default AddNewSpeakerForm;
