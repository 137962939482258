import UserFeedbackAPI from '../serviceAPI/UserFeedbackAPI';
import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';

const initialState = {
  FeedbackListData: [],
  isLoading: false,
  errorCode: null,
  errorMessage: null,
};

export const getAllFeedbacks = createAsyncThunk(
  'getAllFeedbacks',
  async (anyNameOrEven_, { rejectWithValue }) => {
    try {
      const res = await UserFeedbackAPI.GetAllFeedbacks();
      return res;
    } catch (error) {
      return rejectWithValue(error.response.status);
    }
  }
);

export const createFeedbackByUser = createAsyncThunk(
  'createFeedbackByUser',
  async (newData, { rejectWithValue }) => {
    try {
      const res = await UserFeedbackAPI.CreateFeedbackByUser(newData);
      return res;
    } catch (error) {
      return rejectWithValue(error.response);
    }
  }
);

export const getAllFeedbacksByUser = createAsyncThunk(
  'getAllFeedbacksByUser',
  async ({ newData, id }, { rejectWithValue }) => {
    try {
      const res = await UserFeedbackAPI.getAllFeedbacksByUser(newData, id);
      return res;
    } catch (error) {
      return rejectWithValue(error.response);
    }
  }
);

export const deleteFeedbackByUser = createAsyncThunk(
  'deleteFeedbackByUser',
  async (id, { rejectWithValue }) => {
    try {
      const res = await UserFeedbackAPI.DeleteFeedbackByUser(id);
      return res;
    } catch (error) {
      return rejectWithValue(error.response.status);
    }
  }
);

const UserFeedbackSlice = createSlice({
  name: 'userfeedback',
  initialState,
  reducers: {
    resetErrorAlert: (state, action) => {
      state.errorCode = null;
      state.errorMessage = null;
    },
  },
  extraReducers: {
    [getAllFeedbacks.pending]: (state, action) => {
      state.isLoading = true;
    },
    [getAllFeedbacks.fulfilled]: (state, action) => {
      state.isLoading = false;
      state.FeedbackListData = [...action.payload];
    },
    [getAllFeedbacks.rejected]: (state, action) => {
      state.isLoading = false;
      state.errorCode = action.payload;
    },
    [createFeedbackByUser.fulfilled]: (state, action) => {
      state.FeedbackListData.push(action.payload);
    },
    [createFeedbackByUser.rejected]: (state, action) => {
      state.errorMessage = action.payload.data.message
      state.errorCode = action.payload.status;
    },
    [getAllFeedbacksByUser.fulfilled]: (state, action) => {
      state.FeedbackListData = state.FeedbackListData.map((userFeedbackAPI) =>
      userFeedbackAPI._id === action.payload._id ? action.payload : userFeedbackAPI
      );
    },
    [getAllFeedbacksByUser.rejected]: (state, action) => {
      state.errorMessage = action.payload.data.message
      state.errorCode = action.payload.status;
    },
    [deleteFeedbackByUser.fulfilled]: (state, action) => {
      const id = action.payload._id;
      const index = state.FeedbackListData.map((item) => item._id).indexOf(id);
      state.FeedbackListData.splice(index, 1);
    },
    [deleteFeedbackByUser.rejected]: (state, action) => {
      state.errorCode = action.payload;
    },
  },
});

export const { resetErrorAlert } = UserFeedbackSlice.actions;
export default UserFeedbackSlice.reducer;
