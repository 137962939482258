import { Pass } from './Strings';
import CryptoJS from 'crypto-js';
function EncryptData(data) {
  const encryptedData = CryptoJS.AES.encrypt(
    JSON.stringify({ data }),
    Pass
  ).toString();
  return encryptedData;
}

function DecryptData(data) {
  const bytes = CryptoJS.AES.decrypt(data, Pass);
  const decryptedData = JSON.parse(bytes.toString(CryptoJS.enc.Utf8));
  return decryptedData.data;
}

export { EncryptData, DecryptData };
