import { useDispatch, useSelector } from "react-redux";
import "../../../../styles/screens/client-visit-details/manage-module/SpeakersList.css";
import Loader from "../../../common-components/Loader";
import { useEffect } from "react";
import ErrorAlert from "../../../common-components/ErrorAlert";
import ErrorMessages from "../../../../util/ErrorMessages";
import ACInfoCard from "../ACInfoCard";
import {
  getToKnowACData,
  resetErrorAlert,
} from "../../../../slices/GetToKnowACSlice";
import { GetToKnowAC } from "../../../../util/Strings";

function GetToKnowACList() {
  const data = useSelector((state) => state.ac.acData);
  const isLoading = useSelector((state) => state.ac.isLoading);
  const id = useSelector((state) => state.visits.selectedVisitId);
  const errorMessageCode = useSelector((state) => state.ac.errorCode);
  const selectedOfficeId = useSelector(
    (state) => state.officelist.selectedOfficeId
  );
  const dispatch = useDispatch();

  function getAcData() {
    dispatch(getToKnowACData(selectedOfficeId));
  }

  function handleCloseErrorAlert() {
    dispatch(resetErrorAlert(false));
  }

  useEffect(() => {
    getAcData();
  }, []);

  if (isLoading) {
    return <Loader />;
  }
  if (errorMessageCode) {
    return (
      <ErrorAlert
        show={errorMessageCode}
        onClickHandle={handleCloseErrorAlert}
        title="Error"
        subTitle={ErrorMessages(errorMessageCode)}
      />
    );
  }

  return (
    <div className="speakers-list-container">
      <div className="speakers-list-heading">{GetToKnowAC.getToKnowAC}</div>
      <div className="speakers-list-body">
        {data &&
          data.map((item) => {
            return <ACInfoCard data={item} key={item._id} />;
          })}
      </div>
    </div>
  );
}

export default GetToKnowACList;
