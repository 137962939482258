import '../../../../styles/screens/client-visit-details/manage-module/EventsList.css';
import { RequestScreenStringLabels } from '../../../../util/Strings';
import CabListTable from './CabListTable';
function CabsList({ handleBook, isRequest }) {
  return (
    // <div className="events-list-container">
    // <div className="events-list-content">
    <CabListTable handleBook={handleBook} isRequest={isRequest} />
    // </div>
    // </div>
  );
}

export default CabsList;
