import "../../../../styles/screens/client-visit-details/manage-module/ManageModules.css";
import { useState, useEffect } from "react";
import AddNewSpeakerForm from "./AddNewSpeakerForm";
import AddNewEventForm from "./AddNewEventForm";
import AddHotelForm from "./AddHotelForm";
import { useDispatch, useSelector } from "react-redux";
import { createNewSpeaker } from "../../../../slices/SpeakerSlice";
import {
  getWelcomePageData,
  setShowVideoProgressBar,
  setWelcomeEmailErrorMessage,
  setWelcomeMessageErrorMessage,
  setWelcomePageData,
  updateWelcomeData,
} from "../../../../slices/WelcomePageSlice";
import {
  createNewEvent,
  getAllEvents,
} from "../../../../slices/EventListSlice";
import { createNewHotel } from "../../../../slices/HotelSlice";
import { NavLink, Outlet, useLocation } from "react-router-dom";
import AddNewRefuelForm from "./AddNewRefuelForm";
import AddNewRecreationForm from "./AddNewRecreationForm";
import { createNewRefuelData } from "../../../../slices/RefuelSlice";
import { createNewRecreationData } from "../../../../slices/RecreationSlice";
import {
  FormValidationStringLabels,
  ManageModulesStringLabels,
} from "../../../../util/Strings";
import { namesWithMoreSpecialCharacterCondition } from "../../../../util/Validations";

function ManageModules() {
  const location = useLocation();
  const [state, setState] = useState(stateFromUrl());
  const [showCreateNewForm, setShowCreateNewForm] = useState(false);
  const visit = useSelector((state) => state.visits);
  const id = visit.selectedVisitId;
  const selectedOfficeId = useSelector(
    (state) => state.officelist.selectedOfficeId
  );

  const dispatch = useDispatch();
  const welcomeDetails = useSelector((state) => state.welcomePage);
  const welcomevideoUploadStatus = welcomeDetails.welcomeVideoStatus;
  const welcomeFileDetails = welcomeDetails.welcomeVideoFile;
  const welcomeUpdateButton = conditionsForEnablingUpdateButton();
  const videoFileUrl = useSelector(
    (state) => state.welcomePage.welcomeVideoFileURI
  );
  const videoId = useSelector((state) => state.welcomePage.savedVideoId);
  const isSubAdmin = useSelector((state) => state.login.isSubAdmin);
  const namesWithSpecialCharacterCondition = /^[a-zA-Z0-9!#$%&'*+\\/@\-()*[\]{}?,><^%$#~!àèìòùÀÈÌÒÙáéíóúýÁÉÍÓÚÝâêîôûÂÊÎÔÛãñõÃÑÕ\s]+$/;  
  //const emailRegexConditions = /^[a-z0-9]+@pwc.com+$/;

  var menuState;
  function stateFromUrl() {
    var url = location.pathname;
    if (url.includes("welcome")) {
      menuState = "WelcomePage";
    }
    // ac start
    // else if (url.includes('ac')) {
    //   state = 'AC';
    // }
    // ac end
    else if (url.includes("collaborators")) {
      menuState = "Speakers";
    } else if (url.includes("events")) {
      menuState = "Events";
    } else if (url.includes("hotels")) {
      menuState = "Hotels";
    } else if (url.includes("refuel")) {
      menuState = "Refuel";
    } else if (url.includes("roam")) {
      menuState = "Recreation";
    }
  }

  function conditionsForEnablingUpdateButton() {
    if (welcomevideoUploadStatus === null) {
      return (
        welcomeDetails.updatedWelcomePageData.message ===
        welcomeDetails.welcomePageData.message
        // &&welcomeDetails.updatedWelcomePageData.conciergeMail ===
        // welcomeDetails.welcomePageData.conciergeMail
      );
    } else if (
      welcomevideoUploadStatus === "pending" ||
      welcomevideoUploadStatus === "start"
    ) {
      return true;
    } else {
      return false;
    }
  }

  async function getEventsData() {
    dispatch(getAllEvents(id));
  }

  async function getWelcomePageDetails() {
    dispatch(getWelcomePageData(id));
  }

  useEffect(() => {
    getWelcomePageDetails();
    stateFromUrl();
  }, []);

  async function handleAddNewSpeakerFormSubmit(newData) {
    if (newData) {
      const data = { id: selectedOfficeId, newData: newData };
      dispatch(createNewSpeaker(data));
    }
    setShowCreateNewForm(false);
  }
  // ac start
  // async function handleAddNewACFormSubmit(newData) {
  //   if (newData) {
  //     const data = { id: id, newData: newData };
  //     dispatch(createNewGetToKnowAC(data));
  //   }
  //   setShowCreateNewForm(false);
  // }
  // ac end
  async function handleAddNewEventFormSubmit(newData) {
    if (newData) {
      const event = {
        id: id,
        newData: newData,
      };
      dispatch(createNewEvent(event))
        .unwrap()
        .then((res) => {
          getEventsData();
        });
    }
    setShowCreateNewForm(false);
  }

  const checkValidation = () => {
    const {
      isMessageValid,
      // , isEmailValid
    } = welcomePageFormValidation(welcomeDetails.updatedWelcomePageData);
    const isValid = isMessageValid;
    //&& isEmailValid;
    return isValid;
  };

  async function handleUpdateWelcomePageDetails() {
    let welcome = {
      id: id,
      newData: welcomeDetails.updatedWelcomePageData,
      // videoUrl: videoFileUrl
    };
    if (videoId !== 0) {
      welcome["videoId"] = videoId;
    }
    dispatch(setWelcomePageData(welcome));
    if (!checkValidation()) {
      return;
    } else {
      dispatch(setWelcomeEmailErrorMessage(""));
      dispatch(setWelcomeMessageErrorMessage(""));
      dispatch(updateWelcomeData(welcome));
      dispatch(setShowVideoProgressBar(false));
    }
  }

  function welcomePageFormValidation(welcomeData) {
    let isMessageValid = true;
    // let isEmailValid = true;
    // welcome message validations
    if (!welcomeData.message) {
      dispatch(
        setWelcomeMessageErrorMessage(FormValidationStringLabels.welcomeMessage)
      );
      isMessageValid = false;
    } else if (welcomeData.message.length > 250) {
      dispatch(
        setWelcomeMessageErrorMessage(FormValidationStringLabels.maxLength250)
      );
      isMessageValid = false;
    } else if (!welcomeData.message.match(namesWithMoreSpecialCharacterCondition)) {
      dispatch(
        setWelcomeMessageErrorMessage(
          `Message ${FormValidationStringLabels.specialCharsRegexCondition}`
        )
      );
      isMessageValid = false;
    }

    // welcome concierge email validations
    // if (!welcomeData.conciergeMail) {
    //   dispatch(
    //     setWelcomeEmailErrorMessage(FormValidationStringLabels.conciergeMail)
    //   );
    //   isEmailValid = false;
    // } else if (!welcomeData.conciergeMail.match(emailRegexConditions)) {
    //   dispatch(
    //     setWelcomeEmailErrorMessage(
    //       FormValidationStringLabels.emailRegexCondition
    //     )
    //   );
    //   isEmailValid = false;
    // }
    return {
      isMessageValid,
      //isEmailValid
    };
  }

  // async function handleAddNewTouristFormSubmit(newData) {
  //   if (newData) {
  //     const data = { id: id, newData: newData };
  //     dispatch(createNewTouristData(data));
  //   }
  //   setShowCreateNewForm(false);
  // }

  function handleAddNewRefuelFormSubmit(newData) {
    if (newData) {
      const refuel = {
        id: selectedOfficeId,
        newData: newData,
      };
      dispatch(createNewRefuelData(refuel));
    }
    setShowCreateNewForm(false);
  }

  function handleAddNewRecreationFormSubmit(newData) {
    if (newData) {
      const recreation = {
        id: selectedOfficeId,
        newData: newData,
      };
      dispatch(createNewRecreationData(recreation));
    }
    setShowCreateNewForm(false);
  }

  async function handleHotelListFormSubmit(newData) {
    if (newData) {
      const hotel = {
        id: selectedOfficeId,
        newData: newData,
      };
      dispatch(createNewHotel(hotel));
    }
    setShowCreateNewForm(false);
  }

  function resetForm() {
    setShowCreateNewForm(false);
  }

  return (
    <div className='manage-modules-container'>
      <div className='manage-modules-main-container'>
        <div className='manage-modules-header-container'>
          <div className='manage-modules-tabcontainer'>
            {/* <NavLink
              className={({ isActive }) => {
                return isActive
                  ? "manage-modules-tablinks-active"
                  : "manage-modules-tablinks";
              }}
              end
              to={`/client/${id}/manage-modules/welcome`}
              onClick={() => setState("WelcomePage")}
            >
              Welcome Page
              {ManageModulesStringLabels.gateWayToPossibilities}
            </NavLink> */}
            {/* ac start */}
            {/* <NavLink
              className={({ isActive }) => {
                return isActive
                  ? 'manage-modules-tablinks-active'
                  : 'manage-modules-tablinks';
              }}
              end
              to={`/client/${id}/manage-modules/ac`}
              // replace={true}
              onClick={() => {
                setState('AC');
                resetForm();
              }}
            >
            // ac end
              {ManageModulesStringLabels.getToKnowAC}
            </NavLink> */}
            {/* <NavLink
              className={({ isActive }) => {
                return isActive
                  ? "manage-modules-tablinks-active"
                  : "manage-modules-tablinks";
              }}
              end
              to={`/client/${id}/manage-modules/collaborators`}
              // replace={true}
              onClick={() => {
                setState("Speakers");
                resetForm();
              }}
            >
              {ManageModulesStringLabels.titansOfIndustry}
            </NavLink> */}
            {/* <NavLink
              className={({ isActive }) => {
                return isActive
                  ? "manage-modules-tablinks-active"
                  : "manage-modules-tablinks";
              }}
              end
              to={`/client/${id}/manage-modules/events`}
              onClick={() => {
                setState("Events");
                resetForm();
              }}
            >
              {ManageModulesStringLabels.rendezvous}
            </NavLink> */}
            {/* <NavLink
              className={({ isActive }) => {
                return isActive
                  ? "manage-modules-tablinks-active"
                  : "manage-modules-tablinks";
              }}
              end
              to={`/client/${id}/manage-modules/hotels`}
              onClick={() => setState("Hotels")}
            >
               
              {ManageModulesStringLabels.recharge}
            </NavLink>
            <NavLink
              className={({ isActive }) => {
                return isActive
                  ? "manage-modules-tablinks-active"
                  : "manage-modules-tablinks";
              }}
              end
              to={`/client/${id}/manage-modules/refuel`}
              onClick={() => {
                setState("Refuel");
                resetForm();
              }}
            >
              {ManageModulesStringLabels.refuel}
            </NavLink>
            {/* <NavLink
              className={({ isActive }) => {
                return isActive
                  ? 'manage-modules-tablinks-active'
                  : 'manage-modules-tablinks';
              }}
              end
              to={`/client/${id}/manage-modules/tourism`}
              onClick={() => {
                setState('Tourism');
                resetForm();
              }}
            >
              Roam
            </NavLink>

            <NavLink
              className={({ isActive }) => {
                return isActive
                  ? "manage-modules-tablinks-active"
                  : "manage-modules-tablinks";
              }}
              end
              to={`/client/${id}/manage-modules/roam`}
              onClick={() => {
                setState("Recreation");
                resetForm();
              }}
            >
              {ManageModulesStringLabels.roam}
            </NavLink> */}
          </div>
          {menuState === "WelcomePage" ? (
            <button
              disabled={welcomeUpdateButton || isSubAdmin}
              onClick={() => handleUpdateWelcomePageDetails()}
              className={
                welcomeUpdateButton || isSubAdmin
                  ? "button-style-disabled"
                  : "button-style"
              }
            >
              {ManageModulesStringLabels.save}
            </button>
          ) : (
            <button
              onClick={() => setShowCreateNewForm(true)}
              disabled={isSubAdmin}
              className={
                isSubAdmin
                  ? "button-style-disabled"
                  : "button-style"
              }
            >
              {ManageModulesStringLabels.add}
            </button>
          )}
        </div>
        {state === "Speakers" ? (
          <>
            <AddNewSpeakerForm
              show={showCreateNewForm}
              onClickNext={handleAddNewSpeakerFormSubmit}
              data={null}
            />
          </>
        ) : // ac start
        // : state === 'AC' ? (
        //   <>
        //     <AddGetToKnowACForm
        //       show={showCreateNewForm}
        //       onClickNext={handleAddNewACFormSubmit}
        //       data={null}
        //     />
        //   </>
        // )
        // ac end

        menuState === "Events" ? (
          <>
            <AddNewEventForm
              show={showCreateNewForm}
              onClickNext={handleAddNewEventFormSubmit}
              data={null}
            />
          </>
        ) : // state === 'Tourism' ? (
        //   <>
        //     <AddNewTouristForm
        //       show={showCreateNewForm}
        //       onClickNext={handleAddNewTouristFormSubmit}
        //     />
        //   </>
        // ) :
        state === "Refuel" ? (
          <>
            <AddNewRefuelForm
              show={showCreateNewForm}
              onClickNext={handleAddNewRefuelFormSubmit}
              data={null}
            />
          </>
        ) : state === "Recreation" ? (
          <>
            <AddNewRecreationForm
              show={showCreateNewForm}
              onClickNext={handleAddNewRecreationFormSubmit}
              data={null}
            />
          </>
        ) : null}
        <Outlet />
      </div>
      {state === "Hotels" && showCreateNewForm ? (
        <AddHotelForm
          onClickSubmit={handleHotelListFormSubmit}
          show={showCreateNewForm}
          data={null}
        />
      ) : null}
    </div>
  );
}

export default ManageModules;
