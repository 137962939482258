import { useState } from 'react';
import Button from '../custom-components/Button';
import '../../styles/login/LoginScreen.css';
import Required from '../common-components/Required';
import { LoginScreenStringLabels } from '../../util/Strings';
import { useDispatch, useSelector } from 'react-redux';
import { pwcLogin, resetErrorAlert } from '../../slices/LoginSlice';
import ErrorAlert from '../common-components/ErrorAlert';
import ErrorMessages from '../../util/ErrorMessages';

function LoginScreen({ onLogin }) {
  //start: dev login
  // const [userName, setUserName] = useState(null);
  // const [password, setPassword] = useState(null);
  //end: dev login
  const errorMessageCode = useSelector((state) => state.login.errorCode);
  const dispatch = useDispatch();

  function handleCloseErrorAlert() {
    dispatch(resetErrorAlert(false));
  }
  //start: dev login
  // function handleLoginButton() {
  //   if (userName && password) {
  //     const loginDetails = {
  //       userName: userName,
  //       password: password,
  //     };
  //     setUserName(null);
  //     setPassword(null);
  //     onLogin(loginDetails);
  //   }
  // }
  //end: dev login
  async function handlePwcLogin() {
    dispatch(pwcLogin());
  }

  if (errorMessageCode) {
    return (
      <ErrorAlert
        show={errorMessageCode}
        onClickHandle={handleCloseErrorAlert}
        title="Error"
        subTitle={ErrorMessages(errorMessageCode)}
      />
    );
  }

  return (
    <div className="login-modal">
      <div className="login-modal-content" onClick={(e) => e.stopPropagation()}>
        <div className="login-header">{LoginScreenStringLabels.header}</div>
        {/* start: dev login */}
        {/* <div className="login-form-container">
          <div className="form-label-input">
            <label className="form-label">
              {LoginScreenStringLabels.userName}
              <Required />
            </label>
            <div className="form-input-container">
              <input
                type="text"
                value={userName}
                onChange={(e) => setUserName(e.target.value)}
                className="form-input"
                placeholder="Enter User Name"
                required={true}
              ></input>
            </div>
          </div>

          <div className="form-label-input">
            <label className="form-label">
              {LoginScreenStringLabels.password}
              <Required />
            </label>
            <div className="form-input-container">
              <input
                className="form-input"
                type="password"
                value={password}
                onChange={(e) => setPassword(e.target.value)}
                placeholder="Enter Password"
                required={true}
              ></input>
            </div>
          </div>
        </div> */}
        {/* end: dev login */}
        <Button
          styleContiner={'login-button-container'}
          onClickhandle={() => {
            handlePwcLogin();
          }}
        >
          {LoginScreenStringLabels.pwcLogin}
        </Button>
        {/* start: dev login */}
        {/* <Button
          styleContiner={'login-button-container'}
          onClickhandle={() => handleLoginButton()}
        >
          {LoginScreenStringLabels.login}
        </Button> */}
        {/* end: dev login */}
      </div>
    </div>
  );
}
export default LoginScreen;
