import { useSelector } from 'react-redux';
import circleDelete from '../../assets/circle-delete.svg';
import '../../styles/common-components/FileDetailsWithUploadProgress.css';
import { useDispatch } from 'react-redux';
import {
  fileDelete,
  setMultipleFileDetails,
  setFilteredIdForDelete,
} from '../../slices/FileUploadSlice';
import { FileNameFormatter } from '../../util/StringFormatter';
import { UploadFilesString } from '../../util/Strings';

function FileDetailsWithUploadProgress({
  fileSelected,
  handleCancelUpload,
  progressPer,
  isMultiUpload,
  setMultipleFileData,
  handleMultiState,
  type,
}) {
  const prevRechargeMultipleFileData = useSelector(
    (state) => state.hotel.prevHotelImageDetails
  );
  const prevRecreationMultipleFileData = useSelector(
    (state) => state.recreation.prevRecreationImageDetails
  );
  const prevRefuelMultipleFileData = useSelector(
    (state) => state.refuel.prevRefuelImageDetails
  );
  const prevMaterialDetails = useSelector(
    (state) => state.events.prevMaterialDetails
  );
  var filteredId;
  const fileNameWithId = useSelector((state) => state.upload.mutliplePhotoIds);
  const multipleFileDetails = useSelector(
    (state) => state.upload.multipleFileDetails
  );
  const dispatch = useDispatch();

  function setPreviousMultipleFileValue() {
    switch (type) {
      case 'recharge':
        setMultipleFileData(prevRechargeMultipleFileData);
        break;
      case 'recreation':
        setMultipleFileData(prevRecreationMultipleFileData);
        break;
      case 'refuel':
        setMultipleFileData(prevRefuelMultipleFileData);
        break;
      case 'rendezvous':
        setMultipleFileData(prevMaterialDetails);
        break;
      default:
      // Do Nothing
    }
  }

  function handleCancelMultiUpload() {
    if (isMultiUpload) {
      var fileId;
      filteredId = fileNameWithId.filter((file) => {
        if (fileSelected.name === file.fileName) {
          fileId = file.fileId;
          return file.fileId;
        }
      });
      const id = { id: fileId, isVideo: false, isMulti: true };
      const newFileDetails = multipleFileDetails.filter(
        (file) => file.name !== fileSelected.name
      );
      dispatch(setMultipleFileDetails(newFileDetails));
      dispatch(setFilteredIdForDelete(fileId));
      dispatch(fileDelete(id));
      if (multipleFileDetails.length === 1 && fileId) {
        setPreviousMultipleFileValue();
        handleMultiState(fileId);
      }
    }
  }

  return (
    <div className="file-details-with-progress">
      <div className="file-details-row">
        <div className="file-name">{FileNameFormatter(fileSelected.name)}</div>
        <div className="file-details">
          <div className="file-size">
            {Math.floor(fileSelected.size / 1024)} {UploadFilesString.kb}
          </div>
          <div className="close-image">
            <img
              src={circleDelete}
              alt="close"
              onClick={
                isMultiUpload ? handleCancelMultiUpload : handleCancelUpload
              }
            />
          </div>
        </div>
      </div>
      <div className="progress-bar">
        <div
          className="progress-bar-fill"
          style={{ width: `${progressPer}%` }}
        ></div>
      </div>
    </div>
  );
}

export default FileDetailsWithUploadProgress;
