import '../../styles/custom-components/Button.css';

function CancelButton({ children, onClickhandle }) {
  return (
    <>
      <button
        className="cancel-button"
        onClick={() => {
          onClickhandle();
        }}
      >
        {children}
      </button>
    </>
  );
}

export default CancelButton;
