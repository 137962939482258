import { FormValidationStringLabels } from "./Strings";
const nameCondition = /^[a-zA-Z]+(?: [a-zA-z]+)*$/;
const emailCond = /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/;
const numberCondition = /^[0-9]*$/;
const nameWithDigits = /^[a-zA-Z0-9]+(?: [a-zA-z0-9]+)*$/;
const nameWithDigitsandChar = /^(?:[a-z0-9]+\s*-*\s*)+[a-z0-9]+$/i;
const namesWithSpecialCharacterCondition =
  /^[a-zA-Z0-9!#$%&'*+-//=?^_`{|,\n]+(?: [a-zA-Z0-9!#$%&'+-//=?^_`{|,\n]+)*$/;
const namesWithMoreSpecialCharacterCondition = /^[a-zA-Z0-9!#$%&.;'*+\\/@\-()*[\]{}?,><^%$#~!àèìòùÀÈÌÒÙáéíóúýÁÉÍÓÚÝâêîôûÂÊÎÔÛãñõÃÑÕ\s:""`]+$/;
const namewithcharacter = /^[^<@#$%&]+$/;
const ccEmailsCond =
  /^([A-Za-z0-9]+[._]*[A-Za-z0-9]+(?:\.[A-Za-z0-9]+)*@[A-Za-z0-9]+\.[A-Za-z]{2,})(,([ ]?)+[A-Za-z0-9]+[._]*[A-Za-z0-9]+(?:\.[A-Za-z0-9]+)*@[A-Za-z0-9]+\.[A-Za-z]{2,})*$/;

function NewVisitFormValidations(state) {
  let errors = {};
  let isValid = true;

  // visit name validations
  if (!state.name) {
    errors.name = FormValidationStringLabels.name;
    isValid = false;
  } else if (!state.name.match(nameCondition)) {
    errors.name = FormValidationStringLabels.nameRegexCondition;
    isValid = false;
  }

  // start date validations
  if (!state.startDate) {
    errors.startDate = FormValidationStringLabels.startDate;
    isValid = false;
  }

  // end date validations
  if (!state.endDate) {
    errors.endDate = FormValidationStringLabels.endDate;
    isValid = false;
  } else if (state.endDate < state.startDate) {
    errors.endDate = FormValidationStringLabels.invalidVisitEndDate;
    isValid = false;
  }

  //region name validation
  if (!state.region) {
    errors.region = FormValidationStringLabels.region;
    isValid = false;
  } else if (!state.region.match(nameCondition)) {
    errors.region = FormValidationStringLabels.nameRegexCondition;
    isValid = false;
  }

  // company name validations
  // if (!state.companyName) {
  //   errors.companyName = FormValidationStringLabels.name;
  //   isValid = false;
  // } else
  if (state.companyName && !state.companyName.match(namesWithSpecialCharacterCondition)) {
    //errors.name = FormValidationStringLabels.nameRegexCondition;
    errors.companyName = `Company ${FormValidationStringLabels.specialCharsRegexCondition}`;
    isValid = false;
  }
  return { errors, isValid };
}

function NewGeneralNotificationFormValidations(state) {
  let errors = {};
  let isValid = true;

  // notification title validations
  if (!state.type) {
    errors.type = FormValidationStringLabels.title;
    isValid = false;
  } else if (!state.type.match(namesWithSpecialCharacterCondition)) {
    //errors.name = FormValidationStringLabels.nameRegexCondition;
    errors.type = FormValidationStringLabels.titleRegexCondition;
    isValid = false;
  }

  // notification sub-title about me validation
  if (!state.content) {
    errors.content = FormValidationStringLabels.subTitle;
    isValid = false;
  } else if (!state.content.match(namesWithSpecialCharacterCondition)) {
    errors.content = `Sub-title ${FormValidationStringLabels.nameRegexCondition}`;
    isValid = false;
  }

  return { errors, isValid };
}

function NewOfficeFormValidations(state) {
  let errors = {};
  let isValid = true;

  //office name validations
  if (!state.name) {
    errors.name = FormValidationStringLabels.title;
    isValid = false;
  } else if (!state.name.match(nameCondition)) {
    errors.name = FormValidationStringLabels.nameRegexCondition;
    // errors.ti = FormValidationStringLabels.titleRegexCondition;
    isValid = false;
  }

  //office description
  // if (!state.description) {
  //   errors.description = FormValidationStringLabels.description;
  //   isValid = false;
  // }
  // else if (state.description.length > 200) {
  //   errors.description = FormValidationStringLabels.maxLength200;
  //   isValid = false;
  // }
  // else if (!state.description.match(namewithcharacter)) {
  //   errors.description = `Description ${FormValidationStringLabels.specialCharsRegexCondition}`;
  //   isValid = false;
  // }
  //office location
  // if (!state.location) {
  //   errors.location = FormValidationStringLabels.location;
  //   isValid = false;
  // }
  // //  else if (state.location.length > 20) {
  // //   errors.location = FormValidationStringLabels.maxLength20;
  // //   isValid = false;
  // // }
  // else if (!state.location.match(namesWithSpecialCharacterCondition)) {
  //   errors.location = `Location ${FormValidationStringLabels.specialCharsRegexCondition}`;
  //   isValid = false;
  // }

  //city validation
  if (!state.city) {
    errors.city = FormValidationStringLabels.city;
    isValid = false;
  }
  //  else if (state.location.length > 20) {
  //   errors.location = FormValidationStringLabels.maxLength20;
  //   isValid = false;
  // }
  else if (!state.city.match(namesWithSpecialCharacterCondition)) {
    errors.city = `City ${FormValidationStringLabels.specialCharsRegexCondition}`;
    isValid = false;
  }

  //country validation
  if (!state.country) {
    errors.country = FormValidationStringLabels.country;
    isValid = false;
  }
  //  else if (state.location.length > 20) {
  //   errors.location = FormValidationStringLabels.maxLength20;
  //   isValid = false;
  // }
  else if (!state.country.match(namesWithSpecialCharacterCondition)) {
    errors.country = `Country ${FormValidationStringLabels.specialCharsRegexCondition}`;
    isValid = false;
  }

  // office address validation
  if (!state.address) {
    errors.address = FormValidationStringLabels.address;
    isValid = false;
  }
  // else if (state.address.length > 200) {
  //   errors.address = FormValidationStringLabels.maxLength200;
  //   isValid = false;
  // }
  else if (!state.address.match(namesWithSpecialCharacterCondition)) {
    errors.address = `Address ${FormValidationStringLabels.specialCharsRegexCondition}`;
    isValid = false;
  }

  // office latitude validation
  if (!state.latitude) {
    errors.latitude = FormValidationStringLabels.latitude;
    isValid = false;
  } else if (isNaN(parseFloat(state.latitude)) || !isFinite(state.latitude)) {
    errors.latitude = FormValidationStringLabels.invalidLatitude;
    isValid = false;
  }

  //office longitude validation
  if (!state.longitude) {
    errors.longitude = FormValidationStringLabels.longitude;
    isValid = false;
  } else if (isNaN(parseFloat(state.longitude)) || !isFinite(state.longitude)) {
    errors.longitude = FormValidationStringLabels.invalidLongitude;
    isValid = false;
  }
  return { errors, isValid };
}

function NewUserFormValidations(state) {
  let errors = {};
  let isValid = true;

  // user name validations
  if (!state.name) {
    errors.name = FormValidationStringLabels.name;
    isValid = false;
  } else if (!state.name.match(nameCondition)) {
    errors.name = FormValidationStringLabels.nameRegexCondition;
    isValid = false;
  }

  // user email validations
  if (!state.email) {
    errors.email = FormValidationStringLabels.email;
    isValid = false;
  } else if (!state.email.match(emailCond)) {
    errors.email = FormValidationStringLabels.emailRegexCondition;
    isValid = false;
  }

  return { errors, isValid };
}

function NewSubAdminFormValidations(state) {
  let errors = {};
  let isValid = true;

  // first name validations
  if (!state.firstName) {
    errors.firstName = `First ${FormValidationStringLabels.name}`;
    isValid = false;
  } else if (!state.firstName.match(nameCondition)) {
    errors.firstName = `First ${FormValidationStringLabels.nameRegexCondition}`;
    isValid = false;
  }

  // last name validations
  if (!state.lastName) {
    errors.lastName = `Last ${FormValidationStringLabels.name}`;
    isValid = false;
  } else if (!state.lastName.match(nameCondition)) {
    errors.lastName = `Last ${FormValidationStringLabels.nameRegexCondition}`;
    isValid = false;
  }

  // subadmin email validations
  if (!state.email) {
    errors.email = FormValidationStringLabels.email;
    isValid = false;
  } else if (!state.email.match(emailCond)) {
    errors.email = FormValidationStringLabels.emailRegexCondition;
    isValid = false;
  }

  return { errors, isValid };
}

function NewSpeakerFormValidations(state) {
  let errors = {};
  let isValid = true;

  // speaker/titan name validations
  if (!state.name) {
    errors.name = FormValidationStringLabels.name;
    isValid = false;
  } else if (!state.name.match(namesWithMoreSpecialCharacterCondition)) {
    //errors.name = FormValidationStringLabels.nameRegexCondition;
    errors.name = FormValidationStringLabels.alphanumericName;
    isValid = false;
  }

  // titan designation validation
  if (!state.designation) {
    errors.designation = FormValidationStringLabels.designation;
    isValid = false;
  } else if (!state.designation.match(namesWithSpecialCharacterCondition)) {
    errors.designation = `Designation ${FormValidationStringLabels.specialCharsRegexCondition}`;
    isValid = false;
  }

  // titan designation(other) validation
  if ((state.designation === 'Others' || state.designation === 'Client') && !state.designationOther) {
    errors.designationOther = FormValidationStringLabels.designationOther;
    isValid = false;
  } else if (state.designationOther && !state.designationOther.match(namesWithSpecialCharacterCondition)) {
    errors.designationOther = `Designation(Others) ${FormValidationStringLabels.specialCharsRegexCondition}`;
    isValid = false;
  }
  // titan practice validation
  if (!state.practice) {
    errors.practice = FormValidationStringLabels.practice;
    isValid = false;
  } else if (state.practice.length > 32) {
    errors.practice = FormValidationStringLabels.maxLength32;
    isValid = false;
  } else if (!state.practice.match(namesWithSpecialCharacterCondition)) {
    errors.practice = `Company name ${FormValidationStringLabels.specialCharsRegexCondition}`;
    isValid = false;
  }

  // titan's about me validation
  if (!state.about) {
    errors.about = FormValidationStringLabels.aboutMe;
    isValid = false;
  }

  //  else if (state.about.length > 500) {
  //   errors.about = FormValidationStringLabels.maxLength500;
  //   isValid = false;
  // }
  else if (!state.about.match(namesWithMoreSpecialCharacterCondition)) {
    errors.about = `About me ${FormValidationStringLabels.specialCharsRegexCondition}`;
    isValid = false;
  }

  //video/audio validation
  // if (!state.videoId && !state.audioId) {
  //   errors.media = FormValidationStringLabels.mediaRequired;
  //   isValid = false;
  // }

  return { errors, isValid };
}

function NewConciergeFormValidations(state) {
  let errors = {};
  let isValid = true;

  // concierge first name validations
  if (!state.firstName) {
    errors.firstName = FormValidationStringLabels.name;
    isValid = false;
  } else if (!state.firstName.match(namesWithMoreSpecialCharacterCondition)) {
    errors.firstName = FormValidationStringLabels.alphanumericName;
    isValid = false;
  }

  // concierge last name validations
  // if (!state.lastName) {
  //   errors.lastName = FormValidationStringLabels.name;
  //   isValid = false;
  // } else
  if (state.lastName && !state.lastName.match(namesWithMoreSpecialCharacterCondition)) {
    errors.lastName = FormValidationStringLabels.alphanumericName;

    isValid = false;
  }

  // concierge email validations
  if (!state.email) {
    errors.email = FormValidationStringLabels.conciergeMail;
    isValid = false;
  } else if (!state.email.match(emailCond)) {
    errors.email = FormValidationStringLabels.emailRegexCondition;
    isValid = false;
  }

  if (!state.ccEmails) {
    errors.ccEmails = FormValidationStringLabels.conciergeMail;
    isValid = false;
  } else if (!state.ccEmails.match(ccEmailsCond)) {
    errors.ccEmails = FormValidationStringLabels.emailRegexCondition;
    isValid = false;
  }

  // concierge's about me validation
  if (!state.aboutMe) {
    errors.aboutMe = FormValidationStringLabels.aboutMe;
    isValid = false;
  }
  // else if (state.aboutMe.length > 500) {
  //   errors.aboutMe = FormValidationStringLabels.maxLength500;
  //   isValid = false;
  //  }
  else if (!state.aboutMe.match(namesWithMoreSpecialCharacterCondition)) {
    errors.aboutMe = `About me ${FormValidationStringLabels.specialCharsRegexCondition}`;
    isValid = false;
  }

  return { errors, isValid };
}

function NewGetToKnowACValidations(state) {
  let errors = {};
  let isValid = true;

  // Get tot know AC description validation
  if (!state.description) {
    errors.description = FormValidationStringLabels.descriptionRequired;
    isValid = false;
  }
  // else if (state.description.length > 500) {
  //   errors.description = FormValidationStringLabels.maxLength500;
  //   isValid = false;
  // }
  else if (!state.description.match(namesWithMoreSpecialCharacterCondition)) {
    errors.description = `About me ${FormValidationStringLabels.specialCharsRegexCondition}`;
    isValid = false;
  }

  return { errors, isValid };
}

function NewEventFormValidations(state) {
  let errors = {};
  let isValid = true;

  // event title/name validations
  if (!state.title) {
    errors.title = FormValidationStringLabels.name;
    isValid = false;
  } else if (!state.title.match(namesWithMoreSpecialCharacterCondition)) {
    errors.title = FormValidationStringLabels.alphanumericName;
    isValid = false;
  }

  // event type validation
  if (!state.eventType) {
    errors.eventType = FormValidationStringLabels.eventType;
    isValid = false;
  }

  // event description
  if (!state.description) {
    errors.description = FormValidationStringLabels.description;
    isValid = false;
  }
  // else if (state.description.length > 200) {
  //   errors.description = FormValidationStringLabels.maxLength200;
  //   isValid = false;
  // }
  else if (!state.description.match(namesWithMoreSpecialCharacterCondition)) {
    errors.description = `Description ${FormValidationStringLabels.specialCharsRegexCondition}`;
    isValid = false;
  }

  // event date validation
  if (!state.date) {
    errors.date = FormValidationStringLabels.eventDate;
    isValid = false;
  }

  // event start time validation
  if (!state.startTime) {
    errors.startTime = FormValidationStringLabels.eventStartTime;
    isValid = false;
  }

  // event end time validation
  if (!state.endTime) {
    errors.endTime = FormValidationStringLabels.eventEndTime;
    isValid = false;
  } else if (
    state.endTime < state.startTime ||
    state.startTime === state.endTime
  ) {
    errors.endTime = FormValidationStringLabels.invalidEndTime;
    isValid = false;
  }

  // event location validation
  if (!state.location) {
    errors.location = FormValidationStringLabels.location;
    isValid = false;
  } else if (state.location.length > 50) {
    errors.location = FormValidationStringLabels.maxLength50;
    isValid = false;
  } else if (!state.location.match(namesWithSpecialCharacterCondition)) {
    errors.location = `Location ${FormValidationStringLabels.specialCharsRegexCondition}`;
    isValid = false;
  }

  // event speaker validation
  // if (!state.speakers) {
  //   errors.speakers = FormValidationStringLabels.speaker;
  //   isValid = false;
  // }

  return { errors, isValid };
}

function NewRechargeFormValidations(state) {
  let errors = {};
  let isValid = true;

  // hotel name validation
  if (!state.name) {
    errors.name = FormValidationStringLabels.name;
    isValid = false;
  } else if (state.name.length > 50) {
    errors.name = FormValidationStringLabels.maxLength50;
    isValid = false;
  } else if (!state.name.match(namesWithMoreSpecialCharacterCondition)) {
    errors.name = `Name ${FormValidationStringLabels.specialCharsRegexCondition}`;
    isValid = false;
  }

  // hotel description validation
  if (!state.description) {
    errors.description = FormValidationStringLabels.description;
    isValid = false;
  }
  // else if (state.description.length > 200) {
  //   errors.description = FormValidationStringLabels.maxLength200;
  //   isValid = false;
  // }
  else if (!state.description.match(namesWithMoreSpecialCharacterCondition)) {
    errors.description = `Description ${FormValidationStringLabels.specialCharsRegexCondition}`;
    isValid = false;
  }

  // hotel checkin time validation
  // if (!state.checkInTime) {
  //   errors.checkInTime = FormValidationStringLabels.checkInTime;
  //   isValid = false;
  // }

  // // hotel checkout time validation
  // if (!state.checkOutTime) {
  //   errors.checkOutTime = FormValidationStringLabels.checkOutTime;
  //   isValid = false;
  // }
  // hotel driving time validation
  if (!state.drivingTime) {
    errors.drivingTime = FormValidationStringLabels.drivingTime;
    isValid = false;
  } else if (!state.drivingTime.match(nameWithDigitsandChar)) {
    //errors.name = FormValidationStringLabels.nameRegexCondition;
    errors.drivingTime = FormValidationStringLabels.alphanumericName;
    isValid = false;
  }
  // hotel location validation
  if (!state.location) {
    errors.location = FormValidationStringLabels.location;
    isValid = false;
  }
  //  else if (state.location.length > 20) {
  //   errors.location = FormValidationStringLabels.maxLength20;
  //   isValid = false;
  // }
  else if (!state.location.match(namesWithMoreSpecialCharacterCondition)) {
    errors.location = `Location ${FormValidationStringLabels.specialCharsRegexCondition}`;
    isValid = false;
  }

  // hotel address validation
  if (!state.address) {
    errors.address = FormValidationStringLabels.address;
    isValid = false;
  }
  // else if (state.address.length > 200) {
  //   errors.address = FormValidationStringLabels.maxLength200;
  //   isValid = false;
  // }
  else if (!state.address.match(namesWithMoreSpecialCharacterCondition)) {
    errors.address = `Address ${FormValidationStringLabels.specialCharsRegexCondition}`;
    isValid = false;
  }

  // hotel latitude validation
  if (!state.latitude) {
    errors.latitude = FormValidationStringLabels.latitude;
    isValid = false;
  } else if (isNaN(parseFloat(state.latitude)) || !isFinite(state.latitude)) {
    errors.latitude = FormValidationStringLabels.invalidLatitude;
    isValid = false;
  }

  // hotel longitude validation
  if (!state.longitude) {
    errors.longitude = FormValidationStringLabels.longitude;
    isValid = false;
  } else if (isNaN(parseFloat(state.longitude)) || !isFinite(state.longitude)) {
    errors.longitude = FormValidationStringLabels.invalidLongitude;
    isValid = false;
  }

  return { errors, isValid };
}

function NewRefuelFormValidations(state) {
  let errors = {};
  let isValid = true;

  // refuel name validations
  if (!state.name) {
    errors.name = FormValidationStringLabels.name;
    isValid = false;
  } else if (state.name.length > 50) {
    errors.name = FormValidationStringLabels.maxLength50;
    isValid = false;
  } else if (!state.name.match(namesWithMoreSpecialCharacterCondition)) {
    errors.name = `Name ${FormValidationStringLabels.specialCharsRegexCondition}`;
    isValid = false;
  }

  // refuel address validations
  if (!state.address) {
    errors.address = FormValidationStringLabels.address;
    isValid = false;
  }
  // else if (state.address.length > 100) {
  //   errors.address = FormValidationStringLabels.maxLength100;
  //   isValid = false;
  // }
  else if (!state.address.match(namesWithMoreSpecialCharacterCondition)) {
    errors.address = `Address ${FormValidationStringLabels.specialCharsRegexCondition}`;
    isValid = false;
  }

  // refuel filter validation
  // if(!state.filter) {
  //   errors.filter = FormValidationStringLabels.filter;
  //   isValid = false;
  // }

  // refuel type validations
  if (!state.type) {
    errors.type = FormValidationStringLabels.type;
    isValid = false;
  }

  // refuel price validations
  if (!state.price) {
    errors.price = FormValidationStringLabels.price;
    isValid = false;
  } else if (isNaN(parseFloat(state.price)) || !isFinite(state.price)) {
    errors.price = FormValidationStringLabels.invalidPrice;
    isValid = false;
  }

  // refuel description validations
  if (!state.description) {
    errors.description = FormValidationStringLabels.description;
    isValid = false;
  }
  //  else if (state.description.length > 200) {
  //   errors.description = FormValidationStringLabels.maxLength200;
  //   isValid = false;
  // }
  else if (!state.description.match(namesWithMoreSpecialCharacterCondition)) {
    errors.description = `Description ${FormValidationStringLabels.specialCharsRegexCondition}`;
    isValid = false;
  }

  // refuel latitude validation
  if (!state.latitude) {
    errors.latitude = FormValidationStringLabels.latitude;
    isValid = false;
  } else if (isNaN(parseFloat(state.latitude)) || !isFinite(state.latitude)) {
    errors.latitude = FormValidationStringLabels.invalidLatitude;
    isValid = false;
  }

  // refuel longitude validation
  if (!state.longitude) {
    errors.longitude = FormValidationStringLabels.longitude;
    isValid = false;
  } else if (isNaN(parseFloat(state.longitude)) || !isFinite(state.longitude)) {
    errors.longitude = FormValidationStringLabels.invalidLongitude;
    isValid = false;
  }

  return { errors, isValid };
}

function NewRecreationFormValidations(state) {
  let errors = {};
  let isValid = true;

  // roam name validations
  if (!state.name) {
    errors.name = FormValidationStringLabels.name;
    isValid = false;
  } else if (state.name.length > 50) {
    errors.name = FormValidationStringLabels.maxLength50;
    isValid = false;
  } else if (!state.name.match(namesWithMoreSpecialCharacterCondition)) {
    errors.name = `Name ${FormValidationStringLabels.specialCharsRegexCondition}`;
    isValid = false;
  }

  // roam address validations
  if (!state.address) {
    errors.address = FormValidationStringLabels.address;
    isValid = false;
  }
  // else if (state.address.length > 50) {
  //   errors.address = FormValidationStringLabels.maxLength50;
  //   isValid = false;
  // }
  else if (!state.address.match(namesWithMoreSpecialCharacterCondition)) {
    errors.address = `Address ${FormValidationStringLabels.specialCharsRegexCondition}`;
    isValid = false;
  }

  // roam filter validation
  if (!state.filter) {
    errors.filter = FormValidationStringLabels.filter;
    isValid = false;
  }

  // roam area validations
  if (!state.area) {
    errors.area = FormValidationStringLabels.area;
    isValid = false;
  } else if (!state.area.match(namesWithMoreSpecialCharacterCondition)) {
    errors.area = `Area ${FormValidationStringLabels.specialCharsRegexCondition}`;
    isValid = false;
  }

  // roam timing validations
  // if (!state.timing) {
  //   errors.timing = FormValidationStringLabels.timing;
  //   isValid = false;
  // }

  // roam open time validation
  if (!state.openingTime) {
    errors.openingTime = FormValidationStringLabels.openingTime;
    isValid = false;
  }

  // roam close time validation
  if (!state.closingTime) {
    errors.closingTime = FormValidationStringLabels.closingTime;
    isValid = false;
  }
  // else if (
  //   state.closingTime < state.openingTime ||
  //   state.openingTime === state.closingTime
  // ) {
  //   errors.closingTime = FormValidationStringLabels.invalidCloseTime;
  //   isValid = false;
  // }

  // roam closed days validation
  if (!state.closedDays) {
    errors.closedDays = FormValidationStringLabels.closedDays;
    isValid = false;
  }

  // roam latitude validation
  if (!state.latitude) {
    errors.latitude = FormValidationStringLabels.latitude;
    isValid = false;
  } else if (isNaN(parseFloat(state.latitude)) || !isFinite(state.latitude)) {
    errors.latitude = FormValidationStringLabels.invalidLatitude;
    isValid = false;
  }

  // roam longitude validation
  if (!state.longitude) {
    errors.longitude = FormValidationStringLabels.longitude;
    isValid = false;
  } else if (isNaN(parseFloat(state.longitude)) || !isFinite(state.longitude)) {
    errors.longitude = FormValidationStringLabels.invalidLongitude;
    isValid = false;
  }

  // roam description validations
  if (!state.description) {
    errors.description = FormValidationStringLabels.description;
    isValid = false;
  }
  // else if (state.description.length > 200) {
  //   errors.description = FormValidationStringLabels.maxLength200;
  //   isValid = false;
  // }
  else if (!state.description.match(namesWithMoreSpecialCharacterCondition)) {
    errors.description = `Description ${FormValidationStringLabels.specialCharsRegexCondition}`;
    isValid = false;
  }

  return { errors, isValid };
}

function CabScheduleFormValidations(state) {
  let errors = {};
  let isValid = true;

  
  // contact name validations
  if (!state.contactName) {
    errors.contactName = FormValidationStringLabels.contactName;
    isValid = false;
  } else
  if (state.contactName && state.contactName.length > 32) {
    errors.contactName = FormValidationStringLabels.maxLength32;
    isValid = false;
  } else if (state.contactName && !state.contactName.match(nameCondition)) {
    errors.contactName = `Contact ${FormValidationStringLabels.nameRegexCondition}`;
    isValid = false;
  }

  // contact number validations
  if (!state.contactNumber) {
    errors.contactNumber = FormValidationStringLabels.contactNumber;
    isValid = false;
  } else
  if (state.contactNumber && !state.contactNumber.match(numberCondition)) {
    errors.contactNumber = `Contact number${FormValidationStringLabels.onlyDigits}`;
    isValid = false;
  }
  // else if (
  //   state.contactNumber.length !== 10 &&
  //   state.contactNumber.length !== 9
  // ) {
  //   errors.contactNumber = `Contact number ${FormValidationStringLabels.minMaxLengthPhoneNo}`;
  //   isValid = false;
  // }

  // Car name validations
  // if (!state.carName) {
  //   errors.carName = FormValidationStringLabels.carName;
  //   isValid = false;
  // } else
  if (state.carName && state.carName.length > 32) {
    errors.carName = FormValidationStringLabels.maxLength32;
    isValid = false;
  } else if (state.carName && !state.carName.match(nameWithDigits)) {
    errors.carName = `Car Name ${FormValidationStringLabels.charNumRegex}`;
    isValid = false;
  }

  // Car Reg Number validations
  if (!state.carRegNumber) {
    errors.carRegNumber = FormValidationStringLabels.carRegNumber;
    isValid = false;
  }
  // else if (state.carRegNumber.length > 11 || state.carRegNumber.length < 9) {
  //   errors.carRegNumber = FormValidationStringLabels.minMaxCarRegNo;
  //   isValid = false;
  // }
  else
  if (state.carRegNumber && !state.carRegNumber.match(nameWithDigits)) {
    errors.carRegNumber = `Car Registration Number ${FormValidationStringLabels.charNumRegex}`;
    isValid = false;
  }

  // Car name validations
  //  if (!state.carType) {
  //   errors.carType = FormValidationStringLabels.carType;
  //   isValid = false;
  // } else
  if (state.carType && state.carType > 32) {
    errors.carType = FormValidationStringLabels.maxLength32;
    isValid = false;
  } else if (state.carType && !state.carType.match(nameWithDigits)) {
    errors.carType = `Car Type ${FormValidationStringLabels.charNumRegex}`;
    isValid = false;
  }
  if (state.bookings?.length > 0) {
    state.bookings.forEach((eachBooking, index) => {
      //date
      if (!eachBooking.date) {
        errors.date = FormValidationStringLabels.date;
        isValid = false;
      }
      // user
      if (!eachBooking.usersId || eachBooking.usersId.length === 0) {  
        errors.user = FormValidationStringLabels.user;
        isValid = false;
      }

      // source
      if (!eachBooking.pickupLocation) {
        errors.pickupLocation = FormValidationStringLabels.pickupLocation;
        isValid = false;
      } else if (!eachBooking.pickupLocation.match(namesWithSpecialCharacterCondition)) {
        errors.pickupLocation = `Source ${FormValidationStringLabels.specialCharsRegexCondition}`;
        isValid = false;
      }
      //destination
      // if (!eachBooking.dropLocation) {
      //   errors.dropLocation = FormValidationStringLabels.dropLocation;
      //   isValid = false;
      // } else 
      if (eachBooking.dropLocation && !eachBooking.dropLocation.match(namesWithSpecialCharacterCondition)) {
        errors.dropLocation = `Destination ${FormValidationStringLabels.specialCharsRegexCondition}`;
        isValid = false;
      }

      //dropTime
      if (!eachBooking.dropTime) {
        errors.dropTime = FormValidationStringLabels.dropTime;
        isValid = false;
      }
      //pickupTime
      if (!eachBooking.pickupTime) {
        errors.pickupTime = FormValidationStringLabels.pickupTime;
        isValid = false;
      }
    });
  }
  return { errors, isValid };
}

function RechargeScheduleFormValidations(state) {
  let errors = {};
  let isValid = true;
  let formattedCheckInDate = new Date(state.checkInDate);
  let formattedCheckOutDate = new Date(state.checkOutDate);

  // hotel name validation
  if (!state.hotelId) {
    errors.hotelId = FormValidationStringLabels.hotelId;
    isValid = false;
  }

  // hotel checkin time validation
  if (!state.checkInDate) {
    errors.checkInDate = FormValidationStringLabels.checkInDate;
    isValid = false;
  }

  // hotel checkout time validation
  if (!state.checkOutDate) {
    errors.checkOutDate = FormValidationStringLabels.checkOutDate;
    isValid = false;
  } else if (formattedCheckInDate > formattedCheckOutDate) {
    errors.checkOutDate = FormValidationStringLabels.checkInDateGreater;
    isValid = false;
  }

  return { errors, isValid };
}

export {
  NewVisitFormValidations,
  NewUserFormValidations,
  NewSubAdminFormValidations,
  NewSpeakerFormValidations,
  NewEventFormValidations,
  NewRechargeFormValidations,
  NewRefuelFormValidations,
  NewRecreationFormValidations,
  CabScheduleFormValidations,
  RechargeScheduleFormValidations,
  NewConciergeFormValidations,
  NewGetToKnowACValidations,
  NewGeneralNotificationFormValidations,
  NewOfficeFormValidations,
  namesWithMoreSpecialCharacterCondition
};
