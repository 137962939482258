import { useDispatch, useSelector } from 'react-redux';
import { GeneralNotificationLabels } from '../../util/Strings';
import { useState } from 'react';
import GeneralNotificationForm from './GeneralNotificationForm';
import notification from '../../assets/notification.svg'
import { createNewNotification } from '../../slices/GeneralNotificationSlice';

function GeneralNotification({ handleShow }) {
  const isSubAdmin = useSelector((state) => state.login.isSubAdmin);
  const [showForm, setShowForm] = useState(false);
  const dispatch = useDispatch()

  async function handleNewNotification(newData) {
    if (newData) {
      dispatch(createNewNotification(newData));
    }
    setShowForm(false);
    handleShow();
  }

  if (!isSubAdmin) {
    return (
      <div
        className="menu-item"
        onClick={() => {
          setShowForm(true);
        }}
      >
        <GeneralNotificationForm show={showForm} onClickNext={handleNewNotification} />
        <img src={notification} alt="notification"></img>
        <div className="menu-text">
          {GeneralNotificationLabels.generalNotification}
        </div>
      </div>
    );
  }
}
export default GeneralNotification;
