import http from '../http-common';

const GetWelcomePageDetails = async (id) => {
  const welcomeDetails = await http
    .get(`/homeUnderVisit/${id}`)
    .then((response) => {
      return response.data;
    });
  return welcomeDetails;
};

const UpdateWelcomePageDetails = async (id, newData, videoId) => {
  const auth = await http
    .put(`/updateHome/${id}`, {
      title: 'Welcome,',
      //conciergeMail: newData.conciergeMail,
      message: newData.message,
      videoId: videoId,
    })
    .then((response) => {
      return response.data;
    });

  return auth;
};

const WelcomePageAPI = { GetWelcomePageDetails, UpdateWelcomePageDetails };
export default WelcomePageAPI;
