import http from '../http-common';

// const GetAllCabRequests = async (id) => {
//   const cabRequests = await http.get(`/taxiRequests/${id}`).then((response) => {
//     return response.data;
//   });
//   return cabRequests;
// };

const GetAllScheduledCab = async (id) => {
  const scheduledCabs = await http
    .get(`/scheduledCabList/${id}`)
    .then((response) => {
      return response.data;
    });
  return scheduledCabs;
};

const ScheduleCab = async (newData, id) => {
  const auth = await http
    .post(`/scheduleCab/${id}`, newData)
    .then((response) => {
      return response.data;
    });
  return auth;
};

const UpdateCab = async (updateData, id) => {
  const updatedData = await http
    .put(`/updateScheduledCab/${id}`, updateData)
    .then((response) => {
      return response.data;
    });
  return updatedData;
};

const DeleteCab = async (id) => {
  const deletedData = await http
    .delete(`/deleteScheduledCabs/${id}`)
    .then((response) => {
      return response.data;
    });
  return deletedData;
};

const CabListAPI = { ScheduleCab, GetAllScheduledCab, UpdateCab, DeleteCab };
export default CabListAPI;
