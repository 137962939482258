import { useSelector } from 'react-redux';
import '../../styles/common-components/FileUploader.css';
import { useDispatch } from 'react-redux';
import FileDetailsWithUploadProgress from './FileDetailsWithUploadProgress';
import FileDetailsWithUploadProgressForVideo from './FileDetailsWithUploadProgressForVideo';
import { useEffect, useRef } from 'react';
import audioThumbnail from '../../assets/speakers-audio.svg';
import videoThumbnail from '../../assets/speakers-video.svg';
import {
  fileMediaDelete,
  setMediaSelectedFile,
} from '../../slices/SpeakerSlice';
import { UploadFilesString } from '../../util/Strings';

function FileMediaUploader({
  headerName,
  previewShape,
  handleDragOver,
  handleDrop,
  handleFileSelect,
  isMultiUpload = false,
  setPreFileData,
  handleState,
  type,
}) {
  const progressPer = useSelector((state) => state.speakers.mediaProgress);
  const fileSelected = useSelector((state) => state.speakers.selectedMediaFile);
  const prevFileData = useSelector(
    (state) => state.speakers.prevMediaFileDetails
  );

  const mediaId = useSelector((state) => state.speakers.mediaId);

  const dispatch = useDispatch();

  const videoProgressStatus = useSelector(
    (state) => state.speakers.videoStatus
  );

  function videoStatusToProgressBarMapping() {
    if (videoProgressStatus === 'pending') {
      return 33.33;
    } else if (videoProgressStatus === 'start') {
      return 66.66;
    } else if (videoProgressStatus === 'complete') {
      return 100;
    } else {
      return 0;
    }
  }
  function setPreviousFileValue() {
    switch (type) {
      case 'video':
        setPreFileData(prevFileData);
        break;
      case 'audio':
        setPreFileData(prevFileData);
        break;
      default:
      // Do Nothing
    }
  }

  function handleCancelUpload() {
    var id;
    if (type === 'audio') {
      id = { id: mediaId, isVideo: false, isMulti: false };
    } else if (type === 'video' && videoProgressStatus === 'complete') {
      id = { id: mediaId, isVideo: true, isMulti: false };
    } else {
      return;
    }
    dispatch(fileMediaDelete(id));
    dispatch(setMediaSelectedFile(null));
    setPreviousFileValue();
    handleState();
  }

  return (
    <div className="form-label-input">
      <label className="form-label">{headerName}</label>
      <div className="photo-input">
        <div
          className="drop-zone-container"
          onDragOver={handleDragOver}
          onDrop={handleDrop}
        >
          <div className="input-with-para">
            <p>
              {UploadFilesString.dragDrop}
              <span>{UploadFilesString.chooseAFile}</span>
            </p>
            <input
              type="file"
              onChange={handleFileSelect}
              className="file-input"
              multiple={isMultiUpload}
            />
          </div>
        </div>
        <div className="disclaimer">
          {isMultiUpload
            ? 'You can upload multiple photos'
            : 'You can upload only one video or audio file'}
        </div>
        {type === 'video' ? (
          <div className="video-media-progress">
            {fileSelected ? (
              <img
                className={
                  previewShape === 'circular'
                    ? 'preview-media'
                    : 'preview-img-box'
                }
                src={videoThumbnail}
                alt="preview img"
              />
            ) : null}

            {fileSelected && fileSelected.name && fileSelected.size && (
              <FileDetailsWithUploadProgressForVideo
                fileSelected={fileSelected}
                progressPer={videoStatusToProgressBarMapping()}
                handleCancelUpload={handleCancelUpload}
                fromSpeakers={true}
              />
            )}
          </div>
        ) : type === 'audio' ? (
          <div className="preview-media-progress-bar-conatiner">
            {fileSelected ? (
              <img
                className={
                  previewShape === 'circular'
                    ? 'preview-media'
                    : 'preview-img-box'
                }
                src={audioThumbnail}
                alt="preview img"
              />
            ) : null}

            {fileSelected && (
              <FileDetailsWithUploadProgress
                fileSelected={fileSelected}
                handleCancelUpload={handleCancelUpload}
                progressPer={progressPer}
              />
            )}
          </div>
        ) : null}
      </div>
    </div>
  );
}

export default FileMediaUploader;
