import { RecreationStringLabels } from '../../../../util/Strings';
import RecreationTable from './RecreationTable';

function Recreation() {
  return (
    <div className="events-list-container">
      <div className="event-list-heading">{RecreationStringLabels.roam}</div>
      <div className="events-list-content">
        <RecreationTable />
      </div>
    </div>
  );
}

export default Recreation;
