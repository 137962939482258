import http from '../http-common';

async function GetConciergeData(id) {
  const conciergeData = await http.get(`/conceirge/${id}`).then((response) => {
    return response.data;
  });
  return conciergeData;
}

async function CreateNewConciergeData(newData, id) {
  const auth = await http
    .post(`/createConcierge/${id}`, newData)
    .then((response) => {
      return response.data;
    });
  return auth;
}

const UpdateConcierge = async (updateData, id) => {
  const updatedData = await http
    .put(`/updateConcierge/${id}`, updateData)
    .then((response) => {
      return response.data;
    });
  return updatedData;
};

const DeleteConcierge = async (id) => {
  const deletedData = await http
    .delete(`/deleteConcierge/${id}`)
    .then((response) => {
      return response.data;
    });
  return deletedData;
};

const ConciergeAPI = {
  GetConciergeData,
  CreateNewConciergeData,
  UpdateConcierge,
  DeleteConcierge,
};
export default ConciergeAPI;
