import { useEffect } from 'react';
import '../../../../styles/screens/client-visit-details/manage-users/UsersList.css';
import { useDispatch, useSelector } from 'react-redux';
import noUsers from '../../../../assets/no-users.svg';
import Loader from '../../../common-components/Loader';
import {
  getAllUsers,
  resetErrorAlert,
  setErrorAlert,
} from '../../../../slices/UserSlice';
import UserListTableBody from './UserListTableBody';
import { UserScreenStringLabels } from '../../../../util/Strings';
import ErrorAlert from '../../../common-components/ErrorAlert';
import ErrorMessages from '../../../../util/ErrorMessages';

function UsersListTable() {
  const visit = useSelector((state) => state.visits);
  const isVisitCompleted = visit.selectedVisitIsCompleted;
  const data = useSelector((state) => state.users.userData);
  const isLoading = useSelector((state) => state.users.isLoading);
  const errorMessageCode = useSelector((state) => state.users.errorCode);
  const dispatch = useDispatch();

  function getUsersData() {
    dispatch(getAllUsers(visit.selectedVisitId));
  }

  function handleCloseErrorAlert() {
    dispatch(resetErrorAlert(false));
  }

  useEffect(() => {
    getUsersData();
  }, []);

  if (isLoading) {
    return <Loader />;
  }
  if (errorMessageCode) {
    return (
      <ErrorAlert
        show={errorMessageCode}
        onClickHandle={handleCloseErrorAlert}
        title="Error"
        subTitle={ErrorMessages(errorMessageCode)}
      />
    );
  }
  return (
    <>
      {data && data.length > 0 ? (
        <table>
          <thead>
            <tr className="table-header">
              <th>{UserScreenStringLabels.userName}</th>
              <th>{UserScreenStringLabels.email}</th>
              <th>{UserScreenStringLabels.phone}</th>
              <th> </th>
            </tr>
          </thead>
          {data.map((dataItem) => {
            return <UserListTableBody dataItem={dataItem} />;
          })}
        </table>
      ) : (
        <img src={noUsers} alt="no users" className="no-img" />
      )}
    </>
  );
}
export default UsersListTable;
