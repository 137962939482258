import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import GetToKnowACAPI from '../serviceAPI/GetToKnowACAPI';

const initialState = {
    acData: [],
    isLoading: false,
    prevThumbnailDetails: null,
    prevThumbnailId: '',
    errorCode: null,
};

export const getToKnowACData = createAsyncThunk(
    'getToKnowACData',
    async (id, { rejectWithValue }) => {
        try {
            const res = await GetToKnowACAPI.GetToKnowACData(id);
            return res;
        } catch (error) {
            return rejectWithValue(error.response.status);
        }
    }
);

export const createNewGetToKnowAC = createAsyncThunk(
    'createNewGetToKnowAC',
    async ({ newData, id }, { rejectWithValue }) => {
        try {
            const res = await GetToKnowACAPI.CreateNewGetToKnowACData(newData, id);
            return res;
        } catch (error) {
            return rejectWithValue(error.response.status);
        }
    }
);

export const updateGetToKnowAC = createAsyncThunk(
    'updateGetToKnowAC',
    async ({ newData, id }, { rejectWithValue }) => {
        try {
            const res = await GetToKnowACAPI.UpdateGetToKnowAC(newData, id);
            return res;
        } catch (error) {
            return rejectWithValue(error.response.status);
        }
    }
);

export const deleteGetToKnowAC = createAsyncThunk(
    'deleteGetToKnowAC',
    async (id, { rejectWithValue }) => {
        try {
            const res = await GetToKnowACAPI.DeleteGetToKnowAC(id);
            return res;
        } catch (error) {
            return rejectWithValue(error.response.status);
        }
    }
);

const GetToKnowACSlice = createSlice({
    name: 'ac',
    initialState,
    reducers: {
        setPrevThumbnailDetails: (state, action) => {
            state.prevThumbnailDetails = action.payload;
        },
        setPreThumbnailId: (state, action) => {
            state.prevThumbnailId = action.payload;
        },
        resetErrorAlert: (state, action) => {
            state.errorCode = null;
        },
    },
    extraReducers: {
        [getToKnowACData.pending]: (state) => {
            state.isLoading = true;
        },
        [getToKnowACData.fulfilled]: (state, action) => {
            state.isLoading = false;
            state.acData = [...action.payload];
        },
        [getToKnowACData.rejected]: (state, action) => {
            state.isLoading = false;
            state.errorCode = action.payload;
        },
        [createNewGetToKnowAC.fulfilled]: (state, action) => {
            state.acData.push(action.payload);
        },
        [createNewGetToKnowAC.rejected]: (state, action) => {
            state.errorCode = action.payload;
        },
        [updateGetToKnowAC.fulfilled]: (state, action) => {
            state.acData = state.acData.map((data) =>
                data._id === action.payload._id ? action.payload : data
            );
        },
        [updateGetToKnowAC.rejected]: (state, action) => {
            state.errorCode = action.payload;
        },
        [deleteGetToKnowAC.fulfilled]: (state, action) => {
            const id = action.payload._id;
            const index = state.acData.map((item) => item._id).indexOf(id);
            state.acData.splice(index, 1);
        },
        [deleteGetToKnowAC.rejected]: (state, action) => {
            state.errorCode = action.payload;
        },
    },
});

export const { setPrevThumbnailDetails, setPreThumbnailId, resetErrorAlert } =
    GetToKnowACSlice.actions;
export default GetToKnowACSlice.reducer;
