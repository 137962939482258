import { useDispatch, useSelector } from 'react-redux';
import '../../../../styles/screens/client-visit-details/manage-module/EventsList.css';
import DateFormatter from '../../../../util/DateFormatter';
import Button from '../../../custom-components/Button';
import {
  resetErrorAlert,
  updateselectedCabId,
} from '../../../../slices/CabSlice';
import noRide from '../../../../assets/no-request-ride.svg';
import Loader from '../../../common-components/Loader';
import { RequestScreenStringLabels } from '../../../../util/Strings';
import ErrorAlert from '../../../common-components/ErrorAlert';
import ErrorMessages from '../../../../util/ErrorMessages';
import CabListTableBody from './CabListTableBody';

function CabListTable({ handleBook, isRequest }) {
  const dispatch = useDispatch();
  //const cabRequestData = useSelector((state) => state.cabs.cabRequestData);
  const data = useSelector((state) => state.cabs.cabSchduledData);
  const isLoading = useSelector((state) => state.cabs.isLoading);
  //const data = isRequest ? cabRequestData : cabScheduledData;
  const errorMessageCode = useSelector((state) => state.cabs.errorCode);

  function handleCloseErrorAlert() {
    dispatch(resetErrorAlert(false));
  }

  if (isLoading) {
    return <Loader />;
  }

  if (errorMessageCode) {
    return (
      <ErrorAlert
        show={errorMessageCode}
        onClickHandle={handleCloseErrorAlert}
        title="Error"
        subTitle={ErrorMessages(errorMessageCode)}
      />
    );
  }

  return (
    <>
      {data && data.length > 0 ? (
        <table>
          <thead>
            <tr className="table-header">
              <th>{RequestScreenStringLabels.date}</th>
              <th>{RequestScreenStringLabels.userName}</th>
              <th>{RequestScreenStringLabels.pickUpTime}</th>
              <th>{RequestScreenStringLabels.pickUpLocation}</th>              
              <th>{RequestScreenStringLabels.dropTime}</th>
              <th>{RequestScreenStringLabels.dropLocation}</th>
              <th>{RequestScreenStringLabels.cabDriverName}</th>
              <th>{RequestScreenStringLabels.contact}</th>
              <th>{RequestScreenStringLabels.car}</th>
              <th> </th>
            </tr>
          </thead>
          {data.map((dataItem) => {
            return (
             <CabListTableBody dataItem={dataItem}/>
            );
          })}
        </table>
      ) : (
        <img src={noRide} alt="No Roam" className="no-img" />
      )}
    </>
  );
}

export default CabListTable;
