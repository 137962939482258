import { ErrorMessagesStringLAbels } from './Strings';

function ErrorMessages(errorCode) {
  var message;
  switch (errorCode) {
    case 204:
      message = ErrorMessagesStringLAbels.message204;
      break;
    case 400:
      message = ErrorMessagesStringLAbels.message400;
      break;
    case 401:
      message = ErrorMessagesStringLAbels.message401;
      break;
    case 404:
      message = ErrorMessagesStringLAbels.message404;
      break;
    case 500:
      message = ErrorMessagesStringLAbels.message500;
      break;
    default:
      message = ErrorMessagesStringLAbels.defaultmessage;
      break;
  }
  return message;
}
export default ErrorMessages;
