import { useDispatch, useSelector } from "react-redux";
import "../../../styles/screens/client-visit-details/manage-module/SpeakersList.css";
import { useNavigate } from "react-router";
import { useEffect, useState } from "react";
import {
  createFeedbackByUser,
  //getAllSubAdmins,
  getAllFeedbacks,
  resetErrorAlert,
  //  setErrorAlert,
} from "../../../slices/OfficeListSlice";
import Loader from "../../common-components/Loader";
import { OfficeListLabels } from "../../../util/Strings";
import ErrorAlert from "../../common-components/ErrorAlert";
import ErrorMessages from "../../../util/ErrorMessages";
import OfficeCard from "./OfficeCard";

import { getAllOffices } from "../../../slices/OfficeListSlice";
import "../office-list/OfficeList.css";
import "../office-list/OfficeCard.css";
import "../office-list/OfficeCard";
import "../../../styles/screens/client-visit-details/Feedback.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faMagnifyingGlass } from "@fortawesome/free-solid-svg-icons";

function OfficeList() {
  const data = useSelector((state) => state.officelist.OfficeListData);
  const isLoading = useSelector((state) => state.officelist.isLoading);
  const [showForm, setShowForm] = useState(false);
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const errorMessageCode = useSelector((state) => state.officelist.errorCode);
  const errorMessage = useSelector((state) => state.officelist.errorMessage);
  const [input, setInput] = useState("");

  function getAllOfficeData() {
    dispatch(getAllOffices());
  }

  function handleCloseErrorAlert() {
    dispatch(resetErrorAlert(false));
  }

  useEffect(() => {
    getAllOfficeData();
  }, []);

  if (isLoading) {
    return <Loader />;
  }
  if (errorMessage && errorMessageCode === 400) {
    return (
      <ErrorAlert
        show={errorMessageCode}
        onClickHandle={handleCloseErrorAlert}
        title='Error'
        subTitle={errorMessage}
      />
    );
  } else if (errorMessageCode) {
    return (
      <ErrorAlert
        show={errorMessageCode}
        onClickHandle={handleCloseErrorAlert}
        title='Error'
        subTitle={ErrorMessages(errorMessageCode)}
      />
    );
  }

  return (
    <div className='client-container'>
      <div className='client-header'>
        <div
          onClick={() => {
            navigate("/home", { replace: true });
          }}
          className='back-to-visit'
        ></div>
      </div>
      <div className='headings'>{OfficeListLabels.pwcAcOfficeList}</div>
      <div className='officeListContainer'>
        <div className='users-list-header-container'>
          <div className='event-list-heading'>
            {OfficeListLabels.officeList}
          </div>
          <div className='searchContainer'>
            <div className='searchIconText'>
              <FontAwesomeIcon icon={faMagnifyingGlass} color='#D1D1D1' />
              <input
                className='search'
                icon='search'
                placeholder='Search'
                value={input}
                onChange={(e) => setInput(e.target.value)}
              ></input>
            </div>
          </div>
        </div>
        <div className='office-list-body'>
          {data
            .filter((dataItem) => {
              if (dataItem.available)
                return input.toLowerCase() === ""
                  ? dataItem
                  : dataItem.name.toLowerCase().includes(input);
            })
            .map((dataItem) => (
              <OfficeCard dataItem={dataItem} />
            ))}
        </div>
      </div>
    </div>
  );
}
export default OfficeList;
