import '../../../../styles/screens/client-visit-details/manage-module/WelcomePage.css';
import { useDispatch, useSelector } from 'react-redux';
import {
  changeUpdatedWelcomePageData,
  getWelcomePageData,
  resetErrorAlert,
  resetVideoId,
  setShowVideoProgressBar,
  setVideoId,
  setWelcomeVideoFile,
  setWelcomeVideoStatus,
  videoFileUpload,
} from '../../../../slices/WelcomePageSlice';
import { useEffect, useRef, useState } from 'react';
import {
  fileDelete,
  resetMediaErrorAlert,
} from '../../../../slices/FileUploadSlice';
import ConfirmationDialog from '../../../common-components/ConfirmationDialog';
import Loader from '../../../common-components/Loader';
import FileDetailsWithUploadProgressForVideo from '../../../common-components/FileDetailsWithUploadProgressForVideo';
import { WelcomeScreenStringLabels } from '../../../../util/Strings';
import ErrorMessageComponent from '../../../common-components/ErrorMessageComponent';
import useFileValidation from '../../../../util/useFileValidation';
import ErrorAlert from '../../../common-components/ErrorAlert';
import ErrorMessages from '../../../../util/ErrorMessages';

function WelcomePage() {
  const welcome = useSelector(
    (state) => state.welcomePage.updatedWelcomePageData
  );
  const dispatch = useDispatch();
  const [videoFile, setVideoFile] = useState();
  const videoProgressStatus = useSelector(
    (state) => state.welcomePage.welcomeVideoStatus
  );
  const videoId = useSelector((state) => state.welcomePage.welcomeVideoId);
  const savedVideoId = useSelector((state) => state.welcomePage.savedVideoId);
  const videoFileDetails = useSelector(
    (state) => state.welcomePage.welcomeVideoFile
  );
  const videoFileURI = useSelector(
    (state) => state.welcomePage.welcomeVideoFileURI
  );
  const videoUrl = useSelector(
    (state) => state.welcomePage.welcomeVideoFileURL
  );
  const isLoading = useSelector((state) => state.welcomePage.isLoading);
  const [showDelete, setShowDelete] = useState(false);
  const isSubAdmin = useSelector((state) => state.login.isSubAdmin);
  // const [isRunning, setIsRunning] = useState(true);
  const welcomeMessageErrorMessage = useSelector(
    (state) => state.welcomePage.welcomeMessageErrorMessage
  );
  // const welcomeEmailErrorMessage = useSelector(
  //   (state) => state.welcomePage.welcomeEmailErrorMessage
  // );
  const hiddenFileInput = useRef(null);
  const handleClick = (event) => {
    hiddenFileInput.current.click();
    dispatch(setShowVideoProgressBar(true));
  };
  const { errorVideoMessage, setErrorVideoMessage, validateVideoFile } =
    useFileValidation();

  const errorMessageCode = useSelector((state) => state.welcomePage.errorCode);
  const errorVideoCode = useSelector((state) => state.upload.errorCode);
  function handleCloseErrorAlert() {
    dispatch(resetErrorAlert(false));
  }

  function handleCloseMediaErrorAlert() {
    dispatch(resetMediaErrorAlert(false));
  }

  function handleChange(e) {
    const value = e.target.value;
    const name = e.target.name;
    dispatch(changeUpdatedWelcomePageData({ name, value }));
  }

  const handleCancelUpload = function () {
    setShowDelete(true);
  };

  const handleFileSelect = function (event) {
    dispatch(setWelcomeVideoStatus(null));
    const files = event.target.files;
    const valid = validateVideoFile(files);
    const file = files[0];
    if (valid && file) {
      setVideoFile(file);
      dispatch(setShowVideoProgressBar(true));
      dispatch(setWelcomeVideoFile({ name: file.name, size: file.size }));
      postVideoFileData(file);
    }
  };

  function postVideoFileData(file) {
    dispatch(videoFileUpload(file))
      .unwrap()
      .then((res) => {});
  }

  function videoStatusToProgressBarMapping() {
    if (videoProgressStatus === 'pending') {
      return 33.33;
    } else if (videoProgressStatus === 'start') {
      return 66.66;
    } else if (videoProgressStatus === 'complete') {
      return 100;
    } else {
      return 0;
    }
  }

  const videoFileUrl =
    videoUrl === null
      ? 'https://mediaservwpmklrt2v3wqi-inso.streaming.media.azure.net/9235d69d-d0bb-471c-b5ab-cede4d49c67a/98e76f60-0ab4-4cf9-9bcb-8903278e.ism/manifest'
      : videoUrl;
  if (isLoading) {
    return <Loader />;
  }
  if (errorMessageCode || errorVideoCode) {
    return (
      <ErrorAlert
        show={errorMessageCode || errorVideoCode}
        onClickHandle={
          errorMessageCode ? handleCloseErrorAlert : handleCloseMediaErrorAlert
        }
        title="Error"
        subTitle={ErrorMessages(errorMessageCode)}
      />
    );
  }

  return (
    <div className="welcome-page-container">
      <div className="form-row">
        <div className="welcome-message-mail-container">
          <div className="form-label-input">
            <label className="form-label">
              {WelcomeScreenStringLabels.updateWelcomeMessage}
              {/* <Required /> */}
            </label>
            <div className="user-form-input-container">
              <textarea
                className="form-input"
                type="text"
                name="message"
                value={welcome.message}
                onChange={handleChange}
                placeholder="Input message"
                rows={4}
              />
            </div>
            {welcomeMessageErrorMessage !== '' && (
              <p className="error">{welcomeMessageErrorMessage}</p>
            )}
          </div>

          {/* <div className="form-label-input">
            <label className="form-label">
              {WelcomeScreenStringLabels.updateConciergeMailid}
              
            </label>
            <div className="form-input-container">
              <input
                className="form-input"
                type="text"
                name="conciergeMail"
                value={welcome.conciergeMail}
                onChange={handleChange}
                placeholder="Input mail id"
              />
            </div>
            {welcomeEmailErrorMessage !== '' && (
              <p className="error">{welcomeEmailErrorMessage}</p>
            )}
          </div> */}
        </div>

        <div className="video-section">
          <div className="video-heading">
            <label className="form-label ">
              {WelcomeScreenStringLabels.updateWelcomeVideo}
            </label>
          </div>
          <div className="video-container">
            <video className="video-player" src={videoFileUrl} controls />
          </div>
          <div className="video-footer">
            {errorVideoMessage !== '' && (
              <p className="error image-error">{errorVideoMessage}</p>
            )}
            <div className="video-progress">
              {videoFileDetails &&
                videoFileDetails.name &&
                videoFileDetails.size && (
                  <FileDetailsWithUploadProgressForVideo
                    fileSelected={videoFileDetails}
                    progressPer={videoStatusToProgressBarMapping()}
                    handleCancelUpload={handleCancelUpload}
                  />
                )}
            </div>
            <div className="video-replace-btn">
              <button
                className={
                  isSubAdmin || videoProgressStatus !== null
                    ? 'button-style-disabled'
                    : 'video-button'
                }
                onClick={handleClick}
                disabled={
                  isSubAdmin || videoProgressStatus !== null
                }
              >
                {WelcomeScreenStringLabels.replace}
              </button>
              <input
                type="file"
                onChange={handleFileSelect}
                ref={hiddenFileInput}
                style={{ display: 'none' }}
                disabled={
                  isSubAdmin || videoProgressStatus !== null
                }
              />
            </div>
          </div>
        </div>
      </div>
      <ConfirmationDialog
        show={showDelete}
        buttonName="Delete"
        onClickHandle={(flag) => {
          if (flag) {
            const id = { id: savedVideoId, isVideo: true, isMulti: false };
            dispatch(fileDelete(id));
          }
          setShowDelete(false);
        }}
        title="Delete Video"
        subTitle={`Are you sure you want to delete ${
          videoFileDetails && videoFileDetails.name
        }?`}
      />
    </div>
  );
}

export default WelcomePage;
