import { useDispatch, useSelector } from "react-redux";
import back from "../../../assets/right-chevron.svg";
import { useNavigate } from "react-router";
import SubAdminForm from "./SubAdminForm";
import { useEffect, useState } from "react";
import SubAdminListTable from "./SubAdminListTable";
import noSubAdmin from "../../../assets/no-subadmin.svg";
import {
  createNewSubAdmin,
  getAllSubAdmins,
  resetErrorAlert,
  setErrorAlert,
} from "../../../slices/SubAdminSlice";
import Loader from "../../common-components/Loader";
import { ManageSubAdminStringLabels } from "../../../util/Strings";
import ErrorAlert from "../../common-components/ErrorAlert";
import ErrorMessages from "../../../util/ErrorMessages";
function SubAdminList() {
  const data = useSelector((state) => state.subAdmin.subAdminListData);
  const isLoading = useSelector((state) => state.subAdmin.isLoading);
  const [showForm, setShowForm] = useState(false);
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const errorMessageCode = useSelector((state) => state.subAdmin.errorCode);
  const errorMessage = useSelector((state) => state.subAdmin.errorMessage);
  function getSubAdminData() {
    dispatch(getAllSubAdmins());
  }

  function handleCloseErrorAlert() {
    dispatch(resetErrorAlert(false));
  }

  useEffect(() => {
    getSubAdminData();
  }, []);

  function handleFormSubmit(newData) {
    if (newData) {
      dispatch(createNewSubAdmin(newData));
    }
    setShowForm(false);
  }

  if (isLoading) {
    return <Loader />;
  }
  if (errorMessage && errorMessageCode === 400) {
    return (
      <ErrorAlert
        show={errorMessageCode}
        onClickHandle={handleCloseErrorAlert}
        title='Error'
        subTitle={errorMessage}
      />
    );
  } else if (errorMessageCode) {
    return (
      <ErrorAlert
        show={errorMessageCode}
        onClickHandle={handleCloseErrorAlert}
        title='Error'
        subTitle={ErrorMessages(errorMessageCode)}
      />
    );
  }
  return (
    <div className='client-container'>
      <div className='client-header'>
        <div>
          <div
            onClick={() => {
              navigate("/home", { replace: true });
            }}
            className='back-to-visit'
          >
            <img src={back} alt='back to Visit Screen' />
            <div className='back-to-visit-text'>
              {ManageSubAdminStringLabels.backToHome}
            </div>
          </div>
          <div className='heading'>
            {ManageSubAdminStringLabels.manageSubAdmin}
          </div>
        </div>
        <button onClick={() => setShowForm(true)} className='button-style'>
          {ManageSubAdminStringLabels.add}
        </button>
      </div>
      <SubAdminForm
        show={showForm}
        onClickNext={handleFormSubmit}
        data={null}
      />
      <div className='users-list-container'>
        <div className='events-list-content'>
          {data && data.length > 0 ? (
            <table>
              <thead>
                <tr className='table-header'>
                  <th>{ManageSubAdminStringLabels.firstName}</th>
                  <th>{ManageSubAdminStringLabels.lastName}</th>
                  <th>{ManageSubAdminStringLabels.email}</th>
                  <th> </th>
                </tr>
              </thead>
              {data.map((dataItem) => {
                return <SubAdminListTable dataItem={dataItem} />;
              })}
            </table>
          ) : (
            <img
              src={noSubAdmin}
              alt='no titan of industry'
              className='no-img-subAdmin'
            />
          )}
        </div>
      </div>
    </div>
  );
}

export default SubAdminList;
