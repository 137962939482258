import { useNavigate } from "react-router-dom";
import adduser from "../../assets/add-user.svg";
import { useSelector } from "react-redux";
import { UserFeedbackStringLabels } from "../../util/Strings";

function UserFeedback({ handleShow }) {
  const isuserFeedbackAPI = useSelector(
    (state) => state.login.isuserFeedbackAPI
  );
  const navigate = useNavigate();
  if (!isuserFeedbackAPI) {
    return (
      <div
        className='menu-item'
        onClick={() => {
          handleShow();
          navigate("/user-feedback");
        }}
      >
        <img src={adduser} alt='adduser'></img>
        <div className='menu-text'>{UserFeedbackStringLabels.userfeedback}</div>
      </div>
    );
  }
}
export default UserFeedback;
