import edit from "../../../../assets/edit.svg";
import del from "../../../../assets/delete.svg";
import place from "../../../../assets/image-placeholder.png";
import { useDispatch, useSelector } from "react-redux";
import MyGallery from "../../../common-components/ImageGalleryComponent";
import { useState } from "react";
import AddNewRecreationForm from "./AddNewRecreationForm";
import {
  deleteRecreation,
  getAllRecreationData,
  updateRecreation,
} from "../../../../slices/RecreationSlice";
import ConfirmationDialog from "../../../common-components/ConfirmationDialog";
import { RechargeStringLabels } from "../../../../util/Strings";

function RecreationTableBody({ dataItem }) {
  const isSubAdmin = useSelector((state) => state.login.isSubAdmin);
  const isVisitCompleted = useSelector(
    (state) => state.visits.selectedVisitIsCompleted
  );
  const selectedOfficeId = useSelector(
    (state) => state.officelist.selectedOfficeId
  );
  const [showMultiImageGallery, setShowMultiImageGallery] = useState(false);
  const [showForm, setShowForm] = useState(false);
  const dispatch = useDispatch();
  const photos = [dataItem.thumbnailUrl, ...dataItem.photoUrls];
  const [showDelete, setShowDelete] = useState(false);
  function updateRecreationData(newData) {
    if (newData) {
      const dataWithId = { id: dataItem._id, newData: newData };
      dispatch(updateRecreation(dataWithId));
      dispatch(getAllRecreationData(selectedOfficeId));
    }
    setShowForm(false);
  }

  return (
    <tbody key={dataItem._id}>
      <tr className='event-row'>
        <td className='event-title'>{dataItem.name}</td>
        <td>{dataItem.filter}</td>
        <td>{dataItem.area}</td>
        {/* <td>{dataItem.timing}</td> */}
        <td>
          {dataItem.openingTime}-{dataItem.closingTime}|
          {dataItem.closedDays.map((day) => {
            return <div>{day} </div>;
          })}
        </td>
        <td className='address'>{dataItem.address}</td>
        <td className='description-hotel'>{dataItem.description}</td>
        <td className='image-index-container'>
          {/* <div> */}
          <img
            src={dataItem.thumbnailUrl ? dataItem.thumbnailUrl : place}
            alt='place'
            className='tourist-img'
          />
          <div className='image-index'>
            <span>{dataItem.photoUrls.length + 1}</span>
          </div>
          {/* </div> */}
          <div
            className='all-photos'
            onClick={() => {
              setShowMultiImageGallery(true);
              //setCurrentData(dataItem.photoUrls)
            }}
          >
            {RechargeStringLabels.viewAllPhotos}
          </div>
        </td>

        {isVisitCompleted || isSubAdmin ? null : (
          <td>
            <div className='event-icon'>
              <img
                src={edit}
                alt='edit'
                className='icon-middle'
                onClick={(e) => {
                  setShowForm(true);
                  getAllRecreationData(selectedOfficeId);
                }}
              />
              <img
                src={del}
                alt='delete'
                className='icon-middle'
                onClick={() => {
                  setShowDelete(true);
                }}
              />
            </div>
          </td>
        )}
      </tr>
      <MyGallery
        show={showMultiImageGallery}
        setShowMultiImageGallery={setShowMultiImageGallery}
        data={photos}
      />
      <AddNewRecreationForm
        show={showForm}
        data={dataItem}
        onClickNext={updateRecreationData}
      />
      <ConfirmationDialog
        show={showDelete}
        buttonName='Delete'
        onClickHandle={(flag) => {
          if (flag) {
            dispatch(deleteRecreation(dataItem._id));
          }
          setShowDelete(false);
        }}
        title='Delete a Roam Record'
        subTitle={`Are you sure you want to delete ${dataItem.name} record`}
      />
    </tbody>
  );
}
export default RecreationTableBody;
