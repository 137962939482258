import { useNavigate } from 'react-router-dom';
import adduser from '../../assets/add-user.svg';
import { useSelector } from 'react-redux';
import { ManageSubAdminStringLabels } from '../../util/Strings';

function ManageSubAdmin({ handleShow }) {
  const isSubAdmin = useSelector((state) => state.login.isSubAdmin);
  const navigate = useNavigate();
  if (!isSubAdmin) {
    return (
      <div
        className="menu-item"
        onClick={() => {
          handleShow();
          navigate('/manage-sub-admin');
        }}
      >
        <img src={adduser} alt="adduser"></img>
        <div className="menu-text">
          {ManageSubAdminStringLabels.manageSubAdmin}
        </div>
      </div>
    );
  }
}
export default ManageSubAdmin;
