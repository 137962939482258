import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { DecryptData } from '../util/DataEncryption';
import loginAPI from '../serviceAPI/LoginAPI';
const initialState = {
  //start: dev login
  //isAuthenticated: sessionStorage.getItem('token') ? true : false,
  //end: dev login
  isAuth: sessionStorage.getItem('isAuth')
    ? DecryptData(sessionStorage.getItem('isAuth'))
    : null,
  name: sessionStorage.getItem('token')
    ? DecryptData(sessionStorage.getItem('name'))
    : null,
  isSubAdmin: sessionStorage.getItem('isSubAdmin')
    ? DecryptData(sessionStorage.getItem('isSubAdmin'))
    : null,
  errorCode: null,
};

//start: dev login
// export const userLogin = createAsyncThunk(
//   'userLogin',
//   async ({ userName, password }, { rejectWithValue }) => {
//     try {
//       const auth = await loginAPI.AuthenticateUserLogin(userName, password);
//       return auth;
//     } catch (error) {
//       return rejectWithValue(error.response.status);
//     }
//   }
// );
//end: dev login
export const pwcLogin = createAsyncThunk(
  'pwcLogin',
  async (a_, { rejectWithValue }) => {
    try {
      const auth = await loginAPI.PwcLogin();
      return auth;
    } catch (error) {
      return rejectWithValue(error.response.status);
    }
  }
);

export const consoleLogout = createAsyncThunk(
  'consoleLogout',
  async (a_, { rejectWithValue }) => {
    try {
      const auth = await loginAPI.ConsoleLogout();
      return auth;
    } catch (error) {
      return rejectWithValue(error.response.status);
    }
  }
);

const LoginSlice = createSlice({
  name: 'login',
  initialState,
  reducers: {
    refreshIsSubAdmin: (state, action) => {
      state.isSubAdmin = sessionStorage.getItem('isSubAdmin')
        ? DecryptData(sessionStorage.getItem('isSubAdmin'))
        : null;
    },
    resetErrorAlert: (state, action) => {
      state.errorCode = null;
    },
    setIsAuthenticated: (state, action) => {
      state.isAuth = action.payload;
    },
    setName: (state, action) => {
      state.name = action.payload;
    },
    setSubAdmin: (state, action) => {
      state.isSubAdmin = action.payload;
    },
    setErrorCode: (state, action) => {
      state.errorCode = action.payload;
    },
  },
  extraReducers: {
    //start: dev login
    // [userLogin.fulfilled]: (state, action) => {
    //   state.isAuthenticated = action.payload.response ? true : false;
    //   state.name = action.payload.response ? action.payload.name : null;
    // },
    // [userLogin.rejected]: (state, action) => {
    //   state.errorCode = action.payload;
    // },
    //end: dev login
    [pwcLogin.fulfilled]: (state, action) => {
      state.isAuth = true;
    },
    [pwcLogin.rejected]: (state, action) => {
      state.errorCode = action.payload;
    },
    [consoleLogout.fulfilled]: (state, action) => {
        //start: dev login
        //state.isAuthenticated = null;
        //end: dev login
        state.isAuth = null;
        state.name = null;
    },
    [consoleLogout.rejected]: (state, action) => {
      state.errorCode = action.payload;
    },
  },
});
export const {
  refreshIsSubAdmin,
  resetErrorAlert,
  setIsAuthenticated,
  setName,
  setSubAdmin,
  setErrorCode,
} = LoginSlice.actions;
export default LoginSlice.reducer;
