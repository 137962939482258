import http from "../http-common";

async function GetAllRecreationData(id) {
  const recreationData = await http
    .get(`/recreations/${id}`)
    .then((response) => {
      return response.data;
    });
  return recreationData;
}

async function CreateNewRecreationData(newData, id) {
  const auth = await http
    .post(`/createRecreation/${id}`, newData)
    .then((response) => {
      return response.data;
    });
  return auth;
}

const UpdateRecreation = async (updateData, id) => {
  const updatedData = await http
    .put(`/updateRecreation/${id}`, updateData)
    .then((response) => {
      return response.data;
    });
  return updatedData;
};

const DeleteRecreation = async (id) => {
  const deletedData = await http
    .delete(`/deleteRecreation/${id}`)
    .then((response) => {
      return response.data;
    });
  return deletedData;
};

const RecreationAPI = {
  GetAllRecreationData,
  CreateNewRecreationData,
  UpdateRecreation,
  DeleteRecreation,
};
export default RecreationAPI;
