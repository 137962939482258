import BlackButton from '../custom-components/BlackButton';
import CancelButton from '../custom-components/CancelButton';
import CloseButton from '../custom-components/CloseButton';

function ConfirmationDialog({
  show,
  buttonName,
  onClickHandle,
  title,
  subTitle,
}) {
  if (!show) {
    return null;
  }
  return (
    <div className="modal">
      <div className="modal-content" onClick={(e) => e.stopPropagation()}>
        <div className="form-header">
          <div className="header">{title}</div>
          <CloseButton onClickhandle={() => onClickHandle(false)} />
        </div>
        <div className="form-container">
          <div
            style={{
              display: 'flex',
              marginTop: 20,
              fontSize: 16,
              fontWeight: 400,
              color: '#474747',
            }}
          >
            {subTitle}
          </div>
          <div className="footer-button-container">
            <CancelButton onClickhandle={() => onClickHandle(false)}>
              Cancel
            </CancelButton>
            <BlackButton onClickhandle={() => onClickHandle(true)}>
              {buttonName}
            </BlackButton>
          </div>
        </div>
      </div>
    </div>
  );
}

export default ConfirmationDialog;
