import http from '../http-common';

async function CreateNewGeneralNotification(newData, id) {
  const auth = await http
    .post(`/sendPushNotificationToAllUsers`, newData)
    .then((response) => {
      return response.data;
    });
  return auth;
}

const GeneralNotificationAPI = {
  CreateNewGeneralNotification,
};
export default GeneralNotificationAPI;
