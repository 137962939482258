import "../../../styles/screens/client-visit-details/ProfileCard.css";
import avatar from "../../../assets/user-placeholder.png";
import "../../../styles/screens/client-visit-details/SpeakerCard.css";
import edit from "../../../assets/edit.svg";
import del from "../../../assets/delete.svg";
import { useState } from "react";
import { useDispatch } from "react-redux";
import { useSelector } from "react-redux";
import ConfirmationDialog from "../../common-components/ConfirmationDialog";
import {
  deleteGetToKnowAC,
  updateGetToKnowAC,
} from "../../../slices/GetToKnowACSlice";
import { GetToKnowAC } from "../../../util/Strings";
import AddGetToKnowACForm from "./manage-module/AddGetToKnowACForm";

function ACInfoCard({ data }) {
  const dispatch = useDispatch();
  const [showForm, setShowForm] = useState(false);
  const [showDelete, setShowDelete] = useState(false);
  const isVisitCompleted = useSelector(
    (state) => state.visits.selectedVisitIsCompleted
  );
  const isSubAdmin = useSelector((state) => state.login.isSubAdmin);

  function updateACData(newData) {
    if (newData) {
      const dataWithId = { id: data._id, newData: newData };
      dispatch(updateGetToKnowAC(dataWithId));
    }
    setShowForm(false);
  }

  return (
    <div className="ac-card-container">
      <div className="profile-container-ac">
        <div className="ac-photo">
          <>
            {data.photoUrl ? (
              <img src={data.photoUrl} alt="Avatar" />
            ) : (
              <img src={avatar} alt="Avatar" c />
            )}
          </>
          <>
            {isVisitCompleted || isSubAdmin ? null : (
              <div className="profile-controls-ac">
                <div className="control-container">
                  <button>
                    <img
                      src={edit}
                      alt="edit"
                      onClick={(e) => {
                        setShowForm(true);
                      }}
                    />
                  </button>
                  <button>
                    <img
                      src={del}
                      alt="delete"
                      onClick={() => {
                        setShowDelete(true);
                      }}
                    />
                  </button>
                </div>
              </div>
            )}
          </>
        </div>
      </div>

      <div className="profile-description">
        <div className="profile-description-heading">
          {GetToKnowAC.description}
        </div>
        <div
          style={{
            overflow: "hidden",
            textOverflow: "ellipsis",
            display: "-webkit-box",
            lineClamp: 5,
            WebkitLineClamp: 5,
            WebkitBoxOrient: "vertical",
            fontSize: 14,
            fontWeight: 400,
            color: "#818181",
          }}
        >
          {data.description}
        </div>
      </div>
      <AddGetToKnowACForm
        show={showForm}
        onClickNext={updateACData}
        data={data}
      />
      <ConfirmationDialog
        show={showDelete}
        buttonName="Delete"
        onClickHandle={(flag) => {
          if (flag) {
            dispatch(deleteGetToKnowAC(data._id));
          }
          setShowDelete(false);
        }}
        title="Delete a Record"
        subTitle={`Are you sure you want to delete record`}
      />
    </div>
  );
}

export default ACInfoCard;
