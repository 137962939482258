import { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { getAllCabScheduled } from '../../../../slices/CabSlice';
import edit from '../../../../assets/edit.svg';
import del from '../../../../assets/delete.svg';
import ConfirmationDialog from '../../../common-components/ConfirmationDialog';
import CabScheduleForm from './CabScheduleForm';
import DateFormatter from '../../../../util/DateFormatter';
import { deleteCab, updateCab } from '../../../../slices/CabSlice';

function CabListTableBody({ dataItem }) {
  const visit = useSelector((state) => state.visits);
  const id = useSelector((state) => state.visits.selectedVisitId);
  const overlapUpdateError = useSelector((state) => state.cabs.overlapUpdateError);

  const [showUpdateCabForm, setShowUpdateCabForm] = useState(false);
  const [showDelete, setShowDelete] = useState(false);
  const dispatch = useDispatch();
  const isVisitCompleted = visit.selectedVisitIsCompleted;
  const currentDate = new Date()
    .toJSON()
    .slice(0, 10)
    .split('-')
    .reverse()
    .join('/');

    useEffect(() => {
      if (overlapUpdateError === false) {
        setShowUpdateCabForm(false);
      }
    }, [overlapUpdateError]);

  function handleUpdateCab(newData) {
    if (newData) {
      const data = { id: dataItem._id, newData: {...newData, ...newData.bookings[0]} };
      dispatch(updateCab(data));
    } else {
      setShowUpdateCabForm(false);
    } 
    
  }
  return (
    <>
      <tbody key={dataItem._id}>
        <tr className="event-row">
        <td>{dataItem.date ? DateFormatter(dataItem.date) : currentDate}</td>
          
        <td className="event-title">  
          {dataItem?.userName?.map((name, index) => (  
            <div key={index}>{name}</div>  
          ))}  
        </td> 
          <td>{dataItem.pickupTimeFormatted}</td>
          <td>{dataItem.pickupLocation}</td>
          <td>{dataItem.dropTimeFormatted}</td>
          <td>{dataItem.dropLocation}</td>
          <td>{dataItem.contactName}  </td>
          <td>{dataItem.contactNumber}  </td>
          <td>
            {dataItem.carName}  {dataItem.carRegNumber}  {dataItem.carType}
          </td>
          <td>
              <div className="user-list-icon">
                <img
                  src={edit}
                  alt="edit"
                  className="icon-middle"
                  onClick={() => {
                    setShowUpdateCabForm(true);
                  }}
                />

                <CabScheduleForm
                  show={showUpdateCabForm}
                  handleSubmit={handleUpdateCab}
                  data={dataItem}
                />
                <img
                  src={del}
                  alt="delete"
                  className="icon-middle"
                  onClick={(e) => {
                    e.stopPropagation();
                    setShowDelete(true);
                  }}
                />
              </div>
          </td>
        </tr>
      </tbody>
      <ConfirmationDialog
        show={showDelete}
        buttonName="Delete"
        onClickHandle={(flag) => {
          if (flag) {
            dispatch(deleteCab(dataItem._id));
            dispatch(getAllCabScheduled(id));
          }
          setShowDelete(false);
        }}
        title="Delete the Cab record"
        subTitle={`Are you sure you want to delete ${dataItem.userName}'s cab record`}
      />
    </>
  );
}

export default CabListTableBody;
