import "../../../styles/screens/client-visit-details/ClientProfileScreen.css";
import { useSelector } from "react-redux";
import { NavLink, Outlet, useLocation, useNavigate } from "react-router-dom";
import back from "../../../assets/right-chevron.svg";
import {
  ClientProfileScreenStringLabels,
  ManageModulesStringLabels,
} from "../../../util/Strings";
import { useState } from "react";

function ClientProfileScreen() {
  const data = useSelector((state) => state.visits);
  const id = data.selectedVisitId;
  const client = data.selectedVisitName;
  const navigate = useNavigate();
  const location = useLocation();
  const [state, setState] = useState(stateFromUrl());
  const [showCreateNewForm, setShowCreateNewForm] = useState(false);

  function stateFromUrl() {
    const url = location.pathname;
    let state;
    if (url.includes("welcome")) {
      state = "WelcomePage";
    } else if (url.includes("ac")) {
      state = "AC";
    } else if (url.includes("collaborators")) {
      state = "Speakers";
    } else if (url.includes("events")) {
      state = "Events";
    } else if (url.includes("hotels")) {
      state = "Hotels";
    } else if (url.includes("refuel")) {
      state = "Refuel";
    } else if (url.includes("roam")) {
      state = "Recreation";
    }
    return state;
  }

  function resetForm() {
    setShowCreateNewForm(false);
  }

  return (
    <div className="client-container">
      <div
        onClick={() => {
          navigate("/officehome", { replace: true });
        }}
        className="back-to-visit"
      >
        <img src={back} alt="back to Visit Screen" />
        <div className="back-to-visit-text">
          {ClientProfileScreenStringLabels.backToVisitsScreen}
        </div>
      </div>
      <div className="heading">{client ? client : ""}</div>
      <div className="clientVisit-tabContainer ">
        {/* <NavLink
          className={({ isActive }) => {
            return isActive || location.pathname.includes("manage-modules")
              ? "tablinksActive"
              : "tablinks";
          }}
          to={`/client/${id}/manage-modules/`}
        >
          {ClientProfileScreenStringLabels.manageModules}
        </NavLink> */}
        <NavLink
          className={({ isActive }) => {
            return isActive ? "tablinksActive" : "tablinks";
          }}
          end
          to={`/client/${id}/manage-modules/welcome`}
          onClick={() => setState("WelcomePage")}
        >
          {/* Welcome Page */}
          {ManageModulesStringLabels.gateWayToPossibilities}
        </NavLink>
        <NavLink
          className={({ isActive }) => {
            return isActive ? "tablinksActive" : "tablinks";
          }}
          end
          to={`/client/${id}/manage-modules/events`}
          onClick={() => {
            setState("Events");
            resetForm();
          }}
        >
          {ManageModulesStringLabels.rendezvous}
        </NavLink>
        <NavLink
          className={({ isActive }) => {
            return isActive ? "tablinksActive" : "tablinks";
          }}
          to={`/client/${id}/concierge`}
        >
          {ClientProfileScreenStringLabels.concierge}
        </NavLink>
        <NavLink
          className={({ isActive }) => {
            return isActive ? "tablinksActive" : "tablinks";
          }}
          to={`/client/${id}/visit-speakers`}
        >
          {ClientProfileScreenStringLabels.collaborators}
        </NavLink>
        <NavLink
          className={({ isActive }) => {
            return isActive ? "tablinksActive" : "tablinks";
          }}
          to={`/client/${id}/manage-users`}
        >
          {ClientProfileScreenStringLabels.manageInvitees}
        </NavLink>
        <NavLink
          className={({ isActive }) => {
            return isActive ? "tablinksActive" : "tablinks";
          }}
          to={`/client/${id}/requests/`}
        >
          {ClientProfileScreenStringLabels.requests}
        </NavLink>
      </div>
      <Outlet />
    </div>
  );
}

export default ClientProfileScreen;
