import UsersListAPI from '../serviceAPI/UsersListAPI';
import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';

const initialState = {
  userData: [],
  isLoading: false,
  errorCode: null,
  userOptions: [],
};

export const getAllUsers = createAsyncThunk(
  'getUsers',
  async (id, { rejectWithValue }) => {
    try {
      const res = await UsersListAPI.GetAllUsers(id);
      return res;
    } catch (error) {
      return rejectWithValue(error.response.status);
    }
  }
);

export const createNewUser = createAsyncThunk(
  'createNewUser',
  async ({ newData, id }, { rejectWithValue }) => {
    try {
      const res = await UsersListAPI.CreateNewUser(newData, id);
      return res;
    } catch (error) {
      return rejectWithValue(error.response.status);
    }
  }
);

export const updateUser = createAsyncThunk(
  'updateUser',
  async ({ newData, id }, { rejectWithValue }) => {
    try {
      const res = await UsersListAPI.UpdateUser(newData, id);
      return res;
    } catch (error) {
      return rejectWithValue(error.response.status);
    }
  }
);

export const deleteUser = createAsyncThunk(
  'deleteUser',
  async (id, { rejectWithValue }) => {
    try {
      const res = await UsersListAPI.DeleteUser(id);
      return res;
    } catch (error) {
      return rejectWithValue(error.response.status);
    }
  }
);

const UsersSlice = createSlice({
  name: 'users',
  initialState,
  reducers: {
    resetErrorAlert: (state, action) => {
      state.errorCode = null;
    },
  },
  extraReducers: {
    [getAllUsers.pending]: (state) => {
      state.isLoading = true;
    },
    [getAllUsers.fulfilled]: (state, action) => {
      state.isLoading = false;
      state.userData = [...action.payload];
      const options = action.payload.map((user) => {
        const userOptions = { value: user._id, label: user.name };
        return userOptions;
      });
      state.userOptions = options;
    },
    [getAllUsers.rejected]: (state, action) => {
      state.isLoading = false;
      state.errorCode = action.payload;
    },
    [createNewUser.fulfilled]: (state, action) => {
      state.userOptions = [
        ...state.userOptions,
        { value: action.payload._id, label: action.payload.name },
      ];
      state.userData.push(action.payload);
    },
    [createNewUser.rejected]: (state, action) => {
      state.errorCode = action.payload;
    },
    [updateUser.fulfilled]: (state, action) => {
      state.userOptions = state.userOptions.map((user) =>
        user.value === action.payload._id
          ? { value: action.payload._id, label: action.payload.name }
          : user
      );
      state.userData = state.userData.map((user) =>
        user._id === action.payload._id ? action.payload : user
      );
    },
    [updateUser.rejected]: (state, action) => {
      state.errorCode = action.payload;
    },
    [deleteUser.fulfilled]: (state, action) => {
      const id = action.payload._id;
      const index = state.userData.map((item) => item._id).indexOf(id);
      state.userData.splice(index, 1);
    },
    [deleteUser.rejected]: (state, action) => {
      state.errorCode = action.payload;
    },
  },
});
export const { resetErrorAlert } = UsersSlice.actions;
export default UsersSlice.reducer;
