import noRefuel from "../../../../assets/no-refuel.svg";
import { useDispatch, useSelector } from "react-redux";
import {
  getAllRefuelData,
  resetErrorAlert,
} from "../../../../slices/RefuelSlice";
import { useEffect } from "react";
import "../../../../styles/screens/client-visit-details/manage-module/HotelList.css";
import Loader from "../../../common-components/Loader";
import RefuelListTableBody from "./RefuelListTableBody";
import { RefuelStringLabels } from "../../../../util/Strings";
import ErrorAlert from "../../../common-components/ErrorAlert";
import ErrorMessages from "../../../../util/ErrorMessages";

function RefuelListTable() {
  const dispatch = useDispatch();
  const id = useSelector((state) => state.visits.selectedVisitId);
  const isVisitCompleted = useSelector(
    (state) => state.visits.selectedVisitIsCompleted
  );
  const selectedOfficeId = useSelector(
    (state) => state.officelist.selectedOfficeId
  );
  const data = useSelector((state) => state.refuel.refuelData);
  const localCurrency = useSelector((state) => state.refuel.localCurrency);
  const isLoading = useSelector((state) => state.refuel.isLoading);
  const errorMessageCode = useSelector((state) => state.refuel.errorCode);
  function getRefuelData() {
    dispatch(getAllRefuelData(selectedOfficeId));
  }
  function handleCloseErrorAlert() {
    dispatch(resetErrorAlert(false));
  }
  useEffect(() => {
    getRefuelData();
  }, []);

  if (isLoading) {
    return <Loader />;
  }
  if (errorMessageCode) {
    return (
      <ErrorAlert
        show={errorMessageCode}
        onClickHandle={handleCloseErrorAlert}
        title='Error'
        subTitle={ErrorMessages(errorMessageCode)}
      />
    );
  }
  return (
    <>
      {data && data.length > 0 ? (
        <table>
          <thead>
            <tr className='table-header'>
              <th>{RefuelStringLabels.name}</th>
              {/* <th>{RefuelStringLabels.filter}</th> */}
              <th>{RefuelStringLabels.type}</th>
              <th>{RefuelStringLabels.price}(in {localCurrency})</th>
              <th className='address'>{RefuelStringLabels.address}</th>
              <th className='description-hotel'>
                {RefuelStringLabels.description}
              </th>
              <th>{RefuelStringLabels.photo}</th>
              {isVisitCompleted ? null : <th> </th>}
            </tr>
          </thead>
          {data.map((dataItem) => {
            return <RefuelListTableBody dataItem={dataItem} />;
          })}
        </table>
      ) : (
        <img src={noRefuel} alt='no users' className='no-img' />
      )}
    </>
  );
}

export default RefuelListTable;
