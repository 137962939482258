function DateFormatter(date) {
  const replaceDate = date.replaceAll('-', '/');
  const finalFormattedDate = SplitAndReverse(replaceDate);
  return finalFormattedDate;
}

export function SplitAndReverse(data) {
  return data.split('/').reverse().join('/');
}

export function reverseLogicForParsing(date) {
  const reverseDate = SplitAndReverse(date);
  const replaceReversedDate = reverseDate.replaceAll('/', '-');
  return replaceReversedDate;
}

export function splitAndReverseWithEndTime(data) {
  return data.split('/').reverse().join('/').concat(' 23:59');
}

export default DateFormatter;
