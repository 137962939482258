import { SplitAndReverse, reverseLogicForParsing, splitAndReverseWithEndTime } from './DateFormatter';

function FilterByDate(eventData, status) {
  if (status === 'Active') {
    const activeData = eventData.filter((events) => {
      const formattedEndDate = splitAndReverseWithEndTime(events.endDate);
      const formattedStartDate = SplitAndReverse(events.startDate);
      const eventEndDate = GetDateWithParam(formattedEndDate);
      const eventStartDate = GetDateWithParam(formattedStartDate);
      return (
        eventEndDate >= GetCurrentDate() && eventStartDate <= GetCurrentDate()
      );
    });
    return sortBasedOnStartDate(activeData);
  } else if (status === 'Completed') {
    const completedData = eventData.filter((events) => {
      const formattedDate = splitAndReverseWithEndTime(events.endDate);
      const eventEndDate = GetDateWithParam(formattedDate);
      return eventEndDate < GetCurrentDate();
    });
    return sortBasedOnStartDate(completedData);
  } else if (status === 'Upcoming') {
    const upComingData = eventData.filter((events) => {
      const formattedDate = SplitAndReverse(events.startDate);
      const eventStartDate = GetDateWithParam(formattedDate);
      return eventStartDate > GetCurrentDate();
    });
    return sortBasedOnStartDate(upComingData);
  }
}

function GetCurrentDate() {
  const today = new Date();
  return today.getTime();
}

function GetDateWithParam(date) {
  const dateObj = new Date(date);
  return dateObj.getTime();
}

function sortBasedOnStartDate(data) {
  const sortedData = data.sort((a, b) => a.startDate - b.startDate);
  return sortedData;
}

function GetDate() {
  return new Date().toISOString().split('T')[0];
}

function GetMaxDate(date) {
  if (date) {
    const selectedDate = new Date(reverseLogicForParsing(date));
    return selectedDate.toISOString().split('T')[0];
  }
}

function CheckIsVisitCompleted(date) {
  const formattedDate = splitAndReverseWithEndTime(date);
  const eventEndDate = GetDateWithParam(formattedDate);
  return eventEndDate < GetCurrentDate();
}
export {
  FilterByDate,
  CheckIsVisitCompleted,
  GetCurrentDate,
  GetDateWithParam,
  GetDate,
  GetMaxDate,
};
