import { deleteSubAdmin, updateSubAdmin } from '../../../slices/SubAdminSlice';
import ConfirmationDialog from '../../common-components/ConfirmationDialog';
import edit from '../../../assets/edit.svg';
import del from '../../../assets/delete.svg';
import { useState } from 'react';
import { useDispatch } from 'react-redux';
import SubAdminForm from './SubAdminForm';

function SubAdminListTable({ dataItem }) {
  const [showUpdateForm, setShowUpdateForm] = useState(false);
  const [showDelete, setShowDelete] = useState(false);
  const dispatch = useDispatch();

  function handleUpdateSubAdmin(newData) {
    if (newData) {
      const data = { id: dataItem._id, newData: newData };
      dispatch(updateSubAdmin(data));
    }
    setShowUpdateForm(false);
  }
  return (
    <>
      <tbody key={dataItem._id}>
        <tr className="event-row">
          <td>{dataItem.firstName}</td>
          <td>{dataItem.lastName}</td>
          <td>{dataItem.email}</td>
          <td>
            <div className="user-list-icon">
              <img
                src={edit}
                alt="edit"
                className="icon-middle"
                onClick={() => {
                  setShowUpdateForm(true);
                }}
              />
              <SubAdminForm
                show={showUpdateForm}
                onClickNext={handleUpdateSubAdmin}
                data={dataItem}
              />
              <img
                src={del}
                alt="delete"
                className="icon-middle"
                onClick={(e) => {
                  e.stopPropagation();
                  setShowDelete(true);
                }}
              />
            </div>
          </td>
        </tr>
      </tbody>
      <ConfirmationDialog
        show={showDelete}
        buttonName="Delete"
        onClickHandle={(flag) => {
          if (flag) {
            dispatch(deleteSubAdmin(dataItem._id));
          }
          setShowDelete(false);
        }}
        title="Delete the SubAdmin"
        subTitle={`Are you sure you want to delete ${dataItem.firstName} record`}
      />
    </>
  );
}

export default SubAdminListTable;
