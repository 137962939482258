import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import ConciergeAPI from '../serviceAPI/ConciergeAPI';

const initialState = {
  conciergeData: [],
  isLoading: false,
  prevThumbnailDetails: null,
  prevThumbnailId: '',
  errorCode: null,
};

export const getConciergeData = createAsyncThunk(
  'getConciergeData',
  async (id, { rejectWithValue }) => {
    try {
      const res = await ConciergeAPI.GetConciergeData(id);
      return res;
    } catch (error) {
      return rejectWithValue(error.response.status);
    }
  }
);

export const createNewConciergeData = createAsyncThunk(
  'createNewConciergeData',
  async ({ newData, id }, { rejectWithValue }) => {
    try {
      const res = await ConciergeAPI.CreateNewConciergeData(newData, id);
      return res;
    } catch (error) {
      return rejectWithValue(error.response.status);
    }
  }
);

export const updateConcierge = createAsyncThunk(
  'updateConcierge',
  async ({ newData, id }, { rejectWithValue }) => {
    try {
      const res = await ConciergeAPI.UpdateConcierge(newData, id);
      return res;
    } catch (error) {
      return rejectWithValue(error.response.status);
    }
  }
);

export const deleteConcierge = createAsyncThunk(
  'deleteConcierge',
  async (id, { rejectWithValue }) => {
    try {
      const res = await ConciergeAPI.DeleteConcierge(id);
      return res;
    } catch (error) {
      return rejectWithValue(error.response.status);
    }
  }
);

const ConciergeSlice = createSlice({
  name: 'concierge',
  initialState,
  reducers: {
    setPrevThumbnailDetails: (state, action) => {
      state.prevThumbnailDetails = action.payload;
    },
    setPreThumbnailId: (state, action) => {
      state.prevThumbnailId = action.payload;
    },
    resetErrorAlert: (state, action) => {
      state.errorCode = null;
    },
  },
  extraReducers: {
    [getConciergeData.pending]: (state) => {
      state.isLoading = true;
    },
    [getConciergeData.fulfilled]: (state, action) => {
      state.isLoading = false;
      state.conciergeData = action.payload;
    },
    [getConciergeData.rejected]: (state, action) => {
      state.isLoading = false;
      state.errorCode = action.payload;
    },
    [createNewConciergeData.fulfilled]: (state, action) => {
      state.conciergeData = action.payload;
    },
    [createNewConciergeData.rejected]: (state, action) => {
      state.errorCode = action.payload;
    },
    [updateConcierge.fulfilled]: (state, action) => {
      state.conciergeData = action.payload;
    },
    [updateConcierge.rejected]: (state, action) => {
      state.errorCode = action.payload;
    },
    [deleteConcierge.fulfilled]: (state, action) => {
      state.conciergeData = null;
    },
    [deleteConcierge.rejected]: (state, action) => {
      state.errorCode = action.payload;
    },
  },
});

export const { setPrevThumbnailDetails, setPreThumbnailId, resetErrorAlert } =
  ConciergeSlice.actions;
export default ConciergeSlice.reducer;
