import '../../../styles/screens/client-visit-details/ProfileCard.css';
import avatar from '../../../assets/user-placeholder.png';
import '../../../styles/screens/client-visit-details/SpeakerCard.css';
import edit from '../../../assets/edit.svg';
import del from '../../../assets/delete.svg';
import { useState } from 'react';
import { useDispatch } from 'react-redux';
import { useSelector } from 'react-redux';
import ConfirmationDialog from '../../common-components/ConfirmationDialog';
import AddConciergeForm from './concierge/AddConciergeForm';
import { ConciergeStringLabels } from '../../../util/Strings';
import {
  deleteConcierge,
  updateConcierge,
} from '../../../slices/ConciergeSlice';

function ConciergeCard({ data }) {
  const dispatch = useDispatch();
  const [showForm, setShowForm] = useState(false);
  const [showDelete, setShowDelete] = useState(false);
  const isVisitCompleted = useSelector(
    (state) => state.visits.selectedVisitIsCompleted
  );
  const isSubAdmin = useSelector((state) => state.login.isSubAdmin);

  function updateConciergeData(newData) {
    if (newData) {
      const dataWithId = { id: data._id, newData: newData };
      dispatch(updateConcierge(dataWithId));
    }
    setShowForm(false);
  }

  return (
    <div className="speaker-card-container">
      <div className="profile-container">
        <div className="profile">
          <div className="profile-logo-details">
            <div className="profile-logo">
              {data.photoUrl ? (
                <img src={data.photoUrl} alt="Avatar" />
              ) : (
                <img src={avatar} alt="Avatar" />
              )}
            </div>
            <div className="profile-details">
              <div className="profile-details-name">{data.firstName}</div>
              <div className="profile-details-designation speaker-style">
                {data.lastName}
              </div>
              <div className="profile-details-company">{data.email} </div>
            </div>
          </div>
          {isSubAdmin ? null : (
            <div className="profile-controls">
              <button className="control-style">
                <img
                  src={edit}
                  alt="edit"
                  onClick={(e) => {
                    setShowForm(true);
                  }}
                />
              </button>
              <button>
                <img
                  src={del}
                  alt="delete"
                  onClick={() => {
                    setShowDelete(true);
                  }}
                />
              </button>
            </div>
          )}
        </div>
      </div>

      <div className="profile-description">
        <div className="profile-description-heading">
          {ConciergeStringLabels.aboutMe}
        </div>
        <div
          style={{
            overflow: 'hidden',
            textOverflow: 'ellipsis',
            display: '-webkit-box',
            lineClamp: 5,
            WebkitLineClamp: 5,
            WebkitBoxOrient: 'vertical',
            fontSize: 14,
            fontWeight: 400,
            color: '#818181',
          }}
        >
          {data.aboutMe}
        </div>
      </div>
      <AddConciergeForm
        show={showForm}
        onClickNext={updateConciergeData}
        data={data}
      />
      <ConfirmationDialog
        show={showDelete}
        buttonName="Delete"
        onClickHandle={(flag) => {
          if (flag) {
            dispatch(deleteConcierge(data._id));
          }
          setShowDelete(false);
        }}
        title="Delete an AC Visit Partner"
        subTitle={`Are you sure you want to delete ${data.firstName} record`}
      />
    </div>
  );
}

export default ConciergeCard;
