import { useDispatch, useSelector } from "react-redux";
import "../../../styles/screens/client-visit-details/manage-module/SpeakersList.css";
import { useNavigate } from "react-router";
import { useEffect, useState } from "react";
import { resetErrorAlert } from "../../../slices/ManageAcListSlice";
import Loader from "../../common-components/Loader";
import {
  OfficeListLabels,
  ManageAcStringLabels,
  ManageSubAdminStringLabels,
} from "../../../util/Strings";
import ErrorAlert from "../../common-components/ErrorAlert";
import ErrorMessages from "../../../util/ErrorMessages";
import back from "../../../assets/right-chevron.svg";
import {
  getAllOffices,
  createNewOffice,
} from "../../../slices/ManageAcListSlice.js";
import "../office-list/OfficeList.css";
import "../office-list/OfficeCard.css";
import "../office-list/OfficeCard";
import "../../../styles/screens/client-visit-details/Feedback.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faMagnifyingGlass } from "@fortawesome/free-solid-svg-icons";
import ManageAcForm from "./ManageAcForm";
import ManageAcCard from "./ManageAcCard";

function ManageAcList() {
  const data = useSelector((state) => state.officelist.OfficeListData);
  const isLoading = useSelector((state) => state.officelist.isLoading);
  const [showForm, setShowForm] = useState(false);
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const errorMessageCode = useSelector((state) => state.officelist.errorCode);
  const errorMessage = useSelector((state) => state.officelist.errorMessage);
  const [input, setInput] = useState("");

  function getAllOfficeData() {
    dispatch(getAllOffices());
  }

  function handleCloseErrorAlert() {
    dispatch(resetErrorAlert(false));
  }

  useEffect(() => {
    getAllOfficeData();
  }, []);

  function handleFormSubmit(newData) {
    if (newData) {
      dispatch(createNewOffice(newData));
    }
    setShowForm(false);
  }
  if (isLoading) {
    return <Loader />;
  }
  if (errorMessage && errorMessageCode === 400) {
    return (
      <ErrorAlert
        show={errorMessageCode}
        onClickHandle={handleCloseErrorAlert}
        title='Error'
        subTitle={errorMessage}
      />
    );
  } else if (errorMessageCode) {
    return (
      <ErrorAlert
        show={errorMessageCode}
        onClickHandle={handleCloseErrorAlert}
        title='Error'
        subTitle={ErrorMessages(errorMessageCode)}
      />
    );
  }

  return (
    <div className='client-container'>
      <div className='client-header'>
        <div
          onClick={() => {
            navigate("/home", { replace: true });
          }}
          className='back-to-visit'
        >
          <img src={back} alt='back to Visit Screen' />
          <div className='back-to-visit-text'>
            {ManageSubAdminStringLabels.backToHome}
          </div>
        </div>
      </div>
      <div className='headings'>{OfficeListLabels.pwcAcOfficeList}</div>
      <div className='officeListContainer'>
        <div className='users-list-header-container'>
          <div className='event-list-heading'>
            {ManageAcStringLabels.manageAc}
          </div>
          <div className='main-container'>
            <div className='searchContainer'>
              <div className='searchIconText'>
                <FontAwesomeIcon icon={faMagnifyingGlass} color='#D1D1D1' />
                <input
                  className='search'
                  icon='search'
                  placeholder='Search'
                  value={input}
                  onChange={(e) => setInput(e.target.value)}
                ></input>
              </div>
            </div>
            <button
              onClick={() => setShowForm(true)}
              className='add-button-style'
            >
              {ManageAcStringLabels.add}
            </button>
          </div>
        </div>
        <ManageAcForm
          show={showForm}
          onClickNext={handleFormSubmit}
          data={null}
        />
        <div className='office-list-body'>
          {data
            .filter((dataItem) => {
              return input.toLowerCase() === ""
                ? dataItem
                : dataItem.name.toLowerCase().includes(input);
            })
            .map((dataItem) => (
              <ManageAcCard dataItem={dataItem} />
            ))}
        </div>
      </div>
    </div>
  );
}

export default ManageAcList;
