import edit from "../../../../assets/edit.svg";
import del from "../../../../assets/delete.svg";
import "../../../../styles/screens/client-visit-details/manage-module/EventsListTable.css";
import { useDispatch, useSelector } from "react-redux";
import { useEffect, useState } from "react";
import {
  deleteEvent,
  getAllEvents,
  updateEvent,
  updateSelectedEvent,
} from "../../../../slices/EventListSlice";
import AddNewEventForm from "./AddNewEventForm";
import ConfirmationDialog from "../../../common-components/ConfirmationDialog";

function EventsListTableBody({ dataItem }) {
  const data = useSelector((state) => state.events.eventList);
  const selectedData = useSelector((state) => state.events.selectedEvent);
  const speakerDataFromStore = useSelector(
    (state) => state.speakers.speakerData
  );
  const dispatch = useDispatch();
  const [showForm, setShowForm] = useState(false);
  const visit = useSelector((state) => state.visits);
  const id = visit.selectedVisitId;
  const [showDelete, setShowDelete] = useState(false);
  const isVisitCompleted = useSelector(
    (state) => state.visits.selectedVisitIsCompleted
  );
  const isSubAdmin = useSelector((state) => state.login.isSubAdmin);

  function getSpeakerNameFromId(id) {
    const speaker = speakerDataFromStore.filter(
      (speaker) => speaker._id === id
    );
    if (speaker.length > 0) {
      return speaker[0].name;
    }
  }

  function updateEventData(newData) {
    if (newData) {
      const dataWithId = { id: dataItem._id, newData: newData };
      dispatch(updateEvent(dataWithId));
      dispatch(getAllEvents(id));
    }
    setShowForm(false);
  }

  return (
    <>
      <tbody key={dataItem._id}>
        <tr className='event-row'>
          <td className='event-title'>{dataItem.title}</td>
          <td>{dataItem.eventType}</td>
          <td>{dataItem.date}</td>
          <td>
            {dataItem.startTime}-{dataItem.endTime}
          </td>
          <td>{dataItem.location}</td>
          <td>
            {dataItem.speakers && dataItem.speakers.length > 0
              ? dataItem.speakers.map((speaker) => {
                  return (
                    <div>
                      {speaker.hasOwnProperty("name")
                        ? speaker.name
                        : getSpeakerNameFromId(speaker)}
                    </div>
                  );
                })
              : "N/A"}
          </td>
          <td>
            <div className='event-materials'>
              {dataItem.materials && dataItem.materials.length > 0
                ? dataItem.materials.map((material) => {
                    return (
                      <div className='event-materials-container'>
                        <a
                          href={material.fileUri}
                          target='_blank'
                          className='material-style'
                        >
                          {material.fileName}
                        </a>
                      </div>
                    );
                  })
                : "N/A"}
            </div>
          </td>
          <td>
            {isSubAdmin ? null : (
              <div className='event-icon'>
                <img
                  src={edit}
                  alt='edit'
                  className='icon-middle'
                  onClick={(e) => {
                    setShowForm(true);
                    dispatch(updateSelectedEvent(dataItem));
                    getAllEvents(id);
                  }}
                />
                <img
                  src={del}
                  alt='delete'
                  className='icon-middle'
                  onClick={() => {
                    setShowDelete(true);
                  }}
                />
              </div>
            )}
          </td>
        </tr>
        <AddNewEventForm
          show={showForm}
          onClickNext={updateEventData}
          data={dataItem}
        />
        <ConfirmationDialog
          show={showDelete}
          buttonName='Delete'
          onClickHandle={(flag) => {
            if (flag) {
              dispatch(deleteEvent(dataItem._id));
            }
            setShowDelete(false);
          }}
          title='Delete a Event'
          subTitle={`Are you sure you want to delete ${dataItem.title} record`}
        />
      </tbody>
    </>
  );
}

export default EventsListTableBody;
