import { useDispatch, useSelector } from 'react-redux';
import '../../../../styles/screens/client-visit-details/manage-module/SpeakersList.css';
import noConcierge from '../../../../assets/no-concierge.svg';
import Loader from '../../../common-components/Loader';
import { useEffect, useState } from 'react';
import { ConciergeStringLabels } from '../../../../util/Strings';
import ErrorAlert from '../../../common-components/ErrorAlert';
import ErrorMessages from '../../../../util/ErrorMessages';
import ConciergeCard from '../ConciergeCard';
import AddConciergeForm from './AddConciergeForm';
import {
  createNewConciergeData,
  getConciergeData,
  resetErrorAlert,
} from '../../../../slices/ConciergeSlice';

function ConciergeList() {
  const data = useSelector((state) => state.concierge.conciergeData);
  const isLoading = useSelector((state) => state.concierge.isLoading);
  const id = useSelector((state) => state.visits.selectedVisitId);
  const errorMessageCode = useSelector((state) => state.concierge.errorCode);
  const visit = useSelector((state) => state.visits);
  const isSubAdmin = useSelector((state) => state.login.isSubAdmin);
  const dispatch = useDispatch();
  const [showForm, setShowForm] = useState(false);

  function getAllConciergeData() {
    dispatch(getConciergeData(id));
  }

  function handleCloseErrorAlert() {
    dispatch(resetErrorAlert(false));
  }

  useEffect(() => {
    getAllConciergeData();
  }, []);

  function handleFormSubmit(newData) {
    if (newData) {
      const data = { id: visit.selectedVisitId, newData: newData };
      dispatch(createNewConciergeData(data));
    }
    setShowForm(false);
  }

  if (isLoading) {
    return <Loader />;
  }

  if (errorMessageCode) {
    return (
      <ErrorAlert
        show={errorMessageCode}
        onClickHandle={handleCloseErrorAlert}
        title="Error"
        subTitle={ErrorMessages(errorMessageCode)}
      />
    );
  }

  return (
    <div className="users-list-container">
      <div className="users-list-header-container">
        <div className="event-list-heading">
          {ConciergeStringLabels.concierge}
        </div>
        <button
          onClick={() => setShowForm(true)}
          className={
            isSubAdmin || data
              ? 'button-style-disabled'
              : 'button-style'
          }
          disabled={isSubAdmin || data}
        >
          {ConciergeStringLabels.add}
        </button>
      </div>

      <AddConciergeForm
        show={showForm}
        onClickNext={handleFormSubmit}
        data={null}
      />
      <div className="speakers-list-body">
        {data ? <ConciergeCard data={data} /> : null}
      </div>
      {!data && <img src={noConcierge} alt="no AC Visit Partner" className="no-img" />}
    </div>
  );
}

export default ConciergeList;
