import '../../styles/custom-components/Button.css';

function BlackButton({ children, onClickhandle }) {
  return (
    <>
      <button
        className="black-button"
        onClick={() => {
          onClickhandle();
        }}
      >
        {children}
      </button>
    </>
  );
}

export default BlackButton;
