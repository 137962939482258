import { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { deleteUser, updateUser } from "../../../../slices/UserSlice";
import edit from "../../../../assets/edit.svg";
import del from "../../../../assets/delete.svg";
import AddNewUserForm from "./AddNewUserForm";
import ConfirmationDialog from "../../../common-components/ConfirmationDialog";

function UserListTableBody({ dataItem }) {
  console.log("data".dataItem);
  const visit = useSelector((state) => state.visits);
  const [showUpdateUserForm, setShowUpdateUserForm] = useState(false);
  const [showDelete, setShowDelete] = useState(false);
  const dispatch = useDispatch();
  const isSubAdmin = useSelector((state) => state.login.isSubAdmin);
  function handleUpdateUser(newData) {
    if (newData) {
      const data = { id: dataItem._id, newData: newData };
      dispatch(updateUser(data));
    }
    setShowUpdateUserForm(false);
  }
  return (
    <>
      <tbody key={dataItem._id}>
        <tr className='event-row'>
          <td className='event-title'>{dataItem.name}</td>
          <td>{dataItem.email}</td>
          <td>{`${dataItem.countryCode} ${dataItem.phoneNumber}`}</td>

          {isSubAdmin ? null : (
            <td>
              <div className='user-list-icon'>
                <><img
                  src={edit}
                  alt='edit'
                  className='icon-middle'
                  onClick={() => {
                    setShowUpdateUserForm(true);
                  } } /><AddNewUserForm
                    show={showUpdateUserForm}
                    onClickNext={handleUpdateUser}
                    data={dataItem} /></>
                <img
                  src={del}
                  alt='delete'
                  className='icon-middle'
                  onClick={(e) => {
                    e.stopPropagation();
                    setShowDelete(true);
                  }}
                />
              </div>
            </td>
          )}
        </tr>
      </tbody>
      <ConfirmationDialog
        show={showDelete}
        buttonName='Delete'
        onClickHandle={(flag) => {
          if (flag) {
            dispatch(deleteUser(dataItem._id));
          }
          setShowDelete(false);
        }}
        title='Delete the User'
        subTitle={`Are you sure you want to delete ${dataItem.name} record`}
      />
    </>
  );
}

export default UserListTableBody;
