import { useNavigate } from "react-router-dom";
import adduser from "../../assets/add-user.svg";
import { useSelector } from "react-redux";
import { OfficeListLabels } from "../../util/Strings";

function ManageAC({ handleShow }) {
  const isOfficeListAPI = useSelector((state) => state.login.isOfficeListAPI);
  const navigate = useNavigate();
  if (!isOfficeListAPI) {
    return (
      <div
        className='menu-item'
        onClick={() => {
          handleShow();
          navigate("/manage-ac");
        }}
      >
        <img src={adduser} alt='adduser'></img>
        <div className='menu-text'>{OfficeListLabels.manageac}</div>
      </div>
    );
  }
}
export default ManageAC;
