import http from '../http-common';

const GetAllFeedbacks = async () => {
  const users = await http.get('/feedback/all').then((response) => {
    return response.data;
  });
  console.log(users)
  return users;
};

const CreateFeedbackByUser = async (newData) => {
  const auth = await http
    .post('/feedback', {
      email: newData.email,
      rateing: newData.rateing,
     message: newData.message,
    })
    .then((response) => {
      return response;
    });

  return auth.data;
};

const GetAllFeedbacksByUser = async () => {
  const users = await http.get('/feedback/user').then((response) => {
    return response.data;
  });
  return users;
};

// const GetAllFeedbacksByUser = async (updateData, id) => {
//   const updatedData = await http
//     .put(`/updateAdmin/${id}`, {
//       firstName: updateData.firstName,
//       lastName: updateData.lastName,
//       email: updateData.email,
//     })
//     .then((response) => {
//       return response.data;
//     });
//   return updatedData;
// };

const DeleteFeedbackByUser = async (id) => {
  const deletedData = await http
    .delete(`/feedback/${id}`)
    .then((response) => {
      return response.data;
    });
  return deletedData;
};

const UserFeedbackAPI= {
  GetAllFeedbacks,
  CreateFeedbackByUser,
  GetAllFeedbacksByUser,
  DeleteFeedbackByUser
};

export default UserFeedbackAPI;
