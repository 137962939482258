import { useState } from 'react';
import '../../styles/screens/client-visit-details/manage-users/AddNewUserForm.css';
import Required from './Required';
import CloseButton from '../custom-components/CloseButton';
import CancelButton from '../custom-components/CancelButton';
import BlackButton from '../custom-components/BlackButton';
import { GeneralNotificationLabels } from '../../util/Strings';
import { NewGeneralNotificationFormValidations } from '../../util/Validations';
import ErrorMessageComponent from './ErrorMessageComponent';

function GeneralNotificationForm({ show, onClickNext, data }) {
  const [state, setState] = useState({
    type: data && data.type ? data.type : '',
    content: data && data.content ? data.content : '',
  });

  const [validation, setValidation] = useState({});

  function handleChange(e) {
    const value = e.target.value;
    setState({
      ...state,
      [e.target.name]: value,
    });
  }

  const checkValidation = () => {
    const { errors, isValid } = NewGeneralNotificationFormValidations(state);
    setValidation(errors);
    return isValid;
  };

  function handleNextButton() {
    if (checkValidation()) {
      if (state.type && state.content) {
        const newData = state;
        if (data) {
          setState({
            type: newData.type,
            content: newData.content,
          });
          onClickNext(newData);
        } else {
          setState({});
          onClickNext(newData);
        }
      } else {
        onClickNext();
      }
    }
  }

  if (!show) {
    return null;
  }

  return (
    <div className="modal">
      <div className="form-scroll">
        <div className="form-flex">
          <div
            className="user-form-modal-content"
            onClick={(e) => e.stopPropagation()}
          >
            <div className="form-header">
              <div className="header">
                {GeneralNotificationLabels.generalNotificationFormTitle}
              </div>
              <CloseButton
                onClickhandle={() => {
                  if (data === null) {
                    setState({});
                    setValidation({});
                  }
                  onClickNext();
                }}
              />
            </div>

            <div className="form-container">
              <div className="form-label-input">
                <label className="form-label">
                  {GeneralNotificationLabels.title}
                  <Required />
                </label>
                <div className="form-input-container">
                  <input
                    type="text"
                    value={state.type}
                    name="type"
                    onChange={(e) => handleChange(e)}
                    className="form-input"
                    placeholder="Input"
                    required={true}
                  ></input>
                </div>
                <ErrorMessageComponent message={validation.type} />
              </div>

              <div className="form-label-input">
                <label className="form-label">
                  {GeneralNotificationLabels.subTitle}
                  <Required />
                </label>
                <div className="user-form-input-container">
                  <textarea
                    className="form-input"
                    type="text"
                    value={state.content}
                    name="content"
                    onChange={(e) => handleChange(e)}
                    placeholder="Input"
                    rows={3}
                  ></textarea>
                </div>
                <ErrorMessageComponent message={validation.content} />
              </div>

              <div className="footer-button-container">
                <CancelButton
                  onClickhandle={() => {
                    if (data === null) {
                      setState({});
                      setValidation({});
                    }
                    onClickNext();
                  }}
                >
                  {GeneralNotificationLabels.cancel}
                </CancelButton>
                <BlackButton onClickhandle={() => handleNextButton()}>
                  {GeneralNotificationLabels.add}
                </BlackButton>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default GeneralNotificationForm;
