import VisitAPI from '../serviceAPI/VisitAPI';
import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { DecryptData, EncryptData } from '../util/DataEncryption';

const initialState = {
  visitData: [],
  selectedVisitId: sessionStorage.getItem('selectedVisitId')
    ? DecryptData(sessionStorage.getItem('selectedVisitId'))
    : null,
  selectedVisitName: sessionStorage.getItem('selectedVisitName')
    ? DecryptData(sessionStorage.getItem('selectedVisitName'))
    : null,
  selectedVisitEndDate: sessionStorage.getItem('selectedVisitEndDate')
    ? DecryptData(sessionStorage.getItem('selectedVisitEndDate'))
    : null,
  isLoading: false,
  selectedVisitIsCompleted: sessionStorage.getItem('selectedVisitIsCompleted')
    ? DecryptData(sessionStorage.getItem('selectedVisitIsCompleted'))
    : false,
  prevThumbnailDetails: null,
  prevThumbnailId: '',
  errorCode: null,
  updatedVisitData: {}
};

export const getAllVisits = createAsyncThunk(
  'getAllVisits',
  async (officeId, { rejectWithValue }) => {
    try {
      const res = await VisitAPI.GetAllVisits(officeId);
      return res;
    } catch (error) {
      return rejectWithValue(error.response.status);
    }
  }
);

export const createNewVisit = createAsyncThunk(
  'createNewVisit',
  async (newData, { rejectWithValue }) => {
    try {
      const res = await VisitAPI.CreateNewVisit(
        newData
      );
      return res;
    } catch (error) {
      return rejectWithValue(error.response.status);
    }
  }
);

export const updateVisit = createAsyncThunk(
  'updateVisit',
  async ({ newData, id }, { rejectWithValue }) => {
    try {
      const res = await VisitAPI.UpdateVisit(newData, id);
      return res;
    } catch (error) {
      return rejectWithValue(error.response.status);
    }
  }
);

export const updateVisitSpeakers = createAsyncThunk(
  'updateVisitSpeakers',
  async ({ speakers, id }, { rejectWithValue }) => {
    console.log(speakers, "slice")
    try {
      const res = await VisitAPI.UpdateVisitSpeakers(speakers, id);
      return res;
    } catch (error) {
      return rejectWithValue(error.response.status);
    }
  }
);

export const deleteVisit = createAsyncThunk(
  'deleteVisit',
  async (id, { rejectWithValue }) => {
    try {
      const res = await VisitAPI.DeleteVisit(id);
      return res;
    } catch (error) {
      return rejectWithValue(error.response.status);
    }
  }
);

const VisitSlice = createSlice({
  name: 'visits',
  initialState,
  reducers: {
    updateVisitId: (state, action) => {
      sessionStorage.setItem('selectedVisitId', EncryptData(action.payload.id));
      sessionStorage.setItem(
        'selectedVisitName',
        EncryptData(action.payload.name)
      );
      sessionStorage.setItem(
        'selectedVisitEndDate',
        EncryptData(action.payload.endDate)
      );
      state.selectedVisitId = action.payload.id;
      state.selectedVisitName = action.payload.name;
      state.selectedVisitEndDate = action.payload.endDate;
    },
    updatedIsVisitCompleted: (state, action) => {
      sessionStorage.setItem(
        'selectedVisitIsCompleted',
        EncryptData(action.payload)
      );
      state.selectedVisitIsCompleted = action.payload;
    },
    setPrevThumbnailDetails: (state, action) => {
      state.prevThumbnailDetails = action.payload;
    },
    setPreThumbnailId: (state, action) => {
      state.prevThumbnailId = action.payload;
    },
    resetErrorAlert: (state, action) => {
      state.errorCode = null;
    },
  },
  extraReducers: {
    [getAllVisits.pending]: (state) => {
      state.isLoading = true;
    },
    [getAllVisits.fulfilled]: (state, action) => {
      state.isLoading = false;
      state.visitData = [...action.payload];
    },
    [getAllVisits.rejected]: (state, action) => {
      state.isLoading = false;
      state.errorCode = action.payload;
    },
    [createNewVisit.fulfilled]: (state, action) => {
      state.visitData.push(action.payload);
    },
    [createNewVisit.rejected]: (state, action) => {
      state.errorCode = action.payload;
    },
    [updateVisit.fulfilled]: (state, action) => {
      state.visitData = state.visitData.map((visit) =>
        visit._id === action.payload._id ? action.payload : visit
      );
    },
    [updateVisit.rejected]: (state, action) => {
      state.errorCode = action.payload;
    },
    [updateVisitSpeakers.fulfilled]: (state, action) => {
      state.updatedVisitData = action.payload;
    },
    [updateVisitSpeakers.rejected]: (state, action) => {
      state.errorCode = action.payload;
    },
    [deleteVisit.fulfilled]: (state, action) => {
      const id = action.payload._id;
      const index = state.visitData.map((item) => item._id).indexOf(id);
      state.visitData.splice(index, 1);
    },
    [deleteVisit.rejected]: (state, action) => {
      state.errorCode = action.payload;
    },
  },
});

export const { setPrevThumbnailDetails, setPreThumbnailId, updateVisitId, updatedIsVisitCompleted, resetErrorAlert } =
  VisitSlice.actions;
export default VisitSlice.reducer;
