import http from "../http-common";

const GetAllHotels = async (id) => {
  const hotels = await http.get(`/hotels/${id}`).then((response) => {
    return response.data;
  });
  return hotels;
};

// const GetAllHotelRequests = async (id) => {
//   const hotelRequests = await http
//     .get(`/hotelRequest/${id}`)
//     .then((response) => {
//       console.log(response);
//       return response.data;
//     });
//   return hotelRequests;
// };

// const GetAllHotelScheduled = async (id) => {
//   const hotelsScheduled = await http
//     .get(`/scheduledHotelsUnderVisit/${id}`)
//     .then((response) => {
//       console.log(response);
//       return response.data;
//     });
//   return hotelsScheduled;
// };

const CreateNewHotel = async (newData, id) => {
  const auth = await http
    .post(`/createHotel/${id}`, newData)
    .then((response) => {
      return response.data;
    });

  return auth;
};

// const ScheduleHotelBooking = async (newData, id) => {
//   const auth = await http
//     .post(`/schedulehotel/${id}`, newData)
//     .then((response) => {
//       console.log(response);
//       return response.data;
//     });

//   return auth;
// };

const UpdateHotel = async (updateData, id) => {
  const updatedData = await http
    .put(`/updateHotel/${id}`, updateData)
    .then((response) => {
      return response.data;
    });
  return updatedData;
};

const DeleteHotel = async (id) => {
  const deletedData = await http
    .delete(`/deleteHotel/${id}`)
    .then((response) => {
      return response.data;
    });
  return deletedData;
};

const HotelAPI = {
  GetAllHotels,
  // GetAllHotelRequests,
  // GetAllHotelScheduled,
  CreateNewHotel,
  // ScheduleHotelBooking,
  UpdateHotel,
  DeleteHotel,
};

export default HotelAPI;
