import React, { useState } from "react";
import "../../../styles/screens/client-visit-screens/CreateNewVisitForm.css";
import Required from "../../common-components/Required";
import BlackButton from "../../custom-components/BlackButton";
import CancelButton from "../../custom-components/CancelButton";
import CloseButton from "../../custom-components/CloseButton";
import "../office-list/OfficeCard.css";
import {
  ClientVisitScreenStringLabels,
  OfficeListLabels,
  RefuelStringLabels,
  UserFeedbackStringLabels,
} from "../../../util/Strings";
import { NewOfficeFormValidations } from "../../../util/Validations";
import ErrorMessageComponent from "../../common-components/ErrorMessageComponent";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faToggleOff, faToggleOn } from "@fortawesome/free-solid-svg-icons";
import FileUploader from "../../common-components/FileUploader";
import { useDispatch, useSelector } from "react-redux";
import ImagePreviewWithFilename from "../../common-components/ImagePreviewWithFilename";
import { fileUpload, fileDelete, previewImage, progressPercentage, setSelectedFile } from "../../../slices/FileUploadSlice";
import useFileValidation from "../../../util/useFileValidation";
import { setPreThumbnailId, setPrevThumbnailDetails } from "../../../slices/ManageAcListSlice";

function ManageAcForm({ show, onClickNext, data }) {
  const [item, setItem] = React.useState(false);
  const [state, setState] = useState({
    name: data && data.name ? data.name : null,
    address: data && data.address ? data.address : null,
    latitude: data && data.latitude ? data.latitude : null,
    longitude: data && data.longitude ? data.longitude : null,
    city: data && data.city ? data.city : null,
    country: data && data.country ? data.country : null,
    available: data && data.available ? data.available : null,
    photoId: data && data.photoId ? data.photoId : null,
  });
  const [validation, setValidation] = useState({});

  function handleChange(e) {
    const value = e.target.value;
    setState({
      ...state,
      [e.target.name]: value,
    });
  }
  const handleItem = (e) => {
    setState({
      ...state,
      [e.target.name]: e.target.checked,
    });
  };

  const checkValidation = () => {
    const { errors, isValid } = NewOfficeFormValidations(state);
    setValidation(errors);
    return isValid;
  };

  const [prevFileData, setPreFileData] = useState({
    photoUrl: data && data.photoUrl ? data.photoUrl : null,
    fileName: data && data.fileName ? data.fileName : null,
  });

  const dispatch = useDispatch();

  const prevImageId = useSelector((state) => state.concierge.prevThumbnailId);

  const [imageDeleteList, setImageDeleteList] = useState([]);

  const [photoUrl, setPhotoUrl] = useState(null);

  const [prevFileId, setPrevFileId] = useState(
    data && data.photoId ? data.photoId : null
  );

  function handleImageCancel() {
    setState({
      ...state,
      ["photoId"]: null,
    });
    prevFileData.photoUrl = null;
    setImageDeleteList([...imageDeleteList, prevFileId]);
  }

  const { errorSingleMessage, setErrorSingleMessage, validateFile } =
    useFileValidation();

  const handleDrag = function (event) {
    event.preventDefault();
  };

  const handleDrop = function (event) {
    event.preventDefault();
    const files = event.dataTransfer.files;
    postFileData(files);
  };

  const handleFileSelect = function (event) {
    const files = event.target.files;
    postFileData(files);
  };
  
  function handleState() {
    setState({ ...state, ["photoId"]: data && data.photoId });
  }

  function resetImage() {
    dispatch(progressPercentage(0));
    dispatch(setSelectedFile(null));
    dispatch(previewImage(null));
  }

  function postFileData(files) {
    const valid = validateFile(files);
    if (valid) {
      const file = files[0];
      if (prevFileData) {
        dispatch(setPreThumbnailId(prevFileId));
        setPrevFileId(prevFileId);
        dispatch(setPrevThumbnailDetails(prevFileData));
        setPreFileData({ photoUrl: null, fileName: null });
      }
      if (file) {
        dispatch(setSelectedFile(file));
        console.log('file', file)
        dispatch(fileUpload(file))
          .unwrap()
          .then((res) => {
            setPhotoUrl(res[0]._id);
            setState({ ...state, ["photoId"]: res[0]._id });
            setPrevFileId(res[0]._id);
          });
      }
    }
  }

  function handleNextButton() {
    if (checkValidation()) {
      if (
        state.name &&
        state.address &&
        state.latitude &&
        state.longitude &&
        state.city &&
        state.country
      ) {
        const newData = state;
        if (data) {
          resetImage();
          setState({
            name: newData.name,
            address: newData.address,
            latitude: newData.latitude,
            longitude: newData.longitude,
            city: newData.city,
            country: newData.country,
            available: newData.available,
            photoId: newData.photoId,
          });
          imageDeleteList &&
            imageDeleteList.map((image) => {
              dispatch(fileDelete({ id: image }));
            });

          setPhotoUrl(null);
          onClickNext(newData);

          if (state.photoId !== prevImageId && prevImageId !== "") {
            const id = { id: prevImageId, isVideo: false };
            dispatch(fileDelete(id));
            dispatch(setPreThumbnailId(""));
          }

          setPreFileData({
            photoUrl: data && data.photoUrl ? data.photoUrl : null,
            fileName: data && data.fileName ? data.fileName : null,
          });
          setErrorSingleMessage("");
        } else {
          setState({});
          setPhotoUrl(null);
          onClickNext(newData);
        }
      } else {
        onClickNext();
      }
    }
  }

  if (!show) {
    return null;
  }

  return (
    <div className="modal">
      <div className="form-scroll">
        <div className="form-flex">
          <div className="modal-content" onClick={(e) => e.stopPropagation()}>
            <div className="form-header">
              <div className="header">
                {data
                  ? OfficeListLabels.updateFormTitle
                  : OfficeListLabels.officemanagecard}
              </div>
              <CloseButton
                onClickhandle={() => {
                  if (data === null) {
                    setState({});
                    setValidation({});
                  }
                  setPreFileData({
                      photoUrl: data && data.photoUrl ? data.photoUrl : null,
                      fileName: data && data.fileName ? data.fileName : null,
                    });
                  onClickNext();
                }}
              />
            </div>

            <div className="form-container">
              <div className="form-label-input">
                <label className="form-label">
                  {OfficeListLabels.name}
                  <Required />
                </label>
                <div className="form-input-container">
                  <input
                    type="text"
                    value={state.name}
                    name="name"
                    onChange={(e) => handleChange(e)}
                    className="form-input"
                    placeholder="Name"
                    required={true}
                  />
                </div>
                <ErrorMessageComponent message={validation.name} />
              </div>

              {/* <div className='form-label-input'>
                <label className='form-label'>
                  {OfficeListLabels.officeLocation}
                  <Required />
                </label>
                <div className='form-input-container'>
                  <input
                    type='text'
                    value={state.location}
                    name='location'
                    onChange={(e) => handleChange(e)}
                    className='form-input'
                    placeholder='Location'
                    required={true}
                  />
                </div>
                <ErrorMessageComponent message={validation.location} />
              </div> */}

              <div className="form-label-input">
                <label className="form-label">
                  {OfficeListLabels.officeAddress}
                  <Required />
                </label>
                <div className="form-input-container">
                  <input
                    type="text"
                    value={state.address}
                    name="address"
                    onChange={(e) => handleChange(e)}
                    className="form-input"
                    placeholder="Address"
                    required={true}
                  />
                </div>
                <ErrorMessageComponent message={validation.address} />
              </div>

              <div className="form-label-input">
                <label className="form-label">
                  {OfficeListLabels.latitude}
                  <Required />
                </label>
                <div className="form-input-container">
                  <input
                    className="form-input"
                    type="text"
                    name="latitude"
                    value={state.latitude}
                    onChange={(e) => handleChange(e)}
                    placeholder="e.g. 12.9845"
                    required={true}
                  />
                </div>
                <ErrorMessageComponent message={validation.latitude} />
              </div>

              <div className="form-label-input">
                <label className="form-label">
                  {OfficeListLabels.longitude}
                  <Required />
                </label>
                <div className="form-input-container">
                  <input
                    className="form-input"
                    type="text"
                    name="longitude"
                    value={state.longitude}
                    onChange={(e) => handleChange(e)}
                    placeholder="e.g. 77.5846"
                    required={true}
                  />
                </div>
                <ErrorMessageComponent message={validation.longitude} />
              </div>

              <div className="form-label-input">
                <label className="form-label">
                  {OfficeListLabels.city}
                  <Required />
                </label>
                <div className="form-input-container">
                  <input
                    type="text"
                    value={state.city}
                    name="city"
                    onChange={(e) => handleChange(e)}
                    className="form-input"
                    placeholder="City"
                    required={true}
                  />
                </div>
                <ErrorMessageComponent message={validation.city} />
              </div>

              <div className="form-label-input">
                <label className="form-label">
                  {OfficeListLabels.country}
                  <Required />
                </label>
                <div className="form-input-container">
                  <input
                    className="form-input"
                    type="text"
                    name="country"
                    value={state.country}
                    onChange={(e) => handleChange(e)}
                    placeholder="Country"
                    required={true}
                  />
                </div>
                {validation.email && (
                  <p className="error">{validation.country}</p>
                )}
              </div>
              <FileUploader
                previewShape="circular"
                headerName="AC Thumbnail Photo"
                handleDragOver={handleDrag}
                handleDrop={handleDrop}
                handleFileSelect={handleFileSelect}
                setPreFileData={setPreFileData}
                handleState={handleState}
                type="concierge"
              />
              {errorSingleMessage !== "" && (
                <p className="error image-error">{errorSingleMessage}</p>
              )}
              {prevFileData &&
              prevFileData.photoUrl &&
              data &&
              data.photoUrl &&
              data.fileName ? (
                <ImagePreviewWithFilename
                  photoUrl={data.photoUrl}
                  fileName={data.fileName}
                  previewShape="circular"
                  handleCancel={handleImageCancel}
                />
              ) : null}

              <div className="form-label-input">
                <label className="form-label">
                  {state.available
                    ? OfficeListLabels.toggleon
                    : OfficeListLabels.toggleoff}
                </label>
                <label className="form-label" for="check">
                  <FontAwesomeIcon
                    icon={state.available ? faToggleOn : faToggleOff}
                    size="3x"
                  />
                </label>
                <input
                  className="toggle-manage-ac"
                  id="check"
                  type="checkbox"
                  value={state.available}
                  onChange={(e) => handleItem(e)}
                  name={"available"}
                />
              </div>
              <div className="footer-button-container">
                <CancelButton
                  onClickhandle={() => {
                    if (data === null) {
                      setState({});
                      setValidation({});
                    }
                    setPreFileData({
                      photoUrl: data && data.photoUrl ? data.photoUrl : null,
                      fileName: data && data.fileName ? data.fileName : null,
                    });
                    onClickNext();
                    setErrorSingleMessage("");
                  }}
                >
                  {UserFeedbackStringLabels.cancel}
                </CancelButton>
                <BlackButton onClickhandle={() => handleNextButton()}>
                  {data
                    ? UserFeedbackStringLabels.update
                    : UserFeedbackStringLabels.add}
                </BlackButton>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default ManageAcForm;
