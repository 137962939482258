import '../../styles/common-components/ImageGalleryComponent.css';
import 'react-image-gallery/styles/css/image-gallery.css';
import CloseButton from '../../components/custom-components/CloseButton';

import React from 'react';
import ImageGallery from 'react-image-gallery';
import { ImageGalleryString } from '../../util/Strings';

function MyGallery({ show, setShowMultiImageGallery, data }) {
  if (!show) {
    return null;
  }
  var photosArray = [];
  if (data) {
    data &&
      data.map((dataItem) => {
        photosArray.push({ original: dataItem, thumbnail: dataItem });
      });
  }
  return (
    <div className="modal custom-height">
      <div className="modal-image-content" onClick={(e) => e.stopPropagation()}>
        <div className="image-header">
          <div>{ImageGalleryString.photos}</div>
          <CloseButton
            onClickhandle={() => {
              setShowMultiImageGallery(false);
            }}
            isImageCloseButton={true}
          />
        </div>

        {photosArray.length >= 1 ? (
          <ImageGallery
            items={photosArray}
            showFullscreenButton={false}
            showIndex={true}
          />
        ) : (
          <p>{ImageGalleryString.noPhoto}</p>
        )}
      </div>
    </div>
  );
}

export default MyGallery;
