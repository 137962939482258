import avatar from '../../../assets/user-placeholder.png'
import { SupportStringLabels } from '../../../util/Strings';
function SupportCard(){
    return (
        <div className="speaker-card-container">
          <div className="profile-container">
            <div className="profile">
              <div className="profile-logo-details">
                <div className="profile-logo">
                    <img src={avatar} alt="Avatar" />
                </div>
                <div className="profile-details">
                  <div className="profile-details-name">{SupportStringLabels.firstname}</div>
                  <div className="profile-details-designation speaker-style">
                  {SupportStringLabels.lastname}
                  </div>
                  <div className="profile-details-company">{SupportStringLabels.email}</div>
                </div>
              </div>
            </div>
          </div>
    
         
        </div>
      );
}
export default SupportCard;