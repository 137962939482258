import { useState } from "react";
import Select from "react-select";
import "../../../../styles/screens/client-visit-details/manage-module/AddNewEventForm.css";
import Required from "../../../common-components/Required";
import { reverseLogicForParsing } from "../../../../util/DateFormatter";
import { useDispatch, useSelector } from "react-redux";
import CloseButton from "../../../custom-components/CloseButton";
import CancelButton from "../../../custom-components/CancelButton";
import BlackButton from "../../../custom-components/BlackButton";
import { RendezvousStringLabels } from "../../../../util/Strings";
import { GetDate, GetMaxDate } from "../../../../util/FilterDate";
import { NewEventFormValidations } from "../../../../util/Validations";
import ErrorMessageComponent from "../../../common-components/ErrorMessageComponent";
import FileUploader from "../../../common-components/FileUploader";
import {
  setPrevMaterialDetails,
  setPrevMaterialIds,
} from "../../../../slices/EventListSlice";
import {
  fileDelete,
  multipleFileUpload,
  progressPercentage,
  resetMediaErrorAlert,
  resetMultipleFileUpload,
  setSelectedFile,
} from "../../../../slices/FileUploadSlice";
import ImagePreviewWithFilename from "../../../common-components/ImagePreviewWithFilename";
import useFileValidation from "../../../../util/useFileValidation";
import { getAllSpeakers } from "../../../../slices/SpeakerSlice";
import ErrorAlert from "../../../common-components/ErrorAlert";
import ErrorMessages from "../../../../util/ErrorMessages";

function AddNewEventForm({ show, onClickNext, data }) {
  const dispatch = useDispatch();
  const [state, setState] = useState({
    title: data && data.title ? data.title : "",
    eventType: data && data.eventType ? data.eventType : "",
    date: data && data.date ? reverseLogicForParsing(data.date) : "",
    startTime: data && data.startTime ? data.startTime : "",
    endTime: data && data.endTime ? data.endTime : "",
    location: data && data.location ? data.location : "",
    speakers: data && data.speakers ? data.speakers : "",
    description: data && data.description ? data.description : "",
    materials: data && data.materials ? data.materials : null,
  });
  const selectedOfficeId = useSelector(
    (state) => state.officelist.selectedOfficeId
  );
  const visitId = useSelector((state) => state.visits.selectedVisitId);
  const visitEndDate = useSelector(
    (state) => state.visits.selectedVisitEndDate
  );
  if (state.speakers === "") {
    const speakers = dispatch(getAllSpeakers(selectedOfficeId));
    setState({ ...state, ["speakers"]: null });
  }
  const [validation, setValidation] = useState({});
  const [prevMultiFileData, setPrevMultiFileData] = useState(
    data && data.materials ? data.materials : null
  );
  const prevMaterialDetails = useSelector(
    (state) => state.events.prevMaterialDetails
  );
  const [prevMultiFileIds, setPrevMultiFileIds] = useState(
    data && data.materialsId ? data.materialsId : null
  );
  const prevMaterialIds = useSelector((state) => state.events.prevMaterialIds);
  const [imageDeleteList, setImageDeleteList] = useState([]);
  const {
    errorMaterialMessage,
    setErrorMaterialMessage,
    validateMaterialFiles,
  } = useFileValidation();

  const checkValidation = () => {
    const { errors, isValid } = NewEventFormValidations(state);
    setValidation(errors);
    return isValid;
  };

  function handleChange(e) {
    const value = e.target.value;
    setState({
      ...state,
      [e.target.name]: value,
    });
  }
  const errorMessageCode = useSelector((state) => state.upload.errorCode);
  const resetPrevMaterialDetails = useSelector(
    (state) => state.events.prevMaterialDetails
  );
  function handleCloseErrorAlert() {
    dispatch(resetMediaErrorAlert(false));
    setPrevMultiFileData(resetPrevMaterialDetails);
    handleMultipleImageState();
    dispatch(setSelectedFile(null));
    setImageDeleteList([]);
    onClickNext();
  }
  function resetImage() {
    dispatch(progressPercentage(0));
    dispatch(setSelectedFile(null));
    dispatch(resetMultipleFileUpload());
  }

  function handleFileCancel(photoId) {
    const result = prevMultiFileData.filter((image) => {
      if (image._id !== photoId) {
        return true;
      }
      return false;
    });

    const photoIdsUnfiltered = prevMultiFileIds.map((photo) => {
      if (photo !== photoId) {
        return photo;
      }
    });
    const photoIds = photoIdsUnfiltered.filter((id) => {
      if (id !== null) {
        return id;
      }
    });
    setState({
      ...state,
      ["materials"]: photoIds,
    });
    setPrevMultiFileIds(photoIds);
    setPrevMultiFileData(result);
    setImageDeleteList([...imageDeleteList, photoId]);
  }

  const speakersOptions = useSelector((state) => state.speakers.speakerOptions);
  const eventTypeOptions = [
    { value: 1, label: "Presentation" },
    { value: 2, label: "Roundtable" },
    { value: 3, label: "Workshop" },
    { value: 4, label: "Town Hall" },
    { value: 5, label: "One-on-One" },
    { value: 6, label: "Demo" },
    { value: 7, label: "Meet & Greet" },
    { value: 8, label: "Hi-Tea" },
    { value: 9, label: "Lunch" },
    { value: 10, label: "Dinner" },
    { value: 11, label: "Introduction" },
    { value: 12, label: "Welcome" },
    { value: 13, label: "Hackathon" },
    { value: 14, label: "Floor Walk" },
    { value: 15, label: "Team Event" },
    { value: 16, label: "Break" },
    { value: 17, label: "Client Address" },
    { value: 18, label: "Wrap-up" },
    { value: 19, label: "Next Steps" },
  ];

  const handleDrag = function (event) {
    event.preventDefault();
  };

  const handleMultiDrop = function (event) {
    event.preventDefault();
    const files = event.dataTransfer.files;
    postMultipleFileData(files);
  };

  const handleMultiFileSelect = function (event) {
    const files = event.target.files;
    postMultipleFileData(files);
  };

  function handleMultipleImageState(fileId) {
    const materials =
      data &&
      data.materials &&
      data.materials.filter((material) => material._id !== fileId);
    setState({ ...state, ["materials"]: materials });
    setPrevMaterialIds(materials);
  }

  function postMultipleFileData(files) {
    const valid = validateMaterialFiles(files);
    if (valid) {
      if (prevMultiFileData) {
        dispatch(setPrevMaterialIds(prevMultiFileIds));
        setPrevMultiFileIds(prevMultiFileIds);
        dispatch(setPrevMaterialDetails(prevMultiFileData));
        // setPrevMultiFileData([]);
      }
      dispatch(multipleFileUpload(files))
        .unwrap()
        .then((res) => {
          const newIds = res.map((r) => r._id);
          const ids = prevMultiFileIds
            ? prevMultiFileIds.concat(newIds)
            : newIds;
          setState({ ...state, ["materials"]: ids });
          // dispatch(setPrevMaterialDetails(res));
          setPrevMultiFileIds(ids);
        });
    }
  }

  function handleCreateButton() {
    if (checkValidation()) {
      if (
        state.title &&
        state.eventType &&
        state.date &&
        state.startTime &&
        state.endTime &&
        state.location &&
        // state.speakers &&
        state.description
      ) {
        if (data) {
          const newData = {
            title: state.title,
            eventType: state.eventType,
            date: state.date,
            startTime: state.startTime,
            endTime: state.endTime,
            location: state.location,
            speakers: state.speakers,
            description: state.description,
            materials: state.materials,
          };
          imageDeleteList &&
            imageDeleteList.map((image) => {
              dispatch(fileDelete({ id: image }));
            });
          setState(newData);
          resetImage();
          onClickNext(newData);
          setImageDeleteList([]);
          setErrorMaterialMessage("");
          // if (prevMaterialIds !== null) {
          //   const currentMaterialsId =
          //     data && data.materials.map((material) => material._id);
          //   const stateMaterialIds = state.materials.map(
          //     (material) => material._id
          //   );
          //   const prevMaterialId = prevMaterialDetails.map(
          //     (material) => material._id
          //   );
          //   let result =
          //     currentMaterialsId.length === prevMaterialIds.length &&
          //     currentMaterialsId.every(function (element) {
          //       return prevMaterialIds.includes(element);
          //     });
          //   if (!result) {
          //     prevMaterialId.map((prevImageId) => {
          //       const id = { id: prevImageId, isVideo: false, isMulti: true };
          //       dispatch(fileDelete(id));
          //     });

          //     dispatch(setPrevMaterialIds(null));
          //   }
          // }
          // setPrevMultiFileData(data && data.materials);
        } else {
          setState({});
          resetImage();
          setImageDeleteList([]);
          onClickNext(state);
        }
      } else {
        onClickNext();
      }
    }
  }

  if (!show) {
    return null;
  }

  if (errorMessageCode) {
    return (
      <ErrorAlert
        show={errorMessageCode}
        onClickHandle={handleCloseErrorAlert}
        title="Error"
        subTitle={ErrorMessages(errorMessageCode)}
      />
    );
  }
  return (
    <div className="modal">
      <div className="form-scroll">
        <div className="form-flex">
          <div
            className="user-form-modal-content"
            onClick={(e) => e.stopPropagation()}
          >
            <div className="form-header">
              <div className="header">
                {data ? "Update Event" : "Add New Event"}
              </div>
              <CloseButton
                onClickhandle={() => {
                  resetImage();
                  if (data === null) {
                    setState({});
                    setValidation({});
                  }
                  setPrevMultiFileIds(
                    data && data.materialsId ? data.materialsId : null
                  );
                  setPrevMultiFileData(
                    data && data.materials ? data.materials : null
                  );
                  setImageDeleteList([]);
                  setErrorMaterialMessage("");
                  onClickNext();
                }}
              />
            </div>

            <div className="form-container">
              <div className="form-label-input">
                <label className="form-label">
                  {RendezvousStringLabels.eventName}
                  <Required />
                </label>
                <div className="form-input-container">
                  <input
                    type="text"
                    value={state.title}
                    name="title"
                    onChange={(e) => handleChange(e)}
                    className="form-input"
                    placeholder="Input"
                    required={true}
                  ></input>
                </div>
                <ErrorMessageComponent message={validation.title} />
              </div>

              <div className="form-label-input-select">
                <label className="form-label">
                  {RendezvousStringLabels.eventType}
                  <Required />
                </label>
                <Select
                  options={eventTypeOptions}
                  className="basic-single"
                  classNamePrefix="select"
                  name="eventType"
                  defaultValue={
                    data &&
                    eventTypeOptions.filter(
                      (event) => event.label === data.eventType
                    )
                  }
                  closeMenuOnSelect={true}
                  onChange={(e) => {
                    setState({
                      ...state,
                      ["eventType"]: e.label,
                    });
                  }}
                />
                <ErrorMessageComponent message={validation.eventType} />
              </div>

              <div className="form-label-input">
                <label className="form-label">
                  {RendezvousStringLabels.description}
                  <Required />
                </label>
                <div className="user-form-input-container">
                  <textarea
                    className="form-input"
                    type="text"
                    value={state.description}
                    name="description"
                    onChange={(e) => handleChange(e)}
                    placeholder="Input"
                    rows={3}
                  ></textarea>
                </div>
                <ErrorMessageComponent message={validation.description} />
              </div>

              <div className="form-label-input">
                <label className="form-label">
                  {RendezvousStringLabels.date}
                  <Required />
                </label>
                <div className="form-input-container">
                  <input
                    className="form-input"
                    type="date"
                    value={state.date}
                    name="date"
                    onChange={(e) => handleChange(e)}
                    placeholder="Input"
                    required={true}
                    min={GetDate()}
                    max={GetMaxDate(visitEndDate)}
                  ></input>
                </div>
                <ErrorMessageComponent message={validation.date} />
              </div>

              <div className="form-label-input">
                <label className="form-label">
                  {RendezvousStringLabels.startTime}
                  <Required />
                </label>
                <div className="form-input-container">
                  <input
                    className="form-input"
                    type="time"
                    value={state.startTime}
                    name="startTime"
                    onChange={(e) => handleChange(e)}
                    placeholder="Input"
                    required={true}
                  ></input>
                </div>
                <ErrorMessageComponent message={validation.startTime} />
              </div>

              <div className="form-label-input">
                <label className="form-label">
                  {RendezvousStringLabels.endTime}
                  <Required />
                </label>
                <div className="form-input-container">
                  <input
                    className="form-input"
                    type="time"
                    value={state.endTime}
                    name="endTime"
                    onChange={(e) => handleChange(e)}
                    placeholder="Input"
                    required={true}
                  ></input>
                </div>
                <ErrorMessageComponent message={validation.endTime} />
              </div>

              <div className="form-label-input">
                <label className="form-label">
                  {RendezvousStringLabels.location}
                  <Required />
                </label>
                <div className="form-input-container">
                  <input
                    className="form-input"
                    type="text"
                    value={state.location}
                    name="location"
                    onChange={(e) => handleChange(e)}
                    placeholder="Input"
                    required={true}
                  ></input>
                </div>
                <ErrorMessageComponent message={validation.location} />
              </div>

              <div className="form-label-input">
                <label className="form-label">
                  {/* Speakers */}
                  {RendezvousStringLabels.speakers}
                  {/* <Required /> */}
                </label>
                <Select
                  options={speakersOptions}
                  className="basic-multi-select"
                  isMulti
                  classNamePrefix="select"
                  name="speakers"
                  defaultValue={
                    data &&
                    speakersOptions.filter((speaker) => {
                      const speakerIds = data.speakers.map((speaker) => {
                        if (speaker.hasOwnProperty("_id")) {
                          return speaker._id;
                        } else {
                          return speaker;
                        }
                      });
                      if (speakerIds.includes(speaker.value)) {
                        return speaker.value;
                      }
                    })
                  }
                  closeMenuOnSelect={false}
                  onChange={(e) => {
                    const speakerList = e.map((speaker) => {
                      return speaker.value;
                    });
                    setState({
                      ...state,
                      ["speakers"]: speakerList,
                    });
                  }}
                />
                <ErrorMessageComponent message={validation.speakers} />
              </div>

              <FileUploader
                previewShape="rectangle"
                headerName={RendezvousStringLabels.materials}
                handleDragOver={handleDrag}
                handleDrop={handleMultiDrop}
                handleFileSelect={handleMultiFileSelect}
                isMultiUpload={true}
                setMultipleFileData={setPrevMultiFileData}
                handleMultiState={handleMultipleImageState}
                type="rendezvous"
              />
              {errorMaterialMessage !== "" && (
                <p className="error image-error">{errorMaterialMessage}</p>
              )}
              {prevMultiFileData &&
                prevMultiFileData.length >= 1 &&
                data &&
                prevMultiFileData.map((url) => {
                  return (
                    <ImagePreviewWithFilename
                      fileName={url.fileName}
                      isDocFile={true}
                      photoId={url._id}
                      handleCancel={handleFileCancel}
                    />
                  );
                })}
              <div className="footer-button-container">
                <CancelButton
                  onClickhandle={() => {
                    resetImage();
                    if (data === null) {
                      setState({});
                      setValidation({});
                    }
                    setErrorMaterialMessage("");
                    setImageDeleteList([]);
                    onClickNext();
                  }}
                >
                  {RendezvousStringLabels.cancel}
                </CancelButton>
                <BlackButton onClickhandle={() => handleCreateButton()}>
                  {data
                    ? RendezvousStringLabels.update
                    : RendezvousStringLabels.add}
                </BlackButton>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default AddNewEventForm;
