import { useState } from 'react';
import Required from '../../../common-components/Required';
import CloseButton from '../../../custom-components/CloseButton';
import CancelButton from '../../../custom-components/CancelButton';
import BlackButton from '../../../custom-components/BlackButton';
import FileUploader from '../../../common-components/FileUploader';
import { useDispatch, useSelector } from 'react-redux';
import {
  fileDelete,
  fileUpload,
  multipleFileUpload,
  previewImage,
  progressPercentage,
  resetMediaErrorAlert,
  resetMultipleFileUpload,
  setSelectedFile,
} from '../../../../slices/FileUploadSlice';
import { RecreationStringLabels } from '../../../../util/Strings';
import {
  setPreThumbnailId,
  setPrevRecreationIds,
  setPrevRecreationImageDetails,
  setPrevThumbnailDetails,
} from '../../../../slices/RecreationSlice';
import ImagePreviewWithFilename from '../../../common-components/ImagePreviewWithFilename';
import { NewRecreationFormValidations } from '../../../../util/Validations';
import ErrorMessageComponent from '../../../common-components/ErrorMessageComponent';
import useFileValidation from '../../../../util/useFileValidation';
import Select from 'react-select';
import ErrorAlert from '../../../common-components/ErrorAlert';
import ErrorMessages from '../../../../util/ErrorMessages';

function AddNewRecreationForm({ show, onClickNext, data }) {
  const dispatch = useDispatch();
  const [state, setState] = useState({
    name: data && data.name ? data.name : null,
    filter: data && data.filter ? data.filter : null,
    area: data && data.area ? data.area : null,
    //timing: data && data.timing ? data.timing : null,
    openingTime: data && data.openingTime ? data.openingTime : null,
    closingTime: data && data.closingTime ? data.closingTime : null,
    closedDays: data && data.closedDays ? data.closedDays : ['Sunday'],
    address: data && data.address ? data.address : null,
    latitude: data && data.latitude ? data.latitude : null,
    longitude: data && data.longitude ? data.longitude : null,
    description: data && data.description ? data.description : null,
    recreationThumbnailId:
      data && data.recreationThumbnailId ? data.recreationThumbnailId : null,
    recreationPhotoIds:
      data && data.recreationPhotoIds ? data.recreationPhotoIds : null,
  });
  const [prevFileData, setPreFileData] = useState({
    photoUrl: data && data.thumbnailUrl ? data.thumbnailUrl : null,
    fileName: data && data.thumbnailFileName ? data.thumbnailFileName : null,
  });
  const [prevFileId, setPrevFileId] = useState(
    data && data.recreationThumbnailId ? data.recreationThumbnailId : null
  );
  const [prevMultipleFileIds, setPrevMultipleFileIds] = useState(
    data && data.recreationPhotoIds ? data.recreationPhotoIds : null
  );
  const prevThumbnailId = useSelector(
    (state) => state.recreation.prevThumbnailId
  );
  const prevRecreationIds = useSelector(
    (state) => state.recreation.prevRecreationIds
  );
  const [imageDeleteList, setImageDeleteList] = useState([]);

  const roamTypeOptions = [
    { value: 1, label: 'Night Life' },
    { value: 2, label: 'Shopping' },
    { value: 3, label: 'Nature' },
    { value: 4, label: 'Sightseeing' },
  ];

  const closedDaysOption = [
    { value: 1, label: 'Monday' },
    { value: 2, label: 'Tuesday' },
    { value: 3, label: 'Wednesday' },
    { value: 4, label: 'Thrusday' },
    { value: 5, label: 'Friday' },
    { value: 6, label: 'Saturday' },
    { value: 7, label: 'Sunday' },
    { value: 8, label: 'Open all Days' },
  ];

  var multipleFileNameWithImageUrls = [];
  if (data && data.photoUrls && data.photoFileNames) {
    for (let i = 0; i < data.photoUrls.length; i++) {
      multipleFileNameWithImageUrls.push({
        photoUrl: data.photoUrls[i],
        fileName: data.photoFileNames[i],
        id: data.recreationPhotoIds[i],
      });
    }
  }
  const [prevMultiFileData, setPrevMultiFileData] = useState(
    multipleFileNameWithImageUrls
  );

  const [validation, setValidation] = useState({});
  const {
    errorSingleMessage,
    setErrorSingleMessage,
    errorMultiMessage,
    setErrorMultiMessage,
    validateFile,
    validateFiles,
  } = useFileValidation();
  const errorMessageCode = useSelector((state) => state.upload.errorCode);
  const prevRecreationThumbnailFileData = useSelector(
    (state) => state.recreation.prevThumbnailDetails
  );
  const prevRecreationMultipleFileData = useSelector(
    (state) => state.recreation.prevRecreationImageDetails
  );

  function handleCloseErrorAlert() {
    dispatch(resetMediaErrorAlert(false));
    setPrevMultiFileData(prevRecreationMultipleFileData);
    handleMultipleImageState();
    dispatch(setSelectedFile(null));
    setPreFileData(prevRecreationThumbnailFileData);
    handleState();
    dispatch(setSelectedFile(null));
    setImageDeleteList([]);
    onClickNext();
  }
  const checkValidation = () => {
    const { errors, isValid } = NewRecreationFormValidations(state);
    setValidation(errors);
    return isValid;
  };

  function handleChange(e) {
    const value = e.target.value;
    setState({
      ...state,
      [e.target.name]: value,
    });
  }
  function resetImage() {
    dispatch(progressPercentage(0));
    dispatch(setSelectedFile(null));
    dispatch(previewImage(null));
    dispatch(resetMultipleFileUpload());
  }

  function handleImageCancel(photoId) {
    const result = prevMultiFileData.filter((image) => {
      if (image.id !== photoId) {
        return true;
      }
      return false;
    });

    const photoIdsUnfiltered = prevMultipleFileIds.map((photo) => {
      if (photo !== photoId) {
        return photo;
      }
    });
    const photoIds = photoIdsUnfiltered.filter((id) => {
      if (id !== null) {
        return id;
      }
    });
    setState({
      ...state,
      ['recreationPhotoIds']: photoIds,
    });
    setPrevMultipleFileIds(photoIds);
    setPrevMultiFileData(result);
    setImageDeleteList([...imageDeleteList, photoId]);
  }

  function handleThumbnailCancel() {
    setState({
      ...state,
      ['recreationThumbnailId']: null,
    });
    prevFileData.photoUrl = null;
    setImageDeleteList([...imageDeleteList, prevFileId]);
  }

  function handleNextButton() {
    if (checkValidation()) {
      if (
        state.name &&
        state.filter &&
        state.area &&
        //state.timing &&
        state.openingTime &&
        state.closingTime &&
        state.closedDays &&
        state.address &&
        state.latitude &&
        state.longitude &&
        state.description
      ) {
        const newData = state;
        if (data) {
          setState({
            name: newData.name,
            description: newData.description,
            filter: newData.filter,
            address: newData.address,
            latitude: newData.latitude,
            longitude: newData.longitude,
            area: newData.area,
            openingTime: newData.openingTime,
            closingTime: newData.closingTime,
            closedDays: newData.closedDays,
            //timing: newData.timing,
            photo: newData.photo,
            recreationThumbnailId: newData.recreationThumbnailId,
            recreationPhotoIds: newData.recreationPhotoIds,
          });
          imageDeleteList &&
            imageDeleteList.map((image) => {
              dispatch(fileDelete({ id: image }));
            });

          resetImage();
          onClickNext(newData, data._id);
          if (
            state.recreationThumbnailId !== prevThumbnailId &&
            prevThumbnailId !== ''
          ) {
            const id = { id: prevThumbnailId, isVideo: false };
            dispatch(fileDelete(id));
            dispatch(setPreThumbnailId(''));
          }
          setPreFileData({
            photoUrl: data && data.thumbnailUrl ? data.thumbnailUrl : null,
            fileName:
              data && data.thumbnailFileName ? data.thumbnailFileName : null,
          });
          setImageDeleteList([]);
          setErrorSingleMessage('');
          setErrorMultiMessage('');
          // if (prevRecreationIds !== null) {
          //   let result =
          //     state.recreationPhotoIds.length === prevRecreationIds.length &&
          //     state.recreationPhotoIds.every(function (element) {
          //       return prevRecreationIds.includes(element);
          //     });
          //   if (!result) {
          //     prevRecreationIds.map((prevImageId) => {
          //       const id = { id: prevImageId, isVideo: false, isMulti: true };
          //       dispatch(fileDelete(id));
          //     });
          //     dispatch(setPrevRecreationIds(null));
          //   }
          // }
          // setPrevMultiFileData(multipleFileNameWithImageUrls);
        } else {
          setState({});
          resetImage();
          setImageDeleteList([]);
          onClickNext(newData);
        }
      } else {
        onClickNext();
      }
    }
  }

  if (!show) {
    return null;
  }

  function handleMultipleImageState(fileId) {
    const id = data && data.recreationPhotoIds.filter((id) => id !== fileId);
    setState({ ...state, ['recreationPhotoIds']: id });
  }

  const handleFileSelect = function (event) {
    const files = event.target.files;
    postFileData(files);
  };

  const handleMultiFileSelect = function (event) {
    const files = event.target.files;
    postMultipleFileData(files);
  };

  const handleDrag = function (event) {
    event.preventDefault();
  };

  const handleDrop = function (event) {
    event.preventDefault();
    const files = event.dataTransfer.files;
    postFileData(files);
  };

  const handleMultiDrop = function (event) {
    event.preventDefault();
    const files = event.dataTransfer.files;
    postMultipleFileData(files);
  };

  function handleState() {
    setState({
      ...state,
      ['recreationThumbnailId']: data && data.recreationThumbnailId,
    });
  }

  function postMultipleFileData(files) {
    const valid = validateFiles(files);
    if (valid) {
      if (prevMultiFileData) {
        dispatch(setPrevRecreationIds(prevMultipleFileIds));
        setPrevMultipleFileIds(prevMultipleFileIds);
        dispatch(setPrevRecreationImageDetails(prevMultiFileData));
        // setPrevMultiFileData([]);
      }
      dispatch(multipleFileUpload(files))
        .unwrap()
        .then((res) => {
          const newIds = res.map((r) => r._id);
          const ids = prevMultipleFileIds
            ? prevMultipleFileIds.concat(newIds)
            : newIds;
          setState({ ...state, ['recreationPhotoIds']: ids });
          setPrevMultipleFileIds(ids);
        });
    }
  }

  function postFileData(files) {
    const valid = validateFile(files);
    if (valid) {
      const file = files[0];
      if (prevFileData) {
        dispatch(setPreThumbnailId(prevFileId));
        setPrevFileId(prevFileId);
        dispatch(setPrevThumbnailDetails(prevFileData));
        setPreFileData({ photoUrl: null, fileName: null });
      }
      if (file) {
        dispatch(setSelectedFile(file));
        dispatch(fileUpload(file))
          .unwrap()
          .then((res) => {
            setState({ ...state, ['recreationThumbnailId']: res[0]._id });
            setPrevFileId(res[0]._id);
          });
      }
    }
  }
  if (errorMessageCode) {
    return (
      <ErrorAlert
        show={errorMessageCode}
        onClickHandle={handleCloseErrorAlert}
        title="Error"
        subTitle={ErrorMessages(errorMessageCode)}
      />
    );
  }
  return (
    <div className="modal">
      <div className="form-scroll">
        <div className="form-flex">
          <div
            className="user-form-modal-content"
            onClick={(e) => e.stopPropagation()}
          >
            <div className="form-header">
              <div className="header">
                {data
                  ? RecreationStringLabels.formTitleUpdate
                  : RecreationStringLabels.formTitleAdd}
              </div>
              <CloseButton
                onClickhandle={() => {
                  onClickNext();
                  if (data === null) {
                    setState({});
                    setValidation({});
                  }
                  setPreFileData({
                    photoUrl:
                      data && data.thumbnailUrl ? data.thumbnailUrl : null,
                    fileName:
                      data && data.thumbnailFileName
                        ? data.thumbnailFileName
                        : null,
                  });
                  setPrevMultipleFileIds(
                    data && data.recreationPhotoIds
                      ? data.recreationPhotoIds
                      : null
                  );
                  setPrevMultiFileData(multipleFileNameWithImageUrls);
                  setImageDeleteList([]);
                  setErrorSingleMessage('');
                  setErrorMultiMessage('');
                  resetImage();
                }}
              />
            </div>
            <div className="form-container">
              <div className="form-label-input">
                <label className="form-label">
                  {RecreationStringLabels.name}
                  <Required />
                </label>
                <div className="form-input-container">
                  <input
                    type="text"
                    name="name"
                    value={state.name}
                    onChange={(e) => handleChange(e)}
                    className="form-input"
                    placeholder="Input"
                    required={true}
                  />
                </div>
                <ErrorMessageComponent message={validation.name} />
              </div>

              <div className="form-label-input-select">
                <label className="form-label">
                  {RecreationStringLabels.filter}
                  <Required />
                </label>
                <Select
                  options={roamTypeOptions}
                  className="basic-single"
                  classNamePrefix="select"
                  name="eventType"
                  defaultValue={
                    data &&
                    roamTypeOptions.filter(
                      (event) => event.label === data.filter
                    )
                  }
                  closeMenuOnSelect={true}
                  onChange={(e) => {
                    setState({
                      ...state,
                      ['filter']: e.label,
                    });
                  }}
                />
                <ErrorMessageComponent message={validation.filter} />
              </div>

              {/* <div className="form-label-input">
            <label className="form-label">
              {RecreationStringLabels.filter}
              <Required />
            </label>
            <div className="form-input-container">
              <input
                className="form-input"
                type="text"
                name="filter"
                value={state.filter}
                onChange={(e) => handleChange(e)}
                placeholder="Input"
                required={true}
              />
            </div>
            <ErrorMessageComponent message={validation.filter} />
          </div> */}

              <div className="form-label-input">
                <label className="form-label">
                  {RecreationStringLabels.description}
                  <Required />
                </label>
                <div className="user-form-input-container">
                  <textarea
                    className="form-input"
                    type="text"
                    name="description"
                    value={state.description}
                    onChange={(e) => handleChange(e)}
                    placeholder="Input"
                    required={true}
                    rows={3}
                  />
                </div>
                <ErrorMessageComponent message={validation.description} />
              </div>

              <div className="form-label-input">
                <label className="form-label">
                  {RecreationStringLabels.address}
                  <Required />
                </label>
                <div className="form-input-container">
                  <input
                    className="form-input"
                    type="text"
                    name="address"
                    value={state.address}
                    onChange={(e) => handleChange(e)}
                    placeholder="Input"
                    required={true}
                  />
                </div>
                <ErrorMessageComponent message={validation.address} />
              </div>

              <div className="form-label-input">
                <label className="form-label">
                  {RecreationStringLabels.area}
                  <Required />
                </label>
                <div className="form-input-container">
                  <input
                    className="form-input"
                    type="text"
                    name="area"
                    value={state.area}
                    onChange={(e) => handleChange(e)}
                    placeholder="Input"
                    required={true}
                  />
                </div>
                <ErrorMessageComponent message={validation.area} />
              </div>

              <div className="form-label-input">
                <label className="form-label">
                  {RecreationStringLabels.latitude}
                  <Required />
                </label>
                <div className="form-input-container">
                  <input
                    className="form-input"
                    type="text"
                    name="latitude"
                    value={state.latitude}
                    onChange={(e) => handleChange(e)}
                    placeholder="e.g. 12.9845"
                    required={true}
                  />
                </div>
                <ErrorMessageComponent message={validation.latitude} />
              </div>

              <div className="form-label-input">
                <label className="form-label">
                  {RecreationStringLabels.longitude}
                  <Required />
                </label>
                <div className="form-input-container">
                  <input
                    className="form-input"
                    type="text"
                    name="longitude"
                    value={state.longitude}
                    onChange={(e) => handleChange(e)}
                    placeholder="e.g. 77.5846"
                    required={true}
                  />
                </div>
                <ErrorMessageComponent message={validation.longitude} />
              </div>

              {/* <div className="form-label-input">
            <label className="form-label">
              {RecreationStringLabels.timing}
              <Required />
            </label>
            <div className="form-input-container">
              <input
                className="form-input"
                type="text"
                name="timing"
                value={state.timing}
                onChange={(e) => handleChange(e)}
                placeholder="Input"
                required={true}
              />
            </div>
            <ErrorMessageComponent message={validation.timing}/>
          </div> */}

              <div className="form-label-input">
                <label className="form-label">
                  {RecreationStringLabels.openingTime}
                  <Required />
                </label>
                <div className="form-input-container">
                  <input
                    className="form-input"
                    type="time"
                    value={state.openingTime}
                    name="openingTime"
                    onChange={(e) => handleChange(e)}
                    placeholder="Input"
                    required={true}
                  ></input>
                </div>
                <ErrorMessageComponent message={validation.openingTime} />
              </div>

              <div className="form-label-input">
                <label className="form-label">
                  {RecreationStringLabels.closingTime}
                  <Required />
                </label>
                <div className="form-input-container">
                  <input
                    className="form-input"
                    type="time"
                    value={state.closingTime}
                    name="closingTime"
                    onChange={(e) => handleChange(e)}
                    placeholder="Input"
                    required={true}
                  ></input>
                </div>
                <ErrorMessageComponent message={validation.closingTime} />
              </div>

              <div className="form-label-input-select">
                <label className="form-label">
                  {RecreationStringLabels.closedDays}
                  <Required />
                </label>
                <Select
                  options={closedDaysOption}
                  className="basic-multi-select"
                  isMulti
                  classNamePrefix="select"
                  name="closedDays"
                  closeMenuOnSelect={false}
                  defaultValue={
                    data
                      ? data.closedDays.map((closedDay) => {
                          const c = closedDaysOption.filter(
                            (day) => day.label === closedDay
                          );
                          return c[0];
                        })
                      : { value: 7, label: 'Sunday' }
                  }
                  onChange={(e) => {
                    const daysList = e.map((day) => {
                      return day.label;
                    });
                    setState({
                      ...state,
                      ['closedDays']: daysList,
                    });
                  }}
                />
                <ErrorMessageComponent message={validation.closedDays} />
              </div>

              <FileUploader
                previewShape="rectangle"
                headerName={RecreationStringLabels.thumbnail}
                handleDragOver={handleDrag}
                handleDrop={handleDrop}
                handleFileSelect={handleFileSelect}
                setPreFileData={setPreFileData}
                handleState={handleState}
                type="recreation"
              />
              {errorSingleMessage !== '' && (
                <p className="error image-error">{errorSingleMessage}</p>
              )}
              {prevFileData &&
              prevFileData.photoUrl &&
              data &&
              data.thumbnailUrl &&
              data.thumbnailFileName ? (
                <ImagePreviewWithFilename
                  photoUrl={data.thumbnailUrl}
                  fileName={data.thumbnailFileName}
                  handleCancel={handleThumbnailCancel}
                />
              ) : null}

              <FileUploader
                previewShape="rectangle"
                headerName={RecreationStringLabels.photos}
                handleDragOver={handleDrag}
                handleDrop={handleMultiDrop}
                handleFileSelect={handleMultiFileSelect}
                isMultiUpload={true}
                setMultipleFileData={setPrevMultiFileData}
                handleMultiState={handleMultipleImageState}
                type="recreation"
              />
              {errorMultiMessage !== '' && (
                <p className="error image-error">{errorMultiMessage}</p>
              )}
              {prevMultiFileData &&
                prevMultiFileData.length >= 1 &&
                prevMultiFileData.map((url) => {
                  return (
                    <ImagePreviewWithFilename
                      photoUrl={url.photoUrl}
                      fileName={url.fileName}
                      photoId={url.id}
                      handleCancel={handleImageCancel}
                    />
                  );
                })}
              <div className="footer-button-container">
                <CancelButton
                  onClickhandle={() => {
                    onClickNext();
                    if (data === null) {
                      setState({});
                      setValidation({});
                    }
                    setPreFileData({
                      photoUrl:
                        data && data.thumbnailUrl ? data.thumbnailUrl : null,
                      fileName:
                        data && data.thumbnailFileName
                          ? data.thumbnailFileName
                          : null,
                    });
                    setPrevMultipleFileIds(
                      data && data.recreationPhotoIds
                        ? data.recreationPhotoIds
                        : null
                    );
                    setPrevMultiFileData(multipleFileNameWithImageUrls);
                    setImageDeleteList([]);
                    setErrorSingleMessage('');
                    setErrorMultiMessage('');
                    resetImage();
                  }}
                >
                  {RecreationStringLabels.cancel}
                </CancelButton>
                <BlackButton onClickhandle={() => handleNextButton()}>
                  {data
                    ? RecreationStringLabels.update
                    : RecreationStringLabels.add}
                </BlackButton>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default AddNewRecreationForm;
