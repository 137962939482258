import '../../styles/custom-components/Button.css';

function Button({
  children,
  onClickhandle,
  styleButton,
  styleContiner,
  disabled,
}) {
  return (
    <div className={styleContiner}>
      {disabled ? (
        <button
          disabled
          className={
            styleButton
              ? ['custom-button', styleButton]
              : 'custom-button-disabled'
          }
          onClick={() => {
            onClickhandle();
          }}
        >
          {children}
        </button>
      ) : (
        <button
          className={
            styleButton ? ['custom-button', styleButton] : 'custom-button'
          }
          onClick={() => {
            onClickhandle();
          }}
        >
          {children}
        </button>
      )}
    </div>
  );
}

export default Button;
