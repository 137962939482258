import http from "../http-common";
import { DecryptData } from "../util/DataEncryption";

const GetAllOffices = async () => {
  const tokenStr = sessionStorage.getItem("token")
    ? DecryptData(sessionStorage.getItem("token"))
    : null;
  // mutating the token so it is set properly
  http.defaults.headers.Authorization = `Bearer ${tokenStr}`;
  const users = await http.get("/office/all").then((response) => {
    return response.data;
  });
  return users;
};
//console.log("Offices", GetAllOffices());
const CreateNewOffice = async (newData) => {
  const auth = await http
    .post("/office", {
      name: newData.name,
      address: newData.address,
      available: newData.available,
      latitude: newData.latitude,
      longitude: newData.longitude,
      description: newData.description,
      city: newData.city,
      country: newData.country,
      photoId: newData.photoId,
    })
    .then((response) => {
      return response;
    });
  return auth.data;
};

const UpdateOffices = async (updateData, id) => {
  const updatedData = await http
    .put(`/office/${id}`, {
      name: updateData.name,
      address: updateData.address,
      available: updateData.available,
      latitude: updateData.latitude,
      longitude: updateData.longitude,
      photoId: updateData.photoId,
    })
    .then((response) => {
      return response.data;
    });
  return updatedData;
};

const DeleteOffice = async (id) => {
  const deletedData = await http.delete(`/office/${id}`).then((response) => {
    return response.data;
  });
  return deletedData;
};

const OfficeListAPI = {
  UpdateOffices,
  GetAllOffices,
  CreateNewOffice,
  DeleteOffice,
};

export default OfficeListAPI;
