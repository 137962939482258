import '../../../../styles/screens/client-visit-details/manage-module/EventsListTable.css';
import { useDispatch, useSelector } from 'react-redux';
import EventsListTableBody from './EventsListTableBody';
import noPossibilities from '../../../../assets/no-possibilities.svg';
import {
  getAllEvents,
  resetErrorAlert,
} from '../../../../slices/EventListSlice';
import { useEffect } from 'react';
import Loader from '../../../common-components/Loader';
import { RendezvousStringLabels } from '../../../../util/Strings';
import ErrorAlert from '../../../common-components/ErrorAlert';
import ErrorMessages from '../../../../util/ErrorMessages';

function EventsListTable() {
  const data = useSelector((state) => state.events.eventList);
  const isVisitCompleted = useSelector(
    (state) => state.visits.selectedVisitIsCompleted
  );
  const isLoading = useSelector((state) => state.events.isLoading);
  const id = useSelector((state) => state.visits.selectedVisitId);
  const dispatch = useDispatch();
  const errorMessageCode = useSelector((state) => state.events.errorCode);

  function handleCloseErrorAlert() {
    dispatch(resetErrorAlert(false));
  }

  function getEventsData() {
    dispatch(getAllEvents(id));
  }

  useEffect(() => {
    getEventsData();
  }, []);

  if (isLoading) {
    return <Loader />;
  }

  if (errorMessageCode) {
    return (
      <ErrorAlert
        show={errorMessageCode}
        onClickHandle={handleCloseErrorAlert}
        title="Error"
        subTitle={ErrorMessages(errorMessageCode)}
      />
    );
  }

  return (
    <>
      {data && data.length > 0 ? (
        <table>
          <thead>
            <tr className="table-header">
              <th>{RendezvousStringLabels.eventName}</th>
              <th>{RendezvousStringLabels.eventType}</th>
              <th>{RendezvousStringLabels.date}</th>
              <th>{RendezvousStringLabels.time}</th>
              <th>{RendezvousStringLabels.location}</th>
              <th>
                {/* Speakers */}
                {RendezvousStringLabels.speakers}
              </th>
              <th>{RendezvousStringLabels.getReady}</th>
              <th> </th>
            </tr>
          </thead>
          {data.map((dataItem) => {
            return <EventsListTableBody dataItem={dataItem} />;
          })}
        </table>
      ) : (
        <img src={noPossibilities} alt="No Possibilities" className="no-img" />
      )}
    </>
  );
}

export default EventsListTable;
