import ClientVisitCardItem from './ClientVisitCardItem';
import '../../../styles/screens/client-visit-screens/ClientVisitCardGrid.css';
import noVisits from '../../../assets/no-visits.svg';
import { ClientVisitScreenStringLabels } from '../../../util/Strings';
function ClientVisitCardGrid({ data, state }) {
  var number = 0;
  return (
    <>
      {data && data.length > 0 && (
        <div className="row-header">
          <div className="column-header">
            <div>{ClientVisitScreenStringLabels.clientName}</div>
          </div>
          <div className="column-header">
            <div>{ClientVisitScreenStringLabels.startDate}</div>
          </div>
          <div className="column-header">
            <div>{ClientVisitScreenStringLabels.endDate}</div>
          </div>
          <div className="column-header">
            <div>{ClientVisitScreenStringLabels.companyName}</div>
          </div>
          <div className="column-header">
            <div>{ClientVisitScreenStringLabels.companyLogo}</div>
          </div>
          <div className="column-header">
            <div className="region-style">
              {ClientVisitScreenStringLabels.region}
            </div>
          </div>
          <div className="column-header icon-style-header"></div>
        </div>
      )}
      {data && data.length > 0 ? (
        data.map((d) => {
          number = number + 1;
          return (
            <ClientVisitCardItem
              key={d._id}
              data={d}
              sl_number={number}
              state={state}
            />
          );
        })
      ) : (
        <img src={noVisits} alt="No Possibilities" className="no-img" />
      )}
    </>
  );
}

export default ClientVisitCardGrid;
