import { useEffect, useState } from "react";
import logo from "../../assets/Logo.svg";
import logout from "../../assets/logout.svg";
import "../../styles/common-components/AppBar.css";
import ConfirmationDialog from "./ConfirmationDialog";
import { consoleLogout, refreshIsSubAdmin } from "../../slices/LoginSlice";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router";
import ManageSubAdmin from "./ManageSubAdmin";
import { AppBarStringLabels } from "../../util/Strings";
import GeneralNotification from "./GeneralNotification";
import UserFeedback from "./UserFeedback";
import ManageAC from "./ManageAC";

function AppBar() {
  const [show, setShow] = useState(false);
  const [showDialog, setShowDialog] = useState(false);
  const data = useSelector((state) => state.login);
  const userName = data.name;
  const dispatch = useDispatch();
  const navigate = useNavigate();

  function resetShow() {
    setShow(false);
  }
  useEffect(() => {
    dispatch(refreshIsSubAdmin());
  }, []);

  const events = [
    "load",
    "mousemove",
    "mousedown",
    "click",
    "scroll",
    "keypress",
  ];

  let timer;

  const handleLogoutTimer = () => {
    timer = setTimeout(() => {
      resetTimer();
      Object.values(events).forEach((item) => {
        window.removeEventListener(item, resetTimer);
      });
      logoutAction();
    }, 1000 * 60 * 60);
  };

  const resetTimer = () => {
    if (timer) clearTimeout(timer);
  };

  useEffect(() => {
    Object.values(events).forEach((item) => {
      window.addEventListener(item, () => {
        resetTimer();
        const tokenStr = sessionStorage.getItem("token") ? true : false;
        if (tokenStr) {
          handleLogoutTimer();
        }
      });
    });
  }, []);

  const logoutAction = () => {
    dispatch(consoleLogout());
    navigate("/", { replace: true });
  };

  return (
    <div className='appBarOuterContainer'>
      <div className='appBarLeftContainer'>
        <div>
          <img src={logo} alt='pwc logo' />
        </div>
        <div className='appBarTitle'>{AppBarStringLabels.title}</div>
        <div className='dividerContainer'>
          <div className='divider' />
        </div>
      </div>
      <div
        className='appBarProfile'
        onClick={() => {
          setShow(true);
        }}
      >
        <span className='appBarProfileText'>
          {userName ? userName[0].toUpperCase() : null}
        </span>
      </div>
      {show && (
        <div
          className='menu-modal'
          onClick={() => {
            setShow(false);
          }}
        >
          <div
            className='menu-modal-content'
            onClick={(e) => e.stopPropagation()}
          >
            <ManageSubAdmin handleShow={resetShow} />
            <GeneralNotification handleShow={resetShow} />
            <UserFeedback handleShow={resetShow} />
            <ManageAC handleShow={resetShow} />
            <div
              className='menu-item'
              onClick={() => {
                setShow(false);
                setShowDialog(true);
              }}
            >
              <img src={logout} alt='logout'></img>
              <div className='menu-text'>{AppBarStringLabels.logout}</div>
            </div>
          </div>
        </div>
      )}
      <ConfirmationDialog
        show={showDialog}
        buttonName={AppBarStringLabels.logout}
        onClickHandle={(flag) => {
          if (flag) {
            dispatch(consoleLogout());
            navigate("/", { replace: true });
          }
          setShowDialog(false);
        }}
        title={AppBarStringLabels.logout}
        subTitle={AppBarStringLabels.logOutSubTitle}
      />
    </div>
  );
}
export default AppBar;
