import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import RefuelAPI from '../serviceAPI/RefuelAPI';

const initialState = {
  refuelData: [],
  isLoading: false,
  prevThumbnailDetails: null,
  prevThumbnailId: '',
  prevRefuelImageDetails: null,
  prevRefuelIds: null,
  errorCode: null,
  localCurrency: '',
};

export const getAllRefuelData = createAsyncThunk(
  'getAllRefuelData',
  async (id, { rejectWithValue }) => {
    try {
      const res = await RefuelAPI.GetAllRefuelData(id);
      return res;
    } catch (error) {
      return rejectWithValue(error.response.status);
    }
  }
);

export const createNewRefuelData = createAsyncThunk(
  'createNewRefuelData',
  async ({ newData, id }, { rejectWithValue }) => {
    try {
      const res = await RefuelAPI.CreateNewRefuelData(newData, id);
      return res;
    } catch (error) {
      return rejectWithValue(error.response.status);
    }
  }
);

export const updateRefuel = createAsyncThunk(
  'updateRefuel',
  async ({ newData, id }, { rejectWithValue }) => {
    try {
      const res = await RefuelAPI.UpdateRefuel(newData, id);
      return res;
    } catch (error) {
      return rejectWithValue(error.response.status);
    }
  }
);

export const deleteRefuel = createAsyncThunk(
  'deleteRefuel',
  async (id, { rejectWithValue }) => {
    try {
      const res = await RefuelAPI.DeleteRefuel(id);
      return res;
    } catch (error) {
      return rejectWithValue(error.response.status);
    }
  }
);

const RefuelSlice = createSlice({
  name: 'recreation',
  initialState,
  reducers: {
    setPrevThumbnailDetails: (state, action) => {
      state.prevThumbnailDetails = action.payload;
    },
    setPreThumbnailId: (state, action) => {
      state.prevThumbnailId = action.payload;
    },
    setPrevRefuelImageDetails: (state, action) => {
      state.prevRefuelImageDetails = action.payload;
    },
    setPrevRefuelIds: (state, action) => {
      state.prevRefuelIds = action.payload;
    },
    resetErrorAlert: (state, action) => {
      state.errorCode = null;
    },
  },
  extraReducers: {
    [getAllRefuelData.pending]: (state) => {
      state.isLoading = true;
    },
    [getAllRefuelData.fulfilled]: (state, action) => {
      state.isLoading = false;
      state.refuelData = action.payload.refuelData;
      state.localCurrency = action.payload.localCurrency;
    },
    [getAllRefuelData.rejected]: (state, action) => {
      state.isLoading = false;
      state.errorCode = action.payload;
    },
    [createNewRefuelData.fulfilled]: (state, action) => {
      state.refuelData.push(action.payload);
    },
    [createNewRefuelData.rejected]: (state, action) => {
      state.errorCode = action.payload;
    },
    [updateRefuel.fulfilled]: (state, action) => {
      state.refuelData = state.refuelData.map((refuel) =>
        refuel._id === action.payload._id ? action.payload : refuel
      );
    },
    [updateRefuel.rejected]: (state, action) => {
      state.errorCode = action.payload;
    },
    [deleteRefuel.fulfilled]: (state, action) => {
      const id = action.payload._id;
      const index = state.refuelData.map((item) => item._id).indexOf(id);
      state.refuelData.splice(index, 1);
    },
    [deleteRefuel.rejected]: (state, action) => {
      state.errorCode = action.payload;
    },
  },
});

export const {
  setPrevThumbnailDetails,
  setPreThumbnailId,
  setPrevRefuelImageDetails,
  setPrevRefuelIds,
  resetErrorAlert,
} = RefuelSlice.actions;
export default RefuelSlice.reducer;
