import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import GeneralNotificationAPI from '../serviceAPI/GeneralNotificationAPI';
const initialState = {
  notificationData: null,
  errorCode: null,
};

export const createNewNotification = createAsyncThunk(
  'createNewNotification',
  async (newData, { rejectWithValue }) => {
    try {
      const res = await GeneralNotificationAPI.CreateNewGeneralNotification(newData);
      return res;
    } catch (error) {
      return rejectWithValue(error.response.status);
    }
  }
);

const GeneralNotificationSlice = createSlice({
  name: 'generalNotification',
  initialState,
  reducers: {
    setErrorCode: (state, action) => {
      state.errorCode = action.payload;
    },
  },
  extraReducers: {
    [createNewNotification.fulfilled]: (state, action) => {
      state.notificationData= action.payload;
    },
    [createNewNotification.rejected]: (state, action) => {
      state.errorCode = action.payload;
    },
  },
});

export const {
  resetErrorAlert,
} = GeneralNotificationSlice.actions;

export default GeneralNotificationSlice.reducer;
