import { useState } from 'react';
import Required from '../../../common-components/Required';
import CloseButton from '../../../custom-components/CloseButton';
import CancelButton from '../../../custom-components/CancelButton';
import BlackButton from '../../../custom-components/BlackButton';
import { useDispatch, useSelector } from 'react-redux';
import {
  fileDelete,
  fileUpload,
  multipleFileUpload,
  previewImage,
  progressPercentage,
  resetMediaErrorAlert,
  resetMultipleFileUpload,
  setSelectedFile,
} from '../../../../slices/FileUploadSlice';
import FileUploader from '../../../common-components/FileUploader';
import { RechargeStringLabels } from '../../../../util/Strings';
import {
  setPreThumbnailId,
  setPrevHotelIds,
  setPrevHotelImageDetails,
  setPrevThumbnailDetails,
} from '../../../../slices/HotelSlice';
import ImagePreviewWithFilename from '../../../common-components/ImagePreviewWithFilename';
import { NewRechargeFormValidations } from '../../../../util/Validations';
import ErrorMessageComponent from '../../../common-components/ErrorMessageComponent';
import useFileValidation from '../../../../util/useFileValidation';
import ErrorAlert from '../../../common-components/ErrorAlert';
import ErrorMessages from '../../../../util/ErrorMessages';

function AddHotelForm({ onClickSubmit, show, data }) {
  const dispatch = useDispatch();
  const [state, setState] = useState({
    name: data && data.name ? data.name : '',
    description: data && data.description ? data.description : '',
    location: data && data.location ? data.location : '',
    // distance: data && data.distance ? data.distance : "",
    // checkInTime: data && data.checkInTime ? data.checkInTime : '11:00',
    // checkOutTime: data && data.checkOutTime ? data.checkOutTime : '10:00',
    address: data && data.address ? data.address : '',
    latitude: data && data.latitude ? data.latitude : '',
    longitude: data && data.longitude ? data.longitude : '',
    drivingTime: data && data.drivingTime ? data.drivingTime : '',
    hotelThumbnailId:
      data && data.hotelThumbnailId ? data.hotelThumbnailId : null,
    hotelImageIds: data && data.hotelImageIds ? data.hotelImageIds : null,
  });
  const [prevFileData, setPreFileData] = useState({
    photoUrl: data && data.hotelThumbnailUrl ? data.hotelThumbnailUrl : null,
    fileName: data && data.thumbnailFileName ? data.thumbnailFileName : null,
  });
  const [prevFileId, setPrevFileId] = useState(
    data && data.hotelThumbnailId ? data.hotelThumbnailId : null
  );
  const [prevMultipleFileIds, setPrevMultipleFileIds] = useState(
    data && data.hotelImageIds ? data.hotelImageIds : null
  );
  const prevThumbnailId = useSelector((state) => state.hotel.prevThumbnailId);
  const prevHotelIds = useSelector((state) => state.hotel.prevHotelIds);
  const [imageDeleteList, setImageDeleteList] = useState([]);

  const {
    errorSingleMessage,
    setErrorSingleMessage,
    errorMultiMessage,
    setErrorMultiMessage,
    validateFile,
    validateFiles,
  } = useFileValidation();
  const errorMessageCode = useSelector((state) => state.upload.errorCode);
  const prevRechargeMultipleFileData = useSelector(
    (state) => state.hotel.prevHotelImageDetails
  );
  const prevRechargeThumbnailFileData = useSelector(
    (state) => state.hotel.prevThumbnailDetails
  );
  function handleCloseErrorAlert() {
    dispatch(resetMediaErrorAlert(false));
    setPrevMultiFileData(prevRechargeMultipleFileData);
    handleMultipleImageState();
    dispatch(setSelectedFile(null));
    setPreFileData(prevRechargeThumbnailFileData);
    handleState();
    dispatch(setSelectedFile(null));
    setImageDeleteList([]);
    onClickSubmit();
  }

  var multipleFileNameWithImageUrls = [];
  if (data && data.hotelImageUrls && data.photoFileNames) {
    for (let i = 0; i < data.hotelImageUrls.length; i++) {
      multipleFileNameWithImageUrls.push({
        photoUrl: data.hotelImageUrls[i],
        fileName: data.photoFileNames[i],
        id: data.hotelImageIds[i],
      });
    }
  }
  const [prevMultiFileData, setPrevMultiFileData] = useState(
    multipleFileNameWithImageUrls
  );

  const [validation, setValidation] = useState({});

  const checkValidation = () => {
    const { errors, isValid } = NewRechargeFormValidations(state);
    setValidation(errors);
    return isValid;
  };

  function handleChange(e) {
    const value = e.target.value;
    setState({
      ...state,
      [e.target.name]: value,
    });
  }

  function resetImage() {
    dispatch(progressPercentage(0));
    dispatch(setSelectedFile(null));
    dispatch(previewImage(null));
    dispatch(resetMultipleFileUpload());
  }

  function handleImageCancel(photoId) {
    const result = prevMultiFileData.filter((image) => {
      if (image.id !== photoId) {
        return true;
      }
      return false;
    });

    const photoIdsUnfiltered = prevMultipleFileIds.map((photo) => {
      if (photo !== photoId) {
        return photo;
      }
    });
    const photoIds = photoIdsUnfiltered.filter((id) => {
      if (id !== null) {
        return id;
      }
    });
    setState({
      ...state,
      ['hotelImageIds']: photoIds,
    });
    setPrevMultipleFileIds(photoIds);
    setPrevMultiFileData(result);
    setImageDeleteList([...imageDeleteList, photoId]);
  }

  function handleThumbnailCancel() {
    setState({
      ...state,
      ['hotelThumbnailId']: null,
    });
    prevFileData.photoUrl = null;
    setImageDeleteList([...imageDeleteList, prevFileId]);
  }

  function handleNextButton() {
    if (checkValidation()) {
      if (
        state.name &&
        state.location &&
        // state.checkInTime &&
        // state.checkOutTime &&
        state.drivingTime &&
        state.address &&
        state.latitude &&
        state.longitude &&
        state.description
      ) {
        const newData = state;
        if (data) {
          setState({
            name: newData.name,
            description: newData.description,
            location: newData.location,
            address: newData.address,
            latitude: newData.latitude,
            longitude: newData.longitude,
            // checkInTime: newData.checkInTime,
            // checkOutTime: newData.checkOutTime,
            drivingTime: newData.drivingTime,
            hotelThumbnailId: newData.hotelThumbnailId,
            hotelImageIds: newData.hotelImageIds,
          });

          imageDeleteList &&
            imageDeleteList.map((image) => {
              dispatch(fileDelete({ id: image }));
            });

          resetImage();
          onClickSubmit(newData, data._id);

          if (
            state.hotelThumbnailId !== prevThumbnailId &&
            prevThumbnailId !== ''
          ) {
            const id = { id: prevThumbnailId, isVideo: false };
            dispatch(fileDelete(id));
            dispatch(setPreThumbnailId(''));
          }

          setPreFileData({
            photoUrl:
              data && data.hotelThumbnailUrl ? data.hotelThumbnailUrl : null,
            fileName:
              data && data.thumbnailFileName ? data.thumbnailFileName : null,
          });
          setImageDeleteList([]);
          setErrorSingleMessage('');
          setErrorMultiMessage('');
          // if (prevHotelIds !== null) {
          //   let result =
          //     state.hotelImageIds.length === prevHotelIds.length &&
          //     state.hotelImageIds.every(function (element) {
          //       return prevHotelIds.includes(element);
          //     });
          //   if (!result) {
          //     prevHotelIds.map((prevImageId) => {
          //       const id = { id: prevImageId, isVideo: false, isMulti: true };
          //       dispatch(fileDelete(id));
          //     });
          //     dispatch(setPrevHotelIds(null));
          //   }
          // }
          // setPrevMultiFileData(multipleFileNameWithImageUrls);
        } else {
          setState({});
          resetImage();
          setImageDeleteList([]);
          onClickSubmit(newData);
        }
      } else {
        onClickSubmit();
      }
    }
  }

  if (!show) {
    return null;
  }

  function handleState() {
    setState({ ...state, ['hotelThumbnailId']: data && data.hotelThumbnailId });
  }

  function handleMultipleImageState(fileId) {
    const id =
      data &&
      data.hotelImageIds &&
      data.hotelImageIds.filter((id) => id !== fileId);
    setState({ ...state, ['hotelImageIds']: id });
  }

  const handleFileSelect = function (event) {
    const files = event.target.files;
    postFileData(files);
  };

  const handleMultiFileSelect = function (event) {
    const files = event.target.files;
    postMultipleFileData(files);
  };

  const handleDrag = function (event) {
    event.preventDefault();
  };

  const handleDrop = function (event) {
    event.preventDefault();
    const files = event.dataTransfer.files;
    postFileData(files);
  };

  const handleMultiDrop = function (event) {
    event.preventDefault();
    const files = event.dataTransfer.files;
    postMultipleFileData(files);
  };

  function postMultipleFileData(files) {
    const valid = validateFiles(files);
    if (valid) {
      if (prevMultiFileData) {
        dispatch(setPrevHotelIds(prevMultipleFileIds));
        setPrevMultipleFileIds(prevMultipleFileIds);
        dispatch(setPrevHotelImageDetails(prevMultiFileData));
        // setPrevMultiFileData([]);
      }
      dispatch(multipleFileUpload(files))
        .unwrap()
        .then((res) => {
          const newIds = res.map((r) => r._id);
          const ids = prevMultipleFileIds
            ? prevMultipleFileIds.concat(newIds)
            : newIds;
          setState({ ...state, ['hotelImageIds']: ids });
          setPrevMultipleFileIds(ids);
        });
    }
  }

  function postFileData(files) {
    const valid = validateFile(files);
    if (valid) {
      const file = files[0];
      if (prevFileData) {
        dispatch(setPreThumbnailId(prevFileId));
        setPrevFileId(prevFileId);
        dispatch(setPrevThumbnailDetails(prevFileData));
        setPreFileData({ photoUrl: null, fileName: null });
      }
      if (file) {
        dispatch(setSelectedFile(file));
        dispatch(fileUpload(file))
          .unwrap()
          .then((res) => {
            setState({ ...state, ['hotelThumbnailId']: res[0]._id });
            setPrevFileId(res[0]._id);
          });
      }
    }
  }
  if (errorMessageCode) {
    return (
      <ErrorAlert
        show={errorMessageCode}
        onClickHandle={handleCloseErrorAlert}
        title="Error"
        subTitle={ErrorMessages(errorMessageCode)}
      />
    );
  }
  return (
    <div className="modal">
      <div className="form-scroll">
        <div className="form-flex">
          <div
            className="user-form-modal-content"
            onClick={(e) => e.stopPropagation()}
          >
            <div className="form-header">
              <div className="header">
                {data ? 'Update Recharge' : 'New Recharge Info'}
              </div>
              <CloseButton
                onClickhandle={() => {
                  resetImage();
                  if (data === null) {
                    setState({});
                    setValidation({});
                  }
                  setPreFileData({
                    photoUrl:
                      data && data.hotelThumbnailUrl
                        ? data.hotelThumbnailUrl
                        : null,
                    fileName:
                      data && data.thumbnailFileName
                        ? data.thumbnailFileName
                        : null,
                  });
                  setPrevMultipleFileIds(
                    data && data.hotelImageIds ? data.hotelImageIds : null
                  );
                  setPrevMultiFileData(multipleFileNameWithImageUrls);
                  setImageDeleteList([]);
                  setErrorSingleMessage('');
                  setErrorMultiMessage('');
                  onClickSubmit();
                }}
              />
            </div>
            <div className="form-container">
              <div className="form-label-input">
                <label className="form-label">
                  {RechargeStringLabels.recharge}
                  <Required />
                </label>
                <div className="form-input-container">
                  <input
                    type="text"
                    value={state.name}
                    name="name"
                    onChange={(e) => handleChange(e)}
                    className="form-input"
                    placeholder="Input"
                    required={true}
                  ></input>
                </div>
                <ErrorMessageComponent message={validation.name} />
              </div>

              <div className="form-label-input">
                <label className="form-label">
                  {RechargeStringLabels.description}
                  <Required />
                </label>
                <div className="user-form-input-container">
                  <textarea
                    className="form-input"
                    type="text"
                    value={state.description}
                    name="description"
                    onChange={(e) => handleChange(e)}
                    placeholder="Input"
                    rows={3}
                  ></textarea>
                </div>
                <ErrorMessageComponent message={validation.description} />
              </div>

              <div className="form-label-input">
                <label className="form-label">
                  {RechargeStringLabels.location}
                  <Required />
                </label>
                <div className="form-input-container">
                  <input
                    className="form-input"
                    type="text"
                    value={state.location}
                    name="location"
                    onChange={(e) => handleChange(e)}
                    placeholder="Input"
                    required={true}
                  ></input>
                </div>
                <ErrorMessageComponent message={validation.location} />
              </div>

              {/* <div className="form-label-input">
            <label className="form-label">
              {RechargeStringLabels.distance}
              <Required />
            </label>
            <div className="form-input-container">
              <input
                className="form-input"
                type="text"
                value={state.distance}
                name="distance"
                onChange={(e) => handleChange(e)}
                placeholder="Input"
                required={true}
              ></input>
            </div>
          </div> */}

              {/* <div className="form-label-input">
                <label className="form-label">
                  {RechargeStringLabels.checkInTime}
                  <Required />
                </label>
                <div className="form-input-container">
                  <input
                    className="form-input"
                    type="time"
                    value={state.checkInTime}
                    name="checkInTime"
                    onChange={(e) => handleChange(e)}
                    placeholder="Input"
                    required={true}
                  ></input>
                </div>
                <ErrorMessageComponent message={validation.checkInTime} />
              </div> */}
              {/* 
              <div className="form-label-input">
                <label className="form-label">
                  {RechargeStringLabels.checkOutTime}
                  <Required />
                </label>
                <div className="form-input-container">
                  <input
                    className="form-input"
                    type="time"
                    value={state.checkOutTime}
                    name="checkOutTime"
                    onChange={(e) => handleChange(e)}
                    placeholder="Input"
                    required={true}
                  ></input>
                </div>
                <ErrorMessageComponent message={validation.checkOutTime} />
              </div> */}

              <div className="form-label-input">
                <label className="form-label">
                  {RechargeStringLabels.address}
                  <Required />
                </label>
                <div className="form-input-container">
                  <input
                    className="form-input"
                    type="text"
                    name="address"
                    value={state.address}
                    onChange={(e) => handleChange(e)}
                    placeholder="Input"
                    required={true}
                  ></input>
                </div>
                <ErrorMessageComponent message={validation.address} />
              </div>

              <div className="form-label-input">
                <label className="form-label">
                  {RechargeStringLabels.latitude}
                  <Required />
                </label>
                <div className="form-input-container">
                  <input
                    className="form-input"
                    type="text"
                    name="latitude"
                    value={state.latitude}
                    onChange={(e) => handleChange(e)}
                    placeholder="e.g. 12.9845"
                    required={true}
                  />
                </div>
                <ErrorMessageComponent message={validation.latitude} />
              </div>

              <div className="form-label-input">
                <label className="form-label">
                  {RechargeStringLabels.longitude}
                  <Required />
                </label>
                <div className="form-input-container">
                  <input
                    className="form-input"
                    type="text"
                    name="longitude"
                    value={state.longitude}
                    onChange={(e) => handleChange(e)}
                    placeholder="e.g. 77.5846"
                    required={true}
                  />
                </div>
                <ErrorMessageComponent message={validation.longitude} />
              </div>

              <div className="form-label-input">
                <label className="form-label">
                  {RechargeStringLabels.drivingTime}
                  <Required />
                </label>
                <div className="form-input-container">
                  <input
                    className="form-input"
                    type="text"
                    name="drivingTime"
                    value={state.drivingTime}
                    onChange={(e) => handleChange(e)}
                    placeholder="Input"
                    required={true}
                  ></input>
                </div>
                <ErrorMessageComponent message={validation.drivingTime} />
              </div>

              <FileUploader
                previewShape="rectangle"
                headerName={RechargeStringLabels.thumbnail}
                handleDragOver={handleDrag}
                handleDrop={handleDrop}
                handleFileSelect={handleFileSelect}
                setPreFileData={setPreFileData}
                handleState={handleState}
                type="recharge"
              />
              {errorSingleMessage !== '' && (
                <p className="error image-error">{errorSingleMessage}</p>
              )}

              {prevFileData &&
              prevFileData.photoUrl &&
              data &&
              data.hotelThumbnailUrl &&
              data.thumbnailFileName ? (
                <ImagePreviewWithFilename
                  photoUrl={data.hotelThumbnailUrl}
                  fileName={data.thumbnailFileName}
                  handleCancel={handleThumbnailCancel}
                />
              ) : null}

              <FileUploader
                previewShape="rectangle"
                headerName={RechargeStringLabels.photos}
                handleDragOver={handleDrag}
                handleDrop={handleMultiDrop}
                handleFileSelect={handleMultiFileSelect}
                isMultiUpload={true}
                setMultipleFileData={setPrevMultiFileData}
                handleMultiState={handleMultipleImageState}
                type="recharge"
              />
              {errorMultiMessage !== '' && (
                <p className="error image-error">{errorMultiMessage}</p>
              )}
              {prevMultiFileData &&
                prevMultiFileData.length >= 1 &&
                prevMultiFileData.map((url) => {
                  return (
                    <ImagePreviewWithFilename
                      photoUrl={url.photoUrl}
                      fileName={url.fileName}
                      photoId={url.id}
                      handleCancel={handleImageCancel}
                    />
                  );
                })}

              <div className="footer-button-container">
                <CancelButton
                  onClickhandle={() => {
                    resetImage();
                    if (data === null) {
                      setState({});
                      setValidation({});
                    }
                    setPreFileData({
                      photoUrl:
                        data && data.hotelThumbnailUrl
                          ? data.hotelThumbnailUrl
                          : null,
                      fileName:
                        data && data.thumbnailFileName
                          ? data.thumbnailFileName
                          : null,
                    });
                    setPrevMultipleFileIds(
                      data && data.hotelImageIds ? data.hotelImageIds : null
                    );
                    setPrevMultiFileData(multipleFileNameWithImageUrls);
                    setImageDeleteList([]);
                    setErrorSingleMessage('');
                    setErrorMultiMessage('');
                    onClickSubmit();
                  }}
                >
                  {RechargeStringLabels.cancel}
                </CancelButton>
                <BlackButton onClickhandle={() => handleNextButton()}>
                  {data
                    ? RechargeStringLabels.update
                    : RechargeStringLabels.add}
                </BlackButton>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default AddHotelForm;
