import axios from 'axios';
import http from '../http-common';
import {
  setMultipleFileDetails,
  mutlipleProgressPercentage,
  previewImage,
  progressPercentage,
  setSelectedFile,
} from '../slices/FileUploadSlice';
import {
  cancelVideoUpload,
  mediaProgressPercentage,
  setMediaSelectedFile,
} from '../slices/SpeakerSlice';
import { cancelFileUpload } from '../slices/WelcomePageSlice';
import { DecryptData } from '../util/DataEncryption';

const baseURL = process.env.REACT_APP_BASE_URL;

const UploadNewFile = async (file, dispatch) => {
  const tokenStr = sessionStorage.getItem('token')
  ? DecryptData(sessionStorage.getItem('token'))
  : null;
  const formData = new FormData();
  formData.append('file', file);
  const auth = await axios
    .post(`${baseURL}/uploadFile`, formData, {headers: {
      Authorization: `Bearer ${tokenStr}`,
      'Content-Type': 'multipart/form-data',
      'Cache-Control': 'no-cache, no-store, must-revalidate',
      'Content-Security-Policy': `default-src 'self' ${baseURL}; object-src 'none'; frameancestors 'self'; form-action 'self'`,
      'Strict-Transport-Security': 'max-age=63072000; includeSubdomains;preload',
    },
      onUploadProgress: (progressEvent) => {
        const completed = Math.round(
          (progressEvent.loaded * 100) / progressEvent.total
        );
        dispatch(progressPercentage(completed));
      },
    })
    .then((response) => {
      return response.data;
    })
    .finally(() => {
      dispatch(previewImage(URL.createObjectURL(file)));
    });

  return auth;
};

const UploadNewAudioFile = async (file, dispatch) => {
  const tokenStr = sessionStorage.getItem('token')
  ? DecryptData(sessionStorage.getItem('token'))
  : null;
  const formData = new FormData();
  formData.append('file', file);
  const auth = await axios
  .post(`${baseURL}/uploadFile`, formData, {headers: {
    Authorization: `Bearer ${tokenStr}`,
    'Content-Type': 'multipart/form-data',
    'Cache-Control': 'no-cache, no-store, must-revalidate',
    'Content-Security-Policy': `default-src 'self' ${baseURL}; object-src 'none'; frameancestors 'self'; form-action 'self'`,
    'Strict-Transport-Security': 'max-age=63072000; includeSubdomains;preload',
  },
      onUploadProgress: (progressEvent) => {
        const completed = Math.round(
          (progressEvent.loaded * 100) / progressEvent.total
        );
        dispatch(mediaProgressPercentage(completed));
      },
    })
    .then((response) => {
      return response.data;
    });
  return auth;
};

const UploadVideoFile = async (file) => {
  const tokenStr = sessionStorage.getItem('token')
  ? DecryptData(sessionStorage.getItem('token'))
  : null;
  const formData = new FormData();
  formData.append('file', file);
  const auth = await axios
  .post(`${baseURL}/uploadFile`, formData,{headers: {
    Authorization: `Bearer ${tokenStr}`,
    'Content-Type': 'multipart/form-data',
    'Cache-Control': 'no-cache, no-store, must-revalidate',
    'Content-Security-Policy': `default-src 'self' ${baseURL}; object-src 'none'; frameancestors 'self'; form-action 'self'`,
    'Strict-Transport-Security': 'max-age=63072000; includeSubdomains;preload',
  }})
    .then((response) => {
      return response.data;
    });
  return auth;
};

const GetVideoFileUploadDetails = async (id) => {
  const fileDetails = await http
    .get(`/getUploadFileDetail/${id}`)
    .then((response) => {
      return response.data;
    });
  return fileDetails;
};

const DeleteUploadedFile = async (id, dispatch) => {
  const deleteFile = await http
    .delete(`/deleteFile/${id.id}`)
    .then((response) => {
      return response.data;
    })
    .finally(() => {
      if (!id.isMulti) {
        if (!id.isVideo) {
          dispatch(progressPercentage(0));
          dispatch(previewImage(null));
          dispatch(setSelectedFile(null));
        } else {
          dispatch(cancelFileUpload());
        }
      }
    });
  return deleteFile;
};

const DeleteUploadedMediaFile = async (id, dispatch) => {
  const deleteFile = await http
    .delete(`/deleteFile/${id.id}`)
    .then((response) => {
      return response.data;
    })
    .finally(() => {
      if (!id.isMulti) {
        if (!id.isVideo) {
          dispatch(mediaProgressPercentage(0));
          dispatch(setMediaSelectedFile(null));
        } else {
          dispatch(cancelVideoUpload());
        }
      }
    });
  return deleteFile;
};

const UploadMultipleFiles = async (files, dispatch) => {
  const tokenStr = sessionStorage.getItem('token')
  ? DecryptData(sessionStorage.getItem('token'))
  : null;
  const formData = new FormData();
  var fileArray = [];
  for (let i = 0; i < files.length; i++) {
    formData.append('file', files[i]);
    fileArray.push({
      name: files[i].name,
      size: files[i].size,
      preview: URL.createObjectURL(files[i]),
    });
  }
  dispatch(setMultipleFileDetails(fileArray));
  const multiUpload = await axios
  .post(`${baseURL}/uploadFile`, formData, {headers: {
    Authorization: `Bearer ${tokenStr}`,
    'Content-Type': 'multipart/form-data',
    'Cache-Control': 'no-cache, no-store, must-revalidate',
    'Content-Security-Policy': `default-src 'self' ${baseURL}; object-src 'none'; frameancestors 'self'; form-action 'self'`,
    'Strict-Transport-Security': 'max-age=63072000; includeSubdomains;preload',
  },
      onUploadProgress: (progressEvent) => {
        const completed = Math.round(
          (progressEvent.loaded * 100) / progressEvent.total
        );
        dispatch(mutlipleProgressPercentage(completed));
      },
    })
    .then((response) => {
      return response.data;
    });
  return multiUpload;
};

const UploadAPI = {
  UploadNewFile,
  UploadVideoFile,
  GetVideoFileUploadDetails,
  DeleteUploadedFile,
  UploadMultipleFiles,
  UploadNewAudioFile,
  DeleteUploadedMediaFile,
};
export default UploadAPI;
