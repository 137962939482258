import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import RecreationAPI from '../serviceAPI/RecreationAPI';

const initialState = {
  recreationData: [],
  isLoading: false,
  prevThumbnailDetails: null,
  prevThumbnailId: '',
  prevRecreationImageDetails: null,
  prevRecreationIds: null,
  errorCode: null,
};

export const getAllRecreationData = createAsyncThunk(
  'getAllRecreationData',
  async (id, { rejectWithValue }) => {
    try {
      const res = await RecreationAPI.GetAllRecreationData(id);
      return res;
    } catch (error) {
      return rejectWithValue(error.response.status);
    }
  }
);

export const createNewRecreationData = createAsyncThunk(
  'createNewRecreationData',
  async ({ newData, id }, { rejectWithValue }) => {
    try {
      const res = await RecreationAPI.CreateNewRecreationData(newData, id);
      return res;
    } catch (error) {
      return rejectWithValue(error.response.status);
    }
  }
);

export const updateRecreation = createAsyncThunk(
  'updateRecreation',
  async ({ newData, id }, { rejectWithValue }) => {
    try {
      const res = await RecreationAPI.UpdateRecreation(newData, id);
      return res;
    } catch (error) {
      return rejectWithValue(error.response.status);
    }
  }
);

export const deleteRecreation = createAsyncThunk(
  'deleteRecreation',
  async (id, { rejectWithValue }) => {
    try {
      const res = await RecreationAPI.DeleteRecreation(id);
      return res;
    } catch (error) {
      return rejectWithValue(error.response.status);
    }
  }
);

const RecreationSlice = createSlice({
  name: 'refuel',
  initialState,
  reducers: {
    setPrevThumbnailDetails: (state, action) => {
      state.prevThumbnailDetails = action.payload;
    },
    setPreThumbnailId: (state, action) => {
      state.prevThumbnailId = action.payload;
    },
    setPrevRecreationImageDetails: (state, action) => {
      state.prevRecreationImageDetails = action.payload;
    },
    setPrevRecreationIds: (state, action) => {
      state.prevRecreationIds = action.payload;
    },
    resetErrorAlert: (state, action) => {
      state.errorCode = null;
    },
  },
  extraReducers: {
    [getAllRecreationData.pending]: (state) => {
      state.isLoading = true;
    },
    [getAllRecreationData.fulfilled]: (state, action) => {
      state.isLoading = false;
      state.recreationData = [...action.payload];
    },
    [getAllRecreationData.rejected]: (state, action) => {
      state.isLoading = false;
      state.errorCode = action.payload;
    },
    [createNewRecreationData.fulfilled]: (state, action) => {
      state.recreationData.push(action.payload);
    },
    [createNewRecreationData.rejected]: (state, action) => {
      state.errorCode = action.payload;
    },
    [updateRecreation.fulfilled]: (state, action) => {
      state.recreationData = state.recreationData.map((recreation) =>
        recreation._id === action.payload._id ? action.payload : recreation
      );
    },
    [updateRecreation.rejected]: (state, action) => {
      state.errorCode = action.payload;
    },
    [deleteRecreation.fulfilled]: (state, action) => {
      const id = action.payload._id;
      const index = state.recreationData.map((item) => item._id).indexOf(id);
      state.recreationData.splice(index, 1);
    },
    [deleteRecreation.rejected]: (state, action) => {
      state.errorCode = action.payload;
    },
  },
});

export const {
  setPrevThumbnailDetails,
  setPreThumbnailId,
  setPrevRecreationImageDetails,
  setPrevRecreationIds,
  resetErrorAlert,
} = RecreationSlice.actions;
export default RecreationSlice.reducer;
