import '../../../../styles/screens/client-visit-details/manage-module/HotelList.css';
import { RechargeStringLabels } from '../../../../util/Strings';

import HotelListTable from './HotelListTable';
function HotelList() {
  return (
    <div className="events-list-container">
      <div className="event-list-heading">
        {/* Hotel List */}
        {RechargeStringLabels.recharge}
      </div>
      <div className="events-list-content">
        <HotelListTable />
      </div>
    </div>
  );
}

export default HotelList;
