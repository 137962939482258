import TopBar from "./TopBar";

function Terms(){
    const terms = `
    <html>
	<body style="font-family:arial; font-size: 15px;">
	<p> <b>Welcome to AC Visitor App, the PwC AC Client Experience mobile app</b> designed to streamline all visit related information. The app helps you:</p>
	<ul>
		<li style="margin-top:-14px;">View your visit agenda</li>
		<li>Access PwC team member bios</li>
		<li>Access event material</li>
		<li>View your travel information</li>
		<li>Get tourist recommendations</li>
		<li>Request visit specific support from an AC Visit Partner</li>
	</ul>
	<p>This app is intended for information purposes only, to ease and streamline AC Bangalore visitor details and logistics. <b>Your use of and access to the PwC AC Client Experience mobile app is subject at all times to PwC's <a href="https://productcentral.products.pwc.com/us/en/legal/terms-of-use.html">Terms of Use</a>. Your use of the app is at all times governed by and subject to these terms, and your acceptance of those terms.</b></p>

	<p style="color: #D04A02"><b>General business rules for using the app:</b></p>
	<ul>
		<li style="margin-top:-14px;">While we have made every attempt to ensure that the information contained in this app has been obtained from reliable sources, PwC is not responsible for any errors or omissions, or for the results obtained from the use of this information or recommendations.</li>
		<li>Please be courteous to the support/AC Visit Partner services team</li>
		<li>PwC is not responsible for any injury, illness, or loss from information or recommendations provided in the app</li>
		<li>No confidential information should be shared or implied by text or image</li>
		<li>Users can share information with the AC Visit Partner by clicking in the app to send an email to coordinate logistical and travel support as needed</li>
		<li>User should not post or email any contents that violate laws and statutes in any manner such as, but not limited to:
			<ul>
				<li>Contents breaching third-party rights e.g. copyright</li>
				<li>Content which are insulting or defamatory</li>
				<li>Contents promoting any commercial practices considered unfair competition</li>
				<li>Contents violating criminal law or promoting anti-constitutional entities</li>
				<li>Contents annoying pestering or harassing any other user</li>
				<li>Contents which include information for which user has no right to disclose</li>
				<li>Contents intended to disrupt, destroy, limit or impair services on the application</li>
			</ul>
		</li>
	</ul>

	<p style="color: #D04A02"><b>User details and data privacy:</b></p>
	<ul>
		<li style="margin-top:-14px;">PwC is strongly committed to protecting personal data. This privacy statement describes why and how we collect and use personal data and provides information about individuals' rights in relation to personal data. It applies to personal data provided to us, both by individuals themselves or by others.</li>
		<li>We collect data from users through the daily interactions that the users have with the application and through the data that the users provide directly. We use the data for providing support to the users.</li>
		<li>We use a variety of secure technologies and procedures to protect the data that the user provides. All user data is encrypted using Azure Cosmos DB both in rest and in transit.</li>
		<li>Users are not allowed to change any details that are being populated from PwC data records.</li>
		<li>Cookies are not used.</li>
		<li>There is no GPS tracking, app uses weather APIs specific to Bangalore, India</li>
		<li>All user data is encrypted using Azure Cosmos DB both in rest and in transit</li>
		<li>There is a 15-day data retention period unless requested otherwise, meaning all user and meeting data will be purged and access removed 15 days after the meeting concludes</li>
		<li>We acknowledge that any personal data provided by the user to us is extremely important to the user. We shall comply with all applicable legal provisions regarding data protection.</li>
	</ul>

	<p style="color: #D04A02"><b>Details provided by users directly in the application:</b></p>
	<ul>
		<li style="margin-top:-14px;">These includes the following components:
			<ul>
				<li>User profile manual details</li>
				<li>Requests made to AC Visit Partner by the users and/or their admin</li>
				<li>Cab requests submitted by the users and/or their admin</li>
				<li>Any comments provided by the user in any of the modules where posting or mailing functionality is available</li>
			</ul>
		</li>
		<li>While performing any of the above mentioned tasks act with integrity and ensure none of the actions are unethical, illegal or inconsistent with <a href="https://www.pwc.com/codeofconduct">PwC's Code of Conduct</a></li>
		<li>Sharing or posting any unlawful, harassing, defamatory, obscene, or otherwise objectionable material is not acceptable</li>
		<li>No confidential information should be shared in the application</li>
	</ul>

	<p style="color: #D04A02"><b>No responsibility for the User's and Third Party Contents:</b></p>
	<ul>
		<li style="margin-top:-14px;">PwC shall not be liable for any contents provided by or made available by any user, including the user's content.</li>
		<li>Users acknowledge that the application includes and provides content provided by third-party providers. PwC shall not be liable for any such third-party content</li>
	</ul>

	<p style="color: #D04A02"><b>Visitors to our offices:</b>
	<p>We have security measures in place at PwC offices, including CCTV and building access controls Please <a href="https://www.pwc.com/gx/en/legal-notices/pwc-privacy-statement/visitors-to-our-offices.html">click here</a> to view PwC's visitor office security measures</p>
	</p>

	<p style="color: #D04A02"><b>Granting Rights to Use:</b></p>
	<ul>
		<li style="margin-top:-14px;">The user grants us the rights necessary with respect to content to fulfill the purpose of supporting the user</li>
		<li>The user grants the right to store the user's contact information (phone # and email id) in the application</li>
	</ul>

	<p style="color: #D04A02"><b>Be on the lookout</b></p>
	<ul>
		<li style="margin-top:-14px;">If you see something in the app that you feel doesn't belong or could create a problem for a client, colleague or PwC, alert <a href="jennifer.l.etheridge@pwc.com">Jennifer Etheridge</a>, AC CX Leader. We'll investigate and escalate as appropriate. </li>
	</ul>
	
</body>
</html>
    `
    return (
        <div className="web-content">
          <TopBar />
          <div className="web-content-container">
            <div dangerouslySetInnerHTML={{ __html: terms }} />
          </div>
        </div>
      );
    
}
export default Terms;