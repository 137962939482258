import edit from "../../../../assets/edit.svg";
import del from "../../../../assets/delete.svg";
import place from "../../../../assets/image-placeholder.png";
import MyGallery from "../../../common-components/ImageGalleryComponent";
import { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import AddHotelForm from "./AddHotelForm";
import {
  deleteHotel,
  getAllHotelList,
  updateHotel,
} from "../../../../slices/HotelSlice";
import ConfirmationDialog from "../../../common-components/ConfirmationDialog";
import { RechargeStringLabels } from "../../../../util/Strings";

function HotelListTableBody({ dataItem }) {
  const [showMultiImageGallery, setShowMultiImageGallery] = useState(false);
  const isSubAdmin = useSelector((state) => state.login.isSubAdmin);
  const [showForm, setShowForm] = useState(false);
  const thumbnail = dataItem.hotelThumbnailUrl
    ? dataItem.hotelThumbnailUrl
    : place;
  const photos = [thumbnail, ...dataItem.hotelImageUrls];
  const [showDelete, setShowDelete] = useState(false);
  const isVisitCompleted = useSelector(
    (state) => state.visits.selectedVisitIsCompleted
  );
  const selectedOfficeId = useSelector(
    (state) => state.officelist.selectedOfficeId
  );
  const dispatch = useDispatch();
  function updateHotelData(newData) {
    if (newData) {
      const dataWithId = { id: dataItem._id, newData: newData };
      dispatch(updateHotel(dataWithId));
      dispatch(getAllHotelList(selectedOfficeId));
    }
    setShowForm(false);
  }
  return (
    <tbody key={dataItem._id}>
      <tr className='event-row'>
        <td className='event-title'>{dataItem.name}</td>
        <td className='description-hotel'>{dataItem.description}</td>
        <td>{dataItem.location}</td>
        <td>
          {/* <div>{dataItem.distance}</div> */}
          {dataItem.drivingTime}
        </td>
        {/* <td>
          <div>
            {RechargeStringLabels.checkIn} {dataItem.checkInTime}
          </div>
          <div>
            {RechargeStringLabels.checkOut} {dataItem.checkOutTime}
          </div>
        </td> */}
        <td className='address'>{dataItem.address}</td>
        <td className='image-index-container'>
          <img
            src={
              dataItem.hotelThumbnailUrl ? dataItem.hotelThumbnailUrl : place
            }
            alt='place'
            className='tourist-img'
          />
          <div className='image-index'>
            <span>{dataItem.hotelImageUrls.length + 1}</span>
          </div>
          <div
            className='all-photos'
            onClick={() => {
              setShowMultiImageGallery(true);
            }}
          >
            {RechargeStringLabels.viewAllPhotos}
          </div>
        </td>

        {isVisitCompleted || isSubAdmin ? null : (
          <td>
            <div className='event-icon'>
              <img
                src={edit}
                alt='edit'
                className='icon-middle'
                onClick={(e) => {
                  setShowForm(true);
                  getAllHotelList(selectedOfficeId);
                }}
              />
              <img
                src={del}
                alt='delete'
                className='icon-middle'
                onClick={() => {
                  setShowDelete(true);
                }}
              />
            </div>
          </td>
        )}
      </tr>
      <MyGallery
        show={showMultiImageGallery}
        setShowMultiImageGallery={setShowMultiImageGallery}
        data={photos}
      />
      <AddHotelForm
        onClickSubmit={updateHotelData}
        show={showForm}
        data={dataItem}
      />
      <ConfirmationDialog
        show={showDelete}
        buttonName='Delete'
        onClickHandle={(flag) => {
          if (flag) {
            dispatch(deleteHotel(dataItem._id));
          }
          setShowDelete(false);
        }}
        title='Delete a Recharge Record'
        subTitle={`Are you sure you want to delete ${dataItem.name} record`}
      />
    </tbody>
  );
}

export default HotelListTableBody;
