import http from '../http-common';

async function GetToKnowACData(id) {
    const acData = await http.get(`/allknowAboutac/${id}`).then((response) => {
        return response.data;
    });
    return acData;
}

async function CreateNewGetToKnowACData(newData, id) {
    const auth = await http
        .post(`/createknowaboutac/${id}`, newData)
        .then((response) => {
            return response.data;
        });
    return auth;
}

const UpdateGetToKnowAC = async (updateData, id) => {
    const updatedData = await http
        .put(`/updateknowaboutac/${id}`, updateData)
        .then((response) => {
            return response.data;
        });
    return updatedData;
};

const DeleteGetToKnowAC = async (id) => {
    const deletedData = await http
        .delete(`/deleteKnowAboutAC/${id}`)
        .then((response) => {
            return response.data;
        });
    return deletedData;
};

const GetToKnowACAPI = {
    GetToKnowACData,
    CreateNewGetToKnowACData,
    UpdateGetToKnowAC,
    DeleteGetToKnowAC,
};
export default GetToKnowACAPI;