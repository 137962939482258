import React from 'react';
import { DragDropContext, Droppable, Draggable } from '@hello-pangea/dnd';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faGripHorizontal } from "@fortawesome/free-solid-svg-icons";

const getItemStyle = (isDragging, draggableStyle) => ({
    userSelect: "none",
    padding: 10,
    margin: `0 0 5px 0`,
    background: isDragging ? "lightGrey" : "white",
    ...draggableStyle
});

const getListStyle = isDraggingOver => ({
    // background: isDraggingOver ? "#D3D3D3" : "lightgrey",
    padding: 8,
    width: 700
});

const DraggableList = ({
    onDragEnd,
    dataItems,
}) => {
    return (
        <DragDropContext onDragEnd={onDragEnd}>
            <Droppable droppableId="droppable">
                {(provided, snapshot) => (
                    <div
                        {...provided.droppableProps}
                        ref={provided.innerRef}
                        style={getListStyle(snapshot.isDraggingOver)}
                    >
                        {dataItems.map((item, index) => (
                            <Draggable key={item._id} draggableId={item._id} index={index}>
                                {(provided, snapshot) => (
                                    <div
                                        key={item._id}
                                        ref={provided.innerRef}
                                        {...provided.draggableProps}
                                        {...provided.dragHandleProps}
                                        style={getItemStyle(
                                            snapshot.isDragging,
                                            provided.draggableProps.style
                                        )}
                                    >
                                        <div className='speaker-sorting-item'>{item.name} <span>({item.designation})</span>
                                        <FontAwesomeIcon
                                            size="2s"
                                            icon={faGripHorizontal}
                                            className='speaker-sorting-icon'
                                        /></div>
                                    </div>
                                )}
                            </Draggable>
                        ))}
                        {provided.placeholder}
                    </div>
                )}
            </Droppable>
        </DragDropContext>
    );
};

export default DraggableList;
