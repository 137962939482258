import { configureStore } from "@reduxjs/toolkit";
import visitsReducer from "../slices/VisitSlice";
import usersReducer from "../slices/UserSlice";
import speakersReducer from "../slices/SpeakerSlice";
import cabReducer from "../slices/CabSlice";
import welcomePageReducer from "../slices/WelcomePageSlice";
import eventsReducer from "../slices/EventListSlice";
import hotelReducer from "../slices/HotelSlice";
import uploadReducer from "../slices/FileUploadSlice";
import loginReducer from "../slices/LoginSlice";
import refuelReducer from "../slices/RefuelSlice";
import recreationReducer from "../slices/RecreationSlice";
import subAdminReducer from "../slices/SubAdminSlice";
import conciergeReducer from "../slices/ConciergeSlice";
import generalNotificationReducer from "../slices/GeneralNotificationSlice";
import userfeedbackReducer from "../slices/UserFeedbackSlice";
import officelistReducer from "../slices/OfficeListSlice";
import acReducer from "../slices/GetToKnowACSlice";

export const store = configureStore({
  reducer: {
    subAdmin: subAdminReducer,
    visits: visitsReducer,
    users: usersReducer,
    speakers: speakersReducer,
    cabs: cabReducer,
    welcomePage: welcomePageReducer,
    events: eventsReducer,
    hotel: hotelReducer,
    upload: uploadReducer,
    login: loginReducer,
    refuel: refuelReducer,
    recreation: recreationReducer,
    concierge: conciergeReducer,
    userfeedback: userfeedbackReducer,
    officelist: officelistReducer,
    ac: acReducer,
    generalNotification: generalNotificationReducer,
  },
});
