import TopBar from './TopBar';
function Privacy() {
  const privacy = `
    <html>
  <body>
    <style>
      body {
        font-family: arial;
        font-size: 15px;
        // background-color: #303030;
        // color: #ffffff;
        margin: 10;
        width: auto !important;
      }
    </style>
    <p style="color: #d04a02"><b>Introduction</b></p>
    <p>
      AC Visitor is an offering of PricewaterhouseCoopers Advisory Services LLC
      (“PwC”), a member of the PricewaterhouseCoopers global network of firms.
    </p>
    <p>
      This privacy statement explains how we collect, use, disclose, transfer,
      and store personal information in connection with AC Visitor (the
      “Solution”). This privacy statement applies solely to the Solution and
      does not apply to other PwC websites or applications.
    </p>
    <p>
      PwC processes personal information collected by and in connection with
      this Solution under the direction of your employer or other company,
      institution, or organization with which you are affiliated in relation to
      this Solution and/or which has licensed the Solution for your use (your
      “Organization”), and has no ownership of such information. PwC is not
      responsible for how your Organization uses such information. Your
      Organization is responsible for complying with any laws and regulations
      that require providing notice and/or obtaining consent prior to the
      collection and processing of such information.
    </p>
    <p style="color: #d04a02"><b>Information Collection</b></p>
    <p>
      When you access or use our Solution, we will collect personal information
      about you and how you interact with our Solution. We collect personal
      information about you through: (1) information you provide and (2) third
      parties, namely your Organization.
    </p>
    <p style="color: #d04a02"><b>Information You Provide</b></p>
    <p>
      When you access the Solution, you may be asked or choose to provide
      personal information about yourself, such as: identifiers and contact
      information including your name, address, email address, and telephone
      number.
    </p>
    <p>
      We do not intend to collect sensitive personal information about you
      through the Solution. “Sensitive personal information” includes
      information that reveals: social security, driver’s license, government
      identification card, or passport number; account log-in, financial
      account, debit card, or credit card number in combination with any
      required security or access code, password, or credentials allowing access
      to an account; precise geolocation; racial or ethnic origin; political
      opinions; religious or philosophical beliefs; trade union membership;
      genetic data; biometric data for identification; health information;
      sexual orientation/ preferences; citizenship or immigration status;
      personal information of a known child.
    </p>
    <p>
      Please do not submit sensitive personal information through the Solution.
    </p>

    <p style="color: #d04a02"><b>Automatically Collected Information </b></p>
    <p>
      We use only strictly necessary cookies and similar technologies
      (“Cookies”) on our Solution. These Cookies are necessary for the Solution
      to function and cannot be disabled in our systems. You may be able to set
      your browser or device to block these Cookies, but some parts of the
      Solution may no longer work.
    </p>

    <p style="color: #d04a02"><b>Information from Third Parties</b></p>
    <p>
      We may collect the following information about you in connection with the
      Solution from your Organization: name, phone, email address.
    </p>

    <p style="color: #d04a02"><b>Information Use</b></p>
    <p>
      We use the information we collect for the following business purposes:
    </p>
    <ul>
      <li>
        Operating, maintaining, customizing, analyzing, and improving the
        Solution;
      </li>
      <li>
        Preventing and enhancing protection against fraud, spam, harassment,
        intellectual property infringement, crime and security risks;
      </li>
      <li>
        Complying with laws and regulations as well as professional and industry
        standards;
      </li>
      <li>
        Carrying out our contractual obligations, enforcing our rights, and as
        otherwise permitted by the contract between PwC and your Organization;
        and
      </li>
      <li>
        Sending Solution-related notifications to you and/or your Organization.
      </li>
    </ul>
    <p>
      We may also use the information we collect to create aggregate or
      anonymized information, which we may use to build upon and improve the
      Solution and our other products and services as well as for other lawful
      business purposes.
    </p>

    <p style="color: #d04a02"><b>Information Disclosure</b></p>
    <p>
      As we are administering this Solution to you on behalf of your
      Organization, we provide the information we collect through the Solution
      to your Organization. Your Organization using the Solution is responsible
      for its handling of information collected by the Solution in accordance
      with its internal policies and applicable law.
    </p>
    <p>
      PwC is part of a global network of firms. In common with other
      professional service providers, PwC may transfer or disclose the
      information we collect, including your information, to third party
      contractors, subcontractors, subsidiaries, and/or other PwC firms for the
      purposes for which you have submitted the information and for the
      administration of the Solution, our systems, and/or other internal,
      administrative purposes. For example, we may transfer your information to
      third party service providers of identity management, website hosting and
      management, data analysis, data backup, security, and storage services.
    </p>
    <p>
      PwC may also disclose personal information to third parties under the
      following circumstances:
    </p>
    <ul>
      <li>When explicitly requested by a user;</li>
      <li>
        As necessary to comply with law, rules, regulations, legal obligations,
        professional and industry standards, as well as respond to and comply
        with subpoena, search warrant or other legal process, including
        establishing, exercising, or defending our legal rights;
      </li>
      <li>
        As necessary in connection with a corporate reorganization, merger,
        sale, joint venture or other disposition of all or any portion of our
        business, assets or capital.
      </li>
    </ul>
    <p>
      We may also provide aggregate information to third parties for any lawful
      business purpose. We do not sell or share (as such terms are defined by
      applicable law) personal information collected in connection with the
      Solution.
    </p>

    <p style="color: #d04a02"><b>Information Transfer</b></p>
    <p>
      Your information may be transferred outside of your country of residence.
      This includes to countries outside the European Economic Area,
      Switzerland, and the United Kingdom. In accordance with applicable legal
      requirements, we take appropriate measures to facilitate adequate
      protection for any information so transferred.
    </p>
    <p>
      When we transfer personal information from the EEA, Switzerland, or the UK
      to a country or framework not subject to an adequacy decision of the
      applicable regulatory authority, the transfers will be under a legal
      mechanism designed to ensure adequate protection of such personal
      information, such as approved standard contractual clauses.
    </p>
    <p>
      Although not currently relied upon as an authorized mechanism for cross
      border transfers, PwC also adheres to the privacy principles in the EU -
      U.S. Privacy Shield Framework and the Swiss - U.S. Privacy Shield
      Framework as set forth by the U.S. Department of Commerce. To learn more,
      see our Privacy Shield Policy.
    </p>

    <p style="color: #d04a02"><b>Information Retention</b></p>
    <p>
      We will retain the personal information collected by us through the
      Solution for as long as is necessary for the purpose(s) for which it was
      collected in accordance with PwC policies, provided that personal
      information may be held for longer periods where extended retention
      periods are required by law, regulation or professional standards, and in
      order to establish, exercise or defend our legal rights.
    </p>

    <p style="color: #d04a02"><b>Your Cookies Access</b></p>
    <p>
      You may review and update your personal information by logging into the
      Solution, clicking the Profile icon on the home screen, and making your
      updates in the Profile screen.
    </p>
    <p style="color: #d04a02"><b>Managing Cookies </b></p>
    <p>
      For information on how to manage your cookie preferences, please review
      our Cookie Notice. Do Not Track (“DNT”) is a privacy preference that you
      can set in your web browser to send a message to the website operator
      requesting not to be tracked. Currently, we do not respond to these
      signals. For more information about DNT, visit https://allaboutdnt.com/.
    </p>
    <p style="color: #d04a02"><b>Requests and Inquiries</b></p>
    <p>
      Depending on the jurisdiction in which you are located, you may have
      certain rights with respect to your personal information. Subject to
      applicable law, these rights may include the right to access and obtain a
      copy of your personal information (including in a portable format if
      requested); to have your personal information corrected/rectified or
      deleted/erased; to restrict or object to the processing of your personal
      information; to withdraw your consent for processing (where processing is
      based on your consent); and to not receive discriminatory treatment for
      exercising your privacy rights. Applicable laws may also give you the
      right to lodge a complaint with the data protection authority in your
      country.
    </p>
    <p>
      If you have any questions about your Organization’s or this Solution’s
      privacy practices or to exercise any of your rights regarding your
      personal information, please direct your inquiry to the appropriate
      contact within your Organization. If we receive a request or inquiry on
      behalf of your Organization, we will forward your request to your
      Organization (if we can identify them from the details provided) unless
      prohibited by law. If your Organization directs us to take action on your
      request, we will cooperate with your Organization to effectuate the
      request in accordance with applicable law.
    </p>
    <p style="color: #d04a02"><b>Security</b></p>
    <p>
      PwC has implemented generally accepted standards of technology and
      operational security designed to protect personal information from
      unauthorized access, loss, misuse, alteration, or destruction. Only
      authorized PwC personnel and the third parties described in this privacy
      statement are provided access to personal information and these personnel
      and third parties have agreed to maintain the security of this
      information.
    </p>
    <p style="color: #d04a02"><b>Third Party Sites </b></p>
    <p>
      This Solution may link to other websites which do not operate under PwC's
      privacy practices. When you link to other websites, PwC's privacy
      practices no longer apply. We encourage you to review the privacy
      statement of each website you visit before disclosing any personal
      information.
    </p>
    <p style="color: #d04a02"><b>Children</b></p>
    <p>
      PwC understands the importance of protecting children's privacy,
      especially in an online environment. The Solution is not designed for or
      directed at children under the age of 18 years. PwC does not knowingly
      sell or share personal information about children under the age of 18 in
      connection with the Solution.
    </p>
    <p>
      In addition, upon request and where required by law, PwC will remove
      content or information posted to the Solution by users who are under the
      age of 18. Please contact your Organization to request such removal.
    </p>
    <p style="color: #d04a02"><b>Modifications</b></p>
    <p>
      PwC may update this privacy statement at any time by publishing an updated
      version. You can access the most current version of this privacy statement
      at any time on this Solution.
    </p>
  </body>
</html>`
  return (
    <div className="web-content">
      <TopBar />
      <div className="web-content-container">
        <div dangerouslySetInnerHTML={{ __html: privacy }} />
      </div>
    </div>
  );
}
export default Privacy;
