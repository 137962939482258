import { SupportStringLabels } from '../../../util/Strings';
import SupportCard from './SupportCard';
import TopBar from './TopBar';

function Support() {
  return (
    <div className="web-content">
      <TopBar />
      <div className="web-content-container">
        <span className="web-content-header">
          {SupportStringLabels.header}
        </span>
        <div className="support-content">
          <SupportCard />
        </div>
      </div>
    </div>
  );
}
export default Support;
