import MyGallery from "../../../common-components/ImageGalleryComponent";
import AddNewRefuelForm from "./AddNewRefuelForm";
import edit from "../../../../assets/edit.svg";
import del from "../../../../assets/delete.svg";
import place from "../../../../assets/image-placeholder.png";
import { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  deleteRefuel,
  getAllRefuelData,
  updateRefuel,
} from "../../../../slices/RefuelSlice";
import ConfirmationDialog from "../../../common-components/ConfirmationDialog";
import { RefuelStringLabels } from "../../../../util/Strings";

function RefuelListTableBody({ dataItem }) {
  const [showMultiImageGallery, setShowMultiImageGallery] = useState(false);
  const isSubAdmin = useSelector((state) => state.login.isSubAdmin);
  const [showForm, setShowForm] = useState(false);

  const photos = [dataItem.thumbnailUrl, ...dataItem.photoUrls];
  const [showDelete, setShowDelete] = useState(false);
  const isVisitCompleted = useSelector(
    (state) => state.visits.selectedVisitIsCompleted
  );
  const selectedOfficeId = useSelector(
    (state) => state.officelist.selectedOfficeId
  );
  const dispatch = useDispatch();
  function updateRefuelData(newData) {
    if (newData) {
      const dataWithId = { id: dataItem._id, newData: newData };
      dispatch(updateRefuel(dataWithId));
      dispatch(getAllRefuelData(selectedOfficeId));
    }
    setShowForm(false);
  }

  return (
    <tbody key={dataItem._id}>
      <tr className='event-row'>
        <td className='event-title'>{dataItem.name}</td>
        {/* <td>{dataItem.filter}</td> */}
        <td>{dataItem.type}</td>
        <td>{dataItem.price}</td>
        <td className='address'>{dataItem.address}</td>
        <td className='description-hotel'>{dataItem.description}</td>
        <td className='image-index-container'>
          <img
            src={dataItem.thumbnailUrl ? dataItem.thumbnailUrl : place}
            alt='place'
            className='tourist-img'
          />
          <div className='image-index'>
            <span>{dataItem.photoUrls.length + 1}</span>
          </div>
          <div
            className='all-photos'
            onClick={() => {
              setShowMultiImageGallery(true);
            }}
          >
            {RefuelStringLabels.viewAllPhotos}
          </div>
        </td>

        {isVisitCompleted || isSubAdmin ? null : (
          <td>
            <div className='event-icon'>
              <img
                src={edit}
                alt='edit'
                className='icon-middle'
                onClick={(e) => {
                  setShowForm(true);
                  getAllRefuelData(selectedOfficeId);
                }}
              />
              <img
                src={del}
                alt='delete'
                className='icon-middle'
                onClick={() => {
                  setShowDelete(true);
                }}
              />
            </div>
          </td>
        )}
      </tr>
      <MyGallery
        show={showMultiImageGallery}
        setShowMultiImageGallery={setShowMultiImageGallery}
        data={photos}
      />
      <AddNewRefuelForm
        show={showForm}
        onClickNext={updateRefuelData}
        data={dataItem}
      />
      <ConfirmationDialog
        show={showDelete}
        buttonName='Delete'
        onClickHandle={(flag) => {
          if (flag) {
            dispatch(deleteRefuel(dataItem._id));
          }
          setShowDelete(false);
        }}
        title='Delete a Refuel Record'
        subTitle={`Are you sure you want to delete ${dataItem.name} record`}
      />
    </tbody>
  );
}
export default RefuelListTableBody;
