import { useSelector } from "react-redux";
import "../../styles/common-components/FileUploader.css";
import { useDispatch } from "react-redux";
import FileDetailsWithUploadProgress from "./FileDetailsWithUploadProgress";
import { fileDelete } from "../../slices/FileUploadSlice";
import { UploadFilesString } from "../../util/Strings";

function FileUploader({
  headerName,
  previewShape,
  handleDragOver,
  handleDrop,
  handleFileSelect,
  isMultiUpload = false,
  setPreFileData,
  handleState,
  type,
  setMultipleFileData,
  handleMultiState,
}) {
  const progressPer = useSelector((state) => state.upload.progress);
  const fileSelected = useSelector((state) => state.upload.selectedFile);
  const imagePreview = useSelector((state) => state.upload.previewImage);
  const prevFileData = useSelector((state) => state.speakers.prevFileDetails);
  const prevRechargeThumbnailFileData = useSelector(
    (state) => state.hotel.prevThumbnailDetails
  );
  const prevRecreationThumbnailFileData = useSelector(
    (state) => state.recreation.prevThumbnailDetails
  );
  const prevConciergeThumbnailFileData = useSelector(
    (state) => state.concierge.prevThumbnailDetails
  );
  const prevCompanyLogoFileData = useSelector(
    (state) => state.visits.prevThumbnailDetails
  );
  const prevACThumbnailFileData = useSelector(
    (state) => state.ac.prevThumbnailDetails
  );
  const prevRefuelThumbnailFileData = useSelector(
    (state) => state.refuel.prevThumbnailDetails
  );
  const photoId = useSelector((state) => state.upload.photoId);
  const multipleProgressPer = useSelector(
    (state) => state.upload.multipleProgress
  );
  const multipleFileDetails = useSelector(
    (state) => state.upload.multipleFileDetails
  );
  const dispatch = useDispatch();

  function setPreviousFileValue() {
    switch (type) {
      case "concierge":
        setPreFileData(prevConciergeThumbnailFileData);
        break;
      case "visit":
        setPreFileData(prevCompanyLogoFileData);
        break;
      case "titan":
        setPreFileData(prevFileData);
        break;
      case "recharge":
        setPreFileData(prevRechargeThumbnailFileData);
        break;
      case "recreation":
        setPreFileData(prevRecreationThumbnailFileData);
        break;
      case "refuel":
        setPreFileData(prevRefuelThumbnailFileData);
        break;
      case "ac":
        setPreFileData(prevACThumbnailFileData);
        break;
      default:
      // Do Nothing
    }
  }

  function handleCancelUpload() {
    const id = { id: photoId, isVideo: false, isMulti: false };
    dispatch(fileDelete(id));
    setPreviousFileValue();
    handleState();
  }

  return (
    <div className="form-label-input">
      <label className="form-label">{headerName}</label>
      <div className="photo-input">
        <div
          className="drop-zone-container"
          onDragOver={handleDragOver}
          onDrop={handleDrop}
        >
          <div className="input-with-para">
            <p>
              {UploadFilesString.dragDrop}
              <span>{UploadFilesString.chooseAFile}</span>
            </p>
            <input
              type="file"
              onChange={handleFileSelect}
              className="file-input"
              multiple={isMultiUpload}
            />
          </div>
        </div>
        <div className="disclaimer">
          {isMultiUpload
            ? type === "rendezvous"
              ? "You can upload multiple documents"
              : "You can upload multiple photos"
            : "You can upload only one photo"}
        </div>
        {!isMultiUpload ? (
          <div className="preview-img-progress-bar-conatiner">
            {fileSelected && imagePreview ? (
              <div>
                <img
                  className={
                    previewShape === "circular"
                      ? "preview-img"
                      : "preview-img-box"
                  }
                  src={imagePreview}
                  alt="preview img"
                />
              </div>
            ) : null}

            {fileSelected && (
              <FileDetailsWithUploadProgress
                fileSelected={fileSelected}
                handleCancelUpload={handleCancelUpload}
                progressPer={progressPer}
              />
            )}
          </div>
        ) : (
          multipleFileDetails.map((file) => {
            return (
              <div className="preview-img-progress-bar-conatiner">
                {type === "rendezvous" ? null : file && file.preview ? (
                  <div>
                    <img
                      className={
                        previewShape === "circular"
                          ? "preview-img"
                          : "preview-img-box"
                      }
                      src={file.preview}
                      alt="preview img"
                    />
                  </div>
                ) : null}

                {file && (
                  <FileDetailsWithUploadProgress
                    fileSelected={file}
                    handleCancelUpload={handleCancelUpload}
                    progressPer={multipleProgressPer}
                    isMultiUpload={isMultiUpload}
                    setMultipleFileData={setMultipleFileData}
                    handleMultiState={handleMultiState}
                    type={type}
                  />
                )}
              </div>
            );
          })
        )}
      </div>
    </div>
  );
}

export default FileUploader;
