import '../../styles/custom-components/Button.css';
import close from '../../assets/cancel.svg';

function CloseButton({ onClickhandle, isImageCloseButton = false }) {
  return (
    <>
      <button
        className={
          isImageCloseButton ? 'close-button-image-gallery' : 'close-button'
        }
        onClick={() => {
          onClickhandle();
        }}
      >
        <img src={close} alt="edit" className="icon-middle" />
      </button>
    </>
  );
}

export default CloseButton;
