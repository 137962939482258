import { useState } from "react";
import Required from "../../../common-components/Required";
import { useDispatch, useSelector } from "react-redux";
import {
  fileDelete,
  fileUpload,
  previewImage,
  progressPercentage,
  setSelectedFile,
} from "../../../../slices/FileUploadSlice";
import FileUploader from "../../../common-components/FileUploader";
import CloseButton from "../../../custom-components/CloseButton";
import CancelButton from "../../../custom-components/CancelButton";
import BlackButton from "../../../custom-components/BlackButton";
import { ConciergeStringLabels } from "../../../../util/Strings";
import ImagePreviewWithFilename from "../../../common-components/ImagePreviewWithFilename";
import ErrorMessageComponent from "../../../common-components/ErrorMessageComponent";
import useFileValidation from "../../../../util/useFileValidation";
import ErrorAlert from "../../../common-components/ErrorAlert";
import ErrorMessages from "../../../../util/ErrorMessages";
import {
  setPreThumbnailId,
  setPrevThumbnailDetails,
} from "../../../../slices/ConciergeSlice";
import { NewConciergeFormValidations } from "../../../../util/Validations";

function AddConciergeForm({ show, onClickNext, data }) {
  const dispatch = useDispatch();
  const fileUploadId = useSelector((state) => state.upload.id);
  const [state, setState] = useState({
    firstName: data && data.firstName ? data.firstName : "",
    lastName: data && data.lastName ? data.lastName : "",
    email: data && data.email ? data.email : "",
    ccEmails: data && data.ccEmails ? data.ccEmails : "",
    aboutMe: data && data.aboutMe ? data.aboutMe : "",
    photoId: data && data.photoId ? data.photoId : null,
  });
  const [prevFileData, setPreFileData] = useState({
    photoUrl: data && data.photoUrl ? data.photoUrl : null,
    fileName: data && data.fileName ? data.fileName : null,
  });
  const [prevFileId, setPrevFileId] = useState(
    data && data.photoId ? data.photoId : null
  );
  const prevImageId = useSelector((state) => state.concierge.prevThumbnailId);
  const [validation, setValidation] = useState({});
  const { errorSingleMessage, setErrorSingleMessage, validateFile } =
    useFileValidation();
  const [photoUrl, setPhotoUrl] = useState(null);
  const [imageDeleteList, setImageDeleteList] = useState([]);

  function handleChange(e) {
    let value = e.target.value;
    if (e.target.name === "email") {
      value = value.toLowerCase();
    }
    setState({
      ...state,
      [e.target.name]: value,
    });
  }

  const resetPrevFileData = useSelector(
    (state) => state.concierge.prevThumbnailDetails
  );

  const errorMessageCode = useSelector((state) => state.upload.errorCode);

  function handleImageCancel() {
    setState({
      ...state,
      ["photoId"]: null,
    });
    prevFileData.photoUrl = null;
    setImageDeleteList([...imageDeleteList, prevFileId]);
  }

  function handleCloseErrorAlert() {
    setPreFileData(resetPrevFileData);
    handleState();
    dispatch(setSelectedFile(null));
    setImageDeleteList([]);
    onClickNext();
  }

  function resetImage() {
    dispatch(progressPercentage(0));
    dispatch(setSelectedFile(null));
    dispatch(previewImage(null));
  }

  function handleState() {
    setState({ ...state, ["photoId"]: data && data.photoId });
  }

  const checkValidation = () => {
    const { errors, isValid } = NewConciergeFormValidations(state);
    setValidation(errors);
    return isValid;
  };

  function handleCreateButton() {
    if (checkValidation()) {
      if (
        state.firstName &&
        // && state.lastName
        state.email &&
        state.aboutMe &&
        state.ccEmails
      ) {
        resetImage();
        if (data) {
          const newData = {
            firstName: state.firstName,
            lastName: state.lastName,
            email: state.email,
            aboutMe: state.aboutMe,
            photoId: state.photoId,
            ccEmails: state.ccEmails,
          };
          imageDeleteList &&
            imageDeleteList.map((image) => {
              dispatch(fileDelete({ id: image }));
            });

          setPhotoUrl(null);
          onClickNext(newData);

          if (state.photoId !== prevImageId && prevImageId !== "") {
            const id = { id: prevImageId, isVideo: false };
            dispatch(fileDelete(id));
            dispatch(setPreThumbnailId(""));
          }

          setPreFileData({
            photoUrl: data && data.photoUrl ? data.photoUrl : null,
            fileName: data && data.fileName ? data.fileName : null,
          });
          setErrorSingleMessage("");
        } else {
          setState({});
          setPhotoUrl(null);
          setImageDeleteList([]);
          onClickNext(state);
        }
      } else {
        onClickNext();
      }
    }
  }

  if (!show) {
    return null;
  }

  const handleFileSelect = function (event) {
    const files = event.target.files;
    postFileData(files);
  };

  const handleDrag = function (event) {
    event.preventDefault();
  };

  const handleDrop = function (event) {
    event.preventDefault();
    const files = event.dataTransfer.files;
    postFileData(files);
  };

  function postFileData(files) {
    const valid = validateFile(files);
    if (valid) {
      const file = files[0];
      if (prevFileData) {
        dispatch(setPreThumbnailId(prevFileId));
        setPrevFileId(prevFileId);
        dispatch(setPrevThumbnailDetails(prevFileData));
        setPreFileData({ photoUrl: null, fileName: null });
      }
      if (file) {
        dispatch(setSelectedFile(file));
        dispatch(fileUpload(file))
          .unwrap()
          .then((res) => {
            setPhotoUrl(res[0]._id);
            setState({ ...state, ["photoId"]: res[0]._id });
            setPrevFileId(res[0]._id);
          });
      }
    }
  }

  if (errorMessageCode) {
    return (
      <ErrorAlert
        show={errorMessageCode}
        onClickHandle={handleCloseErrorAlert}
        title="Error"
        subTitle={ErrorMessages(errorMessageCode)}
      />
    );
  }

  return (
    <div className="modal">
      <div className="form-scroll">
        <div className="form-flex">
          <div
            className="user-form-modal-content"
            onClick={(e) => e.stopPropagation()}
          >
            <div className="form-header">
              <div className="header">
                {data ? "Update AC Visit Partner" : "Add AC Visit Partner"}
              </div>
              <CloseButton
                onClickhandle={() => {
                  resetImage();
                  if (data === null) {
                    setState({});
                    setValidation({});
                  }
                  setPreFileData({
                    photoUrl: data && data.photoUrl ? data.photoUrl : null,
                    fileName: data && data.fileName ? data.fileName : "file",
                  });
                  setImageDeleteList([]);
                  setErrorSingleMessage("");
                  onClickNext();
                }}
              />
            </div>

            <div className="form-container">
              <div className="form-label-input">
                <label className="form-label">
                  {ConciergeStringLabels.firstName}
                  <Required />
                </label>
                <div className="form-input-container">
                  <input
                    type="text"
                    value={state.firstName}
                    name="firstName"
                    onChange={(e) => handleChange(e)}
                    className="form-input"
                    placeholder="Input"
                    required={true}
                  ></input>
                </div>
                <ErrorMessageComponent message={validation.firstName} />
              </div>

              <div className="form-label-input">
                <label className="form-label">
                  {ConciergeStringLabels.lastName}
                  {/* <Required /> */}
                </label>
                <div className="form-input-container">
                  <input
                    className="form-input"
                    type="text"
                    value={state.lastName}
                    name="lastName"
                    onChange={(e) => handleChange(e)}
                    placeholder="Input"
                    required={true}
                  ></input>
                </div>
                <ErrorMessageComponent message={validation.lastName} />
              </div>

              <div className="form-label-input">
                <label className="form-label">
                  {ConciergeStringLabels.email}
                  <Required />
                </label>
                <div className="form-input-container">
                  <input
                    className="form-input"
                    type="text"
                    value={state.email}
                    name="email"
                    onChange={(e) => handleChange(e)}
                    placeholder="Input"
                    required={true}
                  ></input>
                </div>
                <ErrorMessageComponent message={validation.email} />
              </div>

              <div className="form-label-input">
                <label className="form-label">
                  {ConciergeStringLabels.aboutMe}
                  <Required />
                </label>
                <div className="user-form-input-container">
                  <textarea
                    className="form-input"
                    type="text"
                    value={state.aboutMe}
                    name="aboutMe"
                    onChange={(e) => handleChange(e)}
                    placeholder="Input"
                    rows={3}
                  ></textarea>
                </div>
                <ErrorMessageComponent message={validation.aboutMe} />
              </div>

              <div className="form-label-input">
                <label className="form-label">
                  {ConciergeStringLabels.ccEmails}
                </label>
                <div className="user-form-input-container">
                  <textarea
                    className="form-input"
                    type="text"
                    value={state.ccEmails}
                    name="ccEmails"
                    onChange={(e) => handleChange(e)}
                    placeholder="Input"
                    rows={3}
                  ></textarea>
                </div>
                <ErrorMessageComponent message={validation.ccEmails} />
              </div>

              <FileUploader
                previewShape="circular"
                headerName="AC Visit Partner Photo"
                handleDragOver={handleDrag}
                handleDrop={handleDrop}
                handleFileSelect={handleFileSelect}
                setPreFileData={setPreFileData}
                handleState={handleState}
                type="concierge"
              />
              {errorSingleMessage !== "" && (
                <p className="error image-error">{errorSingleMessage}</p>
              )}
              {prevFileData &&
              prevFileData.photoUrl &&
              data &&
              data.photoUrl &&
              data.fileName ? (
                <ImagePreviewWithFilename
                  photoUrl={data.photoUrl}
                  fileName={data.fileName}
                  previewShape="circular"
                  handleCancel={handleImageCancel}
                />
              ) : null}

              <div className="footer-button-container">
                <CancelButton
                  onClickhandle={() => {
                    resetImage();
                    if (data === null) {
                      setState({});
                      setValidation({});
                    }
                    setPreFileData({
                      photoUrl: data && data.photoUrl ? data.photoUrl : null,
                      fileName: data && data.fileName ? data.fileName : null,
                    });
                    setImageDeleteList([]);
                    setErrorSingleMessage("");
                    onClickNext();
                  }}
                >
                  {ConciergeStringLabels.cancel}
                </CancelButton>
                <BlackButton onClickhandle={() => handleCreateButton()}>
                  {data
                    ? ConciergeStringLabels.update
                    : ConciergeStringLabels.add}
                </BlackButton>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default AddConciergeForm;
