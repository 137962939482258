import http from '../http-common';

const GetAllSpeakers = async (id) => {
  const speakers = await http.get(`/speakers/${id}`).then((response) => {
    return response.data;
  });
  return speakers;
};

const GetAllSpeakersOfVisit = async (id) => {
  const speakers = await http.get(`/visitSpeakers/${id}`).then((response) => {
    return response.data;
  });
  return speakers;
};

const CreateNewSpeaker = async (newData, id) => {
  const auth = await http
    .post(`/createSpeaker/${id}`, {
      name: newData.name,
      designation: newData.designation,
      designationOther: newData.designationOther,
      practice: newData.practice,
      about: newData.about,
      audioId: newData.audioId === '' ? null : newData.audioId,
      videoId: newData.videoId === '' ? null : newData.videoId,
      photoId: newData.photoId,
    })
    .then((response) => {
      return response.data;
    });

  return auth;
};

const UpdateSpeaker = async (updateData, id) => {
  const updatedData = await http
    .put(`/updateSpeaker/${id}`, {
      name: updateData.name,
      designation: updateData.designation,
      designationOther: updateData.designationOther,
      practice: updateData.practice,
      about: updateData.about,
      audioId: updateData.audioId === '' ? null : updateData.audioId,
      videoId: updateData.videoId === '' ? null : updateData.videoId,
      photoId: updateData.photoId,
    })
    .then((response) => {
      return response.data;
    });
  return updatedData;
};

const DeleteSpeaker = async (id) => {
  const deletedData = await http
    .delete(`/deleteSpeaker/${id}`)
    .then((response) => {
      return response.data;
    });
  return deletedData;
};

const SpeakerListAPI = {
  GetAllSpeakers,
  CreateNewSpeaker,
  UpdateSpeaker,
  DeleteSpeaker,
  GetAllSpeakersOfVisit
};
export default SpeakerListAPI;
