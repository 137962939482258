import { useState } from "react";
import "../../../styles/screens/client-visit-screens/CreateNewVisitForm.css";
import Required from "../../common-components/Required";
import BlackButton from "../../custom-components/BlackButton";
import CancelButton from "../../custom-components/CancelButton";
import CloseButton from "../../custom-components/CloseButton";
import { ManageSubAdminStringLabels } from "../../../util/Strings";
import { NewSubAdminFormValidations } from "../../../util/Validations";
import ErrorMessageComponent from "../../common-components/ErrorMessageComponent";

function SubAdminForm({ show, onClickNext, data }) {
  const [state, setState] = useState({
    firstName: data && data.firstName ? data.firstName : null,
    lastName: data && data.lastName ? data.lastName : null,
    email: data && data.email ? data.email : null,
  });

  const [validation, setValidation] = useState({});

  function handleChange(e) {
    let value = e.target.value;
    if (e.target.name === "email") {
      value = value.toLowerCase();
    }
    setState({
      ...state,
      [e.target.name]: value,
    });
  }

  const checkValidation = () => {
    const { errors, isValid } = NewSubAdminFormValidations(state);
    setValidation(errors);
    return isValid;
  };

  function handleNextButton() {
    if (checkValidation()) {
      if (state.firstName && state.lastName && state.email) {
        const newData = state;
        if (data) {
          setState({
            firstName: newData.firstName,
            lastName: newData.lastName,
            email: newData.email,
          });
          onClickNext(newData);
        } else {
          setState({});
          onClickNext(newData);
        }
      } else {
        onClickNext();
      }
    }
  }

  if (!show) {
    return null;
  }

  return (
    <div className="modal">
      <div className="form-scroll">
        <div className="form-flex">
          <div className="modal-content" onClick={(e) => e.stopPropagation()}>
            <div className="form-header">
              <div className="header">
                {data
                  ? ManageSubAdminStringLabels.updateFormTitle
                  : ManageSubAdminStringLabels.addFormTitle}
              </div>
              <CloseButton
                onClickhandle={() => {
                  if (data === null) {
                    setState({});
                    setValidation({});
                  }
                  onClickNext();
                }}
              />
            </div>

            <div className="form-container">
              <div className="form-label-input">
                <label className="form-label">
                  {ManageSubAdminStringLabels.firstName}
                  <Required />
                </label>
                <div className="form-input-container">
                  <input
                    type="text"
                    value={state.firstName}
                    name="firstName"
                    onChange={(e) => handleChange(e)}
                    className="form-input"
                    placeholder="Input"
                    required={true}
                  />
                </div>
                <ErrorMessageComponent message={validation.firstName} />
              </div>

              <div className="form-label-input">
                <label className="form-label">
                  {ManageSubAdminStringLabels.lastName}
                  <Required />
                </label>
                <div className="form-input-container">
                  <input
                    type="text"
                    value={state.lastName}
                    name="lastName"
                    onChange={(e) => handleChange(e)}
                    className="form-input"
                    placeholder="Input"
                    required={true}
                  />
                </div>
                <ErrorMessageComponent message={validation.lastName} />
              </div>

              <div className="form-label-input">
                <label className="form-label">
                  {ManageSubAdminStringLabels.email}
                  <Required />
                </label>
                <div className="form-input-container">
                  <input
                    className="form-input"
                    type="email"
                    name="email"
                    value={state.email}
                    onChange={(e) => handleChange(e)}
                    placeholder="Input"
                    required={true}
                  />
                </div>
                {validation.email && (
                  <p className="error">{validation.email}</p>
                )}
              </div>

              <div className="footer-button-container">
                <CancelButton
                  onClickhandle={() => {
                    if (data === null) {
                      setState({});
                      setValidation({});
                    }
                    onClickNext();
                  }}
                >
                  {ManageSubAdminStringLabels.cancel}
                </CancelButton>
                <BlackButton onClickhandle={() => handleNextButton()}>
                  {data
                    ? ManageSubAdminStringLabels.update
                    : ManageSubAdminStringLabels.add}
                </BlackButton>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default SubAdminForm;
