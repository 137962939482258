import file from '../../assets/file-1453.svg';
import circleDelete from '../../assets/circle-delete.svg';
import ConfirmationDialog from './ConfirmationDialog';
import { useState } from 'react';
function ImagePreviewWithFilename({
  photoUrl,
  fileName,
  previewShape = 'rectangle',
  isDocFile = false,
  handleCancel,
  photoId,
}) {
  const [showDelete, setShowDelete] = useState(false);
  return (
    <div className="existing-file-details">
      {isDocFile && (
        <img src={file} alt="preview file" className="preview-img-doc" />
      )}
      {photoUrl && (
        <>
          <img
            className={
              previewShape === 'circular' ? 'preview-img' : 'preview-img-box'
            }
            src={photoUrl}
            alt="preview img"
          />
        </>
      )}
      <div className="file-name">{fileName}</div>
      <div className="close-image">
        <img
          src={circleDelete}
          alt="close"
          onClick={() => {
            setShowDelete(true);
          }}
        />
      </div>
      <ConfirmationDialog
        show={showDelete}
        buttonName="Delete"
        onClickHandle={(flag) => {
          if (flag) {
            photoId ? handleCancel(photoId) : handleCancel();
          }
          setShowDelete(false);
        }}
        title="Delete a Photo"
        subTitle={`Are you sure you want to delete this photo`}
      />
    </div>
  );
}

export default ImagePreviewWithFilename;
