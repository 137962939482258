export const LoginScreenStringLabels = {
  header: "AC Visitor Console Login",
  userName: "UserName",
  password: "Password",
  login: "Login",
  pwcLogin: "PWC Login",
};

export const Pass = "AdminConsoleSecretCode123";

export const ManageSubAdminStringLabels = {
  updateFormTitle: "Update Sub-Admin",
  addFormTitle: "Create New Sub-Admin",
  firstName: "First Name",
  lastName: "Last Name",
  email: "Email",
  cancel: "Cancel",
  update: "Update",
  add: "Add",
  backToVisitScreenTitle: "Back to Visit Screen",
  manageSubAdmin: "Manage sub-admin",
  backToHome: "Back to Home Screen",
};

export const UserFeedbackStringLabels = {
  userFeedback: "FeedBack List",
  email: "Email",
  username: "userName",
  rating: "Rating",
  message: "Message",
  update: "Update",
  addFeedbackFormTitle: "Create New Feedback",
  add: "Add",
  cancel: "Cancel",
  userfeedback: "User FeedBack",
  backToVisitScreenTitle: "Back to Visit Screen",
  backToHome: "Back to Home Screen",
};

export const ManageAcStringLabels = {
  userFeedback: "FeedBack List",
  email: "Email",
  username: "userName",
  rating: "Rating",
  message: "Message",
  update: "Update",
  addFeedbackFormTitle: "Create New Feedback",
  add: "Add",
  cancel: "Cancel",
  userfeedback: "User FeedBack",
  backToVisitScreenTitle: "Back to Visit Screen",
  manageAc: "Manage ACs",
};

export const AppBarStringLabels = {
  title: "Client EXP",
  logout: "Logout",
  logOutSubTitle: "Are you sure you want to Logout",
};

export const ClientVisitScreenStringLabels = {
  overview: "Overview",
  active: "Active",
  completed: "Completed",
  upcoming: "Upcoming",
  createNew: " Create New",
  name: "Name",
  startDate: "Start Date",
  endDate: "End Date",
  region: "Region",
  cancel: "Cancel",
  update: "Update",
  create: "Create",
  delete: "Delete",
  deleteTitle: "Delete the Visit",
  clientName: "Client Name",
  companyName: "Company Name",
  companyLogo: "Company Logo",
  backToHomeScreen: "Back to Home Screen",
  visits: "Visits",
};

export const ClientProfileScreenStringLabels = {
  backToVisitsScreen: "Back to Visit Screen",
  manageInvitees: "Manage Invitees",
  manageModules: "Manage Modules",
  requests: "Requests",
  concierge: "AC Visit Partner",
  collaborators: "Collaborators",
  backToHome: "Back to Home Screen",
};

export const UserScreenStringLabels = {
  userFormUpdate: "Update User",
  userFormAdd: "New User",
  name: "Name",
  email: "Email Address",
  phone: "Phone Number",
  cancel: "Cancel",
  update: "Update",
  add: "Add",
  userList: "Invitees List",
  userName: "Name",
  countryCode: "Country Code"
};

export const GeneralNotificationLabels = {
  generalNotificationFormTitle: "Add new General Notification",
  title: "Title",
  subTitle: "Sub-title",
  add: "Add",
  cancel: "Cancel",
  generalNotification: "General Notification",
};

export const ManageModulesStringLabels = {
  gateWayToPossibilities: "Gateway to Possibilities",
  titansOfIndustry: "Collaborators",
  getToKnowAC: "Get To Know AC",
  rendezvous: "Events",
  recharge: "Recharge",
  refuel: "Refuel",
  roam: "Roam",
  save: "Save",
  add: "Add",
};

export const GetToKnowAC = {
  getToKnowAC: "Get To Know AC",
  save: "Save",
  add: "Add",
  cancel: "Cancel",
  update: "Update",
  description: "Description",
};

export const UploadFilesString = {
  kb: "KB",
  dragDrop: "Drag and Drop or ",
  chooseAFile: "choose a file",
};

export const ImageGalleryString = {
  photos: "Photos",
  noPhoto: "No More Images!",
  noMedia: "Video/Audio is not added",
};

export const WelcomeScreenStringLabels = {
  updateWelcomeMessage: "Update Welcome Message",
  //updateConciergeMailid: 'Update Concierge Mail id',
  updateWelcomeVideo: "Update Welcome Video",
  replace: "Replace",
};

export const TitansOfIndustryStringLabels = {
  titansOfIndustry: "Collaborators",
  aboutMe: "About Me",
  aboutTitleContent: "About title content...",
  viewMyVideo: "View My Video",
  name: "Name",
  jobTitle: "Job Title",
  jobTitleOther: "Job Title (Others)",
  company: "Company",
  practice: "Practice",
  videoLink: "Video link",
  cancel: "Cancel",
  update: " Update",
  add: "Add",
  feedbacklist: "Feedback List",
  select: 'Select',
  unSelect: 'Remove'
};

export const OfficeListLabels = {
  pwcAcOfficeList: "PwC AC Office List",
  officeList: "Office List",
  managemodules: "Manage Module",
  officemanagecard: "Office Manage Card",
  title: "Title",
  description: "Description",
  officeLocation: "Office Location",
  officeAddress: "Office Address",
  city: "City",
  country: "Country",
  manageac: "Manage AC",
  available: "Available Now",
  unavailable: "Unavailable",
  toggleon: "Available",
  toggleoff: "Unavailable",
  updateFormTitle: "Update AC Card",
  backToOfficeListScreenTitle: "Back to Home Screen",
  name: "Name",
  latitude: "Latitude",
  longitude: "Longitude",
};
export const ConciergeStringLabels = {
  concierge: "AC Visit Partner",
  addConcierge: "Add AC Visit Partner",
  add: "Add",
  firstName: "First Name",
  lastName: "Last Name",
  email: "Email",
  aboutMe: "AC Visit Partner Message",
  photo: "Photo",
  update: "Update",
  cancel: "Cancel",
  ccEmails: "CC Emails",
};

export const RendezvousStringLabels = {
  rendezvous: "Events",
  eventName: "Event Name",
  eventType: "Event Type",
  date: "Date",
  time: "Time",
  location: "Location",
  speakers: "Collaborators",
  getReady: "Get Ready",
  startTime: "Start Time",
  description: "Description",
  endTime: "End Time",
  materials: "Materials",
  cancel: "Cancel",
  update: "Update",
  add: "Add",
};

export const RecreationStringLabels = {
  roam: "Roam",
  name: "Name",
  filter: "Filter",
  area: "Area",
  timing: "Timing",
  address: "Address",
  description: "Description",
  photo: "Photo",
  latitude: "Latitude",
  longitude: "Longitude",
  thumbnail: "Recreation Thumbnail Photo",
  photos: "Recreation Photos",
  update: "Update",
  add: "Add",
  cancel: "Cancel",
  formTitleUpdate: "Update Roam",
  formTitleAdd: "New Roam",
  openingTime: "Opening Time",
  closingTime: "Closing Time",
  closedDays: "Closed Days",
};

export const RefuelStringLabels = {
  refuel: "Refuel",
  name: "Name",
  filter: "Filter",
  type: "Type",
  price: "Price ",
  address: "Address",
  description: "Description",
  photo: "Photo",
  thumbnail: "Refuel Thumbnail Photo",
  latitude: "Latitude",
  longitude: "Longitude",
  photos: "Refuel Photos",
  update: "Update",
  add: "Add",
  cancel: "Cancel",
  formTitleUpdate: "Update Refuel",
  formTitleAdd: "Add Refuel",
  viewAllPhotos: "View all Photos",
};

export const RechargeStringLabels = {
  recharge: "Recharge",
  hotelName: "Hotel Name",
  description: "Description",
  location: "Location",
  distanceAndDuration: "Distance & Duration",
  time: "Driving time to AC",
  address: "Address",
  photo: "Photo",
  distance: "Distance",
  checkInTime: "Check-In-Time",
  checkOutTime: "Check-Out-Time",
  viewAllPhotos: "View all Photos",
  latitude: "Latitude",
  longitude: "Longitude",
  drivingTime: "Driving Time",
  thumbnail: "Hotel Thumbnail Photo",
  photos: "Recharge Photos",
  cancel: "Cancel",
  update: "Update",
  add: "Add",
  checkIn: "Check-in:",
  checkOut: "Check-out:",
};

export const ErrorMessagesStringLAbels = {
  message204: "no record found",
  message400: "Some fields are missing",
  message401: "logout and try logging in again",
  message404: "Something went wrong try again after some time",
  message500: "Something went wrong try again after some time",
  defaultmessage: "refresh and try again",
};

export const RequestScreenStringLabels = {
  cab: "Cab",
  hotel: "Hotel",
  new: "New",
  booked: "Booked",
  hotelScheduleFormTitle: "Hotel Booked Info",
  hotelName: "Hotel Name",
  checkInDate: "Check-In-Date",
  checkOutDate: "Check-Out-Date",
  cancel: "Cancel",
  book: "Book",
  userName: "User Name",
  pickUpTime: "Pick-Up Time",
  pickUpLocation: "Pick-Up Location",
  dropTime: "Drop Time",
  dropLocation: "Drop Location",
  cabDriverName: "Cab Driver Name",
  rechargeRequest: "Recharge Request",
  fromDate: "From Date",
  toDate: "To Date",
  preferences: "Preferences",
  additionalRequest: "Additional Request",
  rideInfo: "Ride Info",
  location: "Pick-up Point",
  destination: "Destination",
  add: "Add",
  date: "Date",
  time: "Time",
  contact: "Contact",
  car: "Car Info",
  shareCabInfo: "Create New Cab Request",
  selectUser: "Select User",
  contactName: "Contact Name",
  contactNo: "Contact Number",
  carName: "Car Name",
  carReg: "Car Registration Number",
  carType: "Car Type",
  update: "Update",
  overlapErrorMessage: "You have an overlapping booking against this date. Please adjust the booking date or pick-up/drop time to manage the booking.",
  updateCabInfo: "Update Cab Request",
};

export const SupportStringLabels = {
  firstname: "Riddhi",
  lastname: "Gandhi",
  email: "riddhi.gandhi@pwc.com",
  header: "Support for AC Visitor Console",
};
export const FormValidationStringLabels = {
  name: "Name is required",
  title: "Title is required",
  titleRegexCondition: "Title can only contain alpha-numeric characters",
  subTitle: "Sub-title is required",
  contactName: "Cab driver name is required",
  nameRegexCondition: "Name can only contain uppercase or lowercase characters",
  alphanumericName: "Name can only contain alpha-numeric characters",
  hotelId: "Select the hotel",
  contactNumber: "Contact number is required",
  phoneNumber: "Phone number is required",
  carName: "Car name is required",
  carRegNumber: "Car registration number is required",
  carType: "Car type is required",
  description: "Description is required",
  email: "Email is required",
  emailRegexCondition: "Please enter valid email address",
  location: "Location is required",
  address: "Address is required",
  latitude: "Latitude is required",
  longitude: "Longitude is required",
  invalidLatitude: "Please enter valid latitude",
  invalidLongitude: "Please enter valid Longitude",
  maxLength200: "Max length of only 200 characters are allowed",
  filter: "Filter is required",
  maxLength50: "Max length of only 50 characters are allowed",
  charNumRegex:
    "can only contain uppercase & lowercase letters, digits from 0-9",
  onlyDigits: "can only contain digits 0-9",
  minMaxLengthPhoneNo: "can be max of 10 digits and min of 9 digits",
  minMaxCarRegNo: "can have max of 11 and min of 9 characters",
  maxLength100: "Max length of only 100 characters are allowed",
  specialCharsRegexCondition: "can only contain uppercase, lowercase letters and digits from 0-9",
  maxLength500: "Max length of only 500 characters are allowed",
  maxLength20: "Max length of only 20 characters are allowed",
  startDate: "Start date is required",
  endDate: "End date is required",
  region: "Region is required",
  maxLength32: "Max length of 32 characters are only allowed",
  eventDate: "Event date is required",
  drivingTime: "Driving Time is a required",
  eventStartTime: "Event start time is required",
  openingTime: "Roam opening time is required",
  closingTime: "Roam closing time is required",
  closedDays: "Roam closed Days is required",
  eventEndTime: "Event end time is required",
  checkInTime: "Checkin time is required",
  checkOutTime: "Checkout time is required",
  checkInDate: "Checkin date is required",
  checkOutDate: "Checkout date is required",
  invalidEndTime: "Please enter valid event end time",
  invalidEndDate: "Please enter valid event end date",
  invalidVisitEndDate: "Please enter valid visit end date",
  conciergeMail: "email is a required",
  designation: "Designation is required",
  designationOther: "Designation(Others) is required",
  // companyName: 'Company name is required',
  invalidCloseTime: "Please enter valid closing time",
  practice: "Practice is required",
  aboutMe: "About me is required",
  descriptionRequired: "Description is required",
  eventType: "Event type is required",
  speaker: "Collaborator required. Please select from the dropdown below",
  type: "Type is required",
  price: "Price is required",
  invalidPrice: "Please enter valid price value",
  area: "Area is required",
  timing: "Timing is required",
  checkInDateGreater: "Check In Date cannot be before Check Out Date",
  welcomeMessage: "Welcome Message is required",
  maxLength250: "Max length of only 250 characters are allowed",
  userId: "Select the user its required field",
  fromLocation: "Source is required",
  toLocation: "Destination is required",
  date: "Booking date is required",
  pickupTime: "Pick-up time is required",
  dropTime: "Drop time is required",
  user: "Select an user",
  pickupLocation:"Pick-up location is required",
  dropLocation:"Drop location is required",
  mediaRequired: "Video/audio is required for collaborator",
  //conciergeMail: 'Concierge Mail is required'
};
