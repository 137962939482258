import { Routes, Route, useNavigate } from "react-router-dom";
import "./App.css";
import AppBar from "./components/common-components/AppBar";
import ClientVisitScreen from "./components/screens/client-visit-screens/ClientVisitScreen";
import ClientProfileScreen from "./components/screens/client-visit-details/ClientProfileScreen";
import LoginScreen from "./components/login/LoginScreen";
import UsersList from "./components/screens/client-visit-details/manage-users/UsersList";
import ManageModules from "./components/screens/client-visit-details/manage-module/ManageModules";
import SpeakersList from "./components/screens/client-visit-details/manage-module/SpeakersList";
import Request from "./components/screens/client-visit-details/request/Request";
import WelcomePage from "./components/screens/client-visit-details/manage-module/WelcomePage";
import EventsList from "./components/screens/client-visit-details/manage-module/EventsList";
import HotelList from "./components/screens/client-visit-details/manage-module/HotelList";
import CabsList from "./components/screens/client-visit-details/request/CabsList";
import Refuel from "./components/screens/client-visit-details/manage-module/RefuelList";
import Recreation from "./components/screens/client-visit-details/manage-module/Recreation";
import { useDispatch, useSelector } from "react-redux";
import { userLogin } from "./slices/LoginSlice";
import SubAdminList from "./components/screens/manage-sub-admin/SubAdminList";
import LoginPWC from "./components/login/LoginPWC";
import ConciergeList from "./components/screens/client-visit-details/concierge/ConciergeList";
import GetToKnowACList from './components/screens/client-visit-details/manage-module/GetToKnowACList';
import Support from "./components/screens/support-terms-privacy/Support";
import Privacy from "./components/screens/support-terms-privacy/Privacy";
import Terms from "./components/screens/support-terms-privacy/Terms";
import UserFeedbackList from "./components/screens/user-feedback.jsx/UserFeedbackList";
import OfficeList from "./components/screens/office-list/OfficeList";
import ManageAcList from "./components/screens/manage-ac/ManageAcList";
import OfficeTabs from "./components/screens/office-home-tabs/OfficeTabs";
import VisitSpeakersList from "./components/screens/client-visit-details/manage-module/VisitSpeakersList";
function App() {
  //start: dev login
  //const isAuthenticated = useSelector((state) => state.login.isAuthenticated);
  // end: dev login
  const isAuth = useSelector((state) => state.login.isAuth);
  const name = useSelector((state) => state.login.name);
  const dispatch = useDispatch();
  const navigate = useNavigate();

  //start: dev login
  // function onLoginHandle(loginDetails) {
  //   dispatch(userLogin(loginDetails)).then((response) => {
  //     //navigate('/visits', { replace: true });
  //     navigate("/home", { replace: true });
  //   });
  // }
  //end: dev login

  // start: PWC login
  if (!isAuth) {
    return (
      <div>
        <LoginScreen />
        <Routes>
          <Route path='/support' element={<Support />} />
          <Route path='/privacy' element={<Privacy />} />
          <Route path='/terms' element={<Terms />} />
        </Routes>
      </div>
    );
  }
  // end: PWC login

  //start: dev login
  // if (!isAuth && isAuthenticated === null) {
  //   return <LoginScreen onLogin={onLoginHandle} />;
  // } else if (!isAuthenticated && isAuth === null) {
  //   return <LoginScreen onLogin={onLoginHandle} />;
  // }
  //end: dev login
  else {
    return (
      <div className='App'>
        <AppBar />
        <Routes>
          <Route path='/login-identity' element={<LoginPWC />} />
          <Route path='/visits' element={<ClientVisitScreen />} />
          <Route path='/client/:id' element={<ClientProfileScreen />}>
            <Route path='manage-users' element={<UsersList />} />
            <Route path='manage-modules' element={<ManageModules />}>
              <Route path='welcome' element={<WelcomePage />} />
              {/* ac start */}
              <Route path="ac" element={<GetToKnowACList />} />
              {/* ac end */}
              <Route path='collaborators' element={<SpeakersList />} />
              <Route path='events' element={<EventsList />} />
              <Route path='hotels' element={<HotelList />} />
              <Route path='cabs' element={<CabsList isRequest={false} />} />
              <Route path='refuel' element={<Refuel />} />
              <Route path='roam' element={<Recreation />} />
            </Route>
            <Route path='requests' element={<Request />} />
            <Route path='visit-speakers' element={<VisitSpeakersList />} />
            <Route path='concierge' element={<ConciergeList />} />
          </Route>
          <Route path='/manage-sub-admin' element={<SubAdminList />} />
          <Route path='/user-feedback' element={<UserFeedbackList />} />
          <Route path='/home' element={<OfficeList />} />
          <Route path='/manage-ac' element={<ManageAcList />} />
          <Route path='/officehome' element={<OfficeTabs />} />
        </Routes>
      </div>
    );
  }
}

export default App;
