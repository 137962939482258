import axios from 'axios';
import { DecryptData } from './util/DataEncryption';

const tokenStr = sessionStorage.getItem('token')
  ? DecryptData(sessionStorage.getItem('token'))
  : null;
const baseURL = process.env.REACT_APP_BASE_URL;

export default axios.create({
  baseURL: baseURL,
  headers: {
    Authorization: `Bearer ${tokenStr}`,
    'Content-Type': 'application/json; charset=utf-8',
    'Cache-Control': 'no-cache, no-store, must-revalidate',
    'Content-Security-Policy': `default-src 'self' ${baseURL}; object-src 'none'; frameancestors 'self'; form-action 'self'`,
    'Strict-Transport-Security': 'max-age=63072000; includeSubdomains;preload',
  },
});
