import { RefuelStringLabels } from '../../../../util/Strings';
import RefuelListTable from './RefuelListTable';

function Refuel() {
  return (
    <div className="events-list-container">
      <div className="event-list-heading">{RefuelStringLabels.refuel}</div>
      <div className="events-list-content">
        <RefuelListTable />
      </div>
    </div>
  );
}

export default Refuel;
