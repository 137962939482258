import { useSelector } from 'react-redux';
import circleDelete from '../../assets/circle-delete.svg';
import '../../styles/common-components/FileDetailsWithUploadProgress.css';
import { FileNameFormatter } from '../../util/StringFormatter';
import { UploadFilesString } from '../../util/Strings';

function FileDetailsWithUploadProgressForVideo({
  fileSelected,
  handleCancelUpload,
  progressPer,
  fromSpeakers,
}) {
  const showFileUploadProgress = useSelector(
    (state) => state.welcomePage.showVideoProgressBar
  );
  return (
    <div className="file-details-with-progress">
      <div className="file-details-row">
        <div className="file-name">{FileNameFormatter(fileSelected.name)}</div>
        <div className="file-details">
          <div className="file-size">
            {Math.floor(fileSelected.size / 1024)} {UploadFilesString.kb}
          </div>
          <div className="close-image">
            <img src={circleDelete} alt="close" onClick={handleCancelUpload} />
          </div>
        </div>
      </div>
      {fromSpeakers === true ? (
        <div className="progress-bar">
          <div
            className="progress-bar-fill"
            style={{ width: `${progressPer}%` }}
          ></div>
        </div>
      ) : (
        showFileUploadProgress && (
          <div className="progress-bar">
            <div
              className="progress-bar-fill"
              style={{ width: `${progressPer}%` }}
            ></div>
          </div>
        )
      )}
    </div>
  );
}

export default FileDetailsWithUploadProgressForVideo;
