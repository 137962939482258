import "../../../styles/screens/client-visit-details/ProfileCard.css";
import "../../../styles/screens/client-visit-details/Feedback.css";
import { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import "../office-list/OfficeList.css";
import "../office-list/OfficeCard.css";
import "././ManageAcCards.css";
import del from "../../../assets/delete.svg";
import { deleteOffice, updateOffices } from "../../../slices/ManageAcListSlice";
import { OfficeListLabels } from "../../../util/Strings";
import ManageAcForm from "./ManageAcForm";
import ConfirmationDialog from "../../common-components/ConfirmationDialog";

function ManageAcCard({ dataItem }) {
  const [showDelete, setShowDelete] = useState(false);
  const [showUpdateForm, setShowUpdateForm] = useState(false);
  const dispatch = useDispatch();
  function updateSpeakerData(newData) {
    if (newData) {
      const dataWithId = { id: dataItem._id, newData: newData };
      dispatch(updateOffices(dataWithId));
    }
    setShowUpdateForm(false);
  }
  return (
    <div
      className={
        dataItem.available
          ? "office-card-container"
          : "office-card-disable-container"
      }
      onClick={() => {
        setShowUpdateForm(true);
      }}
    >
      <img
        src= {dataItem.photoUrl}
        alt='no feedback list'
        className='office-profile-container'
      />
      <div className='office-description'>
        <div className={dataItem.available ? "lines" : "line-red"}></div>
        <div className='user-list-icons'>
          <div className={dataItem.available ? "available" : "unavailable"}>
            {dataItem.available
              ? OfficeListLabels.available
              : OfficeListLabels.unavailable}
          </div>
          <img
            src={del}
            alt='delete'
            className='icon-middles'
            onClick={(e) => {
              e.stopPropagation();
              setShowDelete(true);
            }}
          />
        </div>
        <div
          style={{
            color: " #303030",
            fontSize: "24px",
            fontStyle: "normal",
            fontWeight: "500",
            overflow: "hidden",
            textOverflow: "ellipsis",
            display: "-webkit-box",
            lineClamp: 5,
            WebkitLineClamp: 5,
            WebkitBoxOrient: "vertical",
            fontfamily: "Helvetica Neue",
            paddingLeft: "8px",
            marginTop: "5px",
            marginBottom: "5px",
          }}
        >
          {dataItem.name}
        </div>
        <div className='office-addresses'> {dataItem.address}</div>
        <div
          style={{
            overflow: "hidden",
            textOverflow: "ellipsis",
            display: "-webkit-box",
            lineClamp: 5,
            WebkitLineClamp: 5,
            WebkitBoxOrient: "vertical",
            fontSize: 14,
            fontfamily: "Helvetica Neue",
            paddingLeft: "8px",
            marginTop: "5px",
            marginBottom: "5px",
            color: " #303030",
            lineHeight: "20px",
          }}
        ></div>
        <ManageAcForm
          show={showUpdateForm}
          onClickNext={updateSpeakerData}
          data={dataItem}
        />
      </div>
      <ConfirmationDialog
        show={showDelete}
        buttonName='Delete'
        onClickHandle={(flag) => {
          if (flag) {
            dispatch(deleteOffice(dataItem._id));
          }
          setShowDelete(false);
        }}
        title='Delete this AC Card'
        subTitle={`Are you sure you want to delete ${dataItem.name} record`}
      />
    </div>
  );
}

export default ManageAcCard;
