import { useDispatch, useSelector } from "react-redux";
import noRecreation from "../../../../assets/no-recreation.svg";
import { useEffect, useState } from "react";
import {
  getAllRecreationData,
  resetErrorAlert,
} from "../../../../slices/RecreationSlice";
import MyGallery from "../../../common-components/ImageGalleryComponent";
import Loader from "../../../common-components/Loader";
import RecreationTableBody from "./RecreationTableBody";
import { RecreationStringLabels } from "../../../../util/Strings";
import ErrorAlert from "../../../common-components/ErrorAlert";
import ErrorMessages from "../../../../util/ErrorMessages";

function RecreationTable() {
  const dispatch = useDispatch();
  const id = useSelector((state) => state.visits.selectedVisitId);
  const isVisitCompleted = useSelector(
    (state) => state.visits.selectedVisitIsCompleted
  );
  const selectedOfficeId = useSelector(
    (state) => state.officelist.selectedOfficeId
  );
  const data = useSelector((state) => state.recreation.recreationData);
  const isLoading = useSelector((state) => state.recreation.isLoading);
  const errorMessageCode = useSelector((state) => state.recreation.errorCode);

  function getRecreationData() {
    dispatch(getAllRecreationData(selectedOfficeId));
  }

  function handleCloseErrorAlert() {
    dispatch(resetErrorAlert(false));
  }

  useEffect(() => {
    getRecreationData();
  }, []);

  if (isLoading) {
    return <Loader />;
  }

  if (errorMessageCode) {
    return (
      <ErrorAlert
        show={errorMessageCode}
        onClickHandle={handleCloseErrorAlert}
        title='Error'
        subTitle={ErrorMessages(errorMessageCode)}
      />
    );
  }
  return (
    <>
      {data && data.length > 0 ? (
        <table>
          <thead>
            <tr className='table-header'>
              <th>{RecreationStringLabels.name}</th>
              <th>{RecreationStringLabels.filter}</th>
              <th>{RecreationStringLabels.area}</th>
              <th>{RecreationStringLabels.timing}</th>
              <th className='address'>{RecreationStringLabels.address}</th>
              <th className='description-hotel'>
                {RecreationStringLabels.description}
              </th>
              <th>{RecreationStringLabels.photo}</th>
              {isVisitCompleted ? null : <th> </th>}
            </tr>
          </thead>
          {data.map((dataItem) => {
            return <RecreationTableBody dataItem={dataItem} />;
          })}
        </table>
      ) : (
        <img src={noRecreation} alt='no users' className='no-img' />
      )}
    </>
  );
}

export default RecreationTable;
