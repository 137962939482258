import { useDispatch, useSelector } from "react-redux";
import "../../../../styles/screens/client-visit-details/manage-module/SpeakersList.css";
import SpeakerCard from "../SpeakerCard";
import noTitanOfIndustry from "../../../../assets/no-titan-of-industry.svg";
import Loader from "../../../common-components/Loader";
import { useEffect, useState } from "react";
import {
  getAllSpeakers,
  resetErrorAlert,
} from "../../../../slices/SpeakerSlice";
import { TitansOfIndustryStringLabels } from "../../../../util/Strings";
import ErrorAlert from "../../../common-components/ErrorAlert";
import ErrorMessages from "../../../../util/ErrorMessages";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faMagnifyingGlass } from "@fortawesome/free-solid-svg-icons";

function SpeakersList() {
  const data = useSelector((state) => state.speakers.speakerData);
  const isLoading = useSelector((state) => state.speakers.isLoading);
  const id = useSelector((state) => state.visits.selectedVisitId);
  const selectedOfficeId = useSelector(
    (state) => state.officelist.selectedOfficeId
  );
  const errorMessageCode = useSelector((state) => state.speakers.errorCode);
  const dispatch = useDispatch();
  const [input, setInput] = useState("");

  function getSpeakersData() {
    dispatch(getAllSpeakers(selectedOfficeId));
  }

  function handleCloseErrorAlert() {
    dispatch(resetErrorAlert(false));
  }

  useEffect(() => {
    getSpeakersData();
  }, []);

  if (isLoading) {
    return <Loader />;
  }
  if (errorMessageCode) {
    return (
      <ErrorAlert
        show={errorMessageCode}
        onClickHandle={handleCloseErrorAlert}
        title="Error"
        subTitle={ErrorMessages(errorMessageCode)}
      />
    );
  }

  return (
    <div className="speakers-list-container">
      <div className="speakers-list-heading">
        {/* Speakers List */}
        {TitansOfIndustryStringLabels.titansOfIndustry}
        <div className="speaker-searchContainer">
          <div className="speaker-searchIconText">
            <FontAwesomeIcon
              size="2xs"
              icon={faMagnifyingGlass}
              color="#D1D1D1"
            />
            <input
              className="search"
              icon="search"
              placeholder="Search"
              value={input}
              onChange={(e) => setInput(e.target.value)}
            ></input>
          </div>
        </div>
      </div>
      <div className="speakers-list-body">
        {data &&
          data
            .filter((dataItem) => {
              return input.toLowerCase() === ""
                ? dataItem
                : dataItem.name.toLowerCase().includes(input);
            })
            .map((item) => {
              return <SpeakerCard data={item} key={item._id} />;
            })}
      </div>
      {data.length === 0 && (
        <img
          src={noTitanOfIndustry}
          alt="no titan of industry"
          className="no-img"
        />
      )}
    </div>
  );
}

export default SpeakersList;
