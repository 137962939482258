import CabListAPI from '../serviceAPI/CabListAPI';
import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';

const initialState = {
  // cabRequestData: [],
  cabSchduledData: [],
  selectedCabId: null,
  isLoading: false,
  errorCode: null,
  overlapError: null,
  overlapUpdateError: null,
  errorMessage: null,
};

// export const getAllCabRequest = createAsyncThunk(
//   'getAllCabRequest',
//   async (id, { rejectWithValue }) => {
//     try {
//       const res = await CabListAPI.GetAllCabRequests(id);
//       return res;
//     } catch (error) {
//       return rejectWithValue(error.response.status);
//     }
//   }
// );

export const getAllCabScheduled = createAsyncThunk(
  'getAllCabScheduled',
  async (id, { rejectWithValue }) => {
    try {
      const res = await CabListAPI.GetAllScheduledCab(id);
      return res;
    } catch (error) {
      return rejectWithValue(error.response.status);
    }
  }
);

export const scheduleCab = createAsyncThunk(
  'scheduleCab',
  async ({ newData, id }, { rejectWithValue }) => {
    try {
      const res = await CabListAPI.ScheduleCab(newData, id);
      return res;
    } catch (error) {
      return rejectWithValue(error.response.status);
    }
  }
);

export const updateCab = createAsyncThunk(
  'updateCab',
  async ({ newData, id }, { rejectWithValue }) => {
    try {
      const res = await CabListAPI.UpdateCab(newData, id);
      return res;
    } catch (error) {
      return rejectWithValue(error.response.status);
    }
  }
);

export const deleteCab = createAsyncThunk(
  'deleteCab',
  async (id, { rejectWithValue }) => {
    try {
      const res = await CabListAPI.DeleteCab(id);
      return res;
    } catch (error) {
      return rejectWithValue(error.response.status);
    }
  }
);

const CabSlice = createSlice({
  name: 'cabs',
  initialState,
  reducers: {
    updateselectedCabId: (state, action) => {
      state.selectedCabId = action.payload;
    },
    resetErrorAlert: (state, action) => {
      state.errorCode = null;
    },
  },
  extraReducers: {
    // [getAllCabRequest.pending]: (state) => {
    //   state.isLoading = true;
    // },
    // [getAllCabRequest.fulfilled]: (state, action) => {
    //   state.isLoading = false;
    //   const data = [...action.payload];
    //   const filteredData = data.filter((req) => req.isBooked === false);
    //   state.cabRequestData = filteredData;
    // },
    // [getAllCabRequest.rejected]: (state, action) => {
    //   state.isLoading = false;
    //   state.errorCode = action.payload;
    // },
    [getAllCabScheduled.pending]: (state) => {
      state.isLoading = true;
    },
    [getAllCabScheduled.fulfilled]: (state, action) => {
      state.isLoading = false;
      state.cabSchduledData = [...action.payload];
    },
    [getAllCabScheduled.rejected]: (state, action) => {
      state.isLoading = false;
      state.errorCode = action.payload;
    },
    [scheduleCab.pending]: (state) => {
      state.isLoading = true;
      state.overlapError = null;
    },
    [scheduleCab.fulfilled]: (state, action) => {
      // const index = state.cabRequestData
      //   .map((item) => item._id)
      //   .indexOf(state.selectedCabId);
      //state.cabRequestData.splice(index, 1);
      state.isLoading = false;
      if ( action.payload.status === false ) {
        state.overlapError = true;
        state.errorMessage = action.payload.message;
      }else{
        state.overlapError = false;
        state.cabSchduledData = [...action.payload];
      } 
    },
    [scheduleCab.rejected]: (state, action) => {
      state.errorCode = action.payload;     
    },
    [updateCab.pending]: (state) => {
      // state.isLoading = true;
      state.overlapUpdateError = null;
    },
    [updateCab.fulfilled]: (state, action) => {
      state.isLoading = false;
      if ( action.payload.status === false ) {
        state.overlapUpdateError = true;
        state.errorMessage = action.payload.message;
      }else{
        state.overlapUpdateError = false;
        state.cabSchduledData = [...action.payload];
      }
    },
    [updateCab.rejected]: (state, action) => {
      state.errorCode = action.payload;
    },
    [deleteCab.rejected]: (state, action) => {
      state.errorCode = action.payload;
    },
  },
});

export const { updateselectedCabId, resetErrorAlert } = CabSlice.actions;
export default CabSlice.reducer;
