import http from '../http-common';
import { EncryptData } from '../util/DataEncryption';

const baseURL = process.env.REACT_APP_BASE_URL;
//start: dev login
// const AuthenticateUserLogin = async (userName, password) => {
//   const auth = await http
//     .post(`/login`, { email: userName, password: password })
//     .then((response) => {
//       sessionStorage.setItem('token', EncryptData(response.data.accessToken));
//       sessionStorage.setItem('name', EncryptData(userName[0]));
//       sessionStorage.setItem(
//         'isSubAdmin',
//         EncryptData(response.data.isSubAdmin)
//       );
//       const res = response.data
//         ? { response: true, errorText: null, name: userName }
//         : { response: false, errorText: null };
//       return res;
//     })
//     .catch((error) => {
//       const res = { response: false, errorText: error };
//       return res;
//     });

//   return auth;
// };
//end: dev login
const ConsoleLogout = async () => {
  const auth = await http
    .post(`/admin/logout`)
    .then((response) => {
      sessionStorage.clear();
      return response.data;
    })
    .catch((error) => {
      const res = { response: false, errorText: error };
      return res;
    });

  return auth;
};


async function PwcLogin() {
  window.location = `${baseURL}/openAMAuthenticate`;
  sessionStorage.setItem('isAuth', EncryptData(true));
}
//start: dev login
//const loginAPI = { PwcLogin, AuthenticateUserLogin, ConsoleLogout };
//end: dev login

//start: PWC login
const loginAPI = { PwcLogin, ConsoleLogout};
//end: PWC login

export default loginAPI;
