import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPlus } from "@fortawesome/free-solid-svg-icons";
import { useEffect, useState } from "react";
import "../../../styles/screens/client-visit-screens/ClientVisitScreen.css";
import ClientVisitCardGrid from "./ClientVisitCardGrid";
import CreateNewVisitForm from "./CreateNewVisitForm";
import Button from "../../custom-components/Button";
import { useDispatch, useSelector } from "react-redux";
import "../../../styles/screens/client-visit-details/manage-module/SpeakersList.css";
import {
  createNewVisit,
  getAllVisits,
  resetErrorAlert,
} from "../../../slices/VisitSlice";

import { FilterByDate } from "../../../util/FilterDate";
import { ClientVisitScreenStringLabels } from "../../../util/Strings";
import Loader from "../../common-components/Loader";
import ErrorAlert from "../../common-components/ErrorAlert";
import ErrorMessages from "../../../util/ErrorMessages";

function ClientVisitScreen() {
  const [state, setState] = useState("Active");
  const [showCreateNewVisitForm, setShowCreateNewVisitForm] = useState(false);
  const isSubAdmin = useSelector((state) => state.login.isSubAdmin);
  const data = useSelector((state) => state.visits.visitData);
  const isLoading = useSelector((state) => state.visits.isLoading);
  const errorMessageCode = useSelector((state) => state.visits.errorCode);
  const selectedOfficeId = useSelector(
    (state) => state.officelist.selectedOfficeId
  );
  const dispatch = useDispatch();
  function getVisitsData() {
    dispatch(getAllVisits(selectedOfficeId));
  }
  useEffect(() => {
    getVisitsData();
  }, []);

  function handleOnClickNextForm(newData, isUpdate) {
    if (newData && !isUpdate) {
      let newStartDate = newData.startDate;
      let newEndDate = newData.endDate;
      newData.startDate = newStartDate;
      newData.endDate = newEndDate;
      newData.officeId = selectedOfficeId;
      dispatch(createNewVisit(newData));
    }
    setShowCreateNewVisitForm(false);
  }

  function handleCloseErrorAlert() {
    dispatch(resetErrorAlert(false));
  }

  if (isLoading) {
    return <Loader />;
  }

  if (errorMessageCode) {
    return (
      <ErrorAlert
        show={errorMessageCode}
        onClickHandle={handleCloseErrorAlert}
        title="Error"
        subTitle={ErrorMessages(errorMessageCode)}
      />
    );
  }

  return (
    <div className="clientVisit">
      <div className="clientVisitTabContainer ">
        <button
          className={state === "Active" ? "tablinksActive" : "tablinks"}
          onClick={() => {
            setState("Active");
          }}
        >
          {ClientVisitScreenStringLabels.active}
        </button>
        <button
          className={state === "Completed" ? "tablinksActive" : "tablinks"}
          onClick={() => {
            setState("Completed");
          }}
        >
          {ClientVisitScreenStringLabels.completed}
        </button>
        <button
          className={state === "Upcoming" ? "tablinksActive" : "tablinks"}
          onClick={() => {
            setState("Upcoming");
          }}
        >
          {ClientVisitScreenStringLabels.upcoming}
        </button>
      </div>

      <div className="searchCreateNew">
        {/* <div className="searchContainer">
          <div className="searchIconText">
            <FontAwesomeIcon icon={faMagnifyingGlass} color="#D1D1D1" />
            <input
              className="search"
              icon="search"
              placeholder="Search"
            ></input>
          </div>
        </div> */}

        <div>
          <Button
            onClickhandle={() => setShowCreateNewVisitForm(true)}
            disabled={isSubAdmin === true ? true : false}
          >
            <FontAwesomeIcon size={"sm"} icon={faPlus} />
            {ClientVisitScreenStringLabels.createNew}
          </Button>
          <CreateNewVisitForm
            show={showCreateNewVisitForm}
            onClickNext={handleOnClickNextForm}
            data={null}
          />
        </div>
      </div>
      <div>
        <ClientVisitCardGrid
          data={data ? FilterByDate(data, state) : data}
          state={state}
        />
      </div>
    </div>
  );
}

export default ClientVisitScreen;
