import CloseButton from '../custom-components/CloseButton';
import { ImageGalleryString } from '../../util/Strings';

function MediaPlayer({ show, onCloseHandle, title, mediaUrl, type }) {
  if (!show) {
  return null;
  }
  return (
    <div className="modal">
      <div className="modal-content" onClick={(e) => e.stopPropagation()}>
        <div className="form-header">
          <div className="header">{title}</div>
          <CloseButton onClickhandle={() => onCloseHandle()} />
        </div>
        <div className="form-container">
          {type === 'audio' ? (
            <div className="video-container">
              <audio controls>
                <source src={mediaUrl} />
              </audio>
            </div>
          ) : type === 'video' ? (
            <div className="video-container">
              <video className= "video-player" src={mediaUrl} controls />
            </div>
          ) : (
            <div className="form-container">
              <div
                style={{
                  display: 'flex',
                  marginTop: 20,
                  paddingBottom: 30,
                  fontSize: 16,
                  fontWeight: 400,
                  color: '#474747',
                }}
              >
                {ImageGalleryString.noMedia}
              </div>
            </div>
          )}
        </div>
      </div>
    </div>
  );
}

export default MediaPlayer;
