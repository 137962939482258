import { useState, useEffect } from 'react';
import '../../../../styles/screens/client-visit-details/manage-module/ManageModules.css';
import '../../../../styles/screens/client-visit-details/request/request.css';
import CabsList from './CabsList';
import CabScheduleForm from './CabScheduleForm';
// import HotelRequestTable from './HotelRequestTable';
// import HotelScheduleForm from './HotelScheduleForm';
// import HotelScheduledTable from './HotelScheduledTable';
// import {
//   createNewHotelSchedule,
//   getAllHotelRequestList,
//   getAllHotelScheduledList,
// } from '../../../../slices/HotelSlice';
import { useDispatch, useSelector } from 'react-redux';
import { getAllCabScheduled, scheduleCab } from '../../../../slices/CabSlice';
import { RequestScreenStringLabels } from '../../../../util/Strings';
import CabListTable from './CabListTable';

function Request() {
  // const [state, setState] = useState('cab');
  // const [status, setStatus] = useState('new');
  const [showCabForm, setShowCabForm] = useState(false);
  //const [showHotelForm, setShowHotelForm] = useState(false);
  //const [ids, setIds] = useState(null);
  //const [hotelOptions, setHotelOptions] = useState(null);
  const dispatch = useDispatch();
  const id = useSelector((state) => state.visits.selectedVisitId);
  //const selectedId = useSelector((state) => state.cabs.selectedCabId);
  const visit = useSelector((state) => state.visits);
  const overlapError = useSelector((state) => state.cabs.overlapError);
  // const handleChange = (e) => {
  //   console.log(e.target.value);
  //   setStatus(e.target.value);
  // };

  // function getCabRequestDetails() {
  //   dispatch(getAllCabRequest(id));
  // }

  function getCabScheduledDetails() {
    dispatch(getAllCabScheduled(id));
  }

  // async function getHotelRequestDetails() {
  //   dispatch(getAllHotelRequestList(id));
  // }

  // async function getHotelScheduledDetails() {
  //   dispatch(getAllHotelScheduledList(id));
  // }

  useEffect(() => {
    // getCabRequestDetails();
    // getHotelRequestDetails();
    // getHotelScheduledDetails();
    getCabScheduledDetails();
  }, []);

  useEffect(() => {
    if (overlapError === false) {
      setShowCabForm(false);
    }
  }, [overlapError]);

  // function handleBookCab(cabId) {
  //   setShowCabForm(true);
  // }

  // function handleBookHotel(ids, hotelOptions) {
  //   setShowHotelForm(true);
  //   console.log(hotelOptions);
  //   const hotels = hotelOptions.filter((hotel) => hotel !== null);
  //   setHotelOptions(hotels);
  //   setIds(ids);
  // }

  function handleScheduleCab(newData) {
    if (newData) {
      const validFieldsData = {};
      Object.keys(newData).forEach(eachKey => {
        if (newData[eachKey] !== '') {
          validFieldsData[eachKey] = newData[eachKey]
        }
      });
      const data = { id: id, newData: validFieldsData };
      dispatch(scheduleCab(data));   
    } else {
      setShowCabForm(false);
    }
  }

  // async function handleScheduleHotel(newData) {
  //   if (newData && ids) {
  //     newData.requestedId = ids.requestedId;
  //     newData.userId = ids.userId;
  //     const hotel = {
  //       id: id,
  //       newData: newData,
  //     };
  //     dispatch(createNewHotelSchedule(hotel));
  //   }
  //   setShowHotelForm(false);
  // }
  return (
    //<div className="manage-modules-container">
    <>
      <div className="users-list-container">
        <div className="users-list-header-container">
          <div className="event-list-heading">
            {RequestScreenStringLabels.rideInfo}
          </div>
          <button
            onClick={() => setShowCabForm(true)}
            className='button-style'
          >
            {RequestScreenStringLabels.add}
          </button>
        </div>
        {/* <div className="manage-modules-main-container"> */}
        {/* <div className="request-modules-header-container"> */}
        {/* <div className="manage-modules-tabcontainer">
            <button
              className={
                state === 'cab'
                  ? 'manage-modules-tablinks-active'
                  : 'manage-modules-tablinks'
              }
              onClick={() => {
                setState('cab');
              }}
            >
              {RequestScreenStringLabels.cab}
            </button>
            <button
              className={
                state === 'hotel'
                  ? 'manage-modules-tablinks-active'
                  : 'manage-modules-tablinks'
              }
              onClick={() => {
                setState('hotel');
                setShowCabForm(false);
              }}
            >
              {RequestScreenStringLabels.hotel}
            </button>
          </div> */}
        {/* <form>
            <span className="radio-btn">
              <input
                type="radio"
                value="new"
                id="new"
                onChange={handleChange}
                name="status"
                checked={status === 'new'}
              />
              <label for="new" className="label-style">
                {RequestScreenStringLabels.new}
              </label>
            </span>
            <span className="radio-btn">
              <input
                type="radio"
                value="booked"
                id="booked"
                onChange={handleChange}
                name="status"
              />
              <label for="booked" className="label-style">
                {RequestScreenStringLabels.booked}
              </label>
            </span>
          </form> */}
        {/* </div> */}

        {/* {state === 'cab' && status === 'new' ? (
          <CabsList handleBook={handleBookCab} isRequest={true} />
        ) : state === 'cab' && status === 'booked' ? (
          <CabsList isRequest={false} />
        ) : 
        // state === 'hotel' && status === 'new' ? (
        //   <HotelRequestTable handleBook={handleBookHotel} />
        // ) : state === 'hotel' && status === 'booked' ? (
        //   <HotelScheduledTable />
        // ) : 
        null} */}

        {showCabForm && (
          // && status === 'new'

          <CabScheduleForm
            show={showCabForm}
            handleSubmit={handleScheduleCab}
          />
        )}
        <div className="events-list-content">
          <CabListTable isRequest={false} />
          {/* <CabsList isRequest={false} /></div> */}
        </div>
        {/* {showHotelForm && status === 'new' && (
        <HotelScheduleForm
          show={showHotelForm}
          onClickSubmit={handleScheduleHotel}
          hotels={hotelOptions}
        />
      )} */}
      </div>
    </>
  );
}

export default Request;
