import { useState } from "react";
import "../../../../styles/screens/client-visit-details/manage-users/AddNewUserForm.css";
import Required from "../../../common-components/Required";
import CloseButton from "../../../custom-components/CloseButton";
import CancelButton from "../../../custom-components/CancelButton";
import BlackButton from "../../../custom-components/BlackButton";
import { UserScreenStringLabels } from "../../../../util/Strings";
import { NewUserFormValidations } from "../../../../util/Validations";
import ErrorMessageComponent from "../../../common-components/ErrorMessageComponent";

import countryData from "./../../../../dummyData/CountryCodes.json";

function AddNewUserForm({ show, onClickNext, data }) {
  const [state, setState] = useState({
    name: data && data.name ? data.name : "",
    email: data && data.email ? data.email : "",
    phoneNumber: data && data.phoneNumber ? data.phoneNumber : "",
    countryData: data && data.countryData ? data.countryData : "",
  });
  const [validation, setValidation] = useState({});

  function handleChange(e) {
    let value = e.target.value;
    if (e.target.name === "email") {
      value = value.toLowerCase();
    }
    setState({
      ...state,
      [e.target.name]: value,
    });
  }
  const checkValidation = () => {
    const { errors, isValid } = NewUserFormValidations(state);
    setValidation(errors);
    return isValid;
  };

  function handleNextButton() {
    if (checkValidation()) {
      if (state.name && state.email) {
        const newData = state;
        if (data) {
          setState({
            name: newData.name,
            email: newData.email,
            phoneNumber: newData.phoneNumber,
            countryData: newData.countryData,
          });
          onClickNext(newData);
        } else {
          setState({});
          onClickNext(newData);
        }
      } else {
        onClickNext();
      }
    }
  }

  // const [jobTitle, setJobTitle] = useState(null);
  // const [company, setCompany] = useState(null);
  // const [phoneNumber, setPhoneNumber] = useState(null);
  // const [aboutMe, setAboutMe] = useState(null);
  // const [linkedInLink, setLinkedInLink] = useState(null);
  // const [image, setImage] = useState(null);
  // const dispatch = useDispatch();

  if (!show) {
    return null;
  }

  // const handleFileSelect = function (event) {
  //   const file = event.target.files[0];
  //   postFileData(file);
  // };

  // const handleDrag = function (event) {
  //   event.preventDefault();
  // };

  // const handleDrop = function (event) {
  //   event.preventDefault();
  //   const file = event.dataTransfer.files[0];
  //   postFileData(file);
  // };

  // function postFileData(file) {
  //   if (file) {
  //     dispatch(setSelectedFile(file));
  //     dispatch(fileUpload(file))
  //       .unwrap()
  //       .then((res) => {
  //         setImage(res[0]._id);
  //       });
  //   }
  // }

  return (
    <div className="modal">
      <div className="form-scroll">
        <div className="form-flex">
          <div
            className="user-form-modal-content"
            onClick={(e) => e.stopPropagation()}
          >
            <div className="form-header">
              <div className="header">
                {data
                  ? UserScreenStringLabels.userFormUpdate
                  : UserScreenStringLabels.userFormAdd}
              </div>
              <CloseButton
                onClickhandle={() => {
                  // resetImage()
                  if (data === null) {
                    setState({});
                    setValidation({});
                  }
                  onClickNext();
                }}
              />
            </div>

            <div className="form-container">
              <div className="form-label-input">
                <label className="form-label">
                  {UserScreenStringLabels.name}
                  <Required />
                </label>
                <div className="form-input-container">
                  <input
                    type="text"
                    value={state.name}
                    name="name"
                    onChange={(e) => handleChange(e)}
                    className="form-input"
                    placeholder="Input"
                    required={true}
                  ></input>
                </div>
                <ErrorMessageComponent message={validation.name} />
              </div>

              <div className="form-label-input">
                <label className="form-label">
                  {UserScreenStringLabels.email}
                  <Required />
                </label>
                <div className="form-input-container">
                  <input
                    className="form-input"
                    type="email"
                    name="email"
                    value={state.email}
                    onChange={(e) => handleChange(e)}
                    placeholder="Input"
                    required={true}
                  ></input>
                </div>
                <ErrorMessageComponent message={validation.email} />
              </div>

              <div className="form-label-input">
                <label className="form-label">
                  {UserScreenStringLabels.countryCode}
                </label>
                <select
                  value={state.countryData}
                  name="countryData"
                  onChange={(e) => handleChange(e)}
                >
                  {countryData.map((country) => (
                    <option key={country.code} value={country.code}>
                      {country.name} {country.dial_code}
                    </option>
                  ))}
                </select>
              </div>

              <div className="form-label-input">
                <label className="form-label">
                  {UserScreenStringLabels.phone}
                </label>
                <div className="form-input-container">
                  <input
                    type="text"
                    value={state.phoneNumber}
                    name="phoneNumber"
                    onChange={(e) => handleChange(e)}
                    className="form-input"
                    placeholder="Input"
                  ></input>
                </div>
              </div>

              {/* <div className="form-label-input">
            <label className="form-label">
              Job Title
              <Required />
            </label>
            <div className="form-input-container">
              <input
                className="form-input"
                type="text"
                value={jobTitle}
                onChange={(e) => setJobTitle(e.target.value)}
                placeholder="Input"
                required={true}
              ></input>
            </div>
          </div>
          <div className="form-label-input">
            <label className="form-label">
              Company
              <Required />
            </label>
            <div className="form-input-container">
              <input
                className="form-input"
                type="text"
                value={company}
                onChange={(e) => setCompany(e.target.value)}
                placeholder="Input"
                required={true}
              ></input>
            </div>
          </div>
          <div className="form-label-input">
            <label className="form-label">
              Ph#
              <Required />
            </label>
            <div className="form-input-container">
              <input
                className="form-input"
                type="tel"
                value={phoneNumber}
                onChange={(e) => setPhoneNumber(e.target.value)}
                placeholder="Input"
                required={true}
              ></input>
            </div>
          </div>
          <div className="form-label-input">
            <label className="form-label">
              About Me
              <Required />
            </label>
            <div className="user-form-input-container">
              <textarea
                className="form-input"
                type="text"
                value={aboutMe}
                onChange={(e) => setAboutMe(e.target.value)}
                placeholder="Input"
                rows={3}
              ></textarea>
            </div>
          </div>
          <div className="form-label-input">
            <label className="form-label">
              Linkedin Link
              <Required />
            </label>
            <div className="form-input-container">
              <input
                className="form-input"
                type="link"
                value={linkedInLink}
                onChange={(e) => setLinkedInLink(e.target.value)}
                placeholder="Input"
                required={true}
              ></input>
            </div>
          </div>

          <FileUploader
            previewShape="circular"
            headerName="Photo"
            handleDragOver={handleDrag}
            handleDrop={handleDrop}
            handleFileSelect={handleFileSelect}
          /> */}

              <div className="footer-button-container">
                <CancelButton
                  onClickhandle={() => {
                    // resetImage()
                    if (data === null) {
                      setState({});
                      setValidation({});
                    }
                    onClickNext();
                  }}
                >
                  {UserScreenStringLabels.cancel}
                </CancelButton>
                <BlackButton onClickhandle={() => handleNextButton()}>
                  {data
                    ? UserScreenStringLabels.update
                    : UserScreenStringLabels.add}
                </BlackButton>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default AddNewUserForm;
