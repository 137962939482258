import '../../../../styles/screens/client-visit-details/manage-module/EventsList.css';
import { RendezvousStringLabels } from '../../../../util/Strings';
import EventsListTable from './EventsListTable';

function EventsList() {
  return (
    <div className="events-list-container">
      <div className="event-list-heading">
        {RendezvousStringLabels.rendezvous}
        {/* Events List */}
      </div>
      <div className="events-list-content">
        <EventsListTable />
      </div>
    </div>
  );
}
export default EventsList;
