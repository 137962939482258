import "../../../styles/screens/client-visit-details/ProfileCard.css";
import avatar from "../../../assets/user-placeholder.png";
import "../../../styles/screens/client-visit-details/SpeakerCard.css";
import { useSelector } from "react-redux";
import { TitansOfIndustryStringLabels } from "../../../util/Strings";
import { useState } from "react";
import { useEffect } from "react";

function VisitSpeakerCard({ data, onSelectHandler, isSelected }) {
  const isSubAdmin = useSelector((state) => state.login.isSubAdmin);

  return (
    <div className="speaker-card-container">
      <div className="profile-container">
        <div className="profile">
          <div className="profile-logo-details">
            <div className="profile-logo">
              {data.photoUrl ? (
                <img src={data.photoUrl} alt="Avatar" />
              ) : (
                <img src={avatar} alt="Avatar" c />
              )}
            </div>
            <div className="profile-details">
              <div className="profile-details-name">{data.name}</div>
              <div className="profile-details-designation speaker-style">
              {data.designation === 'Others' || data.designation === 'Client' ? data.designationOther : data.designation}
              </div>
              <div className="profile-details-company">{data.practice} </div>
            </div>
          </div>
        </div>
      </div>
      {!isSubAdmin && (
        <div className="profile-video-button">
          <button
            className="speakerButtonVideo"
            style={{
              backgroundColor: isSelected ? "#474747" : "white",
              color: isSelected ? "white" : "black",
            }}
            onClick={() => onSelectHandler(data._id)}
          >
            {isSelected
              ? TitansOfIndustryStringLabels.unSelect
              : TitansOfIndustryStringLabels.select}
          </button>
        </div>
      )}
    </div>
  );
}

export default VisitSpeakerCard;
