import { useEffect } from "react";
import { useNavigate, useSearchParams } from "react-router-dom";
import { EncryptData } from "../../util/DataEncryption";
import {
  setErrorCode,
  setIsAuthenticated,
  setName,
  setSubAdmin,
} from "../../slices/LoginSlice";
import { useDispatch } from "react-redux";

function LoginPWC() {
  const [searchParams, setSearchParams] = useSearchParams();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const isSubAdmin = searchParams.get("isSubAdmin") === "false" ? false : true;
  const token = searchParams.get("token");
  const email = searchParams.get("email");
  sessionStorage.setItem("token", EncryptData(token));
  email &&
    sessionStorage.setItem("name", EncryptData(searchParams.get("email")[0]));
  sessionStorage.setItem("isSubAdmin", EncryptData(isSubAdmin));
  // sessionStorage.setItem('isAuth', EncryptData(true));
  useEffect(() => {
    const token = searchParams.get("token");
    const email = searchParams.get("email");
    const name = email ? searchParams.get("email")[0] : null;
    const error = searchParams.get("error");
    sessionStorage.setItem("token", EncryptData(token));
    email &&
      sessionStorage.setItem("name", EncryptData(searchParams.get("email")[0]));
    if (token) {
      dispatch(setIsAuthenticated(true));
      dispatch(setName(name));
      dispatch(setSubAdmin(isSubAdmin));
      navigate("/home", { replace: true });
    } else if (error === "internal_server_error") {
      dispatch(setErrorCode(500));
      sessionStorage.setItem("isAuth", EncryptData(false));
    }
  }, []);
}

export default LoginPWC;
