import "../../../styles/screens/client-visit-details/ProfileCard.css";
import avatar from "../../../assets/user-placeholder.png";
import "../../../styles/screens/client-visit-details/SpeakerCard.css";
import edit from "../../../assets/edit.svg";
import del from "../../../assets/delete.svg";
import { useState } from "react";
import AddNewSpeakerForm from "./manage-module/AddNewSpeakerForm";
import { useDispatch } from "react-redux";
import { deleteSpeaker, updateSpeaker } from "../../../slices/SpeakerSlice";
import { useSelector } from "react-redux";
import ConfirmationDialog from "../../common-components/ConfirmationDialog";
import { TitansOfIndustryStringLabels } from "../../../util/Strings";
import MediaPlayer from "../../common-components/MediaPlayer";

function SpeakerCard({ data, readOnly = false }) {
  const dispatch = useDispatch();
  const [showForm, setShowForm] = useState(false);
  const [showDelete, setShowDelete] = useState(false);
  const [showMediaPlayer, setShowMediaPlayer] = useState(false);
  const isVisitCompleted = useSelector(
    (state) => state.visits.selectedVisitIsCompleted
  );
  const isSubAdmin = useSelector((state) => state.login.isSubAdmin);
  const [type, setType] = useState(null);
  const [mediaUrl, setMediaUrl] = useState(null);

  function updateSpeakerData(newData) {
    if (newData) {
      const dataWithId = { id: data._id, newData: newData };
      dispatch(updateSpeaker(dataWithId));
    }
    setShowForm(false);
  }

  return (
    <div className="speaker-card-container">
      <div className="profile-container">
        <div className="profile">
          <div className="profile-logo-details">
            <div className="profile-logo">
              {data.photoUrl ? (
                <img src={data.photoUrl} alt="Avatar" />
              ) : (
                <img src={avatar} alt="Avatar" c />
              )}
            </div>
            <div className="profile-details">
              <div className="profile-details-name">{data.name}</div>
              <div className="profile-details-designation speaker-style">
                {data.designation === 'Others' || data.designation === 'Client' ? data.designationOther : data.designation}
              </div>
              <div className="profile-details-company">{data.practice} </div>
            </div>
          </div>
          {isVisitCompleted || isSubAdmin || readOnly ? null : (
            <div className="profile-controls">
              <button className="control-style">
                <img
                  src={edit}
                  alt="edit"
                  onClick={(e) => {
                    setShowForm(true);
                  }}
                />
              </button>
              <button>
                <img
                  src={del}
                  alt="delete"
                  onClick={() => {
                    setShowDelete(true);
                  }}
                />
              </button>
            </div>
          )}
        </div>
      </div>

      <div className="profile-description">
        <div className="profile-description-heading">
          {TitansOfIndustryStringLabels.aboutMe}
        </div>
        <div
          style={{
            overflow: "hidden",
            textOverflow: "ellipsis",
            display: "-webkit-box",
            lineClamp: 5,
            WebkitLineClamp: 5,
            WebkitBoxOrient: "vertical",
            fontSize: 14,
            fontWeight: 400,
            color: "#818181",
          }}
        >
          {data.about}
        </div>
      </div>
      <div className="profile-video-button">
        <button
          className="speakerButtonVideo"
          onClick={() => {
            setShowMediaPlayer(true);
            if (data) {
              if (data.audio) {
                setType("audio");
                setMediaUrl(data.audio);
              } else if (data.video) {
                setType("video");
                setMediaUrl(data.video);
              }
            }
          }}
        >
          {TitansOfIndustryStringLabels.viewMyVideo}
        </button>
      </div>
      <AddNewSpeakerForm
        show={showForm}
        onClickNext={updateSpeakerData}
        data={data}
      />
      <ConfirmationDialog
        show={showDelete}
        buttonName="Delete"
        onClickHandle={(flag) => {
          if (flag) {
            dispatch(deleteSpeaker(data._id));
          }
          setShowDelete(false);
        }}
        title="Delete a Collaborator"
        subTitle={`Are you sure you want to delete ${data.name} record`}
      />
      <MediaPlayer
        show={showMediaPlayer}
        onCloseHandle={() => setShowMediaPlayer(false)}
        title="Media"
        mediaUrl={mediaUrl}
        type={type}
      />
    </div>
  );
}

export default SpeakerCard;
