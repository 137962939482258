import logo from '../../../assets/Logo.svg';
import { AppBarStringLabels } from '../../../util/Strings';
import acvisitor from '../../../assets/ac-visitor.png';
function TopBar() {
  return (
    <div className="appBarOuterContainer">
      <div className="appBarLeftContainer">
        <div>
          <img src={logo} alt="pwc logo" />
        </div>
        <div className="appBarTitle">{AppBarStringLabels.title}</div>
        <div className="dividerContainer">
          <div className="divider" />
        </div>
      </div>
      <div
        className="acvisitor-logo-container"
      >
        <img className="acvisitorLogo" src={acvisitor} alt="ac visitor logo" />
      </div>
    </div>
  );
}
export default TopBar;
