import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import WelcomePageAPI from '../serviceAPI/WelcomePageAPI';
import UploadAPI from '../serviceAPI/UploadAPI';

const initialState = {
  welcomePageData: {},
  updatedWelcomePageData: {},
  isLoading: false,
  welcomeVideoId: 0,
  savedVideoId: 0,
  welcomeVideoStatus: null,
  welcomeVideoFile: {},
  welcomeVideoFileURI: '',
  showVideoProgressBar: true,
  welcomeVideoFileURL: null,
  welcomeMessageErrorMessage: '',
  welcomeEmailErrorMessage: '',
  errorCode: null,
};

export const getWelcomePageData = createAsyncThunk(
  'getWelcomePageData',
  async (id, { rejectWithValue }) => {
    try {
      const res = await WelcomePageAPI.GetWelcomePageDetails(id);
      return res;
    } catch (error) {
      return rejectWithValue(error.response.status);
    }
  }
);

export const updateWelcomeData = createAsyncThunk(
  'updateWelcomeData',
  async ({ id, newData, videoId }, { rejectWithValue }) => {
    try {
      const res = await WelcomePageAPI.UpdateWelcomePageDetails(
        id,
        newData,
        videoId
      );
      return res;
    } catch (error) {
      return rejectWithValue(error.response.status);
    }
  }
);

export const videoFileUpload = createAsyncThunk(
  'videoFileUpload',
  async (data, { rejectWithValue }) => {
    try {
      const res = await UploadAPI.UploadVideoFile(data);
      return res;
    } catch (error) {
      return rejectWithValue(error.response.status);
    }
  }
);

const WelcomePageslice = createSlice({
  name: 'welcomePage',
  initialState,
  reducers: {
    changeUpdatedWelcomePageData: (state, action) => {
      state.updatedWelcomePageData = {
        ...state.updatedWelcomePageData,
        [action.payload.name]: action.payload.value,
      };
    },
    changeWelcomemVideoStatus: (state, action) => {
      state.welcomeVideoStatus = action.payload;
    },
    setWelcomeVideoFile: (state, action) => {
      state.welcomeVideoFile = action.payload;
    },
    setShowVideoProgressBar: (state, action) => {
      state.showVideoProgressBar = action.payload;
    },
    setWelcomeFileURI: (state, action) => {
      state.welcomeVideoFileURI = action.payload;
    },
    setVideoId: (state, action) => {
      state.savedVideoId = action.payload;
    },
    cancelFileUpload: (state) => {
      state.welcomeVideoStatus = null;
      state.welcomeVideoFile = {};
      state.showVideoProgressBar = false;
      state.welcomeVideoId = 0;
    },
    resetVideoId: (state) => {
      state.welcomeVideoId = 0;
    },
    setWelcomeVideoStatus: (state, action) => {
      state.welcomeVideoStatus = action.payload;
    },
    setWelcomeMessageErrorMessage: (state, action) => {
      state.welcomeMessageErrorMessage = action.payload;
    },
    setWelcomeEmailErrorMessage: (state, action) => {
      state.welcomeEmailErrorMessage = action.payload;
    },
    setWelcomePageData: (state, action) => {
      state.welcomePageData = action.payload;
    },
    resetErrorAlert: (state, action) => {
      state.errorCode = null;
    },
  },
  extraReducers: {
    [getWelcomePageData.pending]: (state) => {
      state.isLoading = true;
    },
    [getWelcomePageData.fulfilled]: (state, action) => {
      state.isLoading = false;
      state.welcomePageData = action.payload;
      state.updatedWelcomePageData = action.payload;
      state.savedVideoId = action.payload.videoId;
      state.welcomeVideoFileURL = action.payload.videoUrl;
    },
    [getWelcomePageData.rejected]: (state, action) => {
      state.isLoading = false;
      state.errorCode = action.payload;
    },
    [updateWelcomeData.fulfilled]: (state, action) => {
      state.welcomePageData = action.payload;
      state.welcomeVideoStatus = null;
      state.welcomeVideoFile = {};
      state.welcomeVideoId = 0;
      state.welcomeVideoFileURL = action.payload.videoUrl;
    },
    [updateWelcomeData.rejected]: (state, action) => {
      state.errorCode = action.payload;
    },
    [videoFileUpload.pending]: (state) => {
      state.welcomeVideoStatus = "pending";
    },
    [videoFileUpload.fulfilled]: (state, action) => {
      state.welcomeVideoId = action.payload[0]._id;
      state.savedVideoId = action.payload[0]._id;
      state.welcomeVideoStatus = action.payload[0].fileUploadStatus;
    },
    [videoFileUpload.rejected]: (state, action) => {
      state.errorCode = action.payload;
    },
  },
});

export const {
  changeUpdatedWelcomePageData,
  changeWelcomemVideoStatus,
  setWelcomeVideoFile,
  resetVideoId,
  setShowVideoProgressBar,
  setWelcomeFileURI,
  cancelFileUpload,
  setVideoId,
  setWelcomeVideoStatus,
  setWelcomeMessageErrorMessage,
  setWelcomeEmailErrorMessage,
  setWelcomePageData,
  resetErrorAlert,
} = WelcomePageslice.actions;
export default WelcomePageslice.reducer;
