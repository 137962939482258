function FileNameFormatter(fileName) {
  const fileNameArray = fileName.split('.');
  const fileNamePart = fileNameArray[0];
  const fileExtensionPart = fileNameArray[1];

  if (fileNamePart.length > 20) {
    const truncatedFileName = fileNamePart.substring(0, 20);
    return truncatedFileName + '.' + fileExtensionPart;
  } else {
    return fileName;
  }
}

export { FileNameFormatter };
