import http from '../http-common';

const GetAllSubAdmins = async () => {
  const users = await http.get('/subAdmins').then((response) => {
    return response.data;
  });
  return users;
};

const CreateNewSubAdmin = async (newData) => {
  const auth = await http
    .post('/createAdminUser', {
      firstName: newData.firstName,
      lastName: newData.lastName,
      email: newData.email,
      password: 'Welcome@pwc01',
      isSubAdmin: true,
    })
    .then((response) => {
      return response;
    });

  return auth.data;
};

const UpdateSubAdmin = async (updateData, id) => {
  const updatedData = await http
    .put(`/updateAdmin/${id}`, {
      firstName: updateData.firstName,
      lastName: updateData.lastName,
      email: updateData.email,
    })
    .then((response) => {
      return response.data;
    });
  return updatedData;
};

const DeleteSubAdmin = async (id) => {
  const deletedData = await http
    .delete(`/deleteAdmin/${id}`)
    .then((response) => {
      return response.data;
    });
  return deletedData;
};

const SubAdminAPI = {
  UpdateSubAdmin,
  GetAllSubAdmins,
  CreateNewSubAdmin,
  DeleteSubAdmin,
};

export default SubAdminAPI;
