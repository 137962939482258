import http from "../http-common";

async function GetAllRefuelData(id) {
  const refuelData = await http.get(`/refuels/${id}`).then((response) => {
    return response.data;
  });
  return refuelData;
}

async function CreateNewRefuelData(newData, id) {
  const auth = await http
    .post(`/createRefuel/${id}`, newData)
    .then((response) => {
      return response.data;
    });

  return auth;
}

const UpdateRefuel = async (updateData, id) => {
  const updatedData = await http
    .put(`/updateRefuel/${id}`, updateData)
    .then((response) => {
      return response.data;
    });
  return updatedData;
};

const DeleteRefuel = async (id) => {
  const deletedData = await http
    .delete(`/deleteRefuel/${id}`)
    .then((response) => {
      return response.data;
    });
  return deletedData;
};

const RefuelAPI = {
  GetAllRefuelData,
  CreateNewRefuelData,
  UpdateRefuel,
  DeleteRefuel,
};
export default RefuelAPI;
